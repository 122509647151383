import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Dropdown, Button, Icon, Tab, Input, Popup } from 'semantic-ui-react';
import Header from '../header/header';
import Footer from '../footer/footer';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PayPalButton } from "react-paypal-button-v2";
import LeftSidebar from '../sidebar/left-sidebar';
import Modal from './Modal';
import NotifyComponent from '../notify/notifyComponent';
import classNames from "classnames";
import { loadStripe } from '@stripe/stripe-js'

const { SearchBar, ClearSearchButton } = Search;

const amounts = [
    { amount: 25 },
    { amount: 50 },
    { amount: 100 },
    { amount: 150 },
    { amount: 200 },
    { amount: 300 },
    { amount: 500 },
    { amount: 1000 },
    { amount: 1500 },
];

const payments = [
    { method: "C/C" },
    { method: "stripe" },
    { method: "PayPal" }
]

class Billing extends Component {
    constructor(props) {
        super(props);
        this.searchKit = {}; //React.createRef();
        this.state = {
            user: null,
            balance: '0.00',
            bonus: '0.00',
            invoicelist: [],
            movementlist: [],
            partners: [],
            orders: [],
            partnerId: '',
            partnerUser: '',
            partnerCustomer: '',
            offset: 0,
            page: 1,
            totalSize: 0,
            sizePerPage: 10,
            currentIndex: 0,
            fixed_amount: null,
            other_amount: null,
            amount: null,
            filter: {},
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false,
            invoice_payment_method: { id: 0, method: 0 },
            isAdmin: false
        }
        localStorage.setItem('api-vs-key', '');
        axios.defaults.headers.common['x-api-vs-key'] = this.token;
        this.token = localStorage.getItem('jwt');
        axios.defaults.headers.common['x-auth-token'] = this.token;
        this.isAdmin = (localStorage.getItem('isAdmin') === "true");
        this.invoice_columns = this.defineInvoiceColumns();
        this.mov_columns = this.defineMovColumns();
        this.ord_columns = this.defineOrdColumns();
        this.getOrders();
        if (this.isAdmin) {
            this.loadUsers();
        }
    }


    componentWillReceiveProps(nextProps) {
        this.setState({ balance: nextProps.userdetails.balance });
    }

    componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }

    getInvoices = (params) => {
        axios.
            get(`${process.env.REACT_APP_API_URL}/api/invoices/filter`, {
                params: {
                    currentIndex: this.state.currentIndex,
                    sizePerPage: this.state.sizePerPage,
                    partnerId: params ? params.partnerId : null
                }
            })
            .then((response) => {
                this.setState({
                    invoicelist: response.data.dataset,
                    totalSize: response.data.totalCount
                });
            })
            .catch((error) => {
                this.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
            });
    };
    getMovements = (params) => {
        if (!params) params = {};
        Object.assign(params, {
            currentIndex: this.state.currentIndex,
            sizePerPage: this.state.sizePerPage
        });
        this.setState({ filter: params });
        axios.
            get(`${process.env.REACT_APP_API_URL}/api/invoices/movements`, {
                params: params
            })
            .then((response) => {
                this.setState({
                    movementlist: response.data.dataset,
                    totalSize: response.data.totalCount
                });
            })
            .catch((error) => {
                this.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
            });
    };
    getOrders = (params) => {
        if (!params) params = {};
        Object.assign(params, {
            currentIndex: this.state.currentIndex,
            sizePerPage: this.state.sizePerPage
        });
        this.setState({ filter: params });
        axios.
            get(`${process.env.REACT_APP_API_URL}/api/billing/order`, { params: params })
            .then((response) => {
                this.setState({
                    orders: response.data.dataset,
                    totalSize: response.data.totalCount
                });
            })
            .catch((error) => {
                this.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
            });
    };

    onPartnerChange = (e, data) => {
        switch (data.name) {
            case "partnerIdInv":
                this.getInvoices({ partnerId: data.value });
                this.setState({ partnerCustomer: data.value });
                break;
            case "partnerIdMov":
                this.setState({ partnerUser: data.value });
                this.getMovements({ partnerId: data.value });
                break;
        }
    };

    onFixedAmountChange = (e, data) => {
        this.setState({
            fixed_amount: data.value,
            other_amount: '',
            amount: data.value
        });
    };
    onOtherAmountChange = (e, data) => {
        let valuetocheck = data.value
        data.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        this.setState({
            fixed_amount: null,
            other_amount: data.value,
            amount: data.value
        });
    };

    handleTableChangeInv = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        var currentIndex = (page - 1) * sizePerPage;
        if (type === 'search') {
            currentIndex = 0;
        }
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/invoices/filter`, {
                params: {
                    currentIndex: currentIndex,
                    sizePerPage: sizePerPage ? sizePerPage : this.state.sizePerPage,
                    q: searchText,
                    sortField: sortField,
                    sortOrder: sortOrder
                }
            })
            .then((response) => {
                let result = response.data.dataset;
                let totalCount = response.data.totalCount;
                this.setState(() => ({
                    invoicelist: response.data.dataset,
                    page,
                    totalSize: totalCount,
                    sizePerPage,
                    currentIndex: currentIndex
                }));
            });
    }


    handleTableChangeMov = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        var currentIndex = (page - 1) * sizePerPage;
        if (type === 'search') {
            currentIndex = 0;
        }
        this.setState({ loading: true });
        var filter = {
            currentIndex: currentIndex,
            sizePerPage: sizePerPage ? sizePerPage : this.state.sizePerPage,
            q: searchText,
            sortField: sortField,
            sortOrder: sortOrder,
            partnerId: this.state.partnerUser ? this.state.partnerUser : null
        };
        this.setState({
            filter: filter,
            page,
            sizePerPage,
            currentIndex: currentIndex,
        }, () => this.getMovements(filter));
    }

    handleTableChangeOrd = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        var currentIndex = (page - 1) * sizePerPage;
        if (type === 'search') {
            currentIndex = 0;
        }
        this.setState({ loading: true });
        var filter = {
            currentIndex: currentIndex,
            sizePerPage: sizePerPage ? sizePerPage : this.state.sizePerPage,
            q: searchText,
            sortField: sortField,
            sortOrder: sortOrder,
            partnerId: this.state.partnerUser ? this.state.partnerUser : null
        };
        this.setState({
            filter: filter,
            page,
            sizePerPage,
            currentIndex: currentIndex,
        }, () => this.getOrders(filter));
    }

    downloadInvoice = (id) => {
        this.notify("Caricamento", "info", "download", false)
        axios.get(`${process.env.REACT_APP_API_URL}/api/invoices/pdf/${id}`, { responseType: 'blob' })
            .then((response) => {
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                const [, filename] = response.headers['content-disposition'].split('filename=');
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                this.notify(<FormattedMessage id="notify.invoice.download" />, "success", "download", true)
            }).catch(e => {
                this.notify(<FormattedMessage id="notify.invoice.download.failed" />, "error", "download", true);
                console.log(e);
            });
    }

    downloadInvoices = (data, callback) => {
        this.notify("Caricamento", "info", "download", false)
        axios.get(`${process.env.REACT_APP_API_URL}/api/invoices/pdfs`, { responseType: 'blob', params: data })
            .then((response) => {
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                const [, filename] = response.headers['content-disposition'].split('filename=');
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                callback(true, null);
                this.notify(<FormattedMessage id="notify.multi-invoice.download" />, "success", "download", true)
            }).catch(e => {
                let error_desc = "";
                if (e.response.status === 504) {
                    error_desc = "no record found";
                }
                this.notify(<FormattedMessage id="notify.multi-invoice.download.failed" />, "error", "download", true)
                console.log(e.response.data);
                callback(null, e);
            });
    }

    downloadInvoicesCSV = (data, callback) => {
        this.notify("Caricamento", "info", "download", false)
        axios.get(`${process.env.REACT_APP_API_URL}/api/invoices/csv`, { responseType: 'blob', params: data })
            .then((response) => {
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                const [, filename] = response.headers['content-disposition'].split('filename=');
                link.setAttribute('download', filename.replace(/['"]+/g, ''));
                document.body.appendChild(link);
                link.click();
                callback(true, null);
                this.notify(<FormattedMessage id="notify.multi-invoiceCSV.download" />, "success", "download", true)
            }).catch(e => {
                let error_desc = "";
                if (e.response.status === 504) {
                    error_desc = "no record found";
                }
                this.notify(<FormattedMessage id="notify.multi-invoiceCSV.download.failed" />, "error", "download", true)
                console.log(e.response.data);
                callback(null, e);
            });
    }

    downloadMovements = (data, callback) => {
        this.notify("Caricamento", "info", "download", false)
        axios.get(`${process.env.REACT_APP_API_URL}/api/invoices/movements/csv`, { responseType: 'blob', params: data })
            .then((response) => {
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                const [, filename] = response.headers['content-disposition'].split('filename=');
                link.setAttribute('download', filename.replace(/['"]+/g, ''));
                document.body.appendChild(link);
                link.click();
                callback(true, null);
                this.notify(<FormattedMessage id="notify.download-movements" />, "success", "download", true)
            }).catch(e => {
                let error_desc = "";
                if (e.response.status === 504) {
                    error_desc = "no record found";
                }
                this.notify(<FormattedMessage id="notify.download-movements.failed" />, "error", "download", true)
                console.log(e.response.data);
                callback(null, e);
            });
    }

    loadUsers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then((response) => {
                this.setState({
                    partners: response.data
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    onChange = (name, val) => {
        this.setState({ [name]: val });
    }

    dateFormatter = () => {
        return {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        };
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    defineOrdColumns = () => {
        var columns = [
            {
                dataField: 'createdAt',
                sort: true,
                text: this.props.intl.formatMessage({ id: "bill.invoice_dt" }),
                formatter: (cellContent, row, rowIndex, formatExtraData) => {
                    return (
                        <div>{this.props.intl.formatDate(row.createdAt, this.dateFormatter())}</div>
                    );
                },
                classes: 'cut-words',
                headerStyle: () => { return { width: "12%" } },
            },
            {
                dataField: 'order_nr',
                text: this.props.intl.formatMessage({ id: "app.order_nr" }),
                classes: 'cut-words',
                headerClasses: 'cut-words',
                headerStyle: () => { return { width: "12%" } },
                formatter: (cellContent, row) => {
                    return(
                        <div className="textToCopy"><span className='textToShow' onClick={(e) => {e.stopPropagation();e.preventDefault()}} onMouseDown={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            let elements = document.getElementsByClassName('tagToCopy')
                            for(let element of elements) {
                                element.innerText="Copied"
                            }
                            
                            await this.sleep(500)
                            for (let element of elements) {
                                element.innerText="Copy"
                            }
                            navigator.clipboard.writeText(row.order_nr);
                            
                        }}>{row.order_nr}</span><div className="tagToCopy">Copy</div></div>
                    )
                }
            }
        ];
        if (this.isAdmin) {
            columns.push({
                dataField: 'customer.name',
                text: this.props.intl.formatMessage({ id: "app.partner" }),
                classes: 'cut-words'
            });
        };
        if (this.isAdmin) {
            columns.push({
                dataField: 'transaction',
                text: this.props.intl.formatMessage({ id: "bill.transaction" }),
                classes: 'cut-words'
            });
        };
        columns.push({
            dataField: 'request_amount',
            text: this.props.intl.formatMessage({ id: "bill.total" }),
            headerStyle: () => { return { width: "10%" } },
            classes: 'cut-words',
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                return (
                    <div>{this.props.intl.formatNumber(row.request_amount, { style: 'currency', currency: 'EUR' })}</div>
                );
            },
        });
        columns.push({
            dataField: 'status',
            text: this.props.intl.formatMessage({ id: "app.status" }),
            headerStyle: () => { return { width: "7%" } },
            formatter: (cellContent) => {
                return (
                    <div><Icon name="circle" color={cellContent === "payed" ? "green" : "yellow"} /></div>
                );
            },
        });
        columns.push({
            dataField: 'df1',
            text: '',
            isDummyField: true,
            headerStyle: (colum, colIndex) => {
                return { width: '50px' };
            },
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                return (
                    <div className="d-flex flex-row">
                        {(this.isAdmin) &&
                            <Modal type={{ Icon: "pencil square", Formtype: "order", ButtonLabel: this.props.intl.formatMessage({ id: "app.edit" }) }} formData={row} afterAction={(data, clbk) => this.editOrder(data, clbk)} notify={this.notify} />
                        }
                    </div>
                );
            },
            formatExtraData: {
                lng: this.props.language
            }
        });
        return columns;
    }

    loadAdmin = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
            .then(async (response) => {
                this.setState({
                    isAdmin: response.data.isAdmin
                });
            })
    }

    componentDidMount = async () => {
        await this.loadAdmin()
    }

    defineMovColumns = () => {
        var columns = [
            {
                dataField: 'createdAt',
                sort: true,
                text: this.props.intl.formatMessage({ id: "bill.invoice_dt" }),
                formatter: (cellContent, row, rowIndex, formatExtraData) => {
                    return (
                        <div>{this.props.intl.formatDate(row.createdAt, this.dateFormatter())}</div>
                    );
                },
                classes: 'cut-words',
                headerStyle: () => { return { width: "9%" } },
            },
            {
                dataField: 'product',
                text: this.props.intl.formatMessage({ id: "app.product" }),
                classes: 'cut-words',
                headerClasses: 'cut-words',
                headerStyle: () => { return { width: "12%" } },
            },
            {
                dataField: 'details',
                text: this.props.intl.formatMessage({ id: "app.description" }),
                classes: 'cut-words',
                headerClasses: 'cut-words',
                headerStyle: () => { return { width: "50%" } },
            }
        ];
        if (this.isAdmin) {
            columns.push({
                dataField: 'customer.name',
                text: this.props.intl.formatMessage({ id: "app.partner" }),
                classes: 'cut-words'
            });
        }
        columns.push({
            dataField: 'bonus',
            text: this.props.intl.formatMessage({ id: "app.bonus" }),
            headerStyle: () => { return { width: "10%" } },
            classes: 'cut-words',
            formatter: (cellContent) => {
                return (
                    <div>{this.props.intl.formatNumber(cellContent, { style: 'currency', currency: 'EUR' })}</div>
                );
            },
        });
        columns.push({
            dataField: 'amount',
            text: this.props.intl.formatMessage({ id: "bill.total" }),
            headerFormatter: () => {
                return (
                    <div className="d-flex">
                        <div>{this.props.intl.formatMessage({ id: "bill.amount" })}</div>
                        {(this.isAdmin) ?
                            <div className="ml-auto"><Modal type={{ Icon: "shop", Formtype: "movement", ButtonLabel: this.props.intl.formatMessage({ id: "app.new" }) }} formData={{}} addMovement={this.addMovement} notify = {this.notify} /></div>
                            : null}
                    </div>
                );
            },
            headerStyle: () => { return { width: "10%" } },
            classes: 'cut-words',
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                return (
                    <div>{this.props.intl.formatNumber(row.amount, { style: 'currency', currency: 'EUR' })}</div>
                );
            },
        });
        return columns;
    }

    onSetPaymentInvoice = (row, e, data) => {
        let cloned = JSON.parse(JSON.stringify(this.state.invoicelist));
        cloned.forEach((line) => {
            if (line.id === row.id) {
                line.new_payment_method = data.value;
            }
        });
        this.setState({
            invoice_payment_method: { id: row.id, method: data.value },
            invoicelist: cloned,
        })
    }

    setInvoiceAsPaid = (row) => {
        this.notify("Caricamento", "info", "pencil", false)
        axios.put(`${process.env.REACT_APP_API_URL}/api/invoices/${row.id}`, { method: row.new_payment_method })
            .then((response) => {
                this.getInvoices();
                this.notify(<FormattedMessage id="notify.modify-invoice" />, "success", "pencil", true)
            })
            .catch((e) => {
                this.notify(<FormattedMessage id="notify.modify-invoice.failed" />, "error", "pencil", true)
                console.log(e.response);
            });
    }

    defineInvoiceColumns = () => {
        var columns = [{
                dataField: 'invoice_nr',
                text: this.props.intl.formatMessage({ id: "bill.invoice_nr" }),
                sort: true,
            }, {
                dataField: 'invoice_date',
                sort: true,
                text: this.props.intl.formatMessage({ id: "bill.invoice_dt" }),
                formatter: (cellContent, row, rowIndex, formatExtraData) => {
                    return (
                        <div>{this.props.intl.formatDate(row.invoice_date, this.dateFormatter())}</div>
                    );
                },
                classes: 'cut-words'
            }, {
                dataField: 'df2',
                text: this.props.intl.formatMessage({ id: "bill.invoice_items" }),
                formatter: (cellContent, row, rowIndex, formatExtraData) => {
                    return (
                        <div>
                            <ul className="items">
                                {row.items.map(function (item, i) {
                                    return <li key={i}>{item.description}</li>
                                })}
                            </ul>
                        </div>
                    );
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '25%', textAlign: 'left' };
                },
                classes: 'items'
            }, {
                dataField: 'method',
                text: this.props.intl.formatMessage({ id: "bill.method" }),
                formatter: (cellContent, row, rowIndex, formatExtraData) => {
                    if (cellContent) {
                        return (
                            <div>{cellContent}</div>
                        );
                    }
                    else if (this.isAdmin) {
                        return (<div>
                            <Dropdown
                                placeholder="Pagata"
                                clearable
                                onChange={(e, data) => this.onSetPaymentInvoice(row, e, data)}
                                name="payment_method"
                                selection
                                className="paid-dropdown"
                                options={payments.map(({ method }) => ({ value: method, text: method }))}
                            />
                            {row.new_payment_method &&
                                <Button className="ml-2" icon size="small" onClick={() => this.setInvoiceAsPaid(row)} ><Icon name="save" /></Button>
                            }
                        </div>);
                    }
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '180px', textAlign: 'left' };
                },
            }, {
                dataField: 'subtotal',
                text: this.props.intl.formatMessage({ id: "bill.subtotal" }),
                classes: 'cut-words',
                headerClasses: 'cut-words',
                formatter: (cellContent, row, rowIndex, formatExtraData) => {
                    return (
                        <div>{this.props.intl.formatNumber(row.subtotal, { style: 'currency', currency: 'EUR' })}</div>
                    );
                }
            }, {
                dataField: 'vat',
                text: this.props.intl.formatMessage({ id: "bill.vat" }),
                classes: 'cut-words',
                headerStyle: (colum, colIndex) => {
                    return { width: '7%', textAlign: 'left' };
                },
                formatter: (cellContent, row, rowIndex, formatExtraData) => {
                    return (
                        <div>{this.props.intl.formatNumber(row.vat, { style: 'currency', currency: 'EUR' })}</div>
                    );
                }
            }, {
                dataField: 'total',
                text: this.props.intl.formatMessage({ id: "bill.total" }),
                classes: 'cut-words',
                formatter: (cellContent, row, rowIndex, formatExtraData) => {
                    return (
                        <div>{this.props.intl.formatNumber(row.total, { style: 'currency', currency: 'EUR' })}</div>
                    );
                },
                sort: true
            }];
        if (this.isAdmin) {
            columns.push({
                dataField: 'customer.name',
                text: this.props.intl.formatMessage({ id: "app.partner" }),
                classes: 'cut-words'
            });
        }
        columns.push({
            dataField: 'df1',
            text: '',
            isDummyField: true,
            headerStyle: (colum, colIndex) => {
                return { width: '50px' };
            },
            headerFormatter: () => {
                return (
                    <div className="d-flex flex-row">
                        {(this.isAdmin) &&
                            <div className="-ml-auto"><Modal type={{ Icon: "file pdf outline", Formtype: "invoice", ButtonLabel: this.props.intl.formatMessage({ id: "app.new" }) }} formData={{}} saveInvoice={this.saveInvoice} notify = {this.notify} /></div>
                        }
                    </div>
                );

            },
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                return (
                    <div className="d-flex flex-row">
                        <Button icon size="small" onClick={() => this.downloadInvoice(row.id)}>
                            <Icon name='file pdf' />
                        </Button>
                    </div>
                );
            },
            formatExtraData: {
                lng: this.props.language
            }
        });
        return columns;
    }

    processStripeTrx = (amount) => (token) => {
        const CURRENCY = 'EUR';
        this.notify("Caricamento", "info", "stripe", false)
        axios.
            post(`${process.env.REACT_APP_API_URL}/api/domains/stripe_payment`, {
                description: 'charge',
                source: token.id,
                currency: CURRENCY,
                amount: this.state.amount * 100
            })
            .then((response) => {
                this.notify(<FormattedMessage id="notify.stripe-pay" />, "success", "stripe", true)
                this.setState({ balance: response.data.new_balance.toFixed(2) });
            })
            .catch((error) => {
                console.log(error);
                this.notify(<FormattedMessage id="notify.stripe-pay.failed" />, "error", "stripe", true)
            });

    }
    processPayPalTrx = (data, actions) => {
        this.notify("Caricamento", "info", "paypal", false)
        actions.order.capture().then((details) => {
            axios.post(`${process.env.REACT_APP_API_URL}/api/domains/paypal_payment`, details)
                .then((response) => {
                    this.notify(<FormattedMessage id="notify.paypal-pay" />, "success", "paypal", true)
                    this.setState({ balance: response.data.new_balance.toFixed(2) });
                    this.getInvoices();
                })
                .catch((error) => {
                    console.log(error);
                    this.notify(<FormattedMessage id="notify.paypal-pay.failed" />, "error", "paypal", true)
                });
        });
    }

    getUserData = (userdata) => {
        this.setState({
            balance: userdata.balance,
            bonus: userdata.bonus
        });
    }

    onTabChange = (e, data) => {
        if (this.searchKit && this.searchKit.props) {
            this.searchKit.props.onClear();
        }
        this.setState({
            currentIndex: 0,
            partnerCustomer: '',
            partnerUser: ''
        }, () => {
            switch (data.activeIndex) {
                case 0:
                    break;
                case 1:
                    this.getInvoices();
                    break;
                case 2:
                    this.getMovements();
                    break;
                case 3:
                    this.getOrders();
                    break;
            }
        });
    }
    saveInvoice = (data, callback) => {
        this.notify("Caricamento", "info", "save", false)
        if (!this.isAdmin) { callback(null, null); }
        axios.post(`${process.env.REACT_APP_API_URL}/api/invoices/`, data)
            .then((response) => {
                this.getInvoices();
                this.notify(<FormattedMessage id="notify.save-invoice" />, "success", "save", true)
                callback(response, null);
            })
            .catch((e) => {
                this.notify(<FormattedMessage id="notify.save-invoice.failed" />, "error", "save", true)
                console.log(e.response);
                callback(null, e);
            });
    };
    addMovement = (data, callback) => {
        this.notify("Caricamento", "info", "add", false)
        if (!this.isAdmin) { callback(null, null); }
        axios.post(`${process.env.REACT_APP_API_URL}/api/billing/movement`, data)
            .then((response) => {
                this.getMovements();
                this.notify(<FormattedMessage id="notify.add-movement" />, "success", "add", true)
                callback(response, null);
            })
            .catch((e) => {
                this.notify(<FormattedMessage id="notify.add-movement.failed" />, "error", "add", true)
                console.log(e.response);
                callback(null, e);
            });
    };

    makePaymentOrder = () => {
        this.setState({
            showPaymentOrder: true
        });
        this.notify("Caricamento", "info", "add", false)
        axios.post(`${process.env.REACT_APP_API_URL}/api/billing/order`, { amount: this.state.amount })
            .then((resp) => {
                this.setState({
                    order_nr: resp.data.order.order_nr,
                    showPaymentOrder: true
                });
                this.notify(<FormattedMessage id="notify.create-payment-order" />, "success", "add", true)
            })
            .catch((e) => {
                this.notify(<FormattedMessage id="notify.create-payment-order.failed" />, "error", "add", true)
                console.log(e.response);
            });
    }
    editOrder = (data, callback) => {
        this.notify("Caricamento", "info", "pencil", false)
        axios.patch(`${process.env.REACT_APP_API_URL}/api/billing/order/${data.id}`, data)
            .then(() => {
                this.notify(<FormattedMessage id="notify.edit-order" />, "success", "pencil", true)
                this.getOrders();
                callback(true, null);
            })
            .catch((e) => {
                this.notify(<FormattedMessage id="notify.edit-order.failed" />, "error", "pencil", true)
                console.log(e.response);
                callback(null, true);
            });
    }

    stripeCheckout = async () => {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
        axios.post(`${process.env.REACT_APP_API_URL}/api/billing/create-checkout-session`, { amount: this.state.amount })
            .then((resp) => {
                return resp.data;
            })
            .then(function (session) {
                return stripe.redirectToCheckout({ sessionId: session.id });
            })
            .catch((e) => {
                console.log(e);
            });
    }


    render() {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

        const { page, sizePerPage, totalSize } = this.state;
        const sizePerPageList = [5, 10, 15, 20];
        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];
        const isAdmin = (localStorage.getItem('isAdmin') === "true");
        const { formatMessage } = this.props.intl;
        const panes = [
            {
                menuItem: this.props.intl.formatMessage({ id: "app.payment" }),
                render: () => <Tab.Pane attached={false} className="tab-no-border p-0 shadow-none">
                    <div className="row paga">

                        <div className="col-12 ml-2">
                            <div className="mb-3">
                                <Dropdown
                                    style={{display: "inline-flex", "flex-direction": "column-reverse"}}
                                    placeholder={formatMessage({ id: "app.recharge" })}
                                    clearable
                                    onChange={this.onFixedAmountChange}
                                    name="fixed_amount"
                                    selection
                                    value={this.state.fixed_amount}
                                    options={amounts.map(({ amount }) => ({ value: amount, text: amount + ' €' }))}
                                />
                                <Input value={this.state.other_amount} type="Number" min="25" className="ml-2 mr-2" style={{height: "42.33px !important", display: "inline-flex", "flex-direction": "column-reverse"}} placeholder={formatMessage({ id: "bill.other_amount" })} onChange={this.onOtherAmountChange} />
                                <i><FormattedMessage id="bill.amount_low" /></i>
                            </div>
                            <div className="-payment-wrapper">
                                {this.state.amount > 0 || true ?
                                    <div className="-payment-box">
                                        <div className="infodiv">
                                            <h5>{formatMessage({ id: "app.warning" })}</h5>
                                            <div className="kx-callout kx-callout-warning">
                                                Per nuovi pagamenti contattare Giampietro Aversa al numero <a href="tel:+39 347 845 2607" style={{color: "lightblue"}}>+39 347 845 2607</a>, oppure inviando una mail a <a href="mailto:giampietro.aversa@cloudonthecloud.com" style={{color: "lightblue"}}>giampietro.aversa@cloudonthecloud.com</a>
                                            </div>
                                        </div>
                                    </div>
                                    : null}

                            </div>
                        </div>

                    </div>
                </Tab.Pane>
            },
            this.state.isAdmin ? {
                menuItem: this.props.intl.formatMessage({ id: "bill.invoices" }),
                render: () => <Tab.Pane attached={false} className="tab-no-border p-0 shadow-none">
                    <div className="row">
                        <div className="col-12">
                            <ToolkitProvider
                                keyField="id"
                                data={this.state.invoicelist}
                                columns={this.invoice_columns}
                                defaultSorted={defaultSorted}
                                bootstrap4
                                search
                            >
                                {
                                    toolkitprops => [
                                        <div className="searchBar">
                                            <div className="searchBtn">
                                                <SearchBar ref={n => this.searchKit = n} id="s1" {...toolkitprops.searchProps} delay={1000} placeholder={formatMessage({ id: "table.search" })} />
                                                <ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
                                            </div>
                                            {isAdmin ?
                                                <div className="d-flex flex-row align-items-center">
                                                    <Modal size="medium" type={{ Icon: "file pdf", Formtype: "reports", ButtonLabel: this.props.intl.formatMessage({ id: "app.download_invoices" }) }} formData={{}} afterAction={(data, clbk) => this.downloadInvoices(data, clbk)} notify = {this.notify}/>
                                                    <Modal size="medium" type={{ Icon: "cloud download", Formtype: "reports", ButtonLabel: this.props.intl.formatMessage({ id: "app.download_invoices_CSV" }) }} formData={{}} afterAction={(data, clbk) => this.downloadInvoicesCSV(data, clbk)} notify = {this.notify} />
                                                    <Dropdown
                                                        className="parnter-list"
                                                        placeholder="Partner"
                                                        onChange={this.onPartnerChange}
                                                        name="partnerIdInv"
                                                        clearable
                                                        value={this.state.partnerCustomer}
                                                        selection
                                                        search
                                                        options={this.state.partners.map(({ id, name }) => ({ value: id, text: name }))}
                                                    />
                                                </div> : null}
                                        </div>,
                                        <div className='infodiv boxTabella'>
                                        <BootstrapTable
                                            {...toolkitprops.baseProps}
                                            remote={{ search: true, pagination: true, sort: true }}
                                            striped hover
                                            classes="table-responsive-lg"
                                            pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                                            onTableChange={this.handleTableChangeInv}
                                        /></div>
                                    ]
                                }
                            </ToolkitProvider>
                        </div>
                    </div>
                </Tab.Pane>
            } : {},
            {
                menuItem: this.props.intl.formatMessage({ id: "bill.movements" }),
                render: () => <Tab.Pane attached={false} className="tab-no-border p-0 shadow-none">
                    <div className="row">
                        <div className="col-12">
                            <ToolkitProvider
                                keyField="rowid"
                                data={this.state.movementlist}
                                columns={this.mov_columns}
                                defaultSorted={defaultSorted}
                                bootstrap4
                                search
                            >
                                {
                                    toolkitprops => [
                                        <div className="searchBar">
                                            <div className="searchBtn">
                                                <SearchBar ref={n => this.searchKit = n} {...toolkitprops.searchProps} delay={1000} placeholder={formatMessage({ id: "table.search" })} />
                                                <ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
                                            </div>
                                            <div className="d-flex flex-row align-items-center">
                                                {isAdmin || true ?
                                                    <Modal size="medium" type={{ Icon: "cloud download", Formtype: "reports", ButtonLabel: this.props.intl.formatMessage({ id: "app.download_movements" }) }} formData={{}} afterAction={(data, clbk) => this.downloadMovements(data, clbk)} />
                                                    : null}
                                                {isAdmin ?
                                                    <Dropdown
                                                        className="parnter-list"
                                                        placeholder="Partner"
                                                        onChange={this.onPartnerChange}
                                                        name="partnerIdMov"
                                                        clearable
                                                        value={this.state.partnerUser}
                                                        selection
                                                        search
                                                        options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                                                    /> : null}
                                            </div>
                                        </div>,
                                        <div className='infodiv boxTabella'>
                                        <BootstrapTable
                                            {...toolkitprops.baseProps}
                                            remote={{ search: true, pagination: true, sort: true }}
                                            striped hover
                                            classes="table-responsive-lg"
                                            pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                                            onTableChange={this.handleTableChangeMov}
                                        /></div>
                                    ]
                                }
                            </ToolkitProvider>
                        </div>
                    </div>
                </Tab.Pane >
            }, (this.state.isAdmin && {
                menuItem: this.props.intl.formatMessage({ id: "app.orders" }),
                render: () => <Tab.Pane attached={false} className="tab-no-border p-0 shadow-none">
                    <div className="row">
                        <div className="col-12">
                            <ToolkitProvider
                                keyField="rowid"
                                data={this.state.orders}
                                columns={this.ord_columns}
                                defaultSorted={defaultSorted}
                                bootstrap4
                                search
                            >
                                {
                                    toolkitprops => [
                                        <div className="searchBar">
                                            <div className="searchBtn">
                                                <SearchBar ref={n => this.searchKit = n} {...toolkitprops.searchProps} delay={1000} placeholder={formatMessage({ id: "table.search" })} />
                                                <ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
                                            </div>
                                            <div className="d-flex flex-row align-items-center">
                                                {isAdmin ?
                                                    <Dropdown
                                                        className="parnter-list"
                                                        placeholder="Partner"
                                                        onChange={this.onPartnerChange}
                                                        name="partnerIdMov"
                                                        clearable
                                                        value={this.state.partnerUser}
                                                        selection
                                                        search
                                                        options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                                                    /> : null}
                                            </div>
                                        </div>,
                                        <div className='infodiv boxTabella'>
                                        <BootstrapTable
                                            {...toolkitprops.baseProps}
                                            remote={{ search: true, pagination: true, sort: true }}
                                            striped hover
                                            classes="table-responsive-lg"
                                            pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                                            onTableChange={this.handleTableChangeOrd}
                                        /></div>
                                    ]
                                }
                            </ToolkitProvider>
                        </div>
                    </div>
                </Tab.Pane >
            })
        ];
        return (
            <div className="kx-page" >
                <Header history={this.props.history} language={this.props.language} changeLanguage={this.props.changeLanguage} getUserData={this.getUserData} />
                <div className="with-nav-bar with-left-nav">
                    <div className="">
                        <div className="kx-left-nav">
                            <LeftSidebar history={this.props.history} location={this.props.location}
                                styles={{ sidebar: { background: "blue" } }} />
                        </div>
                    </div>
                    <div className="kx-content">
                        <div className="kx-container">
                            <div className="row">
                                <div className="col-12 pt-0 pb-2">
                                    <div className="row -paga">
                                        <div className="col-12">
                                            <h5 className="saldo-info pr-2">
                                                <span>{formatMessage({id: "app.balance"})} <span className="orange-color">&euro; {this.state.balance}</span></span>
                                                <span className="ml-3">Bonus: <span className="orange-color">&euro; {this.state.bonus}</span></span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <Tab menu={{ secondary: true, pointing: true }} panes={panes} onTabChange={this.onTabChange} className="tab-no-border " />
                        </div>
                    </div>
                </div>
                {
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
                <Footer />
            </div >
        )
    }
}

export default injectIntl(Billing);
