import React, { Component } from 'react';
import axios from 'axios';
import { Form, Modal, Dropdown, Button, Icon, Input } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';


class ModalChangeApi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apikey : ""
        }
    }
    
    componentDidMount(){
    }
    

    save = async () =>{
        this.props.notify("Caricamento", "info", "add", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vm/changeapi`, {id:this.props.id, apikey: this.state.apikey})
            .then((response) => {
                this.props.notify("API key correttamente modificata", "success", 'add', true)
                this.props.onClose()
                })
            .catch((err) => {
                console.log(err);
            })
    }

    onChangeApi = async (e, data) => {
        await this.setState({ apikey: data.value });
    };


    render() {
        const { open, onClose, type } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    style = {{width: "30%"}}
                >
                <Modal.Header>
                    Modifica API Key
                </Modal.Header>
                    <Modal.Content>
                        <Form className='ConfiguratorForms'>
                            <Form.Input  onChange={this.onChangeApi} value={this.state.apikey} label={"Apikey:"} />
                            <Form.Button  onClick={this.save} className="save-button">
                                {formatMessage({ id: "app.save" })}
                            </Form.Button>
                        </Form>
                    </Modal.Content>
                </Modal>               
            </div>
        );
    }
}

export default injectIntl(ModalChangeApi);
