import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Modal, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import './configuration.scss';
import axios from 'axios';

class ModalEditISP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            server_id: -1,
            server_product: "",
            serversArray: [],
            id: -1,
            description: "",
            dns_server_username: "",
            dns_server_password: "",
            server_password: "",
            soap_location: "",
            soap_uri: "",
            ns1: "",
            ns1_ip: "",
            ns2: "",
            ns2_ip: "",
            partnerid: -1,
            partner: "",
            public: false,
            partners: [],
            site: false,
            mail: false,
            ns: false,
            checkDisabled: true,
            password_api: "",
            isp_password: "",
            mysql_password: "",
            inputType: "password",
            inputType2: "password",
            inputType3: "password",
            inputType4: "password"
            
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    loadPartners = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then((response) => {
                var partners = response.data.map(({ id, userid, name }) => ({ key: id, value: userid, text: name }));
                this.setState({
                    partners: partners
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    onChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    handleInputChange(event, { name, value }) {
        if (name == "server_id"){
            for(let server of this.state.serversArray){
                if(server.server_id == value){
                    this.setState({
                        server_product: server.server_product,
                        checkDisabled: false,
                        site: server.server_product ? server.server_product.includes("web") : false,
                        mail: server.server_product ? server.server_product.includes("mail") : false,
                        ns: server.server_product ? server.server_product.includes("ns") : false
                    })
                }
            }
            
        }
        this.setState({
            [name]: value,
            
        });
    }

    getServers = async() => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/getallserversfromdb`)
            .then(async (response) => {
                if(this.props.data) {
                    for(let server of response.data){
                        if(server.isp_id == this.props.data.id){
                            this.setState({server_id: server.server_id, server_product: server.server_product, server_password: server.root_password, checkDisabled: false})
                        }
                    }   
                }
                
                this.setState({serversArray: response.data})
            })
            .catch((err) => {
                console.log(err);
            })
    }

    addISPServer = async (data) => {
        this.props.notify("Caricamento", "info", "add", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/settings/ispconfig`, data
        ).then((response) => {
            this.props.notify("La configurazione è stata creata", "success", 'add', true);
        }).catch(e => {
            this.props.notify("L'errore durante salvataggio", "error", 'add', true);
        });
    }

    modifyISPServer = async (data) => {
        this.props.notify("Caricamento", "info", "pencil", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/settings/ispconfig`, data)
            .then((response) => {
                this.props.notify("La configurazione è stata salvata", "success", 'pencil', true);
            }).catch(e => {
                this.props.notify("L'errore durante salvataggio", "error", 'pencil', true);
            });
    }

    updateIPConfig = async () => {
        // validate

        if(this.props.data) {
            let payload = {
                id: this.props.data.id,
                description: this.state.description,
                dns_server_username: this.state.dns_server_username,
                dns_server_password: this.state.dns_server_password,
                server_password: this.state.server_password,
                soap_location: this.state.soap_location,
                soap_uri: this.state.soap_uri,
                ns1: this.state.ns1,
                ns1_ip: this.state.ns1_ip,
                ns2: this.state.ns2,
                ns2_ip: this.state.ns2_ip,
                userid: this.state.partnerid,
                web: this.state.site,
                mail: this.state.mail,
                ns: this.state.ns,
                isp_password: this.state.isp_password,
                mysql_password: this.state.mysql_password,
                public: this.state.public,
                server_id: this.state.server_id,
            }
            await this.modifyISPServer(payload)
        } else {
            let payload = {
                description: this.state.description,
                dns_server_username: this.state.dns_server_username,
                dns_server_password: this.state.dns_server_password,
                server_password: this.state.server_password,
                soap_location: this.state.soap_location,
                soap_uri: this.state.soap_uri,
                ns1: this.state.ns1,
                ns1_ip: this.state.ns1_ip,
                ns2: this.state.ns2,
                ns2_ip: this.state.ns2_ip,
                userid: this.state.partnerid,
                web: this.state.site,
                mail: this.state.mail,
                ns: this.state.ns,
                isp_password: this.state.isp_password,
                mysql_password: this.state.mysql_password,
                public: this.state.public,
                server_id: this.state.server_id,
            }
            await this.addISPServer(payload)
        }
        this.props.onClose()
    }

    firstSetUp = async () => {
        if(!this.state.statewritten && this.props.open){
            await this.getServers()
            await this.loadPartners()
            if(this.props.data) {
                this.setState({
                    description: this.props.data.description,
                    dns_server_username: this.props.data.dns_server_username,
                    dns_server_password: this.props.data.dns_server_password || "",
                    isp_password: this.props.data.isp_password || "",
                    mysql_password: this.props.data.mysql_password || "",
                    soap_location: this.props.data.soap_location,
                    soap_uri: this.props.data.soap_uri,
                    partnerid: this.props.data.userid,
                    partner: this.props.data.user,
                    public: this.props.data.public,
                    statewritten: true,
                    site: this.state.server_product ? this.state.server_product.includes("web") : "",
                    mail: this.state.server_product ? this.state.server_product.includes("mail") : "",
                    ns: this.state.server_product ? this.state.server_product.includes("ns") : ""
                })
                if(this.state.server_product){
                    if(this.state.server_product.includes('ns')) {
                        this.setState({
                            ns1: this.props.data.ns1,
                            ns1_ip: this.props.data.ns1_ip,
                            ns2: this.props.data.ns2,
                            ns2_ip: this.props.data.ns2_ip
                        })
                    }
                }
            } else {
                this.setState({
                    statewritten: true
                })
            }
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                server_id: -1,
                server_product: "",
                serversArray: [],
                id: -1,
                description: "",
                dns_server_username: "",
                dns_server_password: "",
                server_password: "",
                soap_location: "",
                soap_uri: "",
                ns1: "",
                ns1_ip: "",
                ns2: "",
                ns2_ip: "",
                partnerid: -1,
                partner: "",
                public: false,
                partners: [],
                site: false,
                mail: false,
                ns: false,
                checkDisabled: true,
                password_api: "",
                isp_password: "",
                mysql_password: "",
                statewritten: false,
                inputType: "password",
                inputType2: "password",
                inputType3: "password"
            })
        }
    }

    switchPassword = () => {
        if(this.state.inputType == "password") {
            this.setState({inputType: "text"})
        } else {
            this.setState({inputType: "password"})
        }
    }

    switchPassword2 = () => {
        if(this.state.inputType2 == "password") {
            this.setState({inputType2: "text"})
        } else {
            this.setState({inputType2: "password"})
        }
    }

    switchPassword3 = () => {
        if(this.state.inputType3 == "password") {
            this.setState({inputType3: "text"})
        } else {
            this.setState({inputType3: "password"})
        }
    }

    switchPassword4 = () => {
        if(this.state.inputType4 == "password") {
            this.setState({inputType4: "text"})
        } else {
            this.setState({inputType4: "password"})
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { data, open, onClose } = this.props;
        this.firstSetUp()
        return (
            <Modal 
                closeIcon={true}
                closeOnDimmerClick={true}
                style={{ margin: '0 auto', width: '50%' }}
                open={open}
                onClose={() => onClose(false)}
            >
                <Modal.Header>
                    {data ? "Modifica ISP" : "Aggiungi ISP"}
                </Modal.Header>
                <Modal.Content className="modal-min-height">
                    <Modal.Description>
                        <Form className="isp-form">
                            <Form.Group>
                                <Form.Input label="Descrizione" name="description" value={this.state.description} onChange={this.handleInputChange} />
                                <Form.Input label="Utente" name="dns_server_username" value={this.state.dns_server_username} onChange={this.handleInputChange}/>
                                <Form.Input label="Password API" type={this.state.inputType} icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword()} />} iconPosition='right' name="dns_server_password" value={this.state.dns_server_password} onChange={this.handleInputChange}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Input label="Password Server" type={this.state.inputType4} icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword4()} />} iconPosition='right' name="server_password" value={this.state.server_password} onChange={this.handleInputChange}/>
                                <Form.Input label="Password ISP" type={this.state.inputType2} icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword2()} />} iconPosition='right' name="isp_password" value={this.state.isp_password} onChange={this.handleInputChange}/>
                                <Form.Input label="Password MySQL" type={this.state.inputType3} icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword3()} />} iconPosition='right' name="mysql_password" value={this.state.mysql_password} onChange={this.handleInputChange}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Input label="Soap location" name="soap_location" value={this.state.soap_location} onChange={this.handleInputChange}  />
                                <Form.Input label="Soap uri" name="soap_uri" value={this.state.soap_uri} onChange={this.handleInputChange}  />
                            </Form.Group>
                            <Form.Group>
                                <Form.Dropdown id="servers"
                                    placeholder="Server"
                                    label="Server"
                                    name="server_id"
                                    value={this.state.server_id}
                                    onChange={this.handleInputChange}
                                    fluid
                                    selection
                                    search
                                    options={this.state.serversArray ? this.state.serversArray.map(({server_id, server}) => ({value: server_id, text: server})) : []}
                                />
                                <Form.Dropdown id="partners"
                                    placeholder="partner"
                                    label="Partner"
                                    name="partnerid"
                                    value={this.state.partnerid}
                                    onChange={this.handleInputChange}
                                    fluid
                                    selection
                                    search
                                    options={this.state.partners}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Checkbox disabled={this.state.checkDisabled} label="Site" toggle checked = {this.state.site} onClick={(e) => this.setState({site: !this.state.site})}/>
                                <Form.Checkbox disabled={this.state.checkDisabled} label="Mail" toggle checked = {this.state.mail} onClick={(e) => this.setState({mail: !this.state.mail})}/>
                                <Form.Checkbox disabled={this.state.checkDisabled} label="Nameserver" toggle checked = {this.state.ns} onClick={(e) => this.setState({ns: !this.state.ns})}/>
                            </Form.Group>
                            {this.state.ns ? <Form.Group>
                                <Form.Input label={formatMessage({ id: "isp.ns1" })} name="ns1" value={this.state.ns1} onChange={this.handleInputChange}/>
                                <Form.Input label={formatMessage({ id: "isp.ns1_ip" })} name="ns1_ip" value={this.state.ns1_ip} onChange={this.handleInputChange}/>
                            </Form.Group> : null}
                            {this.state.ns ? <Form.Group>
                                <Form.Input label={formatMessage({ id: "isp.ns2" })} name="ns2" value={this.state.ns2} onChange={this.handleInputChange}/>
                                <Form.Input label={formatMessage({ id: "isp.ns2_ip" })} name="ns2_ip" value={this.state.ns2_ip} onChange={this.handleInputChange}/>
                            </Form.Group> : null}
                            <Form.Group id="submit">                            
                                <Form.Checkbox name="public" checked={this.state.public} label={formatMessage({ id: "srv.make_visible" })} onChange={() => this.setState({public: !this.state.public})} />                           
                                <Form.Button floated="right" onClick={() => this.updateIPConfig()}>
                                    {data ? formatMessage({ id: "app.edit" }) : formatMessage({ id: "app.add" })}
                                </Form.Button>
                        </Form.Group>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }

}
export default injectIntl(ModalEditISP);