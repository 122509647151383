import React, { Component } from 'react';
import './domains.scss';

import axios from 'axios';

import LeftSidebar from '../sidebar/left-sidebar'
import Header from '../header/header';
import List from '../list/list';
import Footer from '../footer/footer';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Dropdown, Dimmer, Loader, Form, Button, Popup } from 'semantic-ui-react';
import Modal from '../All/ModalWithoutButton';
import ModalInfoDomain from '../All/ModalWithoutButtonDomain';
import ModalConfirmRenew from '../All/ModalConfirmRenew';
import ModalAuthDomain from '../All/ModalAuthDomain';
import ModalMigrateDomain from '../All/ModalMigrateDomain';
import moment from 'moment';
import ModalChangeDns from './modalChangeDns';
import ModalRetryAction from './modalRetryAction';
import ModalReAssignDomain from './ModalReAssignDomain';
import CallToBuy from '../call-to-buy/callToBuy';
import ModalWarning from '../vmservers/modalWarning';
import NotifyComponent from '../notify/notifyComponent';

const { SearchBar, ClearSearchButton } = Search;

class Domains extends Component {
	constructor(props) {
		super(props);
		localStorage.setItem('api-vs-key', '');
		axios.defaults.headers.common['x-api-vs-key'] = this.token;
		this.token = localStorage.getItem('jwt');
		axios.defaults.headers.common['x-auth-token'] = this.token;
		this.isAdmin = (localStorage.getItem('isAdmin') === "true");
		this.updateOffset = this.updateOffset.bind(this);
		this.state = {
			id: -1,
			userid: "",
			domains: [],
			offset: 0,
			page: 1,
			totalSize: 0,
			sizePerPage: 10,
			currentIndex: 0,
			partners: [],
			partnerId: '',
			nsOpened: false,
			renewOpened: false,
			infoOpened: false,
			selectedDomain: {},
			renewDomainInfo: {},
			balance: 0,
			loading: true,
			filter: {},
			autorenew: 0,
			balancealert: 0,
			isSecondLevelUser: false,
			canAdd: true,
			modalAssign: false,
			firstLevelUserID: -1,
			assignValues: {},
			domainSelected: {},
			modalRetryActionOpen: false,
			noDomains: false,
			domainToDelete: {},
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
		};
		if (this.token) {
			if (this.isAdmin) {
				this.loadUsers();
			}
		}
		/* columns definition */
		this.columns = [
		{
			dataField: 'domainstatus',
			text: this.props.intl.formatMessage({ id: "domain.status" }),
			sort: true,
			formatter: (cellContent, row) => {
				let colorDiv = "ui green horizontal label"
				let assigned = this.props.intl.formatMessage({ id: "domain.assigned" })
				if(!this.state.isSecondLevelUser){
					if(row.assignedTo == "Non Assegnato") {
						assigned = this.props.intl.formatMessage({ id: "domain.notassigned"});
					} else {
						assigned = assigned + " " + row.assignedTo;
					}
				};
				let renew = moment(row.last_renew);
				let now = moment();
				if (cellContent.includes("EXPIRING")) {
					if(now.diff(renew, 'days') < 60){
						colorDiv = "ui green horizontal label";
						return (<div><div className={colorDiv}>{cellContent}</div><br></br>{!this.state.isSecondLevelUser ? <div>Multitenancy: {assigned}</div> : null}</div>);
					}else {
						colorDiv = "ui red horizontal label";
						return (<div><div className={colorDiv}>{cellContent}</div><br></br>{!this.state.isSecondLevelUser ? <div>Multitenancy: {assigned}</div> : null}</div>);
					}
				} else if (cellContent.includes("EXPIRED") || cellContent.includes("FAILED")){
					colorDiv = "ui red horizontal label";
					return (<div><div className={colorDiv}>{cellContent}</div><br></br>{!this.state.isSecondLevelUser ? <div>Multitenancy: {assigned}</div> : null}</div>);
				} else if (cellContent ==="PENDING_TRANSFER") {
					colorDiv = "ui blue horizontal label";
					return (<div><div className={colorDiv}>{cellContent}</div><br></br>{!this.state.isSecondLevelUser ? <div>Multitenancy: {assigned}</div> : null}</div>);
				} else if (cellContent ==="ACTION_REQUIRED") {
					colorDiv = "ui red horizontal label";
					return (<Popup className='toolt' trigger={<div><div className={colorDiv}>{"ACTION REQUIRED"}</div><br></br>{!this.state.isSecondLevelUser ? <div>Multitenancy: {assigned}</div> : null}</div>} content={row.transferstatus}/>);
				} else if (cellContent ==="NOT_REGISTERED") {
					colorDiv = "ui red horizontal label";
					return (<Popup className='toolt' trigger={<div><div className={colorDiv}>{"NOT_REGISTERED"}</div><br></br>{!this.state.isSecondLevelUser ? <div>Multitenancy: {assigned}</div> : null}</div>} content={row.transferstatus}/>);
				}  else if (cellContent.includes("INACTIVE")) {
					colorDiv = "ui yellow horizontal label";
					return (<div><div className={colorDiv}>{cellContent}</div><br></br>{!this.state.isSecondLevelUser ? <div>Multitenancy: {assigned}</div> : null}</div>);
				} else if (cellContent.includes("ACTIVE")) {
					colorDiv = "ui green horizontal label";
					return (<div><div className={colorDiv}>{cellContent}</div><br></br>{!this.state.isSecondLevelUser ? <div>Multitenancy: {assigned}</div> : null}</div>);
				} else {
					colorDiv = "ui blue horizontal label";
					return (<div><div className={colorDiv}>{cellContent}</div><br></br>{!this.state.isSecondLevelUser ? <div>Multitenancy: {assigned}</div> : null}</div>);
				}
			},
		}, {
			dataField: 'name',
			text: this.props.intl.formatMessage({ id: "domain.nameServer" }),
			sort: true,
			headerStyle: (colum, colIndex) => {
                return { width: '30%' };
            },
			formatter : (val, row) => {
				return(
					<div><div className="textToCopy"><span className='textToShow' onClick={(e) => {e.stopPropagation();e.preventDefault()}} onMouseDown={async (e) => {
						e.stopPropagation();
						e.preventDefault();
						let elements = document.getElementsByClassName('tagToCopy')
						for(let element of elements) {
							element.innerText="Copied"
						}
						
						await this.sleep(500)
						for (let element of elements) {
							element.innerText="Copy"
						}
						navigator.clipboard.writeText(row.name);
						
					}}><span><strong>{row.name}</strong></span></span><div className="tagToCopy">Copy</div></div><span>{row.registrar == "internetbs" ? "IBS" : row.registrar}</span></div>
				);
			}
		}, {
			dataField: 'expires',
			text: this.props.intl.formatMessage({ id: "domain.expiry" }),
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '100px', textAlign: 'center' };
			},
			formatter: (cellContent, row) => {
				let extraCSS = 'notRed';
				cellContent = moment(row.expires).format("YYYY-MM-DD");
				if (row.alert === 'RENEW') {
					extraCSS = 'isRed';
				}
				if (cellContent) {
					return (<span id={extraCSS} ><FormattedDate value={cellContent} year='numeric' month='2-digit' day='2-digit' /></span>)
				}
			}
		}]
		if (this.isAdmin) {
			this.columns.push({
				dataField: 'user.name',
				text: this.props.intl.formatMessage({ id: "app.partner" }),
				style: { "min-width": "200px", "max-width": "200px", "word-break":"break-word"},
				formatter: (cellContent, row) => {
					return <Button className="buttonAction" onClick = {(e) => {e.preventDefault(); e.stopPropagation(); this.changeAccount(row)}}> {cellContent} </Button>
				}
			});
		};
	}

	sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

	check24Toggle = (row) => {
		let expdate = moment(row.expires, "YYYY-MM-DD");
		let now = moment();
		let delta = moment.duration(expdate.diff(now)).asHours();
		let tld = ""
		let dotfound = false
		for(let ch of row.name){
			if(ch == '.'){
				dotfound = true
			}
			if(dotfound){
				tld+=ch
			}
		}
		let check = 24
		if(tld == '.se'){
			check = 240
		}
		if(delta <= check || !row.expires || !row.registered || row.expires == '0000-00-00'){
			return false;
		}
		else{
			return true;
		}
	}
	
	checkSecondLevel = async () =>{
		await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
            .then(async (response) => {
                await this.setState({
                    isSecondLevelUser: response.data.isseconduser,
					id: response.data.id,
					userid: response.data.userid,
					balance: response.data.balance
                });
				if(this.state.isSecondLevelUser === false){
					this.loadDomains();
				}
				else{
					await axios.post(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondleveltofirstleveluserid?id=${this.state.id}`)
					.then(async (response) => {
						await this.setState({
							firstLevelUserID: response.data
						})
					})
					await this.setState({
						canAdd : false
					})
					await axios.get(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelusers?id=${this.state.id}`)
						.then((response) => {
							if(response.data.domains === true){
								this.loadSecondLevelDomains();
							}
						})
				}
				await this.setState({ loading: false });
            })
		this.defineColumns();
	}

	componentDidMount() {
		this.checkSecondLevel();
	}

	assign = (row) => {
		this.setState({
			modalAssign: true,
			assignValues: row
		})
	}
	assignClose = () =>{
		this.setState({
			modalAssign: false
		})
	}

	changeAccount = async (row) => {
		await axios.post(`${process.env.REACT_APP_API_URL}/api/contact/adminauth`, {userid: row.userid})
            .then((response) => {
                if(!localStorage.getItem("baseuser")){
                    localStorage.setItem("baseuser", localStorage.getItem("user"))
                    localStorage.setItem("basejwt", localStorage.getItem("jwt"))
                }
                localStorage.setItem("jwt", response.data.token);
                localStorage.setItem("isAdmin", false);
                localStorage.setItem("user", response.data.user);
                localStorage.setItem("restore", true);
                localStorage.setItem("multitenancy", response.data.multitenancy);
                window.location.reload();
            })
	}

	handleAutorenewChange = async (row) => {
		if (row.autorenew === 0) {
				await axios.patch(`${process.env.REACT_APP_API_URL}/api/domains/${row.id}/autorenew`, {autorenew: 1})
				.then( async (response) => {
					if (row.registrar === "internetbs") {
						axios.post(`${process.env.REACT_APP_API_URL}/api/domains/autorenew`, {autorinnovosiono: "yes", domain: row.name})
					}
					if (row.registrar === "ascio"){
						axios.post(`${process.env.REACT_APP_API_URL}/api/domains/renewAscio`, {toggle: "unexpire", domainName: row.name})
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		} else {
				await axios.patch(`${process.env.REACT_APP_API_URL}/api/domains/${row.id}/autorenew`, {autorenew: 0})
				.then(async (response) => {
					if (row.registrar === "internetbs") {
						axios.post(`${process.env.REACT_APP_API_URL}/api/domains/autorenew`, {autorinnovosiono: "no", domain: row.name})
					}
					if (row.registrar === "ascio"){
						axios.post(`${process.env.REACT_APP_API_URL}/api/domains/renewAscio`, {toggle: "expire", domainName: row.name})
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		}
		if (this.state.isSecondLevelUser) {
			this.loadSecondLevelDomains();
		} else {
			this.loadDomains(this.state.filter)
		}
    }

	handlechecked = () => {
        return true
    }

	updateOffset(direction) {
		if (direction === 'up') {
			this.setState({ offset: this.state.offset + 8 });
		} else {
			this.setState({ offset: this.state.offset - 8 });
		}
	}

	componentWillUnmount = () => {
		window.stop();
		window.clearTimeout()
	}

	notify = (content, type, icon, completed) => {
		if(!this.state.openNotify) {            
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed,
				openNotify: true
			}, async () => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
		} else {
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed
			}, async() => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
			
		}
	}

	domaindns = (domain) => {
		this.props.history.push("domaindns", { domainid: domain.id, domain: domain.name, isp_id: domain.isp_id, language: this.props.language })
	}

	changeOwner = (domain) => {
		this.props.history.push("changeowner", { domainid: domain.id, domain: domain.name, language: this.props.language })
	}

	/* renew management */
	payRenewDomain = (domain) => {
		this.setState({ renewDomainInfo: domain, renewOpened: true });
	}
	renewConfirmConfig = {
		type: "confirm",
		color: "blue",
		btn_label: this.props.intl.formatMessage({ id: "app.pay_from_account" }),
		btn_size: 'normal',
		hideTrigger: true
	}
	onConfirmOpen = () => {
		return {
			balance: this.state.balance,
			name: this.props.intl.formatMessage({ id: "app.renew" }),
			description: this.state.renewDomainInfo.name
		};
	}
	doRenewDomain = (domain, duration) => {
		this.notify("Caricamento", "info", "undo", false)
		axios.post(`${process.env.REACT_APP_API_URL}/api/domains/renew`, { domain: domain.name, duration: duration })
			.then((response) => {
				this.setState({ amount: response.data.amount });
				//this.notify(`Domain ${domain.name} has been renewed`, 'success');
				this.notify(this.props.intl.formatMessage({ id: "app.success_renew" }, { domain: domain.name }), 'success', "undo", true);
				this.loadDomains(this.state.filter);
			})
			.catch((error) => {
				console.log(error)
				//this.notify('Problem with domain renew', 'danger');
				this.notify(this.props.intl.formatMessage({ id: "app.err_renew" }), 'error', "undo", true);
			});
		this.onRenewClose();
	}
	onRenewClose = () => this.setState({ renewOpened: false });
	/* end renew management */

	changeNS = (domain) => {
		this.setState({ selectedDomain: domain, nsOpened: true });
	}

	loadDomains = (params) => {
		this.setState({ filter: params })
		axios
			.get(`${process.env.REACT_APP_API_URL}/api/domains`, {
				params: params
			})
			.then((response) => {
				if(response.data.dataset.length == 0 && !params) {
					this.setState({noDomains: true})
				} else {
					let totalCount = response.data.totalCount;
					this.setState(() => ({
						domains: response.data.dataset,
						totalSize: totalCount,
						loading: false,
						noDomains: false
					}))
				}
			})
			.catch(function (error) {
				console.log(error)
				this.setState({ loading: false });
			});
	};

	loadSecondLevelDomains = async (params) => {
		await axios.get(`${process.env.REACT_APP_API_URL}/api/domains/secondLevelDomains?firstLevelUserID=${this.state.firstLevelUserID}&id=${this.state.id}`, {params: params})
			.then( async (response) => {
				let totalCount = response.data.totalCount;
				await this.setState(() => ({
					domains: response.data.dataset,
					totalSize: totalCount,
					loading: false
				}))
			}).catch(async function (error) {
				console.log(error)
				await this.setState({ loading: false });
			});
	}

	handleAutomigrateChange = async (row, checked) => {
		await axios.post(`${process.env.REACT_APP_API_URL}/api/domains/automigratetoggle`, {domainName: row.name})
		this.loadDomains(this.state.filter)
	}

	changeDNSatMigrationSubmit = async(row) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/domains/changednsatmigration`, {check: false, domainid: row.id})
	}

	changeDNSatMigration = async (row) => {
		if(row.changeDNSatmigration){
			await this.changeDNSatMigrationSubmit(row)
			this.loadDomains()
		}
		else{
			this.setState({
				domainSelected: row,
				modalChangeDnsOpen : true
			})
		}
	}

	retryAction = (row) => {
		this.setState({
			domainSelected : row,
			modalRetryActionOpen : true		
		})
	}

	defineColumns = async () => {
		this.columns.push({
			dataField: '',
			text: this.props.intl.formatMessage({ id: "app.operation" }),
			headerStyle: (colum, colIndex) => {
				return { width: '200px' };
			},
			formatter: (val, row) => {
				if(this.check24Toggle(row)){
					return (
						<div>
							<Form.Checkbox style={{margin: "2px"}} className="to-bottom" toggle name="autorenew" checked={row.autorenew} label={this.props.intl.formatMessage({ id: "app.autorenew" })} onChange={() => this.handleAutorenewChange(row)}  />
							<Popup className="toolt" content={this.props.intl.formatMessage({id: "app.migrationtooltip"})} position="bottom center" trigger ={<Form.Checkbox style={{margin: "2px"}} className="to-bottom checkdns" toggle name="automigration" checked={row.automigrate} label={this.props.intl.formatMessage({ id: "app.automigration" })} onChange={(e, {checked}) => this.handleAutomigrateChange(row,checked)}  />} />
							{row.automigrate ? <Form.Checkbox className = "changednsatmigration" style={{margin: "2px"}} label = {this.props.intl.formatMessage({id: "dns.migrate"})} checked = {row.changeDNSatmigration} onChange = {() => {this.changeDNSatMigration(row)}}/> : null}						
						</div>
					)
				}
				else{
					return (
						<div>
							<Form.Checkbox style={{margin: "2px"}} className="to-bottom" toggle name="autorenew" checked={row.autorenew} label={this.props.intl.formatMessage({ id: "app.autorenew" })} onChange={() => this.handleAutorenewChange(row)} disabled  />
							<Popup className="toolt" content={this.props.intl.formatMessage({id: "app.migrationtooltip"})} position="bottom center" trigger ={<Form.Checkbox style={{margin: "2px"}} className="to-bottom checkdns" toggle name="automigration" checked={row.automigrate} label={this.props.intl.formatMessage({ id: "app.automigration" })} onChange={(e, {checked}) => this.handleAutomigrateChange(row,checked)}  />} /> 
							{row.automigrate ? <Form.Checkbox className = "changednsatmigration" style={{margin: "2px"}} label = {this.props.intl.formatMessage({id: "dns.migrate"})} checked = {row.changeDNSatmigration} onChange = {() => {this.changeDNSatMigration(row)}}/> : null}						
						</div>                  
					)}
				}
			}
		)
		this.columns.push({
			dataField: 'action',
			text: this.props.intl.formatMessage({ id: "app.action" }),
			headerStyle: (colum, colIndex) => {
				return { width: '40%' };
			},
			formatter: (val, row) => {
				let authinfovisible = true
				let expires = moment(row.expires);
				let now = moment();
				let difference = expires.diff(now, 'days')
					if(difference < 8){
						authinfovisible = false
					}
				return (
					<div className="buttonDomains">
						<Button className="buttonAction" style={{width: "210px"}} onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.authCode(row)}} >{this.props.intl.formatMessage({id: "btn.authcode"})}</Button>
						{!this.state.isSecondLevelUser ? <Button className="buttonAction" style={{width: "280px"}} onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.changeOwner(row)}}>{this.props.intl.formatMessage({id: "btn.changeowner"})}</Button> : null}
						<Button className="buttonAction" onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.domaindns(row)}}>{this.props.intl.formatMessage({id: "btn.dns"})}</Button>
						<Button className="buttonAction" style={{width: "260px"}} onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.changeNS(row)}}>{this.props.intl.formatMessage({id: "btn.nameserver"})}</Button>
						{!this.state.isSecondLevelUser && row.alert === 'RENEW' && !row.autorenew ? <Button onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.payRenewDomain(row)}} >{this.props.intl.formatMessage({ id: 'app.renew' })}</Button> : null}
						{row.domainstatus == 'FAILED' || row.domainstatus == 'ACTION_REQUIRED' || row.domainstatus == 'NOT_REGISTERED' ? <Button className="buttonAction" onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.retryAction(row)}}>{"Riprova"}</Button> : null}
						{localStorage.getItem('isAdmin') === "true" ? <Button className="buttonAction" onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.setState({domainSelected:row.name, ModalReAssignDomainOpen: true})}}>{"Riassegna"}</Button> : null}
						{row.web_server ? <Button className="buttonAction" style={{width: "260px"}} onClick={(e) => {this.props.history.push("/servers/manage", { server: row.web_server, language: this.props.language })}}>Web Server</Button> : null}
						{row.mail_server ? <Button className="buttonAction" style={{width: "260px"}} onClick={(e) => {this.props.history.push("/servers/manage", { server: row.mail_server, language: this.props.language })}}>Mail Server</Button> : null}
						{localStorage.getItem('isAdmin') === "true" ? <Button icon="trash" onClick={(e) => {this.setState({modalWarningOpen: true, domainToDelete: row})}} /> : null}
						{//<Button className="buttonAction" >SSL</Button>
						}
					</div>
					/*<Dropdown className="icon grey" icon='setting' direction='left' button>
						<Dropdown.Menu className="xxx-menu-right">
							{!this.state.isSecondLevelUser && <Dropdown.Item text='Info' onClick={() => this.domainDetails(row)} />}
							{!this.state.isSecondLevelUser && row.alert === 'RENEW' && !row.autorenew ? <Dropdown.Item text={this.props.intl.formatMessage({ id: 'app.renew' })} onClick={() => this.payRenewDomain(row)} /> : null}
							<Dropdown.Item text='DNS' onClick={() => this.domaindns(row)} />
							{!this.state.isSecondLevelUser && <Dropdown.Item text={this.props.intl.formatMessage({ id: 'app.change_owner' })} onClick={() => this.changeOwner(row)} />}
							<Dropdown.Item text={this.props.intl.formatMessage({ id: 'app.change_ns' })} onClick={() => this.changeNS(row)} />
							{!this.state.isSecondLevelUser && authinfovisible && <Dropdown.Item text={this.props.intl.formatMessage({ id: 'domain.authinfo' })} onClick={() => this.authCode(row)} />}
							{!this.state.isSecondLevelUser && row.domainstatus === 'EXPIRING' && row.registrar === 'ascio' && <Dropdown.Divider />}
							{!this.state.isSecondLevelUser && row.domainstatus === 'EXPIRING' && row.registrar === 'ascio' && <Dropdown.Item text={this.props.intl.formatMessage({ id: 'domain.migrate' })} onClick={() => this.migrateDomain(row)}/>}
						</Dropdown.Menu>
					</Dropdown*/);
			}
		});
	}

	loadUsers = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
			.then((response) => {
				this.setState({
					partners: response.data
				});
			})
			.catch((error) => {
                console.log(error)
            });
	}

	onPartnerChange = (e, data) => {
		this.setState({ partnerId: data.value });
		this.loadDomains({ partnerId: data.value });
	}

	handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
		var currentIndex = (page - 1) * sizePerPage;
		if (type === 'search') {
			currentIndex = 0;
		}
		this.setState({ loading: true });
		var filter = {
			currentIndex: currentIndex,
			sizePerPage: sizePerPage ? sizePerPage : this.state.sizePerPage,
			q: searchText,
			sortField: sortField,
			sortOrder: sortOrder,
			partnerId: this.state.partnerId ? this.state.partnerId : null
		};
		this.setState({ filter: filter });
		this.setState({
			page,
			sizePerPage,
			currentIndex: currentIndex,
		})
		if (this.state.isSecondLevelUser) {
			this.loadSecondLevelDomains(filter)
		} else {
			this.loadDomains(filter);
		}
	}

	onNsClose = () => this.setState({ nsOpened: false });

	getUserData = (userdata) => {
		this.setState({ balance: userdata.balance });
	}

	domainDetails = (domain) => {
		this.setState({ selectedDomain: domain, infoOpened: true });
	}
	onInfoClose = (refresh) => {
		this.setState({ infoOpened: false });
		if (refresh) {
			this.loadDomains(this.state.filter);
		}
	}

	authCode = (domain) => {
		this.setState({ selectedDomain: domain, authOpened: true });
	}
	onAuthClose = () => {
		this.setState({ authOpened: false });
	}


	migrateDomain = (domain) => {
		this.setState({ selectedDomain: domain, migrateOpened: true });
	}
	onMigrateClose = (reloadDomains) => {
		this.setState({ migrateOpened: false });
		if (reloadDomains) {
			this.loadDomains(this.state.filter);
		}
	}

	handleTry = async (e) => {
		e.stopPropagation();
		e.preventDefault();
		await axios.get(`${process.env.REACT_APP_API_URL}/api/telegram/trustpilot`)
	}

	deleteDomain = async (id) => {
		this.notify("Caricamento", "info", "trash", false)
		await axios.post(`${process.env.REACT_APP_API_URL}/api/domains/delete/${id}`)
			.then((response) => {
				this.notify(<FormattedMessage id="notify.delete-domain" />, "success", 'trash', true);
				this.loadDomains(this.state.filter)
			})
			.catch((error) => {
				this.notify(<FormattedMessage id="notify.delete-domain.failed" />, "error", 'trash', true)
			})
	}

	render() {
		const { page, sizePerPage, totalSize, loading } = this.state;
		const sizePerPageList = [5, 10, 15];
		const defaultSorted = [{
			dataField: 'id',
			order: 'desc'
		}];
		const rowEvent = {
			onClick: (e, row, rowIndex) => {
				//if(row.mail_server) this.props.history.push("/servers/manage", { server: row.mail_server, language: this.props.language })
				//if(row.web_server) this.props.history.push("/servers/manage", { server: row.web_server, language: this.props.language })
			}
		}
		const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: (row, isSelect) => {
                if(isSelect) {
                    //Se la casella è selezionata inserisce dentro i due array l'elemento corrispondente
                    let renewArray = this.state.selectedServerRenew;
                    let rebootArray = this.state.selectedServerReboot;
                    if (row.status=='EXPIRED') {
                        renewArray.push(row)
                    } else {
                        if(row.status=='ACTIVE') {
                           rebootArray.push(row.id)
                        } else {
                            if(row.status == 'RENEW') {
                                rebootArray.push(row.id);
                                renewArray.push(row)
                            }
                        }
                    } 
                    this.setState({selectedDomainRenew: renewArray, selectedDomainReboot: rebootArray})
                } else {
                    //quando deseleziono la casella cerco nell'array delle selezioni l'index corrispondente all'elemento tolto, successivamente
                    //lo elimino dall'array con splice.

                    //nella ricerca dell'indice: element è l'elemento preso dall'array mentre row.qualcosa è l'elemento della tabella che ho deselezionato
                    let renewArray = this.state.selectedDomainRenew;
                    let rebootArray = this.state.selectedDomainReboot;
                    let indexReboot = 0;
                    let indexRenew = 0;
                    if(row.status == 'ACTIVE') {
                        indexReboot = rebootArray.findIndex( (element) => element == row.id);
                        rebootArray.splice(indexReboot, 1);
                        this.setState({
                            selectedServerReboot: rebootArray
                        })
                    } else {
                        if(row.status == 'EXPIRED') {
                            indexRenew = renewArray.findIndex( (element) => element.id == row.id);
                            renewArray.splice(indexRenew, 1)
                            this.setState({
                                selectedServerRenew: renewArray
                            })
                        } else {
                            if(row.status == 'RENEW') {
                                indexRenew = renewArray.findIndex( (element) => element.id == row.id);
                                indexReboot = rebootArray.findIndex( (element) => element == row.id);
                                renewArray.splice(indexRenew, 1);
                                rebootArray.splice(indexReboot, 1);
                                this.setState({
                                    selectedServerRenew: renewArray,
                                    selectedServerReboot: rebootArray
                                })
                            }
                        }
                    }
                }
            },
            onSelectAll: (isSelect, rows) => {
                if(isSelect) {
                    let renewArray = this.state.selectedDomainRenew;
                    let rebootArray = this.state.selectedDomainReboot;
                    for(let row of rows) {
                        if(row.status == 'EXPIRED') {
                            renewArray.push(row)
                        } else {
                            if(row.status=='ACTIVE') {
                               rebootArray.push(row.id)
                            } else {
                                if(row.status == 'RENEW') {
                                    rebootArray.push(row.id);
                                    renewArray.push(row)
                                }
                            }
                        } 
                    }
                    this.setState({selectedDomainRenew: renewArray, selectedDomainReboot: rebootArray})
                } else {
                    let renewArray = this.state.selectedDomainRenew;
                    let rebootArray = this.state.selectedDomainReboot;
                    let indexReboot = 0;
                    let indexRenew = 0;
                    for(let row of rows) {
                        if(row.status == 'ACTIVE') {
                            indexReboot = rebootArray.findIndex( (element) => element == row.id);
                            rebootArray.splice(indexReboot, 1);
                            this.setState({
                                selectedDomainReboot: rebootArray
                            })
                        } else {
                            if(row.status == 'EXPIRED') {
                                indexRenew = renewArray.findIndex( (element) => element.id == row.id);
                                renewArray.splice(indexRenew, 1)
                                this.setState({
                                    selectedDomainRenew: renewArray
                                })
                            } else {
                                if(row.status == 'RENEW') {
                                    indexRenew = renewArray.findIndex( (element) => element.id == row.id);
                                    indexReboot = rebootArray.findIndex( (element) => element == row.id);
                                    renewArray.splice(indexRenew, 1);
                                    rebootArray.splice(indexReboot, 1);
                                    this.setState({
                                        selectedDomainRenew: renewArray,
                                        selectedDomainReboot: rebootArray
                                    })
                                }
                            }
                        }
                    }
                }
            }
        }
		const isAdmin = (localStorage.getItem('isAdmin') === "true");
		const { formatMessage } = this.props.intl;
		return (
			<div className="kx-page">
				{loading ?
					<Dimmer active inverted>
						<Loader size='medium'></Loader>
					</Dimmer>
					: null}

				<Header history={this.props.history} language={this.props.language} changeLanguage={this.props.changeLanguage} getUserData={this.getUserData} />

				<div className="with-nav-bar with-left-nav">


					<div className="">
						<div className="kx-left-nav">
							<LeftSidebar history={this.props.history} location={this.props.location}
								styles={{ sidebar: { background: "blue" } }} />
						</div>
					</div>

					<div className="kx-content pt-0">

						<div className="kx-container">
							{this.state.noDomains && !this.state.isSecondLevelUser ?  <div className="noElementContainer">
							<CallToBuy history={this.props.history}  location='Domini' extra={<List
								history={this.props.history}
								reload={this.loadDomains}
								domains={this.state.domains.slice(0 + this.state.offset, 8 + this.state.offset)}
								loadData={this.loadDomains}
								notify={this.notify}
							/> }/></div>
							:
							
							<div className="gigaContainer">
								{this.state.canAdd ? <List
									history={this.props.history}
									reload={this.loadDomains}
									domains={this.state.domains.slice(0 + this.state.offset, 8 + this.state.offset)}
									loadData={this.loadDomains}
									notify={this.notify}
								/> : null}
							<div className="row">
								<Modal selectedDomain={this.state.selectedDomain} type={{ Formtype: "server", ButtonLabel: this.props.intl.formatMessage({ id: "app.edit" }) }} notify={this.notify} open={this.state.nsOpened} onClose={this.onNsClose} />
								<ModalInfoDomain selectedDomain={this.state.selectedDomain} type={{ Formtype: "server", ButtonLabel: this.props.intl.formatMessage({ id: "app.edit" }) }} notify={this.notify} open={this.state.infoOpened} onClose={this.onInfoClose} />
								<ModalAuthDomain selectedDomain={this.state.selectedDomain} notify={this.notify} open={this.state.authOpened} onClose={this.onAuthClose} />
								<ModalMigrateDomain selectedDomain={this.state.selectedDomain} notify={this.notify} open={this.state.migrateOpened} onClose={this.onMigrateClose} onDone={this.onMigrateClose} />
								<ModalConfirmRenew renewDomainInfo={this.state.renewDomainInfo} config={this.renewConfirmConfig} onYes={(domain, duration) => this.doRenewDomain(domain, duration)} init={this.onConfirmOpen} notify={this.notify} open={this.state.renewOpened} onClose={this.onRenewClose} />
								<ModalChangeDns domain={this.state.domainSelected} notify={this.notify} open={this.state.modalChangeDnsOpen} onClose={() => {this.setState({modalChangeDnsOpen: false}, () => {this.loadDomains()})}} />
								<ModalRetryAction domain={this.state.domainSelected} notify={this.notify} open={this.state.modalRetryActionOpen} onClose={() => {this.setState({modalRetryActionOpen: false}, () => {this.loadDomains()})}}  />
								{isAdmin ? <ModalReAssignDomain domainHandle={this.state.domainSelected} open={this.state.ModalReAssignDomainOpen} notify = {this.notify} onClose={()=> {this.setState({ModalReAssignDomainOpen:false}); this.loadDomains()}}/>: null}
								<div className="col-12">
								{this.state.balancealert === 1 && !this.state.isSecondLevelUser ? <strong><p style={{color:'red'}}><FormattedMessage id="domain.alert" /></p></strong>: null}
									<ToolkitProvider
										keyField="id"
										data={this.state.domains}
										columns={this.columns}
										defaultSorted={defaultSorted}
										bootstrap4
										search
									>
										{
											toolkitprops => [
												<div className="searchBar">
                                                    <div className="searchBtn">
                                                        <SearchBar ref={n => this.searchKit = n} id="s1" delay={1500} {...toolkitprops.searchProps} placeholder={formatMessage({ id: "table.search" })} />
                                                        <ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
                                                    </div>
                                                    {isAdmin ?
                                                        <div className="d-flex flex-row align-items-center">
                                                            <Dropdown
                                                                className="parnter-list"
                                                                placeholder="Partner"
                                                                onChange={this.onPartnerChange}
                                                                name="partnerId"
                                                                clearable                                                                
                                                                selection
                                                                search
                                                                options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                                                            />
                                                        </div> : null}
                                                </div>,
												<div className='infodiv boxTabella'>
													<div style={{display: "flex"}}>
														{1*this.state.totalSize>99 ? document.querySelector(':root').style.setProperty('--lunghezzaDomini', "30px") : document.querySelector(':root').style.setProperty('--lunghezzaDomini', "20px")}
														<div className="tabName"><div className="roundCount domainCounter"><span>{this.state.totalSize}</span></div>{this.props.intl.formatMessage({ id: "domain.title" })}</div>
													</div>
													<BootstrapTable
														{...toolkitprops.baseProps}
														remote={{ search: true, pagination: true, sort: true }}
														hover
														rowEvents={ rowEvent }
														//selectRow={ selectRow }
														classes="table-responsive"
														pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
														onTableChange={this.handleTableChange}
													/>
												</div>
											]
										}
									</ToolkitProvider>
								</div>
							</div></div>}
						</div>
					</div>

				</div>
				{
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
				<Footer />
				<ModalWarning open={this.state.modalWarningOpen} warning={"delete"} deleteElement={this.state.domainToDelete.name}  onClose={() => this.setState({modalWarningOpen: false})} onYes={() => {this.setState({modalWarningOpen: false}); this.deleteDomain(this.state.domainToDelete.id)}}/>
			</div>
		);
	}
}

export default injectIntl(Domains);
