import React, { Component } from "react";
import "./form-dns.scss";

import axios from "axios";

class FormDNS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      type: "",
      ttl: "",
      extra: "",
      ip: "",
      domain: this.props.domainid
    };
  }

  onChange = e => {
    let valuetocheck = e.target.value
        e.target.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    // get our form data out of state
    const { name, type, ttl, extra, ip, domain } = this.state;
    const token = localStorage.getItem("jwt");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/dns/me`,
        { name, type, ttl, extra, ip, domain },
        {
          headers: {
            "x-auth-token": token
          }
        }
      )
      .then(response => {
        this.props.showAlert("Inserimento DNS riuscito");
        this.props.closeForm(response);
        this.setState({
          name: "",
          type: "",
          ttl: "",
          extra: "",
          ip: ""
        });
      })
      .catch(error => {
        this.props.showAlert("Inserimento DNS non riuscito.");
      });
  };

  render() {
    const { name, type, ttl, extra, ip } = this.state;
    return (
      <div>
        <form className="dns-form-container" onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>Tipo</label>
                <select
                  name="type"
                  className="form-control"
                  value={type}
                  onChange={this.onChange}
                  required
                >
                  <option defaultValue />
                  <option value="a">A</option>
                  <option value="cname">CNAME</option>
                  <option value="txt">TXT</option>
                  <option value="mx">MX</option>
                  <option value="google mail">GOOGLE MAIL</option>
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group" style={{ marginBottom: "10px" }}>
                <label>Nome:</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  required
                  value={name}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group" style={{ marginBottom: "10px" }}>
                <label>IP/Data:</label>
                <input
                  type="text"
                  name="ip"
                  className="form-control"
                  required
                  value={ip}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <div className="form-group" style={{ marginBottom: "10px" }}>
                <label>TTL:</label>
                <input
                  type="text"
                  name="ttl"
                  className="form-control"
                  value={ttl}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group" style={{ marginBottom: "10px" }}>
                <label>AUX:</label>
                <input
                  type="text"
                  name="extra"
                  className="form-control"
                  value={extra}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>

          <div className="text-right">
            <button className="btn btn-lg btn-primary" type="submit">
              <a> Salva DNS </a>
            </button>
            <button
              type="button"
              className="btn btn-lg btn-outline-dark redo-dns"
              onClick={this.props.closeForm}
            >
              Annulla
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default FormDNS;
