import React, { Component } from 'react';
import '../list-item/list-item.scss';
import Header from '../header/header';
import LeftSidebar from '../sidebar/left-sidebar';
import Footer from '../footer/footer';
import { Breadcrumb, Table, Icon, Button, Modal, Form, Dropdown } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Moment from 'moment';
import 'moment/locale/it';
import axios from 'axios';
import NotifyComponent from '../notify/notifyComponent';

const dnsTypeOptions = [
	{ text: 'NS', value: 'NS' },
	{ text: 'TXT', value: 'TXT' },
	{ text: 'A', value: 'A' },
	{ text: 'CNAME', value: 'CNAME' },
	{ text: 'MX', value: 'MX' }
];

class DomainDns extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.token = localStorage.getItem('jwt');
		axios.defaults.headers.common['x-auth-token'] = this.token;
		this.state = {
			collapse: false,
			user: {},
			dns: [],
			domainid: this.props.location.state.domainid,
			domaininfo: {},
			open: false,
			confirm: false,
			name: '',
			type: '',
			ttl: '',
			aux: '',
			data: '',
			domain: '',
			id: null,
			isEdit: false,
			isDetails: false,
			showDetails: false,
			background: 'white',
			dropdownselect: null,
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
		};
		this.loadDomain(this.props.location.state.domainid);
		this.loadDNS(this.props.location.state.domainid);
		const token = localStorage.getItem('jwt');
		axios.defaults.headers.common['x-auth-token'] = token;
		this.formatMessage = this.props.intl.formatMessage;
	}

	componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }

	loadDomain = (domainid) => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/domains/${domainid}`
		).then((res) => {
			this.setState({ domaininfo: res.data });
		});
	}
	toggle() {
		this.setState({ collapse: !this.state.collapse });
	}

	loadDNS = (id) => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/api/dns/` + id)
			.then((response) => {
				this.setState({ dns: response.data, domainid: id, showDetails: true });
			})
			.catch((e) => {
			});
	};

	onSubmit = (e) => {
		e.preventDefault();
		this.notify("Caricamento", "info", "true", false)
		const { name, type, ttl, aux, data, domain, domainid } = this.state;
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/api/dns`,
				{ name, type, ttl, aux, data, domain, domainid }
			)
			.then((response) => {
				this.notify(this.formatMessage({ id: "app.record_saved" }), "success", 'save', true);
				this.loadDNS(this.state.domainid)
				this.close();
			}).catch(e => {
				this.notify(this.formatMessage({ id: "app.record_saved.failed" }), "error", 'save', true);
				this.close();
			})
	};

	onSubmitUpdate = (e) => {
		e.preventDefault();
		this.notify("Caricamento", "info", "pencil", false)
		const { name, type, ttl, aux, data, domain, domainid } = this.state;
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/api/dns/rr/` + this.state.id,
				{ name, type, ttl, aux, data, domain, domainid }
			)
			.then((response) => {
				this.loadDNS(this.state.domainid);
				this.notify(this.formatMessage({ id: "app.record_saved" }), "success", 'pencil', true);
				this.close();
			}).catch(e => {
				this.notify("" + e.response.data, "error", 'pencil', true);
				this.close();
			})
	};

	onYesDeleteDns = (e) => {
		e.preventDefault();
		this.notify("Caricamento", "info", "trash", false)
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/api/dns/rr_delete/` + this.state.id
			)
			.then((response) => {
				this.notify(this.formatMessage({ id: "app.record_deleted" }), "success", 'trash', true);
				this.loadDNS(this.state.domainid);
				this.closeConfirm();
			}).catch(e => {
				this.notify(e.response.data.message, "error", 'trash', true);
				this.closeConfirm();
			})
	};

	show = (size) => () => this.setState({ size, open: true });
	close = () => this.setState({ open: false });
	showConfirm = (size) => () => this.setState({ size, confirm: true });
	closeConfirm = () => this.setState({ confirm: false });

	editDns = (dns) => {
		this.setState({
			isEdit: true,
			name: dns.name,
			type: dns.type,
			domain: dns.domain,
			ttl: dns.ttl,
			data: dns.data,
			aux: (dns.aux !== "0" ? dns.aux : ""),
			id: dns.id
		});
		this.show('small')();
	};

	deleteDns = (dns) => {
		this.setState({
			id: dns.id
		});
		this.showConfirm('mini')();
	}

	showDetails = () => {
		this.setState({ isDetails: true });
		this.show('mini')();
	};
	addDns = () => {
		this.setState({
			isEdit: false,
			isDetails: false,
			name: '',
			type: '',
			domain: this.state.domain,
			ttl: 3600,
			data: '',
			aux: '',
			id: null
		});
		this.show('small')();
	};

	onChange = (e) => {
		let valuetocheck = e.target.value
        e.target.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
		this.setState({ [e.target.name]: e.target.value });
	};

	getUserData = (userdata) => {
		this.setState({ user: userdata });
	}

	syncZone = async(isp_id) => {
        this.notify("Caricamento", "info", "sync", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/domains/loadzones/${isp_id}`)
            .then((response) => {
                this.notify("Syncronized", "success", 'sync', true)
            })
            .catch((error) => {
                this.notify(""+error, "error", 'sync', true)
            })
    }

	render() {
		const { open, size, confirm } = this.state;
		const { formatMessage } = this.props.intl;
		return (
			<div className="kx-page">
				<Header history={this.props.history} language={this.props.language} changeLanguage={this.props.changeLanguage} getUserData={this.getUserData} />

				<div className="with-nav-bar with-left-nav">


					<div className="">
						<div className="kx-left-nav">
							<LeftSidebar history={this.props.history} location={this.props.location}
								styles={{ sidebar: { background: "blue" } }} />
						</div>
					</div>

					<div className="modal-container">

						<Modal size={size} closeIcon={true}
							closeOnDimmerClick={true}
							open={confirm}
							onClose={this.closeConfirm}
						>
							<Modal.Header><FormattedMessage id="app.confirm_delete" /></Modal.Header>
							<Modal.Content>
								<FormattedMessage id="app.confirm_delete_text" />
							</Modal.Content>
							<Modal.Actions>
								<Button color='red' inverted onClick={this.closeConfirm}>
									<Icon name='remove' /> <FormattedMessage id="app.no" />
								</Button>
								<Button color='green' inverted onClick={this.onYesDeleteDns}>
									<Icon name='checkmark' /> <FormattedMessage id="app.yes" />
								</Button>
							</Modal.Actions>
						</Modal>


						{!this.state.isDetails ? (
							<Modal size={size} open={open} onClose={this.close}>
								<Modal.Header><FormattedMessage id="dns.add_modify_record" /></Modal.Header>
								<Modal.Content>
									<Form>
										<Form.Field>
											<label><FormattedMessage id="dns.record_type" /></label>
											<Dropdown
												placeholder={formatMessage({ id: "dns.record_type" })}
												onChange={(e) => {
													this.setState({ type: e.target.textContent });
													if (e.target.textContent === "MX") {
														this.setState({ aux: "10" });
													}
													else {
														this.setState({ aux: "" });
													}
												}}
												fluid
												selection
												options={dnsTypeOptions}
												value={this.state.type}
											/>

										</Form.Field>
										<Form.Field>
											<label><FormattedMessage id="dns.name" /></label>
											<input
												name="name"
												placeholder={formatMessage({ id: "dns.name" })}
												value={this.state.name}
												onChange={this.onChange}
											/>
										</Form.Field>
										<Form.Field>
											<label><FormattedMessage id="dns.ip_data" /></label>
											<input
												name="data"
												placeholder={formatMessage({ id: "dns.ip_data" })}
												value={this.state.data}
												onChange={this.onChange}
											/>
										</Form.Field>
										<Form.Field>
											<label><FormattedMessage id="dns.ttl" /></label>
											<input
												name="ttl"
												placeholder={formatMessage({ id: "dns.ttl" })}
												value={this.state.ttl || "3600"}
												onChange={this.onChange}
											/>
										</Form.Field>
										<Form.Field>
											<label><FormattedMessage id="dns.aux" /></label>
											<input
												name="aux"
												placeholder={formatMessage({ id: "dns.aux" })}
												value={this.state.aux}
												onChange={this.onChange}
											/>
										</Form.Field>
									</Form>
								</Modal.Content>
								<Modal.Actions>
									<Button negative icon="cancel" labelPosition="left" onClick={this.close} content={formatMessage({ id: "app.cancel" })} />
									<Button
										positive
										icon="checkmark"
										labelPosition="right"
										onClick={!this.state.isEdit ? this.onSubmit : this.onSubmitUpdate}
										content={!this.state.isEdit ? formatMessage({ id: "app.add" }) : formatMessage({ id: "app.update" })}
									/>
								</Modal.Actions>
							</Modal>
						) : (
							<Modal className="mini-modals" size={size} open={open} onClose={this.close}>
								<div className="details-heading">
									<Modal.Header><FormattedMessage id="app.details" /></Modal.Header>
								</div>
								<Modal.Content>
									{/* <Grid.Column width={4}>
											<Card fluid className="main-card-custom-style"> */}
									<div className="text-left">
										<div className="row">
											<div className="col-lg-12 col-md-12 col-sm-12 info-text">
												<b><FormattedMessage id="app.registrtant" />: </b> {this.state.user.name}
											</div>

											<div className="col-lg-12 col-md-12 col-sm-12 info-text">
												<b><FormattedMessage id="app.created_by" />: </b>
												{this.state.user.email}
											</div>

											<div className="col-lg-12 col-md-12 col-sm-12 info-text">
												<b><FormattedMessage id="app.status" />: </b>
												{this.state.domaininfo.domainstatus}
											</div>
										</div>
										{/* <div className="row">
												<div className="col-lg-12 col-md-12 col-sm-12 info-text">
													<b>Ultima azione: </b>
													Azione
												</div>
											</div> */}
										<div className="row justify-content-between">
											<div className="col-lg-12 col-md-12 col-sm-12 info-text">
												<b><FormattedMessage id="app.registered" />: </b>
												{Moment(this.state.domaininfo.registered).locale(this.props.language).format('L')}
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 info-text">
												<b><FormattedMessage id="app.expired" />: </b>
												{Moment(this.state.domaininfo.expired).locale(this.props.language).format('L')}
											</div>
										</div>
									</div>
									{/* </Card> */}
									{/* </Grid.Column> */}
								</Modal.Content>
								<Modal.Actions>
									<div className="cancel-button">
										<Button negative icon="crossmark" onClick={this.close}>
											Cancel
											</Button>
									</div>
								</Modal.Actions>
							</Modal>
						)}
					</div>


					{/* {this.state.showDetails ? ( */}

					{/* <Card fluid className="main-card-custom-style"> */}

					<div className="kx-content">

						<div className="kx-container">
							<div className="row">
								{/*<Modal type={{ Formtype: "contact", ButtonLabel: "New" }} formData={{}} addContact={this.addContact} />*/}

								<div className="col-12">

									<Breadcrumb className="kx-breadcrumb">
										<Breadcrumb.Section link onClick={() => this.props.history.goBack()}><FormattedMessage id="app.domains" /></Breadcrumb.Section>
										<Breadcrumb.Divider icon='right chevron' />
										<Breadcrumb.Section link onClick={this.showDetails}>{this.props.location.state.domain}</Breadcrumb.Section>
										<Breadcrumb.Divider icon='right arrow' />
										<Breadcrumb.Section active>Domain Name System (DNS)</Breadcrumb.Section>
									</Breadcrumb>

									<Table id="DNStable" className="tables" striped>
										<Table.Header>
											<Table.Row>
												<Table.HeaderCell width={1}><FormattedMessage id="dns.type" /></Table.HeaderCell>
												<Table.HeaderCell width={3}><FormattedMessage id="dns.name" /></Table.HeaderCell>
												<Table.HeaderCell width={6}><FormattedMessage id="dns.ip_data" /></Table.HeaderCell>
												<Table.HeaderCell width={1}><FormattedMessage id="dns.ttl" /></Table.HeaderCell>
												<Table.HeaderCell width={1}><FormattedMessage id="dns.aux" /></Table.HeaderCell>
												<Table.HeaderCell width={2}>
													<div style={{ display: "flex", "align-items": "center", textAlign: 'left' }}>
														<Button icon="add" className="details-add" onClick={this.addDns}>{/* content={formatMessage({ id: "dns.add_dns" })} */}
														</Button>
														<Button className="syncButtonDNS" onClick={() => this.syncZone(this.props.isp_id)}>SINCRONIZZA</Button>
													</div>
												</Table.HeaderCell>
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{this.state.dns.map((dns) => (
												<Table.Row key={dns.id}>
													<Table.Cell>{dns.type}</Table.Cell>
													<Table.Cell>{dns.name}</Table.Cell>
													<Table.Cell className="ellipsis">{dns.data}</Table.Cell>
													<Table.Cell>{dns.ttl}</Table.Cell>
													<Table.Cell>{dns.aux}</Table.Cell>
													<Table.Cell textAlign="left">
														{dns.type !== 'soa' ? (
															[<Button key={'e' + dns.id} size='xtiny' icon="pencil square" onClick={() => this.editDns(dns)}></Button>,
															<Button key={'d' + dns.id} size='xtiny' icon="trash" onClick={() => this.deleteDns(dns)}></Button>]
														) : null}
													</Table.Cell>
												</Table.Row>
											))}
										</Table.Body>
										<Table.Footer fullWidth>
											<Table.Row>
												<Table.HeaderCell />
												<Table.HeaderCell colSpan="8">
												</Table.HeaderCell>
											</Table.Row>
										</Table.Footer>
									</Table>
								</div>
							</div>
						</div>
					</div>
				</div>
				{
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
				<Footer />
			</div>
		);
	}
}

export default injectIntl(DomainDns);
