/*
    ATTENZIONE, QUESTO FILE SI AUTODISTRUGGERA' A BREVE

    (da cancellare)

*/
import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Button, Search, Label, Grid, Checkbox, Icon } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './server.scss';
import axios from 'axios';
import PropTypes from 'prop-types'

const resultRenderer = (data) => {
    const { firstname, lastname, code } = data;
    var name = firstname + ' ' + lastname + ' [' + code + ']';
    return (<Label key={code} content={name} />);
}

resultRenderer.propTypes = {
    lastname: PropTypes.string,
    firstname: PropTypes.string,
    code: PropTypes.string
}

class ISPConfigForm extends Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('jwt');
        axios.defaults.headers.common['x-auth-token'] = this.token;
        this.state = {
            id: null,
            description: "",
            dns_server_client_id: "",
            dns_server_id: "",
            dns_server_username: "",
            dns_server_password: "",
            soap_location: "",
            soap_uri: "",
            ns1: "",
            ns1_ip: "",
            ns2: "",
            ns2_ip: "",
            userid: "",
            isLoading: false,
            results: [],
            admin_contact_id: "",
            admin: '',
            technical_contact_id: "",
            technical: "",
            billing_contact_id: "",
            billing: "",
            responsible: "",
            public: false,
            server_product: "ns",
            partners: [],
            inputType: "password"
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateIPConfig = this.updateIPConfig.bind(this);
        this.formatMessage = this.props.intl.formatMessage;
        //this.handleSearchChange = this.handleSearchChange.bind(this);
        this.search = _.debounce((value) => {
            this.setState({ isLoading: true });
            if (value) {
                var options = { params: { q: value } };
            }
            axios.get(`${process.env.REACT_APP_API_URL}/api/contact/contact`, options)
                .then((response) => {

                    this.setState({
                        isLoading: false,
                        results: response.data.map(user => ({
                            lastname: `${user.lastname}`,
                            firstname: `${user.firstname}`,
                            code: `${user.id}`
                        }))
                    })
                })
                .catch((error) => {
                    this.showAlert(error.message);
                });
        }, 300);
        this.loadPartners();
    }

    onChange = (e, { name, value }) => {
        this.setState({ [name]: value });
        this.search(value);
    }
    handleInputChange(event, { name, value, checked, type }) {
        if (type === 'checkbox') {
            value = checked;
        }
        if (name == "server_id"){
            for(let server of this.state.serversArray){
                if(server.id == value){
                    this.setState({
                        server_product: server.server_product
                    })
                }
            }
        }
        this.setState({
            [name]: value
        });
    }
    //{ result }
    handleResultSelect = (e, { name, result }) => {
        this.setState({
            [name]: result.firstname + ' ' + result.lastname,
            [name + '_contact_id']: result.code
        });
    }

    updateIPConfig() {
        // validate
        const { addServer, closeMe, notify } = this.props;
        let payload = {
            id: this.state.id,
            description: this.state.description,
            dns_server_client_id: this.state.dns_server_client_id,
            dns_server_id: this.state.dns_server_id,
            dns_server_username: this.state.dns_server_username,
            dns_server_password: this.state.dns_server_password,
            soap_location: this.state.soap_location,
            soap_uri: this.state.soap_uri,
            ns1: this.state.ns1,
            ns1_ip: this.state.ns1_ip,
            ns2: this.state.ns2,
            ns2_ip: this.state.ns2_ip,
            userid: this.state.userid,
            admin_contact_id: this.state.admin_contact_id,
            admin: this.state.admin,
            technical_contact_id: this.state.technical_contact_id,
            technical: this.state.technical,
            billing_contact_id: this.state.billing_contact_id,
            billing: this.state.billing,
            responsible: this.state.responsible,
            public: this.state.public,
            server_id: this.state.server_id,
            dns_server_client_id: this.state.dns_server_client_id
        }
        addServer(payload, function (res, err) {
            if (!err) {
                closeMe();
            }
        });
    }

    getContacts = (filter) => {
        if (filter) {
            var options = { params: { q: filter } };
        }
        axios.get(`${process.env.REACT_APP_API_URL}/api/contact/contact`, options)
            .then((response) => {
                this.setState({ contactlist: response.data });
            })
            .catch((error) => {
                this.showAlert(error.message);
            });
    };

    loadPartners = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then((response) => {
                var partners = response.data.map(({ id, userid, name }) => ({ key: id, value: userid, text: name }));
                this.setState({
                    partners: partners
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    getServers = async() => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/getallserversfromdb`)
            .then(async (response) => {
                for(let server of response.data){
                    if(server.isp_id == this.props.formData.id){
                        this.setState({server_id: server.server_id, server_product: server.server_product ? server.server_product: "ns"})
                    }
                }
                this.setState({serversArray:response.data})
            })
            .catch((err) => {
                console.log(err);
            })
    }

    switchPassword = () => {
        if(this.state.inputType == "password") {
            this.setState({inputType: "text"})
        } else {
            this.setState({inputType: "password"})
        }
    }

    firstSetUp = () => {
        if(!this.state.statewritten && this.props.open){
            this.getServers()
            this.setState({
                id: this.props.formData.id,
                description: this.props.formData.description,
                dns_server_client_id: "",
                dns_server_id: this.props.formData.dns_server_id,
                dns_server_username: this.props.formData.dns_server_username,
                dns_server_password: "",
                soap_location: this.props.formData.soap_location,
                soap_uri: this.props.formData.soap_uri,
                ns1: this.props.formData.ns1,
                ns1_ip: this.props.formData.ns1_ip,
                ns2: this.props.formData.ns2,
                ns2_ip: this.props.formData.ns2_ip,
                userid: this.props.formData.userid,
                admin_contact_id: this.props.admin_contact_id,
                technical_contact_id: this.props.formData.technical_contact_id,
                billing_contact_id: this.props.formData.billing_contact_id,
                dns_server_client_id: this.props.formData.dns_server_client_id,
                user: this.props.formData.user,
                responsible: this.props.formData.responsible,
                public: this.props.formData.public,
                statewritten: true
            })
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                statewritten: false
            })
        }
    }

    //const ISPConfigForm = ({ onChange, addServer, formData }) => (
    render() {
        //const onChange = this.props.onChange;
        //const addServer = this.props.addServer;
        //const formData = this.props.formData;
        //const closeMe = this.props.closeMe;
        const { formatMessage } = this.props.intl;
        const isAdmin = (localStorage.getItem('isAdmin') === "true");
        this.firstSetUp()
        return (
            <div>
                <div className="server-form">
                    <Form className="isp-form">
                        <Form.Group>
                            <Form.Input label={formatMessage({ id: "app.description" })} name="description" value={this.state.description} onChange={this.handleInputChange}  />
                            <Form.Input label={formatMessage({ id: "isp.client_id" })} name="dns_server_client_id" value={this.state.dns_server_client_id} onChange={this.handleInputChange}   />
                            <Form.Input label={formatMessage({ id: "isp.server_id" })} name="dns_server_id" value={this.state.dns_server_id} onChange={this.handleInputChange}   />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input label={formatMessage({ id: "isp.location" })} name="soap_location" value={this.state.soap_location} onChange={this.handleInputChange}  />
                            <Form.Input label={formatMessage({ id: "isp.uri" })} name="soap_uri" value={this.state.soap_uri} onChange={this.handleInputChange}  />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input label={formatMessage({ id: "app.username" })} name="dns_server_username" value={this.state.dns_server_username} onChange={this.handleInputChange}  />
                            <Form.Input label={formatMessage({ id: "app.password" })} name="dns_server_password" type={this.state.inputType} icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword()} />} iconPosition='right' value={this.state.dns_server_password} onChange={this.handleInputChange}  />
                        </Form.Group>
                        <Form.Group >
                            <div className="field">
                                <label className="searchLa">Admin</label>
                                <Search
                                    name="admin"
                                    fluid
                                    loading={this.state.isLoading}
                                    onResultSelect={this.handleResultSelect}
                                    onSearchChange={this.onChange}
                                    results={this.state.results}
                                    value={this.state.admin}
                                    resultRenderer={resultRenderer}
                                    size="huge"
                                //{...this.props}
                                />
                            </div>
                            <div className="field">
                                <label className="searchLa">Technical</label>
                                <Search
                                    name="technical"
                                    fluid
                                    loading={this.state.isLoading}
                                    onResultSelect={this.handleResultSelect}
                                    onSearchChange={this.onChange}
                                    results={this.state.results}
                                    value={this.state.technical}
                                    resultRenderer={resultRenderer}
                                    size="huge"
                                // {...this.props}
                                />
                            </div>
                            <div className="field">
                                <label className="searchLa">Billing</label>
                                <Search
                                    name="billing"
                                    fluid
                                    loading={this.state.isLoading}
                                    onResultSelect={this.handleResultSelect}
                                    onSearchChange={this.onChange}
                                    results={this.state.results}
                                    value={this.state.billing}
                                    resultRenderer={resultRenderer}
                                    size="huge"
                                //{...this.props}
                                />
                            </div>
                        </Form.Group>
                        { this.state.server_product.includes("ns") ?<>
                        <Form.Group>
                            <Form.Input label={formatMessage({ id: "isp.ns1" })} name="ns1" value={this.state.ns1 || ''} onChange={this.handleInputChange}  />
                            <Form.Input label={formatMessage({ id: "isp.ns1_ip" })} name="ns1_ip" value={this.state.ns1_ip || ''} onChange={this.handleInputChange}  />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input label={formatMessage({ id: "isp.ns2" })} name="ns2" value={this.state.ns2 || ''} onChange={this.handleInputChange}  />
                            <Form.Input label={formatMessage({ id: "isp.ns2_ip" })} name="ns2_ip" value={this.state.ns2_ip || ''} onChange={this.handleInputChange}  />
                        </Form.Group>
                        </> : null}
                        <Form.Group>
                            <Form.Input label={formatMessage({ id: "isp.responsible" })} name="responsible" value={this.state.responsible || ''} onChange={this.handleInputChange}  />
                            {isAdmin ?
                                <Form.Dropdown id="partners"
                                    placeholder="parnter"
                                    label="Partner"
                                    name="userid"
                                    value={this.state.userid}
                                    onChange={this.handleInputChange}
                                    fluid
                                    selection
                                    search
                                    options={this.state.partners || []}
                                /> : null}
                        </Form.Group>
                        <Form.Group>
                            <Form.Dropdown
                                placeholder="Server"
                                label="Server"
                                name="server_id"
                                value={this.state.server_id}
                                onChange={this.handleInputChange}
                                fluid
                                selection
                                search
                                options={this.state.serversArray ? this.state.serversArray.map(({server_id, server}) => ({value: server_id, text: server})) : []}
                            />
                        </Form.Group>
                        <Form.Group id="submit">
                            {isAdmin ?
                                <Form.Checkbox name="public" checked={this.state.public || false} label={formatMessage({ id: "srv.make_visible" })} onChange={this.handleInputChange} />
                                : null}
                            {/* <div className="width-100">
                                <div className="next-button-right">
                                    <a className="button-next-height" floated="right" onClick={this.updateIPConfig}>
                                        <span className="invisible">next</span>
                                    </a>
                                </div>
                            </div> */}
                            <Form.Button floated="right" onClick={this.updateIPConfig}>
                                {this.state.id ? formatMessage({ id: "app.edit" }) : formatMessage({ id: "app.add" })}
                            </Form.Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        );
    }
}
export default injectIntl(ISPConfigForm);
