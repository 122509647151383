import React, { Component } from 'react';
import { Button, Dropdown, Modal, Icon, Form } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import axios from 'axios';

class ModalConfirmRenew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            duration: '1Y',
            balance: 0
        }
        this.token = localStorage.getItem('jwt');
        axios.defaults.headers.common['x-auth-token'] = this.token;
    }

    //componentWillReceiveProps(prevProps) {
    componentDidUpdate(prevProps) {
        if (prevProps.renewDomainInfo !== this.props.renewDomainInfo) {
            this.modalInit();
        }
    }

    modalInit = () => {
        let data = this.props.init();
        this.setState({
            balance: data.balance,
            name: data.name,
            description: data.description
        });
        this.getPrice();
    }

    getPrice = () => {
        let product = {
            product: {
                type: 'domain',
                name: this.props.renewDomainInfo.name,
                action: 'renewal',
                duration: this.state.duration,
                registrar: this.props.renewDomainInfo.registrar
            }
        };
        axios.post(`${process.env.REACT_APP_API_URL}/api/domains/price`, product)
            .then((response) => {
                this.setState({ amount: response.data.amount });
            })
            .catch((error) => {
                this.props.notify(this.props.intl.formatMessage({ id: "app.err_price" }), "close", "error", true);
            });
    }

    handleClose = () => this.setState({ modalOpen: false });
    handleYes = () => {
        this.props.onYes(this.props.renewDomainInfo, this.state.duration);
    }

    durationsList = (intl) => [
        { text: '1 ' + intl.formatMessage({ id: "reg.year" }), value: '1Y' },
        { text: '2 ' + intl.formatMessage({ id: "reg.years" }), value: '2Y' },
        { text: '3 ' + intl.formatMessage({ id: "reg.years" }), value: '3Y' },
        { text: '4 ' + intl.formatMessage({ id: "reg.years" }), value: '4Y' },
        { text: '5 ' + intl.formatMessage({ id: "reg.years" }), value: '5Y' }
    ];

    render() {
        const { config, open, onClose, intl } = this.props;
        return (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    open={open}
                    onClose={onClose}
                    size="tiny"
                >
                    <Modal.Header>
                        <span className="text-secondary"><FormattedMessage id="app.product" />:</span> <strong>{this.state.name}</strong>
                    </Modal.Header>
                        <div style={{marginTop: "4%"}}></div>
                    <Modal.Content image className="modal-min-height">
                        <Modal.Description>
                            {this.state.description && (<h5><FormattedMessage id="app.description" />: <strong>{this.state.description}</strong></h5>)}
                            <div style={{marginTop: "3%"}}></div>
                                <label><h5><FormattedMessage id="reg.duration" />:</h5></label>
                                <Dropdown
                                    placeholder="Select Duration"
                                    onChange={(e, data) => {
                                        this.setState({ duration: data.value }, () => this.getPrice());

                                    }}
                                    selection
                                    options={this.durationsList(intl)}
                                    defaultValue={this.durationsList(intl)[0].value}
                                />
                            <div style={{marginTop: "3%"}}></div>
                            <h5><FormattedMessage id="app.price" />: <span className="badge badge-kx">&euro; {this.state.amount}</span></h5>
                            <div style={{marginTop: "3%"}}></div>
                            <h5><FormattedMessage id="app.balance" />: <span className="badge badge-success">&euro; {this.state.balance}</span></h5>
                            <div style={{marginTop: "3%"}}></div>

                            <p className="text-kx"><FormattedMessage id="payment.confirm_payment" /></p>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={!config.hideTrigger ? this.handleClose : onClose}>
                            <Icon name='remove' /> <FormattedMessage id="app.no" />
                        </Button>
                        <Button onClick={this.handleYes} disabled={!this.state.amount}>
                            <Icon name='checkmark' /> <FormattedMessage id="app.yes" />
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalConfirmRenew);

