import React, { Component } from 'react';
import {Modal, Form, TextArea} from 'semantic-ui-react';
import axios from 'axios';
import { injectIntl } from 'react-intl';

class ModalEditSSH extends Component {
    constructor(props) {
        super(props);
		this.instance = React.createRef();
        this.state = {
            text1:"",
            text2:""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    save = async() => {
        this.props.notify("Caricamento", "info", "save", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/editssh`, {id: this.props.data.id, private: this.state.text1, public: this.state.text2})
            .then((response) => {
                this.props.notify("Modificato correttamente!", "success", 'save', true)
                this.props.onClose()
            })
            .catch((error) => {
                console.log(error)
                this.props.notify("" + error, 'warning', 'save', true);
            })
    }

    firstSetUp = () => {
        if(!this.state.statewritten && this.props.open){
            this.setState({
                statewritten: true,
                text1: this.props.data.private,
                text2: this.props.data.public
            })
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                statewritten: false
            })
        }
    }

    getUsers = async() => {
        await axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then(async (response) => {
                this.setState({usersArray:response.data})
            })
            .catch((err) => {
                console.log(err);
            })
    }

    
    async handleInputChange(event, { name, value }) {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        await this.setState({
            [name]: value
        });
    }

    

    render() {
        const {open, onClose} = this.props;
        const { formatMessage } = this.props.intl;
        this.firstSetUp();
        return (
            <div>
                <Modal closeIcon={true}
                    className="overflow-yes"
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto', width: '30%' }}
                    open={open}
                    onClose={() => onClose(false)}>
                    <Modal.Header>
                        Modifica Chiavi
                    </Modal.Header>
                    <Modal.Content className="modal-min-height">
                        <Modal.Description>
                                <div className="server-form">
                                    <Form className="overflow-yes">
                                        <Form.Field type="text" value={this.state.text1} label={"SSH Privata"} name={"text1"} control={TextArea} onChange={this.handleInputChange} placeholder="Scrivi qui il testo delle istruzioni ricordando di andare a capo ad ogni frase" style={{border:"1px solid gray", "min-height":"45px", "max-height":"500px", "height":"250px"}}/>
                                        <Form.Field type="text" value={this.state.text2} label={"SSH Pubblica"} name={"text2"} control={TextArea} onChange={this.handleInputChange} placeholder="Scrivi qui il testo delle istruzioni ricordando di andare a capo ad ogni frase" style={{border:"1px solid gray", "min-height":"45px", "max-height":"500px", "height":"250px"}}/>
                                        <Form.Button onClick={() => {this.save()}} >
                                            {formatMessage({ id: "app.save" })}
                                        </Form.Button>
                                    </Form>
                                </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalEditSSH);