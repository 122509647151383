import React, { Component } from 'react';
import './left-sidebar.scss';
import { Icon, Button, Popup, Grid, Menu, Sidebar } from 'semantic-ui-react';
import ReactTooltip from "react-tooltip";
import { injectIntl } from 'react-intl';
import axios from 'axios';
import Multitenancy from '../All/Multitenancy';
import Changelog from '../All/modalChangelogs';
const mql = window.matchMedia(`(min-width: 800px)`);

class LeftSidebar extends Component {
    constructor(props) {
		super(props);
		this.state = {
			isAdmin: false,
			sidebarDocked: mql.matches,
			sidebarOpen: false,
            isSecondLevelUser: (localStorage.getItem("multitenancy") == 'true'),
			modalChangelogOpen: false,
			showDomini: false,
			showServer: false,
			showBarmetal: true,
			idUser: 0,
			multitenOptions: {}
		};
		
		this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
		this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
	}

	getUser = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
            .then(async (response) => {
                this.setState({
					isAdmin: response.data.isAdmin,
                    idUser: response.data.id
                }, async () =>{
					if(response.data.isseconduser) {
						await axios.get(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelusers?id=${response.data.id}`)
							.then(async (response) => { 
								this.setState({
									multitenOptions: response.data
								})
							})
					}
				});
            })
    }



	componentDidMount = () => {
		this.getUser();
	}

	componentWillMount() {
		mql.addListener(this.mediaQueryChanged);
	}

	componentWillUnmount() {
		mql.removeListener(this.mediaQueryChanged);
	}

	onSetSidebarOpen(open) {
		this.setState({ sidebarOpen: open });
	}

	mediaQueryChanged() {
		this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
	}

    render() {
        let currentLocation = this.props.location.pathname;
		const { formatMessage } = this.props.intl;
        return(
            <Sidebar
                as={Menu}
				id="sidebar"
                animation="uncover"
                direction="left"
				onHide={localStorage.setItem("dashVisible", false)}
                icon='labeled'
                vertical
                visible={localStorage.getItem("dashVisible")}
				width='thin'				
            >
                <Popup className="toolt" content={"Dashboard"} position="right center" trigger={<Menu.Item as='a' onClick={() => this.props.history.push("/dashboard")} className={currentLocation.includes('dashboard') ? 'sidebar-active' : ''}>
                    <img id="sideDash" src="\Dashboard.png" style={{ display: 'inline-block'}}/>
                </Menu.Item>}/>
                
				{this.state.multitenOptions.id ? this.state.multitenOptions.domains ? <Popup className="toolt" content={this.props.intl.formatMessage({id: "dash.menuDomini"})} position="right center" trigger={<Menu.Item onClick={() => {this.state.showDomini ? this.setState({showDomini: false, showClient: false, showServer: false, showBarmetal: false}) : 
						this.setState({showDomini: true, showClient: false, showServer: false, showBarmetal: false})}}>
						<img id="sideDomMen" src="\logo_domain_giorno.png" style={{ display: 'inline-block'}}/>
					</Menu.Item>}/> : null : <Popup className="toolt" content={this.props.intl.formatMessage({id: "dash.menuDomini"})} position="right center" trigger={<Menu.Item onClick={() => {this.state.showDomini ? this.setState({showDomini: false, showClient: false, showServer: false, showBarmetal: false}) : 
						this.setState({showDomini: true, showClient: false, showServer: false, showBarmetal: false})}}>
						<img id="sideDomMen" src="\logo_domain_giorno.png" style={{ display: 'inline-block'}}/>
					</Menu.Item>}/>}
				{this.state.showDomini || currentLocation.includes('domains') || currentLocation.includes('serverlist') ? (
					<Popup className="toolt" content={this.props.intl.formatMessage({id: "dash.domini"})} position="right center" trigger={<Menu.Item
						as='a'
						id="menuDomini"
						onClick={() => this.props.history.push("/domains")} className={currentLocation.includes('domains') ? 'subMenu sidebar-active' : 'subMenu'}>
						<img id="sideDom" src="\domain inclusivi.png" style={{ display: 'inline-block'}}/>

					</Menu.Item>}/>
				) : null}
					{this.state.multitenOptions.id ? this.state.multitenOptions.servers ? <Popup className="toolt" content={"Server"} position="right center" trigger={<Menu.Item onClick={() => {this.state.showServer ? this.setState({showClient: false, showServer: false, showBarmetal: false, showDomini: false}) : 
						this.setState({showServer: true, showClient: false, showBarmetal: false, showDomini: false})}}>
						<img id="sideServerMen" src="\KonsoleX_logo_server_02.png" style={{ display: 'inline-block'}}/>
					</Menu.Item>}/> : null : <Popup className="toolt" content={"Server"} position="right center" trigger={<Menu.Item onClick={() => {this.state.showServer ? this.setState({showClient: false, showServer: false, showBarmetal: false, showDomini: false}) : 
						this.setState({showServer: true, showClient: false, showBarmetal: false, showDomini: false})}}>
						<img id="sideServerMen" src="\KonsoleX_logo_server_02.png" style={{ display: 'inline-block'}}/>
					</Menu.Item>}/>}
				{this.state.showServer || currentLocation.includes('servers') || currentLocation.includes('MailGateway') || currentLocation.includes('firewalls') ? (
					<Popup className="toolt" content={"Server cloud"} position="right center" trigger={<Menu.Item
						as='a'
						id="menuServer"						
						onClick={() => this.props.history.push("/servers")} className={currentLocation.includes('servers') ? 'sidebar-active' : ''}>
						<img id="sideServer" src="\server cloud.png" style={{ display: 'inline-block'}}/>
					</Menu.Item>}/>
				) : null}
				{!this.state.multitenOptions.id ? this.state.showServer || currentLocation.includes('servers') || currentLocation.includes('MailGateway') || currentLocation.includes('firewalls') ? (
					<Popup className="toolt" content={"Gateway"} position="right center" trigger={<Menu.Item
						as='a'
						id="menuGateway"
						onClick={() => this.props.history.push("/MailGateway")} className={currentLocation.includes('MailGateway') ? 'sidebar-active' : ''}>
						<img id="sideGateway" src="/Gateway_giorno.png" style={{ display: 'inline-block'}}/>
					</Menu.Item>}/>
				) : null : null}
				{!this.state.multitenOptions.id ? this.state.showServer || currentLocation.includes('servers') || currentLocation.includes('MailGateway') || currentLocation.includes('firewalls') ? (
					<Popup className="toolt" content={"Firewall"} position="right center" trigger={<Menu.Item
						as='a'
						id="menuFirewall"
						onClick={() => this.props.history.push("/firewalls")} className={currentLocation.includes('firewalls') ? 'sidebar-active' : ''}>
						<img id="sideFire" src="\Firewall giorno.png" style={{ display: 'inline-block'}} />
					</Menu.Item>}/>
				) : null : null}
 				{/*<Menu.Item onClick={() => {this.state.showBarmetal ? this.setState({showBarmetal: false}) : this.setState({showBarmetal: true})}}>
						<img src="\robottino day.png" style={{ display: 'inline-block' }} height="40px" width="40px"/>
					</Menu.Item>
				{this.state.showBarmetal ? (
					<Menu.Item
						as='a'
						id="menuBarmetal"
						onClick={() => (localStorage.setItem("tabRobots", "server"), this.props.history.push("/barmetal"))} className={currentLocation.includes('barmetal')&&(localStorage.getItem("tabRobots")=="server") ? 'sidebar-active' : ''}>
						<img src="\Data Backup.png" style={{ display: 'inline-block' }} height="40px" width="40px"/>
					</Menu.Item>
				) : null}*/}
				
					{!this.state.multitenOptions.id ? <Popup className="toolt" content={"CloudBox"} position="right center" trigger={<Menu.Item
						as='a'
						id="menuStoragebox"
						onClick={() => {localStorage.setItem("tabRobots", "storagelist"); if(currentLocation.includes("/cloudbox")){window.location.reload()}else{this.props.history.push("/cloudbox")}}} className={currentLocation.includes('cloudbox') ? 'sidebar-active' : ''}>
						<img id="sideStorage" src="\storagebox notte.png" style={{ display: 'inline-block' }} height="40px" width="40px"/>
					</Menu.Item>}/> : null}
				{/*
				{{this.state.showBarmetal ? (
					<Menu.Item
						as='a'
						id="menuSwitch"
						onClick={() => (localStorage.setItem("tabRobots", "vSwitch"), this.props.history.push("/barmetal"))} className={currentLocation.includes('barmetal')&&(localStorage.getItem("tabRobots")=="vSwitch") ? 'sidebar-active' : ''}>
						<img src="\Web Hosting.png" style={{ display: 'inline-block' }} height="40px" width="40px"/>
					</Menu.Item>
				) : null}
				{this.state.showBarmetal ? (
					<Menu.Item
						as='a'
						id="menuBarfirewall"
						onClick={() => (localStorage.setItem("tabRobots", "firewall"), this.props.history.push("/barmetal"))} className={currentLocation.includes('barmetal')&&(localStorage.getItem("tabRobots")=="firewall") ? 'sidebar-active' : ''}>
						<img src="\Firewall giorno.png" style={{ display: 'inline-block' }} height="40px" width="40px"/>
					</Menu.Item>
				) : null}*/}
					{this.state.multitenOptions.id ? this.state.multitenOptions.clients ? <Popup className="toolt" content={this.props.intl.formatMessage({id: "dash.utilities"})} position="right center" trigger={<Menu.Item onClick={() => {this.state.showClient ? this.setState({showClient: false, showServer: false, showBarmetal: false, showDomini: false}) : 
						this.setState({showClient: true, showServer: false, showBarmetal: false, showDomini: false})}}>
						<img id="sideClientMen" src="\clientmenu.png" style={{ display: 'inline-block'}}/>
					</Menu.Item>}/> : null : <Popup className="toolt" content={this.props.intl.formatMessage({id: "dash.utilities"})} position="right center" trigger={<Menu.Item onClick={() => {this.state.showClient ? this.setState({showClient: false, showServer: false, showBarmetal: false, showDomini: false}) : 
						this.setState({showClient: true, showServer: false, showBarmetal: false, showDomini: false})}}>
						<img id="sideClientMen" src="\clientmenu.png" style={{ display: 'inline-block'}}/>
					</Menu.Item>}/>}
				{!this.state.multitenOptions.id ? this.state.showClient || currentLocation.includes('services') || currentLocation.includes('contactlist') || currentLocation.includes('multitenancy') || currentLocation.includes('billing') ? (
					<Popup className="toolt" content={this.props.intl.formatMessage({id: "nav.services"})} position="right center" trigger={<Menu.Item
						as='a'
						id="menuServizi"
						onClick={() => this.props.history.push("/services")} className={currentLocation.includes('services') ? 'sidebar-active' : ''}>
						<img id="sideServizi" src="\servizi attivi.png" style={{ display: 'inline-block'}}/>
					</Menu.Item>}/>
				) : null : null}
				{this.state.showClient || currentLocation.includes('services') || currentLocation.includes('contactlist') || currentLocation.includes('multitenancy') || currentLocation.includes('billing') ? (
					<Popup className="toolt" content={this.props.intl.formatMessage({id: "nav.contacts"})} position="right center" trigger={<Menu.Item
						as='a'
						id="menuClienti"
						onClick={() => this.props.history.push("/contactlist")} className={currentLocation == '/contactlist' ? 'sidebar-active' : ''}>
						<img id="sideClient" src="\clients icon.png" style={{ display: 'inline-block'}} />
					</Menu.Item>}/>
				) : null}
				{!this.state.multitenOptions.id ? this.state.showClient || currentLocation.includes('services') || currentLocation.includes('contactlist') || currentLocation.includes('multitenancy') || currentLocation.includes('billing') ? (
					<Popup className="toolt" content={"Multitenancy"} position="right center" trigger={<Menu.Item
						as='a'
						id="menuMultitenancy"
						onClick={() => this.props.history.push("/multitenancy")} className={currentLocation == '/multitenancy' ? 'sidebar-active' : ''}>
						<img id="sideMultiten" src="\multitenancy giorno.png" style={{ display: 'inline-block'}}/>
					</Menu.Item>}/>
				) : null : null}
				{!this.state.multitenOptions.id ? this.state.showClient || currentLocation.includes('services') || currentLocation.includes('contactlist') || currentLocation.includes('multitenancy') || currentLocation.includes('billing') ? (
					<Popup className="toolt" content={this.props.intl.formatMessage({id: "bill.invoices"})} position="right center" trigger={<Menu.Item
						as='a'
						id="menuFatture"
						onClick={() => this.props.history.push("/billing")} className={currentLocation == '/billing' ? 'sidebar-active' : ''}>
						<img id="sideFatture" src="/fatture.png" style={{ display: 'inline-block'}}/>
					</Menu.Item>}/>
				) : null : null}
				<Popup className="toolt" content={this.props.intl.formatMessage({id: "dash.ticket"})} position="right center" trigger={<Menu.Item onClick={() => {this.props.history.push("/tickets")}} className={currentLocation == '/tickets' ? 'sidebar-active' : ''}>
					<img id="sideTicket" src="\Tickets giorno.png" style={{ display: 'inline-block'}}/>
					</Menu.Item>}/>
                {this.state.isAdmin ? <Popup className="toolt" content={"Admin"} position="right center" trigger={<Menu.Item as='a' onClick={() => this.props.history.push("/partners")} data-tip={formatMessage({ id: "nav.partners" })} className={currentLocation == '/partners' ? 'sidebar-active' : ''}>
                        <img id="sideAdmin" src="/Admin.png" style={{ display: 'inline-block'}}/>
                </Menu.Item>}/> : null}
            </Sidebar>
        )
    }
}

export default injectIntl(LeftSidebar);