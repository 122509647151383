import React, { Component } from 'react';
import axios from 'axios';
import { Form, Modal, Dropdown, Button, Icon, Popup, Message } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

class ModalAssignTo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            domainArray : [],
            serverArray : [],
            domainsAssigned : [],
            serversAssigned : [],
            contact : "",
            purgedDomainArray:[],
            purgedServerArray:[],
            statewritten: false
        }
    }
   
    handleOpen = async () =>{
        this.getAssigned()
        this.setState({modalOpen: true})
    }

    handleClose = () =>{
        this.props.onClose();
        this.setState({modalOpen: false})
    }

    getAssigned = async () =>{
        let domainArray
        let serverArray
        await axios.get(`${process.env.REACT_APP_API_URL}/api/multitenancy/assign`)
        .then(async (response) => {
            domainArray= response.data.domainArray
            serverArray= response.data.serverArray
        })
        let domainsAssigned = [];
        for(let domain of domainArray){ //array con tutti i domini assegnati al singolo user
            if(domain.assignedTo === this.props.secondleveluserid){
                domainsAssigned.push(domain.name);
            }
        }
        let serversAssigned = [];
        for(let server of serverArray){  //array con tutti i server assegnati al singolo user
            if(server.assignedTo === this.props.secondleveluserid){
                serversAssigned.push(server.server);
            }
        }
        let domainsAlreadyAssigned = [];
        for(let domain of domainArray){ //per gli admin -> assegnati ma ad altri
            if(domain.assignedTo != "-1"){
                domainsAlreadyAssigned.push(domain.name);
            }
        }
        let serversAlreadyAssigned = [];
        for(let server of serverArray){ //per gli admin -> assegnati ma ad altri
            if(server.assignedTo != "-1"){
                serversAlreadyAssigned.push(server.server);
            }
        }
        let purgedDomainArray = []
        let purgedServerArray = []
        for(let domain of domainsAssigned){
            purgedDomainArray.push({value: domain, text: domain})
        }
        for(let domain of domainArray){ //domini disponibili
            if (!domainsAlreadyAssigned.includes(domain.name)){
                purgedDomainArray.push({value: domain.name, text: domain.name})
            }
        }
        for(let server of serversAssigned){
            purgedServerArray.push({value: server, text: server})
        }
        for(let server of serverArray){ //server disponibili
            if (!serversAlreadyAssigned.includes(server.server)){
                purgedServerArray.push({value: server.server, text: server.server})
            }
        }
        this.setState({
            domainArray : domainArray,
            serverArray : serverArray,
            serversAssigned: serversAssigned,
            domainsAssigned: domainsAssigned,
            purgedDomainArray : purgedDomainArray,
            purgedServerArray : purgedServerArray
        })
    }

    onChangeDomains = async (e, data) => {
        await this.setState({ domainsAssigned: data.value });
    };
    onChangeServers = async (e, data) => {
        await this.setState({ serversAssigned: data.value });
    };
    
    assignTo = async () =>{
        this.props.notify("Caricamento", "info", "add", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/multitenancy/assignto`, {secondleveluserid: this.props.secondleveluserid, serversAssigned: this.state.serversAssigned, domainsAssigned: this.state.domainsAssigned})
            .then((response) => {
                if(response.status === 200){
                    this.props.notify(this.props.intl.formatMessage({ id: "domain.assignsuccess" }), "success", "add", true)
                }
                this.handleClose();
            })
            .catch((err) => {
                this.props.notify(this.props.intl.formatMessage({ id: "domain.assignerror" }), "error", "add", true)
            })
        this.getAssigned();
    }

    render() {
        const { open, onClose, type } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            //const ModalModalExample = ({ onChange, addServer, addContact, type, formData }) => (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    open={this.state.modalOpen}
                    onClose={this.handleClose}
					trigger={
						<Popup className="toolt" position="bottom center" content="Questa funzione è abilitata per i contatti che possiedono un account di secondo livello" trigger={<Button icon size={"small"} onClick={this.handleOpen} disabled = {this.props.secondleveluserid != -1 ? false : true}>
							<Icon name={type.Icon} title={type.ButtonLabel} />
						</Button>}/>
					}
                    style = {{width: "30%"}}
                >
                <Modal.Header>
                    <FormattedMessage id="domain.assign" />
                </Modal.Header>
                    <Modal.Content>
                        <Message color="yellow">
                            <p>Se si dispone di un considerevole numero di server e domini si prega di attendere il caricamento prima che diventino visibili nelle tendine sottostanti</p>
                        </Message>
                        <div style = {{ "padding-left": "0", "padding-right": "2rem", "padding-bottom": "1rem" }}>
                            <label> {this.props.intl.formatMessage({ id: "domain.assign" })} </label>
                        </div>
                        <div>
                            <Form.Dropdown 
                                placeholder="Domains"
                                style = {{"border-width" : "3px", "border-style" : "groove", "max-width": "100%", "border-radius" : "5px", "border-color" : "#000000"}}
                                search
                                value = {this.state.domainsAssigned}
                                onChange={this.onChangeDomains}
                                name="domain"
                                clearable
                                multiple
                                selection
                                options={this.state.purgedDomainArray}
                            />
                        </div>
                        <div style = {{"margin-top":"15px"}} />
                        <div style = {{ "padding-left": "0", "padding-right": "2rem", "padding-bottom": "1rem" }}>
                            <label> {this.props.intl.formatMessage({ id: "srv.assign" })} </label>
                        </div>
                        <div>
                            <Dropdown 
                                placeholder="Servers"
                                style = {{"border-width" : "3px", "border-style" : "groove", "max-width": "100%", "border-radius" : "5px", "border-color" : "#000000"}}
                                search
                                onChange={this.onChangeServers}
                                value = {this.state.serversAssigned}
                                name="server"
                                clearable
                                multiple
                                selection
                                options={this.state.purgedServerArray}
                            />
                        </div>
                        <Form.Button style = {{ "margin-left" : "80%", "margin-bottom" : "3%", "margin-top" : "3%"}}  onClick={this.assignTo}>
                            {formatMessage({ id: "app.save" })}
                        </Form.Button>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalAssignTo);
