import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Icon } from 'semantic-ui-react';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import NotifyComponent from '../notify/notifyComponent';

class ResetPass extends Component {
  constructor(props) {
    super(props);

    this.checkHash = this.checkHash.bind(this);
    this.checkHash();

    this.state = {
      password: "",
      password2: "",
      hash: this.props.match.params.h,
      disabled: true,
      inputType: "password",
      openNotify: false,
      notifyType: "",
      notifyContent: "",
      notifyIcon: "",
      completed: false
    };
  }

  componentWillUnmount = () => {
		window.stop();
		window.clearTimeout()
	}

  sleep = (ms) => {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	notify = (content, type, icon, completed) => {
		if(!this.state.openNotify) {            
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed,
				openNotify: true
			}, async () => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
		} else {
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed
			}, async() => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
			
		}
	}

  checkHash = () => {
    axios.post(
      `${process.env.REACT_APP_API_URL}/api/users/resetpass`, { hash: this.props.match.params.h })
      .then((response) => {
        if (response.data.status === 'SUCCESS') {
          this.setState({ disabled: false });
        }
      })
      .catch(e => {
        console.log(e);
        this.notify(this.props.intl.formatMessage({ id: "login.reset_hash_wrong" }), "error", 'close', true);
        this.props.history.push("/login");
      });
  };

  onChange = e => {
    let valuetocheck = e.target.value
    e.target.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
    this.setState({ [e.target.name]: e.target.value });
  };

  switchPassword = () => {
    if(this.state.inputType == "password") {
        this.setState({inputType: "text"})
    } else {
        this.setState({inputType: "password"})
    }
}

  onSubmit = e => {
    e.preventDefault();
    this.notify("caricamento", "info", 'undo', false);
    const { password, hash } = this.state;
    if (this.state.password == this.state.password2) {
      axios.put(`${process.env.REACT_APP_API_URL}/api/users/resetpass`, {
        password,
        hash
      })
        .then(response => {
          this.notify(this.props.intl.formatMessage({ id: "app.passwords_changed" }), "success", 'undo', true);
          this.props.history.push("/login");
        })
        .catch((error) => {
          console.log("" + error)
          this.notify("Mail not confermed", "error", 'undo', true)
        })
    } else {
      this.notify(this.props.intl.formatMessage({ id: "app.passwords_not_match" }), "error", 'undo', true);
      //this.props.showModal("Le due password non coincidono!");
    }
  };

  render() {
    const { password, password2 } = this.state;
    return (
      <form className="form-login" onSubmit={this.onSubmit}>
        <div className="row">
          <div
            className="col-12 text-left"
            style={{ paddingTop: "8px", paddingBottom: "4px" }}
          >
            <h5><FormattedMessage id="login.new_pwd_title" /></h5>
          </div>
        </div>
        <hr className="reduced-hr-margin" style={{ marginTop: 0 }} />
        <div className="form-group text-left">
          <label><FormattedMessage id="app.new_password" /></label>
          <input
            disabled={this.state.disabled}
            type={this.state.inputType}
            icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword()} />} iconPosition='right'
            name="password"
            className="form-control"
            required="required"
            value={password}
            onChange={this.onChange}
          />
        </div>
        <div className="form-group text-left">
          <label><FormattedMessage id="app.conferm_password" /></label>
          <input
            disabled={this.state.disabled}
            type={this.state.inputType}
            icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword()} />} iconPosition='right'
            name="password2"
            className="form-control"
            required="required"
            value={password2}
            onChange={this.onChange}
          />
        </div>
        <p id="pass-not-match">Le due password non coincidono!</p>
        <div className="form-group">
          <button type="submit" className="btn btn-primary btn-block" disabled={this.state.disabled}>
            <FormattedMessage id="user.submit" />
          </button>
        </div>
        {
          this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
        }
      </form>
    );
  }
}

export default withRouter(injectIntl(ResetPass));
