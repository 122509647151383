import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl } from 'react-intl';
import NotifyComponent from '../notify/notifyComponent';

class ResetMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      locationID: "",
      openNotify: false,
      notifyType: "",
      notifyContent: "",
      notifyIcon: "",
      completed: false
    };
  }

  
  handleLogo = () => {
    let location = "" + window.location;
    if(location.includes("?id=")){
      let id = "" + location.split(['=']).pop()
      this.setState({
        locationID: id
      })
    }
  }

  componentDidMount() {
    this.handleLogo();
  }


  componentWillUnmount = () => {
		window.stop();
		window.clearTimeout()
	}

  sleep = (ms) => {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	notify = (content, type, icon, completed) => {
		if(!this.state.openNotify) {            
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed,
				openNotify: true
			}, async () => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
		} else {
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed
			}, async() => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
			
		}
	}

  onChange = e => {
    let valuetocheck = e.target.value
        e.target.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
    this.setState({ [e.target.name]: e.target.value });
  };

  sendResetEmail = (e) => {
    e.preventDefault();
    // get our form data out of state
    const { email } = this.state;
    this.setState({ email: "" });
    axios.put(`${process.env.REACT_APP_API_URL}/api/users/reset`, { email })
      .then((response) => {
        this.notify(this.props.intl.formatMessage({ id: "login.reset_password_sent" }), "success", 'undo', true);
        this.setState({ email: "" });
        //this.props.history.push("/login");
      })
      .catch(error => {
        this.setState({ email: email });
        this.notify(this.props.intl.formatMessage({ id: "login.reset_password_not_sent" }), "error", 'undo', true);
      });
  };

  render() {
    const { email } = this.state;
    return (
      <form className="form-login" onSubmit={this.sendResetEmail}>
        <div className="row">
          <div
            className="col-8 text-left"
            style={{ paddingTop: "8px", paddingBottom: "4px" }}
          >
            <h5><FormattedMessage id="login.find_password" /></h5>
          </div>
          <div className="col-4 text-right">
            <h6 id="show-login" style={{ paddingTop: "12px" }}>
              <Link className="link-blue" to={this.state.locationID != "" ? "/login" + "?id=" + this.state.locationID : "/login"}>
                <FormattedMessage id="app.back" />
              </Link>
            </h6>
          </div>
        </div>
        <hr className="reduced-hr-margin mt-0 mb-3" />
        <div className="form-group text-left">
          <label><FormattedMessage id="login.insert_email" /></label>
          <input
            type="email"
            className="form-control"
            name="email"
            required="required"
            placeholder=""
            value={email}
            onChange={this.onChange}
          />
        </div>
        {/* <p id="pass-not-match">Le due password non coincidono!</p> */}
        <div className="form-group">
          <button className="btn btn-primary btn-block" disabled={this.state.email === ''}>
            <FormattedMessage id="login.reset" />
          </button>
        </div>
        {
          this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
        }
      </form>
    );
  }
}

export default withRouter(injectIntl(ResetMail));
