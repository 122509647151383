import React, { Component } from 'react';
import {Modal, Form, TextArea} from 'semantic-ui-react';
import axios from 'axios';
import { injectIntl } from 'react-intl';

class ModalAddSSH extends Component {
    constructor(props) {
        super(props);
		this.instance = React.createRef();
        this.state = {
            text1:"",
            text2:"",
            allservers:[]
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    save = async() => {
        this.props.notify("Caricamento", "info", "save", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/addssh`, {id: this.state.server_id, private: this.state.text1, public: this.state.text2})
            .then((response) => {
                this.props.notify("Creato correttamente!", "success", "save", true)
                this.props.onClose()
            })
            .catch((error) => {
                console.log(error)
                this.props.notify("" + error, 'error', 'save', true);
            })
    }

    getAllServerDetails = async (id) => {
        const serverId = id;
        let status
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/getallserversfromdb`)
            .then((response) => {
                this.setState({
                    allservers: response.data
                })
            })
        return status
    };

    generate = async() => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/generatessh`)
            .then((response) => {
                this.setState({
                    text1: response.data.text1,
                    text2: response.data.text2
                })
            })
            .catch((error) => {
                console.log(error)
                this.props.notify("" + error, 'warning', 'add', true);
            })
    }

    firstSetUp = () => {
        if(!this.state.statewritten && this.props.open){
            this.getAllServerDetails()
            this.setState({
                statewritten: true
            })
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                statewritten: false
            })
        }
    }
    
    async handleInputChange(event, { name, value }) {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        await this.setState({
            [name]: value
        });
    }

    onChangeServer = async (e, data) => {
        await this.setState({ server_id: data.value });
    };

    render() {
        const {open, onClose} = this.props;
        const { formatMessage } = this.props.intl;
        this.firstSetUp();
        return (
            <div>
                <Modal closeIcon={true}
                    className="overflow-yes"
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto', width: '30%' }}
                    open={open}
                    onClose={() => onClose(false)}>
                    <Modal.Header>
                        Aggiungi Chiavi
                    </Modal.Header>
                    <Modal.Content className="modal-min-height">
                        <Modal.Description>
                                <div className="server-form">
                                    <Form className="overflow-yes">
                                        <Form.Button onClick={() => {this.generate()}} >Generate Key Pair</Form.Button>
                                        <Form.Field type="text" value={this.state.text1} label={"SSH Privata"} name={"text1"} control={TextArea} onChange={this.handleInputChange} placeholder="Scrivi qui il testo delle istruzioni ricordando di andare a capo ad ogni frase" style={{border:"1px solid gray", "min-height":"45px", "max-height":"500px", "height":"100px"}}/>
                                        <Form.Field type="text" value={this.state.text2} label={"SSH Pubblica"} name={"text2"} control={TextArea} onChange={this.handleInputChange} placeholder="Scrivi qui il testo delle istruzioni ricordando di andare a capo ad ogni frase" style={{border:"1px solid gray", "min-height":"45px", "max-height":"500px", "height":"100px"}}/>
                                        <Form.Dropdown 
                                            placeholder="Server"
                                            search
                                            onChange={this.onChangeServer}
                                            name="server"
                                            clearable
                                            selection
                                            label = "Assegna chiavi ad un Server:"
                                            options={this.state.allservers.map(({ server, server_id }) => ({ value: server_id, text: server }))}
                                            value = {this.state.server_id}
                                        /> 
                                        <Form.Button onClick={() => {this.save()}} >
                                            {formatMessage({ id: "app.save" })}
                                        </Form.Button>
                                    </Form>
                                </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalAddSSH);