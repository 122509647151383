import React, { Component } from 'react';
import axios from 'axios';
import { Form, Modal, Dropdown, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';


class ModalAssignToCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serversArray : [],
            contact : ""
        }
    }

    getServers = async() => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/getallserversfromdb`)
            .then(async (response) => {
                for(let server of response.data){
                    if(server.isp_id == this.props.row.id){
                        this.setState({server_id: server.id})
                    }
                }
                this.setState({serversArray:response.data})
            })
            .catch((err) => {
                console.log(err);
            })
    }
    

    assignTo = async () =>{
        this.props.notify("Caricamento", "info", "add", false)
        if (this.state.server_id) {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/assigntoserver`, {isp_id:this.props.row.id, server_id:this.state.server_id})
                .then((response) => {
                    this.props.notify("Progetto correttamente assegnato", "success", 'add', true)
                    this.props.onClose()
                    })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            this.props.notify("Seleziona prima un server", "error", 'add', true)
        }
    }

    onChangeServer = async (e, data) => {
        await this.setState({ server_id: data.value });
    };

    firstSetUp = () => {
        if(!this.state.statewritten && this.props.open){
            this.getServers()
            this.setState({
                statewritten: true
            })
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                statewritten: false,
                server_id: null
            })
        }
    }

    render() {
        const { open, onClose, type } = this.props;
        const { formatMessage } = this.props.intl;
        this.firstSetUp()
        return (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    style = {{width: "30%"}}
                >
                <Modal.Header>
                    Assegna Progetto
                </Modal.Header>
                    <Modal.Content>
                        <Form className='ConfiguratorForms overflow-yes'>
                            <Form.Dropdown 
                                placeholder="Assegna a Server"
                                search
                                onChange={this.onChangeServer}
                                name="server"
                                clearable
                                selection
                                label = "Assegna il progetto al Server:"
                                options={this.state.serversArray.map(({ server, id }) => ({ value: id, text: server }))}
                                value = {this.state.server_id}
                            />
                        <Form.Button onClick={this.assignTo} className="save-button">
                            {formatMessage({ id: "app.save" })}
                        </Form.Button>
                        </Form>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalAssignToCustomer);
