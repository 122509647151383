import React, { Component } from 'react';
import axios from 'axios';
import { Modal, Dropdown, Button, Select, Form, Label } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

class ModalRetryAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
            registrantid: 0,
            adminid: 0,
            techid: 0,
            billingid: 0,
            dnsServers:[],
            dnsServer: 0,
            domainName: "",
            registrar: "internetbs",
            action: "register",
            authcode: "",
            registrantid: "",
            techid: "",
            billingid: "",
            adminid: "",
            registrantidname: "",
            techidname: "",
            billingidname: "",
            adminidname: "",
            mailServers: [],
            webServers: [],
            dnsServers: [],
            mailServer: "",
            webServer: "",
            response: []
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.isAdmin = (localStorage.getItem('isAdmin') === "true");
    }

    firstSetUp = async () => {
        if(this.props.open && !this.state.statewritten){
            this.setState({
                statewritten : true
            })
            this.setState({
                registrantid: this.props.domain.registrant_id,
                techid: this.props.domain.tech_id,
                billingid: this.props.domain.billing_id,
                adminid: this.props.domain.admin_id,
                dnsServer: this.props.domain.isp_id,
                domainName: this.props.domain.name,
                registrar: this.props.domain.registrar,
                action: this.props.domain.action,
                authcode: this.props.domain.authcode
            })
            await this.getDNSServers()
            await this.getMailServers()
            await this.getWebServers()
            await this.getContacts()
            if(this.props.domain.registrar == 'internetbs'){
                await this.getResponse()
            }
        }
        if(!this.props.open && this.state.statewritten){
            this.setState({
                statewritten : false
            })
        }
    }

    getResponse = async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/domains/getresponse`, {domain: this.props.domain.name})
            .then((response) => {
                this.setState({ response: response.data });
            }).catch(e => {
                console.log(e)
            })
    }

    getDNSServers = async () => {
        await axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/server/getdns`)
            .then((response) => {
                let servers = []
                for(let isp of response.data.servers){
                    servers.push(isp)
                }
                this.setState({ dnsServers: servers });
            }).catch(e => {
            })
    }
	getContacts = async () => {
		await axios.get(`${process.env.REACT_APP_API_URL}/api/contact/allcontacts`)
			.then((response) => {
				this.setState({
					contacts: response.data,
					registrantid: response.data.length > 0 ? response.data[0].id : null,
					adminid: response.data.length > 0 ? response.data[0].id : null,
					techid: response.data.length > 0 ? response.data[0].id : null,
					billingid: response.data.length > 0 ? response.data[0].id : null
				});
			})
	};
    

    handleInputChange = (event, { name, value, text }) => {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        let nuovonome = name + "name"
        this.setState({
            [name]: value,
            [nuovonome] : text
        });
    }
    
    onChange = (event, val) => {
        this.setState({ [val.name]: val.value }, () => { //async
            //this.prepareServers();
        });
    }

    
    getWebServers = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/server/getWebServers`
            )
            .then((response) => {
                this.setState({ webServers: response.data.servers });
                this.setState({ webServer: response.data.servers[0].ip }, () => {
                });
            }).catch(e => {
            })
    }

    getMailServers = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/server/getMailServers`
            )
            .then((response) => {
                this.setState({ mailServers: response.data.servers })
                this.setState({ mailServer: response.data.servers[0].ip }, () => {
                });
            }).catch(e => {
            })
    }


    submit = async () => {
        if (this.state.response.includes("Auth Info") || this.props.domain.transferstatus.includes("Auth Info")){
            axios.post(`${process.env.REACT_APP_API_URL}/api/domains/retry`,{domain:this.props.domain.name, authinfo: this.state.authcode}
            ).then((response) => {
                if (response.data.status.toUpperCase() == 'SUCCESS') {
                    this.props.notify(<FormattedMessage id="notify.retry-domain-command" />, 'success', "undo", true);
                    this.props.onClose()
                }
            }).catch(e => {
                if(e.response) if(e.response.data) if(e.response.data.message){
                    this.props.notify(e.response.data.message, 'error', "undo", true);
                } else {
                    this.props.notify(""+e.response.data, 'error', "undo", true);
                }
            })
        } else {
            let contacts = {
                registrant: { id: this.state.registrantid, fullname: this.state.registrantidname },
                admin: { id: this.state.adminid, fullname: this.state.adminidname },
                technical: { id:  this.state.techid, fullname: this.state.techidname },
                billing: { id:  this.state.billingid, fullname: this.state.billingidname }
            }
            let servers = {
                server_web: this.state.webServer,
                server_email: this.state.mailServer,
                isp_id: this.state.dnsServer
            }
            this.props.notify("eseguendo il comando...", "info", "undo", false)
            let domain = { domain: this.props.domain.name, duration: '1Y', contacts: contacts, action: this.state.action, authcode: this.state.authcode, servers: servers, registrar: this.state.registrar }
            axios.post(`${process.env.REACT_APP_API_URL}/api/domains/saveDomain`,
                {
                    domainDetails: domain,
                    retry: true
                }
            ).then((response) => {
                if (response.data.status === 'success') {
                    this.props.notify(<FormattedMessage id="notify.retry-domain-command" />, 'success', "undo", true);
                    //refactor - when domain will be registered //saveDns(dns, s.data.zoneid, s.data.domainid)
                    //history.push("/") - go to index
                }
            }).catch(e => {
                if(e.response) if(e.response.data) if(e.response.data.message)
                    this.props.notify(e.response.data.message, 'error', "undo", true);
            })
        }
}
    
    registrar = [
        {
            key: 'internetbs',
            text: 'internetbs',
            value: 'internetbs'
        },
        {
            key: 'ascio',
            text: 'ascio',
            value: 'ascio'
        }];

    action = [
        {
            key: 'register',
            text: 'Registrazione',
            value: 'register'
        },
        {
            key: 'transfer',
            text: 'Trasferimento',
            value: 'transfer'
        }];


    render() {
        const { open, onClose } = this.props;
        const { formatMessage } = this.props.intl;
        
        this.firstSetUp()
        return (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto', width: '50%', height: '110%' }}
                    open={open}
                    onClose={() => onClose(false)}
                >
                    <Modal.Header>
                        Ritenta Azione
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>  
                            
                        <Form style={{width:"100%", "overflow-wrap":"wrap-word"}} className="overflow-yes"> 
                                <Form.Group>
                                    <Form.Input label={"Nome Dominio"} value={this.state.domainName || ''} name="domainName" onChange={this.handleInputChange} />
                                    {this.isAdmin ? <Form.Field
                                        control={Select}
                                        name='registrar'
                                        label={"Registrar"}
                                        options={this.registrar}
                                        onChange={this.handleInputChange}
                                        value={this.state.registrar || ''}
                                    /> : null}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        control={Select}
                                        name='action'
                                        label={"Azione"}
                                        options={this.action}
                                        onChange={this.handleInputChange}
                                        value={this.state.action || ''}
                                    />
                                    {this.state.action == 'transfer' ? 
                                        <Form.Input label={"Authcode"} value={this.state.authcode || ''} name="authcode" onChange={this.onChange} />
                                    : null}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        control={Select}
                                        onChange={this.handleInputChange}
                                        label={"Registrant ID"}
                                        name="registrantid"
                                        selection
                                        options={this.state.contacts.map(({ id, email }) => ({ value: id, text: email }))}
                                        value={this.state.registrantid}
                                    />
                                    <Form.Field
                                        control={Select}
                                        onChange={this.handleInputChange}
                                        label={"Admin ID"}
                                        name="adminid"
                                        selection
                                        options={this.state.contacts.map(({ id, email }) => ({ value: id, text: email }))}
                                        value={this.state.adminid}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Field
                                        control={Select}
                                        onChange={this.handleInputChange}
                                        label={"Tech ID"}
                                        name="techid"
                                        selection
                                        options={this.state.contacts.map(({ id, email }) => ({ value: id, text: email }))}
                                        value={this.state.techid}
                                    />
                                    <Form.Field
                                        control={Select}
                                        onChange={this.handleInputChange}
                                        label={"Billing ID"}
                                        name="billingid"
                                        selection
                                        options={this.state.contacts.map(({ id, email }) => ({ value: id, text: email }))}
                                        value={this.state.billingid}
                                    />
                                </Form.Group>
                                <Form.Group>  
                                    <Form.Field
                                        className='overflow-yes'
                                        control={Select}
                                        placeholder="type"
                                        onChange={this.handleInputChange}
                                        label={"Mail Server"}
                                        name="mailServer"
                                        // fluid
                                        selection
                                        options={this.state.mailServers.map(({ ip, name }) => ({ value: ip, text: name }))}
                                        value={this.state.mailServer}
                                    />
                                    <Form.Field
                                        className='overflow-yes'
                                        control={Select}
                                        placeholder="type"
                                        onChange={this.handleInputChange}
                                        label={"Web Server"}
                                        name="webServer"
                                        selection
                                        options={this.state.webServers.map(({ ip, name }) => ({ value: ip, text: name }))}
                                        value={this.state.webServer}
                                    />
                                    <Form.Field
                                        className='overflow-yes'
                                        control={Select}
                                        placeholder="type"
                                        onChange={this.handleInputChange}
                                        label={"DNS Server"}
                                        name="dnsServer"
                                        selection
                                        options={this.state.dnsServers.map(({ id, description }) => ({ value: id, text: description }))}
                                        value={this.state.dnsServer}
                                    />
                                </Form.Group>
                                
                                <Form.Group>
                                    {this.state.response.length != 0 ? <p className="ui horizontal red label">{this.state.response.includes("Billing problem") ? "Operation error, please try again later" : this.state.response}</p> : null}
                                    {this.props.domain.transferstatus ? this.props.domain.transferstatus ? <p className="ui horizontal red label">{this.props.domain.transferstatus.includes("Billing problem") ? "Operation error, please try again later" : this.props.domain.transferstatus}</p> : null : null}
                                </Form.Group>
                              
                            </Form>
                        <div style = {{"margin-top":"5%", "text-align":"center", "position":"relative"}} >
                            <Button onClick={this.submit}><FormattedMessage id="app.save" /></Button>
                        </div> 
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalRetryAction);