import React, { Component } from 'react';
import axios from 'axios';
import { Form, Modal } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

class ModalWithoutButtonDomian extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domaininfo: {},
            domain: '',
            id_domain: '0',
            partners: [],
            partner: '',
            registrant_id: '',
            admin_id: '',
            tech_id: '',
            biling_id: '',
            contacts: []
        }
        this.isAdmin = (localStorage.getItem('isAdmin') === "true");
        if (this.isAdmin) {
            this.loadPartners();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedDomain !== this.props.selectedDomain) {
            const currentDomain = this.props.selectedDomain;
            this.setState({
                partner: currentDomain.userid,
                registrant_id: currentDomain.registrant_id,
                admin_id: currentDomain.admin_id,
                tech_id: currentDomain.tech_id,
                billing_id: currentDomain.billing_id,
                domain: currentDomain.name,
                id_domain: currentDomain.id
            });
            let contactsid = `${currentDomain.admin_id},${currentDomain.registrant_id},${currentDomain.tech_id},${currentDomain.billing_id}`;
            this.loadContact(contactsid);
            //this.loadDomain(this.props.selectedDomain.id);
        }
    }

    loadContact = (id) => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/contact/contact/(${id})`)
            .then((response) => {
                this.setState({
                    contacts: response.data,
                });
            })
            .catch(function (e) {
                console.log(e);
            });
    };

    loadDomain = (domainid) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/domains/${domainid}`
        ).then((res) => {
            this.setState({
                domaininfo: res.data
            });
        });
    }

    loadPartners = () => {
        //axios.get(`${process.env.REACT_APP_API_URL}/api/users`)
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then((response) => {
                this.setState({
                    partners: response.data
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    handlePartnerChange = (e, { value }) => this.setState({ partner: value });

    changePartner = () => {        
        this.props.notify("Caricamento", "info", "pencil", false)
        const { formatMessage } = this.props.intl;
        const id_domain = this.state.id_domain;
        axios
            .patch(`${process.env.REACT_APP_API_URL}/api/domains/${id_domain}`, { userid: this.state.partner })
            .then((response) => {
                this.props.notify(formatMessage({ id: "app.partner_changed" }), "success", "pencil", true)
                this.props.onClose(true);
            })
            .catch(function (error) {
                console.log(error);
                this.props.notify(formatMessage({ id: "app.partner_not_changed" }) + "\n" + error.response.data.message, "error", "pencil", true)
            });
    }

    render() {
        const { open, onClose } = this.props;
        const { formatMessage } = this.props.intl;
        const isAdminUser = (localStorage.getItem('isAdmin') === "true");
        return (
            //const ModalModalExample = ({ onChange, addServer, addContact, type, formData }) => (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto' }}
                    open={open}
                    onClose={() => onClose(false)}
                >
                    <Modal.Header>
                        <FormattedMessage id="app.domain_info" />
                    </Modal.Header>
                    <Modal.Content className="modal-min-height">
                        <Modal.Description>
                            <div>
                                <div className="server-form">
                                    <Form className="overflow-yes">
                                        <Form.Group>
                                            <Form.Input width={16} type="text" label={formatMessage({ id: "app.domain" })} value={this.state.domain || ''} name="description" readOnly />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Dropdown
                                                label={formatMessage({ id: "reg.registrtant_contact" })}
                                                readOnly
                                                value={this.state.registrant_id}
                                                fluid
                                                selection
                                                options={this.state.contacts.map(({ firstname, lastname, id }) => ({ value: id, text: [firstname, lastname].join(' ') }))}
                                            />
                                            <Form.Dropdown
                                                label={formatMessage({ id: "reg.admin_contact" })}
                                                readOnly
                                                value={this.state.admin_id}
                                                fluid
                                                selection
                                                options={this.state.contacts.map(({ firstname, lastname, id }) => ({ value: id, text: [firstname, lastname].join(' ') }))}
                                            />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Dropdown
                                                label={formatMessage({ id: "reg.technical_contact" })}
                                                readOnly
                                                value={this.state.tech_id}
                                                fluid
                                                selection
                                                options={this.state.contacts.map(({ firstname, lastname, id }) => ({ value: id, text: [firstname, lastname].join(' ') }))}
                                            />
                                            <Form.Dropdown
                                                label={formatMessage({ id: "reg.billing_contact" })}
                                                readOnly
                                                value={this.state.billing_id}
                                                fluid
                                                selection
                                                options={this.state.contacts.map(({ firstname, lastname, id }) => ({ value: id, text: [firstname, lastname].join(' ') }))}
                                            />
                                        </Form.Group>
                                        {isAdminUser ?
                                            ([<Form.Group>
                                                <Form.Dropdown
                                                    label="Partner"
                                                    className="parnter-list"
                                                    placeholder=""
                                                    onChange={this.handlePartnerChange}
                                                    value={this.state.partner || ''}
                                                    name="partner"
                                                    selection
                                                    search
                                                    options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                                                />
                                            </Form.Group>,
                                            <Form.Group>
                                                <div className="field"></div>
                                                <Form.Button floated="right" onClick={() => this.changePartner()} className="save-button" disabled={this.state.disableSubmit}>
                                                    {formatMessage({ id: "app.save" })}
                                                </Form.Button>
                                            </Form.Group>])
                                            :
                                            <Form.Group>
                                                <div className="field"></div>
                                                <Form.Button floated="right" onClick={() => onClose(false)} className="save-button" >
                                                    {formatMessage({ id: "app.close" })}
                                                </Form.Button>
                                            </Form.Group>
                                        }
                                    </Form>
                                </div>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalWithoutButtonDomian);
