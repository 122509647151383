import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import "./notifyComponent.scss"
import { Button, Input, Grid, Popup, Icon, Image, Select, Checkbox, Tab, Menu } from 'semantic-ui-react';
import axios from 'axios';

class NotifyComponent extends Component {
    constructor(props) {
        super(props);
        this.state={
            content: "",
            type: "",
            time: 0,
            notifyTitle: 'Info'
        }

    }

    componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    verifyProgress = async () => {
        if(this.props.completed) {
            document.querySelector(':root').style.setProperty('--awaiting', 'running')
            let attesa = 5*25/100
            await this.sleep(attesa*1000);
            this.setState({
                content: this.props.content,
                type: this.props.type
            })
            if(this.props.type == 'info') {
                this.setState({
                    notifyTitle: "Informazione"
                })
            } else if(this.props.type == 'success') {
                this.setState({
                    notifyTitle: "Operazione ruscita"
                })
            } else if(this.props.type == 'error') {
                this.setState({
                    notifyTitle: "Errore"
                })
            } else if(this.props.type == 'warning') {
                this.setState({
                    notifyTitle: "Attenzione!"
                })
            }        
        } else {
            await this.sleep(1000);
            try {
               this.verifyProgress(); 
            } catch {
                console.log("stack overflow")
            }   
            
        }
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    firstSetup = () => {
        if(!this.state.statewritten && this.props.open){
            this.setState({
                statewritten: true
            }, async () => {
                if(!this.props.completed) {
                    let attesa = 5*50/100
                    await this.sleep(attesa*1000);
                    document.querySelector(':root').style.setProperty('--awaiting', 'paused');
                    this.verifyProgress();
                } else {
                    this.setState({
                        content: this.props.content,
                        type: this.props.type,
                    })
                    document.querySelector(':root').style.setProperty('--op', 0);
                    if(this.props.type == 'info') {
                        document.querySelector(':root').style.setProperty('--colorOfNotify', '#2185d0');
                    } else if(this.props.type == 'success') {
                        document.querySelector(':root').style.setProperty('--colorOfNotify', '#21BA45');
                    } else if(this.props.type == 'error') {
                        document.querySelector(':root').style.setProperty('--colorOfNotify', '#db2828');
                    } else if(this.props.type == 'warning') {
                        document.querySelector(':root').style.setProperty('--colorOfNotify', '#fbbd08');
                    }
                }
                
            })
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                statewritten: false
            })
        }
    }

    componentDidMount = () => {
        document.querySelector(':root').style.setProperty('--op', 1);
        document.querySelector(':root').style.setProperty('--colorOfNotify', 'white');
        if(this.props.type == 'info') {
            this.setState({
                notifyTitle: "Informazione"
            })
        } else if(this.props.type == 'success') {
            this.setState({
                notifyTitle: "Operazione ruscita"
            })
        } else if(this.props.type == 'error') {
            this.setState({
                notifyTitle: "Errore"
            })
        } else if(this.props.type == 'warning') {
            this.setState({
                notifyTitle: "Attenzione!"
            })
        }
        this.setState({
            content: <FormattedMessage id="notify.loading"/>,
            type: "info"
        })        
    }

    render() {
        const { onClose } = this.props;
        this.firstSetup();
        return(
            <div id="notifyBody" className={this.state.type}>
                <div id="notifyHeader"><span>{this.state.notifyTitle}</span><Icon name="delete" style={{cursor: "pointer"}} onClick={() => onClose(false)}/></div>
                <div className="notifyCommandContainer">
                    <Icon className="notifyIcon" name={this.props.icon} size="large"/>
                    <div className="notifyCommand"><label><span>{this.state.content}</span></label></div>
                </div>
                <div className="notifyProgressBar"/>
            </div>
        )
    }
}

export default injectIntl(NotifyComponent)