import React, { Component } from 'react';
import '../All/table.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Dropdown, Button, Form, Checkbox } from 'semantic-ui-react';
import axios from 'axios';
import { getOSIcon } from 'react-os-icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import '../vmservers/vmservers.scss';
import ModalConferm from '../All/ModalConferm';
import ModalEditSSH from './ModalEditSSH';
import ModalTestSSH from './ModalTestSSH';
import ModalAddSSH from './ModalAddSSH';
import moment from 'moment';
import NotifyComponent from '../notify/notifyComponent';

const { SearchBar, ClearSearchButton } = Search;

class ConfigurationServerTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: -1,
            user: null,
            servers: [],
            partners: [],
            partnerId: '',
            offset: 0,
            page: 1,
            totalSize: 0,
            sizePerPage: 10,
            currentIndex: 0,
            loading: false,
            filter: {},
            renewProductInfo: {},
            askAPI: false,
            readyVS: false, // enable server actions
            openConfirmation: false,
            service: '',
            isSecondLevelUser: false,
            canAdd: true,
            modalAssign: false,
            assignValues: {},
            firstLevelUserID: -1,
            modalVNCOpen: false,
            server_info: {id: "19400717"},
            copiedStatus: "Copy",
            selectedServerRenew: [],
            selectedServerReboot: [],
            os: "",
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
        }
        localStorage.setItem('api-vs-key', '');
        axios.defaults.headers.common['x-api-vs-key'] = this.token;
        this.token = localStorage.getItem('jwt');
        axios.defaults.headers.common['x-auth-token'] = this.token;
        this.isAdmin = (localStorage.getItem('isAdmin') === "true");
        this.myuser = (localStorage.getItem('user'));

        this.osLoghi = [
            {
                key: "windows",
                value: "windows",
                text: getOSIcon({os: "Windows", class: "oSystem", size:21})
            },
            {
                key: "debian",
                value: "debian",
                text: getOSIcon({os: "Debian", class: "oSystem", size:21})
            },
            {
                key: "ubuntu",
                value: "ubuntu",
                text: getOSIcon({os: "Ubuntu", class: "oSystem", size:21})
            },
            {
                key: "fedora",
                value: "fedora",
                text: getOSIcon({os: "Fedora", class: "oSystem", size:21})
            }
        ]

        this.columns =  [
            {
                dataField: 'status',
                sort: true,
                classes: 'statusclass',
                text: this.props.intl.formatMessage({ id: "app.status" }),
                formatter: (cellContent, row, rowIndex, formatExtraData) => {
                    let assigned = this.props.intl.formatMessage({ id: "domain.assigned" });
                    if(!this.state.isSecondLevelUser){
                        if(row.assignedTo == "Non Assegnato") {
                            assigned = this.props.intl.formatMessage({ id: "domain.notassigned"});
                        } else {
                            assigned = assigned + " " + row.assignedTo;
                        }
                    };
                    let classDiv = "ui green horizontal label";
                    if(cellContent == 'ACTIVE'){
                        classDiv = "ui green horizontal label";
                    } else if(cellContent == 'EXPIRED' || cellContent == 'TO DELETE'){
                        classDiv = "ui red horizontal label";
                    } else if(cellContent == 'RENEW'){
                        classDiv = 'ui yellow horizontal label';
                    }
                    return (
                        <div className="d-flex">
                            <div><div className={classDiv}>{cellContent}</div><br></br><div>{assigned}</div></div>
                        </div>);
                }
                //clock outline
            }, {
                dataField: 'server',
                text: this.props.intl.formatMessage({ id: "srv.server" }),
                sort: true,
                classes: "serverclass",
                headerStyle: (colum, colIndex) => {
                    return { width: '25%' };
                },
                style: {width: "10px"},
                formatter: (val, row) => {
                    return <div>
                        <div  
                        className='textToCopy'>
                            <strong className='textToShow'
                            onClick={(e) => {e.stopPropagation();e.preventDefault()}}
                            onMouseDown={(e) => this.clickToCopy(e, row, "server")}>{row.server}</strong>
                            <div className='tagToCopy'
                            onClick={(e) => {e.stopPropagation();e.preventDefault()}}
                            onMouseDown={(e) => this.clickToCopy(e, row, "server")}>
                                Copy
                            </div>
                        </div>
                    </div>
                }
            }, {
                dataField: 'product_type',
                sort: false,
                text: "Product",
                classes: "serverclass",
                formatter: (val, row) => {
                    return <Form>
                        <div style = {{display: "inline-flex", "padding":"10px"}}>
                            <span>Site </span><Checkbox style = {{"padding-left":"4px"}} toggle checked = {row.site} onClick={(e) => {this.toggleProduct(e, row.id, "web")}}></Checkbox>
                        </div >
                        <div style = {{display: "inline-flex", "padding":"10px"}}>
                            <span>Mail </span><Checkbox style = {{"padding-left":"4px"}} toggle checked = {row.mail} onClick={(e) => {this.toggleProduct(e, row.id, "mail")}}></Checkbox>
                        </div >
                        <div style = {{display: "inline-flex", "padding":"10px"}}>
                            <span>Nameserver </span><Checkbox style = {{"padding-left":"4px"}} toggle checked = {row.nameserver} onClick={(e) => {this.toggleProduct(e, row.id, "ns")}}></Checkbox>
                        </div>
                        <div style = {{display: "inline-flex", "padding":"10px"}}>
                            <span>Gateway </span><Checkbox style = {{"padding-left":"4px"}} toggle checked = {row.gateway} onClick={(e) => {this.toggleProduct(e, row.id, "MailGateway")}}></Checkbox>
                        </div>
                    </Form>
                }
            }, {
                dataField: '',
                text: "OS",
                classes: "osColumn",
                formatter: (cellContent, row) => {
                    return <Dropdown 
                        label="OS"
                        options = {this.osLoghi}
                        onChange={(e, value) => this.setState({os: value.value}, () => this.saveOS(row.server_id, this.state.os))} 
                        defaultValue={row.os} 
                    />
                }
            }];
            if (this.isAdmin) {
                this.columns.push({
                    dataField: 'customer.name',
                    text: this.props.intl.formatMessage({ id: "app.partner" }),
                    style: { "min-width": "150px", "max-width": "150px", "word-break":"break-word"},
                    formatter: (cellContent, row) => {
                        return <Button className="buttonAction" onClick = {(e) => {e.preventDefault(); e.stopPropagation(); this.changeAccount(row)}}> {cellContent} </Button>
                    }
                });
            }
        }

        componentWillUnmount = () => {
            window.stop();
            window.clearTimeout()
        }
    
        sleep = (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    
        notify = (content, type, icon, completed) => {
            if(!this.state.openNotify) {            
                this.setState({
                    notifyContent: content,
                    notifyType: type,
                    notifyIcon: icon,
                    completed: completed,
                    openNotify: true
                }, async () => {
                    if(completed) {
                        await this.sleep(10000);
                        this.setState({
                            openNotify: false
                        })
                    }
                })
            } else {
                this.setState({
                    notifyContent: content,
                    notifyType: type,
                    notifyIcon: icon,
                    completed: completed
                }, async() => {
                    if(completed) {
                        await this.sleep(10000);
                        this.setState({
                            openNotify: false
                        })
                    }
                })
                
            }
        }

    saveOS = async (id, os) => {
        this.notify("Caricamento", "info", "save", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/change-os`, {id: id, os: os})
            .then((response) => {
                this.getServers(this.state.filter);
                this.notify("OS salvato con successo", "success", "save", true)
            })
            .catch((error) => {
                this.notify(error.message, 'error', "save", true);
            });
    }


    componentDidMount(){
        this.getAllServerDetails()
        this.loadUsers();
    }

    clickToCopy = async (e, row, type) => {
        e.stopPropagation();
        e.preventDefault();
        let elements = document.getElementsByClassName('tagToCopy')
        for(let element of elements) {
            element.innerText="Copied"
        }
        
        await this.sleep(500)
        for (let element of elements) {
            element.innerText="Copy"
        }
        navigator.clipboard.writeText(row[type]);
        
    }

    toggleProduct = async (e, id, type) => {
        e.stopPropagation();
        e.preventDefault();
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/toggle-product`, {id, type})
            .then((response) => {
                this.getServers(this.state.filter)
            })
            .catch((error) => {
                console.log("" + error)
            });   
    }

    getKeys = async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/getkeyslist`)
        .then((response) => {
            if (response.data){
                this.setState({
                    keysArray: response.data
                })
            }
        })
        .catch((error) => {
            console.log(error)
            this.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
        });
    }

    deleteProject = (id) => {
        this.notify("Caricamento", "info", "trash", false)
        axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/deletessh`, {id:id})
        .then(async (response) => {
            this.notify("La chiave è stata eliminata", "success", "trash", true);
            this.getKeys();
        }).catch(e => {
            this.notify("L'errore durante l'eliminazione", "error", "trash", true);
            console.log(e);
        });
    }
    getAllServerDetails = async (id) => {
        const serverId = id;
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/getallservers`)
            .then((response) => {
                this.setState({
                    allservers: response.data
                })
            })
        this.getServers()
    };

    getServers = async (params) => {
        this.setState({ filter: params })
        this.setState({ loading: true });
        axios.get(`${process.env.REACT_APP_API_URL}/api/vm/filter`, {params: params})
            .then(async (response) => {
                let totalCount = response.data.totalCount;
                let servers = []
                for(let server of response.data.dataset){
                    for(let server_info of this.state.allservers){
                        if(server.server_id == server_info.id){
                            let inputstatus = server_info.status
                            let ipv4 = server_info.public_net.ipv4.ip
                            let private_ip = server_info.private_net.length > 0 ? server_info.private_net[0].ip : null
                            Object.assign(server, {inputstatus: inputstatus, ipv4: ipv4, private_ip: private_ip})
                        }
                    }
                    Object.assign(server, {site: server.server_product ? server.server_product.includes("web") : false})
                    Object.assign(server, {mail: server.server_product ? server.server_product.includes("mail") : false})
                    Object.assign(server, {gateway: server.server_product ? server.server_product.includes("MailGateway") : false})
                    Object.assign(server, {nameserver: server.server_product ? server.server_product.includes("ns") : false})
                    servers.push(server)
                }
                this.setState(({
                    servers: servers,
                    totalSize: totalCount,
                    loading: false
                }));
            })
            .catch((error) => {
                console.log(error)
            });

    };

	changeAccount = async (row) => {
		await axios.post(`${process.env.REACT_APP_API_URL}/api/contact/adminauth`, {userid: row.userid})
            .then((response) => {
                if(!localStorage.getItem("baseuser")){
                    localStorage.setItem("baseuser", localStorage.getItem("user"))
                    localStorage.setItem("basejwt", localStorage.getItem("jwt"))
                }
                localStorage.setItem("jwt", response.data.token);
                localStorage.setItem("isAdmin", false);
                localStorage.setItem("user", response.data.user);
                localStorage.setItem("restore", true);
                localStorage.setItem("multitenancy", response.data.multitenancy);
                window.location.reload();
            })
	}

    loadUsers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then((response) => {
                this.setState({
                    partners: response.data
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    defineColumns = () => {
        this.columns.push({
            dataField: 'azioni',
            text: this.props.intl.formatMessage({ id: "app.action" }),
            isDummyField: true,
            headerStyle: (colum, colIndex) => {
                return { width: '10%', "text-align": "right" };
            },
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                try{
                    if (row.status == 'TO DELETE' || row.status == 'EXPIRED') {
                        return (
                            <div className="buttonServer">
                            <Button className="buttonAction" disabled onClick={(e) => this.serverAction(e, row.server_id, 'reset')}>{this.props.intl.formatMessage({id: "srv.reboot"})}</Button>
                            <Button className="buttonAction" disabled onClick={(e) => this.serverAction(e, row.server_id, 'shutdown')}>{this.props.intl.formatMessage({id: "srv.shutdown_btn"})}</Button>
                            <Button className="buttonAction" disabled onClick={(e) => this.serverAction(e, row.server_id, 'poweroff')}>{this.props.intl.formatMessage({id: "srv.poweroff_btn"})}</Button>
                            <Button className="buttonAction" disabled onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.setState({modalVNCOpen: true, lastid: row.server_id})}}>{this.props.intl.formatMessage({id: "btn.vnc"})}</Button>
                            <Button className="buttonAction lockButton" disabled onClick={(e) => this.changeLock(e, row)}icon="lock"></Button>
                            <Button className="buttonAction" disabled onClick={(e) => this.serverAction(e, row.server_id, 'create_backup')}>{this.props.intl.formatMessage({id: "app.backups"})}</Button>
                            {row.userid == this.myuser && !this.state.isSecondLevelUser ? row.status == 'EXPIRED' ? <Button className="buttonAction" onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.payRenewServer(row) }} > {this.props.intl.formatMessage({ id: 'srv.renew' })}</Button> :  <Button className="buttonAction" disabled onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.payRenewServer(row) }} > {this.props.intl.formatMessage({ id: 'srv.renew' })}</Button> : null}
                            {this.isAdmin ? <Button className = 'buttonAction' onClick = {(e) => {e.preventDefault(); e.stopPropagation(); this.setState({modalReAssignServerOpen : true, idSelected: row.id})}} > Riassegna </Button> : null}
                            {this.isAdmin ? <Button className='buttonAction deleteButton' onClick={(e) => this.serverDelete(e, row)} icon="trash" ></Button> : null}
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className="buttonServer">
                            <Button className="buttonAction" onClick={(e) => this.serverAction(e, row.server_id, 'reset')}>{this.props.intl.formatMessage({id: "srv.reboot"})}</Button>
                            <Button className="buttonAction" onClick={(e) => this.serverAction(e, row.server_id, 'shutdown')}>{this.props.intl.formatMessage({id: "srv.shutdown_btn"})}</Button>
                            <Button className="buttonAction" onClick={(e) => this.serverAction(e, row.server_id, 'poweroff')}>{this.props.intl.formatMessage({id: "srv.poweroff_btn"})}</Button>
                            <Button className="buttonAction" onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.setState({modalVNCOpen: true, lastid: row.server_id})}}>{this.props.intl.formatMessage({id: "btn.vnc"})}</Button>
                            <Button className="buttonAction lockButton" onClick={(e) => this.changeLock(e, row)}icon="lock"></Button>
                            <Button className="buttonAction" onClick={(e) => this.serverAction(e, row.server_id, 'create_backup')}>{this.props.intl.formatMessage({id: "app.backups"})}</Button>
                            {row.status === 'RENEW' && row.userid == this.myuser && !this.state.isSecondLevelUser ? <Button className="buttonAction" onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.payRenewServer(row) }} > {this.props.intl.formatMessage({ id: 'srv.renew' })}</Button> : null}
                            {this.isAdmin ? <Button className = 'buttonAction' onClick = {(e) => {e.preventDefault(); e.stopPropagation(); this.setState({modalReAssignServerOpen : true, idSelected: row.id})}} > Riassegna </Button> : null}
                            {this.isAdmin ? <Button className='buttonAction deleteButton' onClick={(e) => this.serverDelete(e, row)} icon="trash" ></Button> : null}
                            </div>
                        )
                    }
                    /*<Dropdown className="icon grey" icon='setting' direction='left' button>
                        <Dropdown.Menu className="xxx-menu-right">
                            {this.isAdmin ? <Dropdown.Item text={this.props.intl.formatMessage({ id: 'app.details' })} onClick={() => this.serverDetails(row)} /> : null}
                            {row.status === 'RENEW' && row.userid == this.myuser && !this.state.isSecondLevelUser ? <Dropdown.Item text={this.props.intl.formatMessage({ id: 'srv.renew' })} onClick={() => this.payRenewServer(row)} /> : null}
                            {row.status !== 'TO DELETE' && (row.userid == this.myuser) && row.autorenew && !this.state.isSecondLevelUser ? <Dropdown.Item text={this.props.intl.formatMessage({ id: 'srv.noautorenew' })} onClick={() => this.toggleAutorenew(row)} /> : null}
                            {row.status !== 'TO DELETE' && (row.userid == this.myuser) && !row.autorenew && !this.state.isSecondLevelUser ? <Dropdown.Item text={this.props.intl.formatMessage({ id: 'srv.autorenew' })} onClick={() => this.toggleAutorenew(row)} /> : null}
                            {this.state.readyVS ? <Dropdown.Item text={this.props.intl.formatMessage({ id: 'srv.manage' })} onClick={() => this.manageServer(row)} /> : null}
                            {this.isAdmin ? <Dropdown.Item text={this.props.intl.formatMessage({ id: 'srv.delete' })} onClick={() => this.serverDelete(row)} /> : null}
                        </Dropdown.Menu>
                    </Dropdown>*/;
                } catch(e) {
                    console.log(e)
                }
            },
            formatExtraData: {
                lng: this.props.language
            }
        })
        if(this.isAdmin) {
            this.columns.push({
                dataField: 'adminButtons',
                text: '',
                isDummyField: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '50px' };
                },
                headerFormatter: () => {
                    return (
                        <div className="-d-flex">
                            {(this.isAdmin) ?
                                <Button icon="add" className="details-add" onClick={(e) => this.serverDetails(e, {id: null})} />
                                : null}
                        </div>
                    );
                },
                formatter: (cellContent, row) => {
                    try{
                    return (
                        <div className="-d-flex">
                            {(this.isAdmin) ?
                                <Button icon="pencil" className="details-add" onClick={(e) => this.serverDetails(e, row)} />
                                : null}
                        </div>
                    );
                    } catch(e) {console.log(e)}
                        
                }
            })
        }
    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        var currentIndex = (page - 1) * sizePerPage;
        if (type === 'search') {
            currentIndex = 0;
        }
        this.setState({ loading: true });
        var filter = {
            currentIndex: currentIndex,
            sizePerPage: sizePerPage ? sizePerPage : this.state.sizePerPage,
            q: searchText,
            sortField: sortField,
            sortOrder: sortOrder,
            partnerId: this.state.partnerId ? this.state.partnerId : null
        };
        this.setState({ filter: filter });
        this.setState({
            page,
            sizePerPage,
            currentIndex: currentIndex,
        })
        if (this.state.isSecondLevelUser) {
			this.getSecondLevelServers(filter)
		} else {
			this.getServers(filter);
		}
    }

    render() {
        const { page, sizePerPage, totalSize, loading } = this.state;
        const sizePerPageList = [5, 10, 15];
        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];
        const isAdmin = (localStorage.getItem('isAdmin') === "true");
        const { formatMessage } = this.props.intl;
        return (
            <div className="kx-page">
                <ModalEditSSH data = {this.state.selectedSSH} notify = {this.notify} open={this.state.modalEditSSHOpen} onClose={()=>{this.setState({modalEditSSHOpen: false}); this.getKeys() }} />
                <ModalTestSSH data = {this.state.selectedSSH} notify = {this.notify} open={this.state.modalTestSSHOpen} onClose={()=>{this.setState({modalTestSSHOpen: false}); this.getKeys() }} />
                <ModalAddSSH notify = {this.notify} open={this.state.modalAddSSHOpen} onClose={()=>{this.setState({modalAddSSHOpen: false}); this.getKeys() }} />
                <ToolkitProvider
                    keyField="id"
                    data={this.state.servers}
                    columns={this.columns}
                    defaultSorted={defaultSorted}
                    bootstrap4
                    search
                >
                    {
                        toolkitprops => [
                            <div className="searchBar">
                                <div className="searchBtn">
                                    <SearchBar ref={n => this.searchKit = n} id="s1" {...toolkitprops.searchProps} delay={1000} placeholder={formatMessage({ id: "table.search" })} />
                                    <ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
                                </div>
                                {isAdmin ?
                                    <div className="d-flex flex-row align-items-center">
                                        <Dropdown
                                            className="parnter-list"
                                            placeholder="Partner"
                                            onChange={this.onPartnerChange}
                                            name="partnerIdInv"
                                            clearable
                                            value={this.state.partnerCustomer}
                                            selection
                                            search
                                            options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                                        />
                                    </div> : null}
                            </div>,
                            <div className='infodiv boxTabella'>
                                <div style={{display: "flex"}}><div className="tabName"><div className="roundCount"><span>{this.state.totalSize}</span></div>Servers</div></div>
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    remote={{ search: true, pagination: true, sort: true }}
                                    hover
                                    classes="table-responsive tabellaServer overflow-yes"
                                    pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                                    onTableChange={this.handleTableChange}
                                />
                            </div>
                        ]
                    }
                </ToolkitProvider>
                {
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
            </div>)
    }
}

export default injectIntl(ConfigurationServerTypes);