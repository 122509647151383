import crypto from 'crypto'

const generatePassword = (
    length = 20,
    wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!?'
  ) =>
    Array.from(crypto.randomFillSync(new Uint8Array(length)))
      .map((x) => wishlist[x % wishlist.length])
      .join('')

const generatePasswordISP = (
    length = 9,
    wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!'
  ) =>
    Array.from(crypto.randomFillSync(new Uint8Array(length)))
      .map((x) => wishlist[x % wishlist.length])
      .join('')

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default{
    generatePassword,
    generatePasswordISP,
    sleep
}