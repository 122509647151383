import React, { Component } from "react";
import "./tab-dns.scss";
import FormDNS from "../form-dns/form-dns";
import TabDNSItem from "../tab-dns-item/tab-dns-item";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Collapse } from "reactstrap";
import AlertBox from "../utils/alert";

class TabDNS extends Component {
  constructor(props) {
    super(props);
    this.loadDNS = this.loadDNS.bind(this);
    this.addFormToggle = this.addFormToggle.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.loadDNS();
    this.state = {
      addFormCollapse: false,
      dns: [],
      alertMessage: "",
      alertDisplay: false
    };
  }

  addFormToggle(addedDNS) {
    this.setState({ addFormCollapse: !this.state.addFormCollapse });
    if (addedDNS.data) {
      this.setState({ dns: addedDNS.data.dns });
    }
  }

  loadDNS = () => {
    const token = localStorage.getItem("jwt");
    const decoded = jwt_decode(token);
    if (decoded.isAdmin !== true) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/dns/me/` +
            this.props.domain._id,
          {
            headers: {
              "x-auth-token": localStorage.getItem("jwt")
            }
          }
        )
        .then(response => {
          this.setState({ dns: response.data });
        })
        .catch(function(error) {
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/dns/` +
            this.props.domain._id,
          {
            headers: {
              "x-auth-token": token
            }
          }
        )
        .then(response => {
          this.setState({ dns: response.data });
        })
        .catch(function(error) {
        });
    }
  };

  //Alert inner code
  showAlert(message) {
    this.setState({
      alertMessage: message,
      alertDisplay: true
    });

    setTimeout(() => {
      this.setState({ alertDisplay: false });
    }, 3000);
  }

  render() {
    return (
      <div>
        <AlertBox
          message={this.state.alertMessage}
          alertDisplay={this.state.alertDisplay}
        />
        <div className="row dns-list-head">
          <hr />
          <div className="col-1 dns-text">ID</div>
          <div className="col-1 dns-text">Tipo</div>
          <div className="col-3 dns-text">Nome</div>
          <div className="col-4 dns-text">IP/Data</div>
          <div className="col-1 dns-text">AUX</div>
          <div className="col-2 dns-text">TTL</div>
        </div>
        <hr style={{ marginBottom: 0 }} />

        {this.state.dns.map(dns => (
          <TabDNSItem
            className="dns-list-body"
            key={dns._id}
            dns={dns}
            domainid={this.props.domain._id}
            reload={this.loadDNS}
            showAlert={this.showAlert}
          />
        ))}

        <div className="dns-list-footer">
          <Collapse isOpen={this.state.addFormCollapse}>
            <FormDNS
              domainid={this.props.domain._id}
              closeForm={this.addFormToggle}
              showAlert={this.showAlert}
            />
          </Collapse>
          <Collapse className="text-right" isOpen={!this.state.addFormCollapse}>
            <button
              onClick={this.addFormToggle}
              className="btn btn-lg btn-primary btn-list"
            >
              Aggiungi DNS
            </button>
          </Collapse>
        </div>
        <hr />
      </div>
    );
  }
}

export default TabDNS;
