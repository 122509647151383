import React, { Component } from 'react';
import '../All/table.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Dropdown, Button, Input } from 'semantic-ui-react';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import '../vmservers/vmservers.scss';
import ModalConferm from '../All/ModalConferm';
import ModalAssignToCustomer from './ModalAssignToCustomer';
import ModalChangeApi from './ModalChangeApi';
import ModalAddProject from './ModalAddProject';
import NotifyComponent from '../notify/notifyComponent';
const { SearchBar, ClearSearchButton } = Search;

class ConfigurationServers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
			totalSize: 0,
			sizePerPage: 10,
            projects: [],
            statewritten: false,
            selectedID: -1,
            partners: [],
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false,
            isAdmin: false
        }
        localStorage.setItem('api-vs-key', '');
        axios.defaults.headers.common['x-api-vs-key'] = this.token;
        this.token = localStorage.getItem('jwt');
        axios.defaults.headers.common['x-auth-token'] = this.token;
    }

    componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }

	loadUsers = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
			.then((response) => {
				this.setState({
					partners: response.data
				});
			})
			.catch((error) => {
                console.log(error)
            });
	}

    componentDidMount(){
        this.getadmin()
        this.loadUsers()
        this.getProjects()
    }

    getProjects = async (partnerId) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/api/vm/projects`, {params: {partnerId: partnerId}})
        .then((response) => {
            if (response.data){
                this.setState({
                    projects: response.data
                })
            }
        })
        .catch((error) => {
            console.log(error)
            this.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
        });
    }

    getServers = async (partnerId) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vm/syncservers`)
        .then((response) => {
            if (response.data){
                this.setState({
                    projects: response.data
                })
                this.notify("Sincronizzato", "success", "close", true);
            }
        })
        .catch((error) => {
            console.log(error)
            this.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
        });
    }

    changeName = async (e, id) => {        
		this.notify("Caricamento", "info", "pencil", false)
        let valuetocheck = e.target.value
        let value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vm/changeprojectname`, {id: id, value: value})
        .then((response) => {
            this.notify("Nome progetto modificato con successo", "success", "pencil", true)
        })
        .catch((error) => {
          this.notify("" + error, "error", "pencil", true);
        });
    }

    changeAccount = async (row) => {
		await axios.post(`${process.env.REACT_APP_API_URL}/api/contact/adminauth`, {userid: row.userid})
            .then((response) => {
                if(!localStorage.getItem("baseuser")){
                    localStorage.setItem("baseuser", localStorage.getItem("user"))
                    localStorage.setItem("basejwt", localStorage.getItem("jwt"))
                }
                localStorage.setItem("jwt", response.data.token);
                localStorage.setItem("user", response.data.user);
                localStorage.setItem("restore", true);
                localStorage.setItem("multitenancy", response.data.multitenancy);
                window.location.reload();
            })
	}

    deleteProject = async (id) => {
        this.notify("Caricamento", "info", "trash", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vm/deleteproject`, {id: id})
        .then((response) => {
            this.notify("Progetto cancellato con successo", "success", "trash", true)
            this.getProjects()
        })
        .catch((error) => {
            this.notify(""+error, "error", "trash", true)
        });
    }

    getadmin = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
			.then((response) => {
				this.setState({
					isAdmin: response.data.isAdmin
				});
			})
			.catch((error) => {
                console.log(error)
            });
	}
    
    columns = [
        {
            dataField: 'hetzner_id',
            sort: true,
            text: 'ID Hetzner',
            headerStyle: (colum, colIndex) => {
                return { width: '110px' };
            },
        },
        {
            dataField: 'project_name',
            sort: true,
            text: 'Nome Progetto',
            headerStyle: (colum, colIndex) => {
                return { width: '350px' };
            },
            formatter: (cellContent, row, rowIndex, formatExtraData) => {

                return <Input transparent id="nomeProgetto" fluid defaultValue = {cellContent} onBlur={(e) => this.changeName(e, row.id)} />
            }
        },
        {
            dataField: 'servers',
            text: '',
            headerFormatter: () => {
                return (<><Button onClick={()=>{this.getServers()}}>Sync lista server (lento)</Button></>);
            },
            headerStyle: (colum, colIndex) => {
                return { width: '270px' };
            },
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                this.setState({servers:cellContent})
                return <Dropdown 
                    style = {{"border-width" : "3px", "border-style" : "groove", "max-width": "100%", "border-radius" : "5px", "border-color" : "#000000"}}
                    search
                    id="idListaServer"
                    defaultValue = {cellContent ? cellContent.length > 0 ? cellContent[0].id : null : null}
                    selection
                    options={cellContent ? cellContent.map(({ id, name }) => ({ value: id, text: name })) : null}
                />
            }
        },{
            dataField: 'customer.name',
            text: this.props.intl.formatMessage({ id: "app.partner" }),
            headerStyle: (colum, colIndex) => {
                return { width: '200px' };
            },
            formatter: (cellContent, row) => {
                return <Button className="buttonAction" onClick = {(e) => {e.preventDefault(); e.stopPropagation(); this.changeAccount(row)}}> {cellContent} </Button>
            }
        },        {
            dataField: 'azioni',
            sort: true,
            text: 'Azioni',
            headerStyle: (colum, colIndex) => {
                return { width: '800px' };
            },
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                return <div>
                    <Button onClick = {() => this.setState({modalAssignToCustomerOpen: true, selectedID: row.id})}>Assegna Progetto ad un Partner</Button>
                    <Button onClick = {() => this.setState({modalChangeApiOpen: true, selectedID: row.id})}>Modifica Api Key al Partner</Button>
                </div>
            }
        },
        {
            headerFormatter: () => {
                return (
                    <div className="d-flex">
                        <Button icon = "add" onClick = {() => this.setState({modalAddProjectOpen: true})}/>
                    </div>
                )},
                
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                return <div>
                    <ModalConferm modalCfg={{ type: "delete"}} onYes={() => this.deleteProject(row.id)} />
                </div>
            }
        }
    ]

    render() {
        const { page, sizePerPage, totalSize, loading } = this.state;
        const sizePerPageList = [5, 10, 15];
        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];
        const { formatMessage } = this.props.intl;
        return (
            <div className="kx-page">
            <ModalAssignToCustomer servers={this.state.servers} id= {this.state.selectedID} notify = {this.notify} open={this.state.modalAssignToCustomerOpen} onClose={()=>{this.setState({modalAssignToCustomerOpen: false}); this.getProjects() }}/>
            <ModalChangeApi id = {this.state.selectedID} notify = {this.notify} open={this.state.modalChangeApiOpen} onClose={()=>{this.setState({modalChangeApiOpen: false}); this.getProjects() }} />
            <ModalAddProject notify = {this.notify} open={this.state.modalAddProjectOpen}onClose={()=>{this.setState({modalAddProjectOpen: false}); this.getProjects() }} />
                <ToolkitProvider
                    keyField="id"
                    data={this.state.projects}
                    columns={this.columns}
                    defaultSorted={defaultSorted}
                    bootstrap4
                    search
                >
                    {
                        toolkitprops => [
                            <div className="searchBar">
                                <div className="searchBtn">
                                    <SearchBar ref={n => this.searchKit = n} id="s1" delay={1500} {...toolkitprops.searchProps} placeholder={formatMessage({ id: "table.search" })} />
                                    <ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
                                </div>
                                {this.state.isAdmin ?
                                    <div className="d-flex flex-row align-items-center">
                                        <Dropdown
                                            className="parnter-list"
                                            placeholder="Partner"
                                            onChange={(e, data) => {this.getProjects(data.value)}}
                                            name="partnerId"
                                            clearable                                                                
                                            selection
                                            search
                                            options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                                        />
                                    </div> : null}
                            </div>,
                            <div className='infodiv boxTabella'>
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    hover
                                    classes="table-responsive overflow-yes"
                                    pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                                    onTableChange={this.handleTableChange}
                                />
                            </div>
                        ]
                    }
                </ToolkitProvider>
                {
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
            </div>)
    }
}

export default injectIntl(ConfigurationServers);
