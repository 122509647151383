import React, { Component } from 'react';
import { Button, Header, Modal, Icon } from 'semantic-ui-react';
import ReactTooltip from "react-tooltip";
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';

class ModalConfirmPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            balance: 0
        }
        this.externalClose = this.externalClose.bind(this);
    }

    handleOpen = () => {
        try {
            if (this.props.new_contacts) {
                if (this.props.new_contacts[0].contact_id != -1 && this.props.new_contacts[1].contact_id != -1 && this.props.new_contacts[2].contact_id != -1 && this.props.new_contacts[3].contact_id != -1) {
                    this.setState({disableCheckout: false})
                    let data = this.props.onOpen();
                    this.setState({
                        modalOpen: true,
                        balance: data.balance,
                        name: data.name,
                        description: data.description
                    });
                } else {
                    this.setState({disableCheckout: true})
                }
            } else {
                this.setState({disableCheckout: false})
                let data = this.props.onOpen();
                this.setState({
                    modalOpen: true,
                    balance: data.balance,
                    name: data.name,
                    description: data.description})
            }
		} catch (error) {
            console.log(error)
			this.props.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true)
            this.setState({modalOpen: false})
		}
    }
    handleClose = () => this.setState({ modalOpen: false });
    handleYes = () => {
        this.setState({ modalOpen: false });
        this.props.onYes();
    }

    externalClose() {
        this.setState({ modalOpen: false });
    }

    render() {
        const { config, amount, disableCheckout } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <Modal closeIcon={true}
                    className="conferm-modal" 
                    closeOnDimmerClick={true}
                    open={this.state.modalOpen}
                    onClose={this.handleClose}
                    trigger={
                        <Button disabled={disableCheckout} /* data-tip={formatMessage({ id: config.btn_label })} */ icon size={config.btn_size ? config.btn_size : 'small'} onClick={this.handleOpen} color={config.color}>
                            {/* {(modalCfg.type === "delete") ? formatMessage({ id: "app.delete" }) : formatMessage({ id: "app.confirm" })} */}
                            Checkout
                        </Button>
                    }
                >
                    <Modal.Header className="modal-header-margin-bottom">
                        <span className="text-secondary"><FormattedMessage id="app.product" />:</span> <strong>{this.state.name}</strong>
                    </Modal.Header>
                    <Modal.Content image className="modal-min-height">
                        <Modal.Description>
                            {this.state.description && (<p>Descrizione: <strong>{this.state.description}</strong></p>)}
                            <p>Prezzo: <span className="badge badge-info">&euro; {amount}</span></p>
                            <p>Saldo: <span className="badge badge-danger">&euro; {this.state.balance}</span></p>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.handleClose}>
                            <Icon name='remove' /> <FormattedMessage id="app.no" />
                        </Button>
                        <Button onClick={this.handleYes}>
                            <Icon name='checkmark' /> <FormattedMessage id="app.yes" />
                        </Button>
                    </Modal.Actions>
                    {/* <div className="margin-top-20">
                        <a className="" inverted onClick={this.handleClose}>
                            <span style={{ width: "20%" }}><span className="invisible">next</span></span>
                        </a>
                        <a className="confirm-payment" inverted onClick={this.handleYes}>
                            <span style={{ width: "20%" }}><span className="invisible">next</span></span>
                        </a>
                    </div> */}
                </Modal>
                <ReactTooltip place="left" className="tooltip-style" />
            </div>
        );
    }
}

export default injectIntl(ModalConfirmPayment);

