import React, { Component } from 'react';
import { Form, Button, Dropdown, Checkbox } from 'semantic-ui-react';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import axios from 'axios';
import './server.scss';

class AllForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			partners: [], 
			type: ''
		};
		const { onChange, formData } = this.props;
		// questo è una cazzata, non ci sono state
		var formDataJson = {};
		if (typeof formData === 'function') {
			formDataJson = formData();
		}
		else {
			formDataJson = formData;
		}
		if (formDataJson) {
			onChange("name", formDataJson.name);
			onChange("type", formDataJson.type);
			this.state.type =  formDataJson.type;
			onChange("ip", formDataJson.ip);
			onChange("userid", formDataJson.userid);
			onChange("id", formDataJson.id);
			onChange("public", formDataJson.public);
		}
		this.loadPartners();
	}

	createServer = () => {
		const { addServer, closeMe } = this.props;
		addServer(function (res, err) {
			if (!err) {
				closeMe();
			}
		});
	}
	loadPartners = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
			.then((response) => {
				var partners = response.data.map(({ id, userid, name }) => ({ key: id, value: userid, text: name }));
				this.setState({
					partners: partners
				});
			})
			.catch((error) => {
                console.log(error)
            });
	}
	onChangeLocal = (fld, val) => {
		this.setState({[fld]: val});
		this.props.onChange(fld, val);
	}
	changeIpLabel = () => {
		var res = this.props.intl.formatMessage({ id: "app.ip" });
		if (this.state.type == 'ns'){
			var res = this.props.intl.formatMessage({ id: "app.fqdn" });
		}
		return <label>{res}</label>;
	}
	//const AllForm = ({ onChange, addContact, addServer, type }) => (
	render() {
		const { onChange, addServer, formData, addContact, closeMe, type } = this.props;
		const { formatMessage } = this.props.intl;
		const isAdmin = (localStorage.getItem('isAdmin') === "true");
		var formDataJson = {};
		if (typeof formData === 'function') {
			formDataJson = formData;
		}
		else {
			formDataJson = formData ? formData : {};
		}
		return (
			<div>
				{(type.Formtype === "server") ?
					<div className="server-form">
						<Form className="overflow-yes">
							<Form.Group >
								{/* defaultValue={formDataJson.title}  */}
								<Form.Input label="Title" name="title" defaultValue={formDataJson.name} placeholder="Title" onChange={(e) => onChange("name", e.target.value)} />
							</Form.Group>
							<Form.Dropdown
								placeholder="type"
								label="Type"
								defaultValue={formDataJson.type}
								onChange={(e, data) => {
									this.onChangeLocal("type", data.value)
								}}
								fluid
								selection
								options={[
									{ text: 'Web', value: 'web', key: 'web' },
									{ text: 'E-Mail', value: 'mail', key: 'mail' },
									{ text: 'Name Server', value: 'ns', key: 'ns' }
								]}
							/>
							<Form.Group>
								<Form.Input label={this.changeIpLabel} name="ip" defaultValue={formDataJson.ip} placeholder="" onChange={(e) => onChange("ip", e.target.value)} />
							</Form.Group>
							{isAdmin ?
								<Form.Dropdown
									placeholder="partner"
									label="Partner"
									defaultValue={formDataJson.userid}
									onChange={(e, data) => { onChange("userid", data.value) }}
									fluid
									search
									selection
									options={this.state.partners}
								/> : null}
							<Form.Group>
								{isAdmin ?
									<Form.Checkbox name="public" defaultChecked={formDataJson.public} label={formatMessage({ id: "srv.make_visible" })} onChange={(e, data) => onChange("public", data.checked)} />
									: null}
								<Form.Button floated="right" onClick={this.createServer}>
									{formatMessage({ id: "app.save" })}
								</Form.Button>
							</Form.Group>
						</Form>
					</div> : ""}

				{(type.Formtype === "dns") ?
					<Form>
						<Form.Group>
							<Form.Input
								label="First name"
								name="firstname"
								placeholder="First Name"
								onChange={(e) => onChange(e)}
								width={8}
							/>
							<Form.Input label="Last Name" name="lastname" placeholder="Last Name" onChange={(e) => onChange(e)} width={8} />
						</Form.Group>
						<Form.Group>
							<Form.Input label="Email" name="email" placeholder="Email" onChange={(e) => onChange(e)} width={8} />
							<Form.Input label="Phone Number" name="phonenumber" placeholder="Phone Number" onChange={(e) => onChange(e)} width={8} />
						</Form.Group>
						<Form.Group>
							<Form.Input label="City" name="city" placeholder="City" onChange={(e) => onChange(e)} width={8} />
							<Form.Input label="Street" name="street" placeholder="Street" onChange={(e) => onChange(e)} width={8} />
						</Form.Group>
						<Form.Group>
							<Form.Input label="Country Code" name="countrycode" placeholder="Country Code" onChange={(e) => onChange(e)} width={8} />
							<Form.Input label="Postal Code" name="postalcode" placeholder="Postal Code" onChange={(e) => onChange(e)} width={8} />
						</Form.Group>
						<Button floated="right" onClick={() => addContact()}>
							{(type.Formtype === "server") ? "Add Server" : "Add Contact1"}
						</Button>
					</Form> : ""}
			</div>
		);
	}
}
export default injectIntl(AllForm);

