import React, { Component } from "react";
import "./login.scss";
import axios from "axios";
import { Route, Redirect, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import LoginForm from "./login-form";
import Signup from "./signup";
import ResetMail from "./reset-mail";
import ResetPass from "./reset-pass";
import Footer from "../footer/footer";

import { Modal, ModalBody } from "reactstrap";
import Header from "../header/header";
import soc from "../../services/socket-connection";

class Login extends Component {
  constructor(props) {
    super(props);

    this.modalToggle = this.modalToggle.bind(this);
    this.login = this.login.bind(this);
    this.verifyMail = this.verifyMail.bind(this);
    this.showModal = this.showModal.bind(this);

    this.state = {
      id: "",
      locationID: "",
      logo: "",
      logolink: "",
      loggedIn: false,
      modal: false,
      modalMessage: "",
    };
    this.socketRef = null;
  }
  

  handleLogo = () => {
    let location = "" + window.location;
    if(location.includes("?id=")){
      let id = "" + location.split(['=']).pop()
      axios.post(`${process.env.REACT_APP_API_URL}/api/users/logonoauth?id=${id}`)
        .then((response) => {
          if(response.data === 404){
            this.setState({
              locationID: id,
              logo: "/TAB_icon_day.png",
              logolink: "https://konsolex.cloudonthecloud.com/"
            })
          }
          else{
            this.setState({
              locationID: id,
              logo: response.data.logo,
              logolink: response.data.logolink
            })
          }
      })
    }
    else
    {
      this.setState({
        logo: "/TAB_icon_day.png"
      })
    }
  }

  componentDidMount() {
    //Refactor necessario?
    this.handleLogo();
    const { match } = this.props;
    if (match.params.h && match.path === "/login/verify/:h") {
      this.verifyMail(match.params.h);
    }
  }

  findID(){
    let location = "" + window.location;
    if(location.includes("?id=")){
      let id = "" + location.split(['=']).pop()
      return id;
    }
    else{
      return null;
    }
  }



  verifyMail(hash) {
    axios
      .put(`${process.env.REACT_APP_API_URL}/api/users/verify`, {
        hash,
      })
      .then((response) => {
        this.showModal(
          "Indirizzo verificato! Ora puoi accedere inserendo e-mail e password"
        );
      })
      .catch((error) => {
        this.showModal(error.response.data);
        this.props.history.push("/login");
      });
  }

  modalToggle() {
    this.setState({ modal: !this.state.modal });
  }

   login() {
    this.setState({
      loggedIn: true,
    });
  }

  showModal(message) {
    this.setState({
      modalMessage: message,
      modal: !this.state.modal,
    });
    window.location = window.location + "?id=" + this.state.locationID
  }

  render() {
    if (this.state.loggedIn === true) {
      return <Redirect to="/" />;
    }

    return (
      <div className="login-background">
        <Header
          // noSidenav={false}
          language={this.props.language}
          changeLanguage={this.props.changeLanguage}
        />
        <div className="login-wrapper">
          <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
          <div id="cloud3">
            <div id="innerCircle9"/>
            <div id="innerCircle10"/>
            <div id="innerCircle11"/>
            <div id="innerCircle12"/>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="Card-div">
                <div className="container-form-login">
                  <div className="login">
                    <img src = "/TAB_icon_day.png" style = {{"max-height" : "50px", "max-width" : "200px"}}/>
                  </div>
                  {/* <a href="/login">
										<img src="/KonsoleX-logo-basic.png" width="100" className="img-fluid logo" />
							<span>powered By CloudontheCLoud</span>
              		</a> */}
                  <div className="login-slider-container">
                    <Switch>
                      <Route
                        exact
                        path="/login"
                        render={() => (
                          <LoginForm
                            login={this.login}
                            addNotification={this.props.addNotification}
                            history = {this.props.history}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/login/signup"
                        render={() => (
                          <Signup
                            showModal={this.showModal}
                            addNotification={this.props.addNotification}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/login/reset"
                        render={() => (
                          <ResetMail
                            showModal={this.showModal}
                            addNotification={this.props.addNotification}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/login/reset/:h"
                        render={() => (
                          <ResetPass
                            showModal={this.showModal}
                            addNotification={this.props.addNotification}
                          />
                        )}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footerLogin" >
          <div id="cloud">
            <div id="innerCircle1"/>
            <div id="innerCircle2"/>
            <div id="innerCircle3"/>
            <div id="innerCircle4"/>
          </div>
          <Footer />
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalToggle}
          onClick={this.modalToggle}
        >
          <ModalBody className="text-center">
            {this.state.modalMessage}
          </ModalBody>
        </Modal>
        {/* <Footer color={'#ddd'} img={'/cloudlogo.png'} /> */}
      </div>
    );
  }
}

const _Login = injectIntl(Login);

export default withRouter(_Login);
