import React, { Component } from 'react';
import { Button, Header, Modal, Icon } from 'semantic-ui-react';
import FormWidthField from './Form';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';

class ModalConferm extends Component {
    constructor(props) {
        super(props);
        this.state = { modalOpen: false }
        this.externalClose = this.externalClose.bind(this);
    }

    handleOpen = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ modalOpen: true })
    }
    handleClose = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ modalOpen: false })
    }
    handleYes = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ modalOpen: false });
        this.props.onYes()
    }

    externalClose() {
        this.setState({ modalOpen: false });
    }

    checkType(type) {
        switch(type){
            case "delete":
                return <Icon name="trash" />
            case "mount":
                return <div>Rebuild</div>
            case "iso":
            return <div>Mount</div>
            case "restore":
            return <span>Restore</span>
            case "plus":
                return <Icon name="plus"/>
            case "pencil":
                return <Icon name="pencil"/>
            case "edit": 
                return <Icon name="edit outline"/>
            case "close":
                return <FormattedMessage id="app.close"/>
            default:
                return <Icon name="check circle outline" />
        }
    }

    checkWarning(type){
        switch(type){
            case "delete":
                return <FormattedMessage id="app.confirm_delete_text"/>
            case "mount":
                return <div style = {{"color":"red", "font-size" : "16px"}}><b><FormattedMessage id="app.confirm_delete_text" /></b></div>
            default:
                return <FormattedMessage id="app.confirm_delete_text"/>
        }
    }

    render() {
        const { onYes, onNo, modalCfg } = this.props;
        const {formatMessage} = this.props.intl;
        return (
            <div className="modalContainer">
                <Modal closeIcon={true} className="delete-modal" 
                    closeOnDimmerClick={true}
                    open={this.state.modalOpen}
                    onClose={this.handleClose}
                    onClick={(e)=>{e.preventDefault();e.stopPropagation()}}
                    trigger={
                        this.props.dis == true ? 
                        <Button icon size="small" onClick={this.handleOpen} color={modalCfg.color} disabled>
                            {/* {(modalCfg.type === "delete") ? formatMessage({ id: "app.delete" }) : formatMessage({ id: "app.confirm" })} */}
                            {this.checkType(modalCfg.type)}
                        </Button> :
                        <Button icon size="small" onClick={this.handleOpen} color={modalCfg.color}>
                            {/* {(modalCfg.type === "delete") ? formatMessage({ id: "app.delete" }) : formatMessage({ id: "app.confirm" })} */}
                            {this.checkType(modalCfg.type)}
                        </Button>

                    }
                >
                    <Modal.Header className="modal-header-margin-bottom">
                        <FormattedMessage id="app.confirm_delete" />
                    </Modal.Header>
                    <Modal.Content image>
                        {this.checkWarning(modalCfg.type)}
                        <div style={{"margin-top":"20px"}}></div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.handleClose}>
                            <Icon name='remove' /> <FormattedMessage id="app.no" />
                        </Button>
                        <Button onClick={this.handleYes}>
                            <Icon name='checkmark' /> <FormattedMessage id="app.yes" />
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalConferm);

