// import React, { Component } from "react";
// import Moment from "moment";
// import axios from "axios";
// import "./tab-info.scss";


// class TabInfo extends Component {
//   constructor(props) {
//     super(props);
//     //this.loadUser = this.loadUser.bind(this);

//     this.loadUser = this.loadUser.bind(this);
//     this.loadUser();
//     this.state = {
//       user: {}
//     };
//   }

//   loadUser = () => {
//     return axios
//       .get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
//         headers: {
//           "x-auth-token": localStorage.getItem("jwt")
//         }
//       })
//       .then(res => {
//         this.setState({ user: res.data });
//       });
//   };

//   render() {
//     return (
//       <div className="text-left">
//         <div className="row">
//           <div className="col-lg-5 col-md-5 col-sm-12 info-text">
//             <b>Di: </b> {this.state.user.name}
//           </div>

//           <div className="col-lg-5 col-md-5 col-sm-12 info-text">
//             <b>Partner: </b>
//             {this.state.user.email}
//           </div>

//           <div className="col-lg-2 col-md-2 col-sm-12 text-right info-text">
//             <b>Stato: </b>
//             Stato
//           </div>
//         </div>
//         <div className="row info-text">
//           <div className="col-lg-10 col-md-10 col-sm-12">
//             <b>Ultima azione: </b>
//             Azione
//           </div>
//         </div>
//         <div className="row justify-content-between">
//           <div className="col-lg-4 col-md-4 col-sm-12 info-text">
//             <b>Creato il: </b>
//             {Moment(this.props.domain.creation).format("L")}
//           </div>
//           <div className="col-lg-4 col-md-4 col-sm-12 text-right info-text">
//             <b>Scadenza: </b>
//             {Moment(this.props.domain.expires).format("L")}
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default TabInfo;


import React, { Component } from "react";
import Moment from "moment";
import axios from "axios";
import "./tab-info.scss";


class TabInfo extends Component {
  constructor(props) {
    super(props);
    //this.loadUser = this.loadUser.bind(this);

    this.loadUser = this.loadUser.bind(this);
    this.loadUser();
    this.state = {
      user: {}
    };
  }

  loadUser = () => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
        headers: {
          "x-auth-token": localStorage.getItem("jwt")
        }
      })
      .then(res => {
        this.setState({ user: res.data });
      });
  };

  render() {
    return (
      <div className="text-left">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12 info-text">
            <b>Di: </b> {this.state.user.name}
          </div>

          <div className="col-lg-5 col-md-5 col-sm-12 info-text">
            <b>Partner: </b>
            {this.state.user.email}
          </div>

          <div className="col-lg-2 col-md-2 col-sm-12 info-text">
            <b>Stato: </b>
            Stato
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10 col-md-10 col-sm-12 info-text">
            <b>Ultima azione: </b>
            Azione
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-lg-4 col-md-4 col-sm-12 info-text">
            <b>Creato il: </b>
            {Moment(this.props.domain.creation).format("L")}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 info-text">
            <b>Scadenza: </b>
            {Moment(this.props.domain.expires).format("L")}
          </div>
        </div>
      </div>
    );
  }
}

export default TabInfo;
