import React, { Component } from 'react';
import axios from 'axios';
import './vmservers.scss';
import { Form, Modal, Dropdown, Popup, Button  } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactNotification from 'react-notifications-component';

class ModalWarning extends Component {
    constructor(props) {
        super(props);
        this.state ={
            domain: "",
            isRipristino: false
        }
    }
    
    warningSetup = (warning) => {
        if(warning == 'delete') {
            this.setState({
                warning: "Sei sicuro di volere eliminare "+this.props.deleteElement+"? Questa azione è irreversibile."
            })
        } else if(warning == 'reboot') {
            this.setState({
                warning: "Sei sicuro di volere riavviare il server?"
            })
        } else if(warning == 'shutdown') {
            this.setState({
                warning: "Sei sicuro di volere spegnere il server?"
            })
        } else if(warning== 'setStatus') {
            this.setState({
                warning: "Sei sicuro di volere modificare lo stato?"
            })
        } else if(warning== 'acquistoServer') {
            this.setState({
                warning: "Sei sicuro di voler acquistare il server?"
            })
        } else if(warning=='mount') {
            this.setState({
                warning: "Sei sicuro di voler montare la snapshot selezionata?"
            })
        } else if(warning=='rebuild') {
            this.setState({
                warning: "Sei sicuro di voler ripristinare il server alla snapshot selezionata?"
            })
        } else if(warning=="restoreBackup") {
            this.setState({
                warning: "Vuoi sovrascrivere i file dopo il ripristino?",
                isRipristino: true
            })
        }
    }

    firstSetUp = () => {
        if(!this.state.statewritten && this.props.open){
            this.setState({
                statewritten: true
            })
            this.warningSetup(this.props.warning)
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                statewritten: false,
                isRipristino: false
            })
        }
    }

    render () {
        const{ open, onClose, onYes } = this.props;

        this.firstSetUp()
        if(this.state.isRipristino) {
            return(            
                <Modal 
                    closeIcon={true}
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto', width: '50%' }}
                    open={open}
                    onClose={() => this.props.onDeny()}
                >
                    <Modal.Header>
                        ATTENZIONE
                    </Modal.Header>
                    <Modal.Content className="modal-min-height">  
                        <Modal.Description>
                            {this.state.warning}
                        </Modal.Description>
                        <Modal.Actions className='noBorderActions'>
                            <div className='buttonModalWarning'>
                                <Button onClick={() => this.props.onDeny()}>Indietro</Button>
                                <Button style={{"background-color": "#db2828"}} onClick={() => onClose()}>Annulla</Button>
                                <Button style={{"background-color": "#21ba45"}} onClick={() => onYes()}>Conferma</Button>
                            </div>
                        </Modal.Actions>
                    </Modal.Content>
                </Modal>
            )
        } else {
            return(            
                <Modal 
                    closeIcon={true}
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto', width: '50%' }}
                    open={open}
                    onClose={() => this.props.onClose()}
                >
                    <Modal.Header>
                        ATTENZIONE
                    </Modal.Header>
                    <Modal.Content className="modal-min-height">  
                        <Modal.Description>
                            {this.state.warning}
                        </Modal.Description>
                        <Modal.Actions className='noBorderActions'>
                            <div className='buttonModalWarning'>
                                <Button style={{"background-color": "#db2828"}} onClick={() => onClose()}>Annulla</Button>
                                <Button style={{"background-color": "#21ba45"}} onClick={() => onYes()}>Conferma</Button>
                            </div>
                        </Modal.Actions>
                    </Modal.Content>
                </Modal>
            )
        }
    }
}

export default injectIntl(ModalWarning);