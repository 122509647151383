import React, { Component } from 'react';
import axios from 'axios';
import { Form, Modal, Icon } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

class ModalAuthDomian extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domaininfo: {},
            domain: '',
            id_domain: '0',
            partners: [],
            partner: '',
            registrant_id: '',
            admin_id: '',
            tech_id: '',
            biling_id: '',
            contacts: [],
            copied: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedDomain !== this.props.selectedDomain) {
            const currentDomain = this.props.selectedDomain;
            this.setState({
                id_domain: currentDomain.id,
                auth: "...",
                copied: false
            });
            this.loadAuth(currentDomain.id);
        }
    }

    loadAuth = (domainid) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/domains/${domainid}/kxauthcode`
        ).then((res) => {
            this.setState({
                auth: res.data.authcode || ""
            });
        })
    }

    copyToClipboard = () => {
        navigator.clipboard.writeText(this.state.auth);
        this.setState({ copied: true });
    }

    render() {
        const { open, onClose } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            //const ModalModalExample = ({ onChange, addServer, addContact, type, formData }) => (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto' }}
                    open={open}
                    onClose={() => onClose(false)}
                >
                    <Modal.Header>
                        <FormattedMessage id="domain.authinfo" />
                    </Modal.Header>
                    <Modal.Content className="modal-min-height">
                        <Modal.Description>
                            <div>
                                <div className="server-form">
                                    <Form className="overflow-yes">
                                        <Form.Group>
                                            <Form.Input width={16} type="text" value={this.state.auth || ''} name="auth" readOnly icon={<Icon name={this.state.copied ? 'copy' : 'copy outline'} link onClick={() => this.copyToClipboard()} />} />
                                        </Form.Group>

                                        <Form.Group>
                                            <div className="field"></div>
                                            <Form.Button floated="right" onClick={() => onClose(false)} width={16} className="save-button" >
                                                {formatMessage({ id: "app.close" })}
                                            </Form.Button>
                                        </Form.Group>

                                    </Form>
                                </div>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalAuthDomian);
