import React, { Component } from "react";
import "./tabs.scss";
import { TabContent, TabPane, NavLink } from "reactstrap";
import classnames from "classnames";
import TabInfo from "../tab-info/tab-info";
import TabDNS from "../tab-dns/tab-dns";

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <div>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1");
              }}
            >
              Informazioni
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => {
                this.toggle("2");
              }}
            >
              DNS
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={classnames({ active: this.state.activeTab === "3" })}
              onClick={() => {
                this.toggle("3");
              }}
            >
              Altro
            </NavLink>
          </li>
        </ul>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <TabInfo domain={this.props.domain} />
          </TabPane>
          <TabPane tabId="2">
            <TabDNS domain={this.props.domain} />
          </TabPane>
          <TabPane tabId="3">Altro</TabPane>
        </TabContent>
      </div>
    );
  }
}

export default Tabs;
