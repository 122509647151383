import React, { Component, Suspense, lazy } from 'react';
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import './App.scss';
import './components/All/modal.scss'
import Console from './components/console/console';
import Domains from './components/domains/domains';
import Login from './components/login/login';
import DomainRegistration from './components/list/domainRegistration'
import DomainDns from './components/domaindns/domaindns'
import ChangeOwner from './components/changeowner/changeowner'
import { Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_it from 'react-intl/locale-data/it';
import messages_en from './translations/en.json';
import messages_it from './translations/it.json';
import ServerList from './components/All/Servers';
import AllContacts from './components/All/Contacts';
import Partners from './components/All/Partners';
import Billing from './components/All/Billing';
import Contract from './components/All/Contract';
import axios from 'axios';
import StripeResponsefrom from './components/All/StripeResponse';
import Configurations from './components/Configurations/Configurations';
const Firewalls = lazy(() => import('./components/vmservers/firewalls'));
const FirewallManage = lazy(() => import('./components/vmservers/firewallManage'));
//const vmServersCreate = lazy(() => import('./components/vmservers/vmserversCreate'));
const vmServersCreate = lazy(() => import('./components/vmservers/createServer'));
const vmServersSetup = lazy(() => import('./components/vmservers/setupServer'));
const VirtualServers = lazy(() => import('./components/All/VirtualServers'));
const vmServersManage = lazy(() => import('./components/vmservers/vmserversManage'));
const Services = lazy(() => import('./components/services/services'));
const MailGateway = lazy(() => import('./components/All/MailGateway'));
const Telegram = lazy(() => import('./components/All/Logs'));
const MultiTenancy = lazy(() => import('./components/All/Multitenancy'));
const DashBoard = lazy(() => import('../dashboard'));
const BarMetal = lazy(() => import('../app/components/BarMetal/BarMetal'));
const Profile = lazy(() => import('../app/components/user-profile/userprofile'));
const StorageboxCreate = lazy(() => import('../app/components/BarMetal/StorageboxCreate'))
const StorageboxUpgrade = lazy(() => import('../app/components/BarMetal/StorageboxUpgrade'))

addLocaleData([...locale_en, ...locale_it]);
localStorage.setItem("dashVisible", false);

const messages = {
	en: messages_en,
	it: messages_it
};

const PrivateRoute = ({ component: Component, changeLanguage, language, addNotification, userdetails, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			localStorage.getItem('jwt') ? (
				<Component language={language} changeLanguage={changeLanguage} addNotification={addNotification} userdetails={userdetails} {...props} />
			) : (
				<Redirect to="/login" />
			)}
	/>
);

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			changeLang: localStorage.getItem('lang') || 'it' ,
			user: '',
			userdetails: {},
			notifications: [],
			contract: 1,
			logout: false,
			check: false
		};
		this.instance = React.createRef();
		this.addNotification = this.addNotification.bind(this);
		this.token = localStorage.getItem('jwt');
		if (this.token) {
			axios.defaults.headers.common['x-auth-token'] = this.token;
		}
		let that = this;

		axios.interceptors.response.use(function (response) {
			return response;
		}, function (error) {
			if (error.response.status === 401) {
				if (localStorage.getItem('jwt')) {
					that.logOut();
				}
			}
			else {
				return Promise.reject(error);
			}
		});
		if (this.token) {
			axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`
			).then((res) => {
				this.setState({
					changeLang: res.data.language ? res.data.language : 'it',
					user: res.data._id,
					contract: res.data.contract,
					userdetails: res.data
				});
			})
			.catch(e => {
				console.log(e, 'logged out');
			});
		}
	}

	loadData = async () => {
		if (!window.location.pathname.includes("/login")) {
			await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
				.then(async (response) => {
					await this.setState({
						contract: response.data.contract
					});
				})
				.catch((e) => {
					console.log(e, "Logged out")
				})
			}
    }

	logOut = () => {
		let previoustheme = localStorage.getItem('theme')
		localStorage.clear();
		localStorage.setItem('theme', previoustheme)
		this.setState(
			{ logout: true },
			() => window.location = '/login');
	};

	addNotification(message, type) {
		const id = this.instance.current.addNotification({
			title: "Konsolex",
			message: message,
			type: type,
			insert: "top",
			container: "bottom-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 10000
			},
			dismissable: { click: true }
		});

		return this.setState({
			notifications: [
				...this.state.notifications,
				id
			]
		});
	}

	changeLanguage = (lang) => {
		this.setState({ changeLang: lang });
		if (this.token) {
			axios.post(`${process.env.REACT_APP_API_URL}/api/users/changelang`, { language: lang }
			)
		}
	};

	intlErrHandler = (e) => {
		console.log('translation error', e);
	}

	dismissContract = () => {
        this.setState({ selectedServer: null, contractOpen: false });
    }


	loadContract = async () => {
		if (this.state.logout === false) {
			await this.loadData()
			if (this.state.contract !== 1 && !window.location.pathname.includes("/login")) {
				this.setState({
					contractOpen: true
				});
			}}
			window.addEventListener('popstate', (event) => {
				if (this.state.contract !== 1 && !window.location.pathname.includes("/login")) {
					this.setState({
						contractOpen: true
					})
				}});
	}

    componentDidMount() {
		if(!this.state.check) {
			window.addEventListener('error', e => {
				// prompt user to confirm refresh
				this.setState({
					check: true
				})
				if (/Loading chunk [\d]+ failed/.test(e.message)) {
				window.location.reload();
				}
			});
		}
		
		this.loadContract()
	}

	componentWillReceiveProps() {
		if (this.state.contract === 1 || window.location.pathname.includes("/login")) {
			this.setState({
				contractOpen: false
			})
		this.loadContract()
		}}

	render() {
		const propsExtra = {
			language: this.state.changeLang,
			changeLanguage: this.changeLanguage,
			addNotification: this.addNotification
		};
		if (this.state.logout === true && window.location.pathname.includes("/login")) {
			return (<Router><Redirect to={{ pathname: '/login', popMessage: 'Logout riuscito!' }} /></Router>);
		}
		return (
			<div>
				<ReactNotification ref={this.instance} />
				<IntlProvider locale={this.state.changeLang} messages={messages[this.state.changeLang]} onError={this.intlErrHandler}>
					<Router>
						<div className="App">
							<Route exact path="/">
								<Redirect to="/dashboard" />
							</Route>
							<Contract open ={this.state.contractOpen} onClose={this.dismissContract}/>
							<Route
								path="/login"
								render={() =>
									localStorage.getItem('jwt') ? (
										<Redirect
											to={{
												pathname: '/',
												state: { changeLanguage: this.changeLanguage }
											}}
										/>
									) : (
										<Login language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} />
									)}
							/>
							<Route path="/login/verify/:h" component={Login} />
							<Route path="/domainRegistration" render={(props) => <DomainRegistration {...props} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} />} />
							<PrivateRoute path="/home" component={Console} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} />
							<PrivateRoute path="/domains" component={Domains} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} />
							<PrivateRoute path="/domaindns" component={DomainDns} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} />
							<Route path="/serverlist" render={(props) => <ServerList {...props} {...propsExtra} />} />
							<PrivateRoute path="/contactlist" component={AllContacts} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} />
							<PrivateRoute path="/partners" component={Partners} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} />
							<PrivateRoute path="/configuration" component={Configurations} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} />
							<PrivateRoute exact path="/billing" component={Billing} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
							<PrivateRoute exact path="/billing/stripeok" component={StripeResponsefrom} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
							<Route path="/changeowner" render={(props) => <ChangeOwner {...props} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} />} />

							<Suspense fallback={<div>Loading...</div>}>
								<PrivateRoute path="/vmservers/create" component={vmServersCreate} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute exact path="/vmservers/setup" component={vmServersSetup} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute path="/cloudbox/create" component={StorageboxCreate} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute path="/cloudbox/upgrade" component={StorageboxUpgrade} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute exact path="/servers" component={VirtualServers} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute path="/servers/manage" component={vmServersManage} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute path="/services" component={Services} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute exact path="/firewalls" component={Firewalls} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute exact path="/firewalls/:id/manage" component={FirewallManage} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute exact path="/firewalls/create" component={FirewallManage} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute exact path="/tickets" component={Telegram} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute path="/MailGateway" component={MailGateway} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute path="/multitenancy" component={MultiTenancy} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute path="/dashboard" component={DashBoard} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute exact path="/cloudbox" component={BarMetal} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
								<PrivateRoute path="/profile" component={Profile} language={this.state.changeLang} changeLanguage={this.changeLanguage} addNotification={this.addNotification} userdetails={this.state.userdetails} />
							</Suspense>
						</div>
					</Router>
				</IntlProvider>
			</div>
		);
	}
}

export default App;
