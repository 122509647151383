import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Table, Input, Menu, Icon, Dropdown, Pagination } from 'semantic-ui-react';
import Modal from './Modal';
import Header from '../header/header';
import ModalConfirm from './ModalConferm';
import Footer from '../footer/footer';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import LeftSidebar from '../sidebar/left-sidebar';
import NotifyComponent from '../notify/notifyComponent';
const { SearchBar, ClearSearchButton } = Search;

class ServerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainid: -1,
            name: "",
            ip: "",
            type: "",
            user: null,
            userid: "",
            public: 0,
            serverList: [],
            partners: [],
            currentIndex: 0,
            sizePerPage: 10,
            totalCount: 0,
            filter: '',
            isSecondLevelUser: false,
            canAdd: true,
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
        }
        localStorage.setItem('api-vs-key', '');
        axios.defaults.headers.common['x-api-vs-key'] = this.token;
        const token = localStorage.getItem('jwt');
        axios.defaults.headers.common['x-auth-token'] = token;
        this.isAdmin = (localStorage.getItem('isAdmin') === "true");
        /*  if (this.isAdmin) {
             this.loadPartners();
         }
         this.getServers(); */
        this.columns = this.defineColumns();
    }

    checkSecondLevel = () =>{
		axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
            .then((response) => {
                this.setState({
                    isSecondLevelUser: response.data.isseconduser,
					mainid: response.data.id
                });
				if(this.state.isSecondLevelUser === false){
                    this.getServers();
                    if (this.isAdmin) {
                        this.loadPartners();
                    }
				}
				else{
					axios.get(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelusers?id=${this.state.mainid}`)
						.then((response) => {
							if(response.data.hosts === true){
                                this.getServers();
                                if (this.isAdmin) {
                                    this.loadPartners();
                                }
							}
                            else{
                                this.setState({
                                    canAdd: false
                                })
                            }
						})
				}
				this.setState({ loading: false });
            })
	}
    
    componentDidMount() {
        this.checkSecondLevel();
    }

    componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }

    defineColumns = () => {
        const { formatMessage } = this.props.intl;
        var columns = [{
            dataField: 'name',
            text: formatMessage({ id: "dns.name" }),
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left' };
            },
            style: { textOverflow: 'ellipsis' },
            align: 'left'
        }, {
            dataField: 'type',
            text: formatMessage({ id: "dns.type" }),
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left' };
            },
            classes: 'txt-truncated',
            align: 'left'
        }
            , {
            dataField: 'ip',
            text: formatMessage({ id: "dns.ip_data" }),
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left' };
            },
            classes: 'txt-truncated',
            align: 'left',
            formatter: (cellContent, row) => {
                return(
                <div className="textToCopy"><span className='textToShow' onClick={(e) => {e.stopPropagation();e.preventDefault()}} onMouseDown={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    let elements = document.getElementsByClassName('tagToCopy')
                    for(let element of elements) {
                        element.innerText="Copied"
                    }
                    
                    await this.sleep(500)
                    for (let element of elements) {
                        element.innerText="Copy"
                    }
                    navigator.clipboard.writeText(cellContent);
                    
                }}>{cellContent}</span><div className='tagToCopy'>copy</div></div>
                )
            }
        },
        {
            dataField: 'user.name',
            text: formatMessage({ id: "app.partner" }),
            classes: 'txt-truncated',
            align: 'left'
        },
        {
            dataField: 'df1',
            isDummyField: true,
            text: '',
            headerStyle: (colum, colIndex) => {
                return { width: '100px', textAlign: 'left' };
            },
            headerFormatter: () => {
                return (<Modal type={{ Icon: "add", Formtype: "server", ButtonLabel: this.props.intl.formatMessage({ id: "app.add" }) }} onChange={this.onChange} addServer={this.addServer} notify = {this.notify} size="small" />);
                //return (<Modal type={{ Icon: "world", Formtype: "ispcserver", ButtonLabel: "Add Server" }} formData={{}} onChange={this.onChange} addServer={this.addISPServer} notify={this.notify} />);
            },
            formatter: (cellContent, row) => {
                return (
                    <div className="d-flex flex-row">
                        <Modal type={{ Icon: "pencil square", Formtype: "server", ButtonLabel: this.props.intl.formatMessage({ id: "app.edit" }) }} formData={row} onChange={this.onChange} addServer={this.modifyServer} notify={this.notify} size="small" />
                        <ModalConfirm modalCfg={{ type: "delete"}} onBoh={this.deleteServer} onYes={() => this.deleteServer(row.id)} />
                    </div>
                );
            },
            align: 'center'
        }
        ];
        return columns;
    };

    onPartnerChange = (e, data) => {
        this.setState({ partnerId: data.value });
        this.getServers({ partnerId: data.value });
    };

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        var currentIndex = (page - 1) * sizePerPage;
        if (type === 'search') {
            currentIndex = 0;
        }
        this.setState({ loading: true });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/server/filter`, {
                params: {
                    currentIndex: currentIndex,
                    sizePerPage: sizePerPage ? sizePerPage : this.state.sizePerPage,
                    q: searchText,
                    sortField: sortField,
                    sortOrder: sortOrder,
                    partnerId: this.state.partnerId ? this.state.partnerId : null
                }
            })
            .then((response) => {
                let result = response.data.dataset;
                let totalCount = response.data.totalCount;
                this.setState(() => ({
                    serverList: response.data.dataset,
                    page,
                    /*data: result.slice(currentIndex, currentIndex + sizePerPage),*/
                    totalSize: totalCount,
                    sizePerPage,
                    currentIndex: currentIndex,
                    loading: false
                }));
            });
    }

    onPartnerChange = (e, data) => {
        this.setState({ partnerId: data.value });
        this.getServers({ partnerId: data.value });
    };

    addServer = (callback) => {
        this.notify("Caricamento", "info", "add", false)
        axios.post(`${process.env.REACT_APP_API_URL}/api/server/addServer`,
            { name: this.state.name, ip: this.state.ip, type: this.state.type }
        ).then((response) => {
            callback(response, null);
            this.notify(this.props.intl.formatMessage({ id: "msg.host_saved" }), "success", "add", true)
            this.getServers();
        }).catch(e => {
            //+ e.response.data.message
            this.notify(this.props.intl.formatMessage({ id: "msg.host_not_saved" }), "error", "add", true)
            callback(null, e);
        })
    }

    modifyServer = (callback) => {
        this.notify("Caricamento", "info", "pencil", false)
        const payload = {
            id: this.state.id,
            userid: this.state.userid,
            name: this.state.name,
            ip: this.state.ip,
            type: this.state.type,
            public: this.state.public,
            modifiedBy: this.state.user
        };
        axios.put(`${process.env.REACT_APP_API_URL}/api/server/${this.state.id}`, payload
        ).then((response) => {
            this.notify(this.props.intl.formatMessage({ id: "msg.host_saved" }), "success", "pencil", true)
            this.getServers();
            callback(response, null);
        }).catch(e => {
            this.notify(this.props.intl.formatMessage({ id: "msg.host_not_saved" }), "error", "pencil", true)
            callback(null, e);
        })
    }

    /*
    loadUser = () => {
        return axios
            .get(`${process.env.REACT_APP_API_URL}/api/users/me`)
            .then((res) => {
                this.setState({ user: res.data.userid });
                this.getServers();
            }).catch(e => {
            })
    };
    */

    loadPartners = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then((response) => {
                this.setState({
                    partners: response.data
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    getServers = (params) => {
        let index = 0;
        if (params) {
            index = params.index ? params.index : 0;
        }
        axios.
            get(`${process.env.REACT_APP_API_URL}/api/server/filter`, {
                params: {
                    currentIndex: index,
                    sizePerPage: this.state.sizePerPage,
                    partnerId: params ? params.partnerId : null
                }
            })
            .then((response) => {
                this.setState({ serverList: response.data.dataset });
                this.setState({
                    serverList: response.data.dataset,
                    loading: false,
                    totalSize: response.data.totalCount,
                });
            })
            .catch((e) => {
                this.notify(this.props.intl.formatMessage({ id: "notify.loading-error" }), "error", "close", true)
            });
    };

    deleteServer = (id) => {
        this.notify("Caricamento", "info", "trash", false)
        axios.delete(`${process.env.REACT_APP_API_URL}/api/server/${id}`)
            .then((response) => {
                this.notify(this.props.intl.formatMessage({ id: "msg.host_deleted" }), "success", "trash", true)
                this.getServers();
            }).catch(e => {
                this.notify(this.props.intl.formatMessage({ id: "msg.host_not_deleted" }), "error", "trash", true)
                console.log(e);
            });
    }


    onChange = (name, val) => {
        this.setState({ [name]: val });
    }

    showPage = (direction) => {
        let index = -1;
        if (direction === 'prev') {
            if (this.state.currentIndex > 0) {
                index = this.state.currentIndex - this.state.sizePerPage;
            }
        }
        if (direction === 'next') {
            if (this.state.currentIndex + this.state.sizePerPage < this.state.totalCount) {
                index = this.state.currentIndex + this.state.sizePerPage;
            }
        }
        if (index >= 0) {
            this.getServers({ index: index });
        }
    }

    getUserData = (userdata) => {
        this.setState({ balance: userdata.balance });
    }


    render() {
        const { page, sizePerPage, totalSize, loading } = this.state;
        const sizePerPageList = [10, 15, 20];
        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];
        const isAdmin = (localStorage.getItem('isAdmin') === "true");
        const { formatMessage } = this.props.intl;

        return (
            <div className="kx-page">

                <Header history={this.props.history} language={this.props.language} changeLanguage={this.props.changeLanguage} getUserData={this.getUserData} />

                <div className="with-nav-bar with-left-nav">


                    <div className="">
                        <div className="kx-left-nav">
                            <LeftSidebar history={this.props.history} location={this.props.location}
                                styles={{ sidebar: { background: "blue" } }} />
                        </div>
                    </div>

                    <div className="kx-content">

                        <div className="kx-container">
                            {/* <div className="col-6 mb-2 billing-padding-left">
                                <Input icon='search' placeholder={this.props.intl.formatMessage({ "id": "table.search" }) + '...'} onChange={this.searchServer} />
                            </div> */}
                            {/*  <div className="col-6 mb-2">
                                {isAdmin ?
                                    <Dropdown
                                        className="parnter-list"
                                        placeholder="Partner"
                                        onChange={this.onPartnerChange}
                                        name="partnerId"
                                        clearable
                                        selection
                                        options={this.state.partners.map(({ id, name }) => ({ value: id, text: name }))}
                                    /> : null}
                            </div> */}

                            <div className="row">
                                <div className="col-12">
                                    <ToolkitProvider
                                        keyField="id"
                                        data={this.state.serverList}
                                        columns={this.columns}
                                        defaultSorted={defaultSorted}
                                        bootstrap4
                                        search
                                    >
                                        {
                                            toolkitprops => [ 
                                                <div className="searchBar">
                                                    <div className="searchBtn">
                                                        <SearchBar ref={n => this.searchKit = n} id="s1" {...toolkitprops.searchProps} delay={1000} placeholder={formatMessage({ id: "table.search" })} />
                                                        <ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
                                                    </div>
                                                    {isAdmin ?
                                                        <div className="d-flex flex-row align-items-center">
                                                            <Dropdown
                                                                className="parnter-list"
                                                                placeholder="Partner"
                                                                onChange={this.onPartnerChange}
                                                                name="partnerIdInv"
                                                                clearable
                                                                value={this.state.partnerCustomer}
                                                                selection
                                                                search
                                                                options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                                                            />
                                                        </div> : null}
                                                </div>,                                               
                                                <div className='infodiv boxTabella tabTag'>
                                                <BootstrapTable
                                                    {...toolkitprops.baseProps}
                                                    remote={{ search: true, pagination: true, sort: true }}
                                                    striped hover
                                                    classes="table-responsive-lg"
                                                    pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                                                    // className="isp-boostrap-table"
                                                    onTableChange={this.handleTableChange}
                                                /></div>
                                            ]
                                        }
                                    </ToolkitProvider>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
                <Footer />

            </div >)
    }
}
export default injectIntl(ServerList);