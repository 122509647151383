import React, { Component } from 'react';
import axios from 'axios';
import { Form, Modal, Dropdown, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

class ModalReAssignGateways extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersArray : [],
            contact : ""
        }
    }

    componentDidMount(){
        this.getUsers()
    }

    getUsers = async() => {
        await axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then(async (response) => {
                this.setState({usersArray:response.data})
            })
            .catch((err) => {
                console.log(err);
            })
    }
    
    assignTo = async () =>{
        this.props.notify("Caricamento", "info", "add", false)
        if (this.state.newuserid) {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/reassignto`, {id:this.props.data.id, userid:this.state.newuserid})
                .then((response) => {
                    this.props.notify("Cloudbox correttamente riassegnato", "success", 'add', true)
                    this.props.onClose()
                    })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            this.props.notify("Seleziona prima un partner", "error", 'add', true)
        }
    }

    onChangeUser = async (e, data) => {
        await this.setState({ newuserid: data.value });
    };


    render() {
        const { open, onClose, type } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    style = {{width: "30%"}}
                >
                <Modal.Header>
                    Riassegna Gateway
                </Modal.Header>
                    <Modal.Content>
                        <div style = {{ "padding-left": "0", "padding-right": "2rem", "padding-bottom": "1rem" }}>
                            <label> {"Il CloudBox sarà visibile a:"} </label>
                        </div>
                        <div>
                            <Form.Dropdown 
                                placeholder="Nuovo Proprietario"
                                style = {{"border-width" : "3px", "border-style" : "groove", "max-width": "100%", "border-radius" : "5px", "border-color" : "#000000"}}
                                search
                                defaultValue = {this.state.domainsAssigned}
                                onChange={this.onChangeUser}
                                name="domain"
                                clearable
                                selection
                                options={this.state.usersArray.map(({ name, userid }) => ({ value: userid, text: name }))}
                            />
                        </div>
                        <Form.Button style = {{"margin-right" : "5%", "margin-left" : "80%", "margin-bottom" : "3%", "margin-top" : "3%"}}  onClick={this.assignTo} className="save-button">
                            {formatMessage({ id: "app.save" })}
                        </Form.Button>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalReAssignGateways);
