import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Button, Label, Dropdown} from 'semantic-ui-react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import '../All/table.scss';
import ModalConfirm from '../All/ModalConferm';
import ModalEditPassword from './ModalEditPassword';
import ModalReAssignGateway from './ModalReAssignGateway'
import ModalEditID from './ModalEditID'
import ModalEditPort from './ModalEditPort'
import ModalConvertServerToGateway from './ModalConvertServerToGateway';
import NotifyComponent from '../notify/notifyComponent';
const { SearchBar, ClearSearchButton } = Search;


class ConfigurationGateways extends Component {
	constructor(props) {
		super(props);
		localStorage.setItem('api-vs-key', '');
		axios.defaults.headers.common['x-api-vs-key'] = this.token;
		this.token = localStorage.getItem('jwt');
		axios.defaults.headers.common['x-auth-token'] = this.token;
		this.isAdmin = (localStorage.getItem('isAdmin') === "true");
        this.myuser = (localStorage.getItem('user'));
        this.notify = this.notify.bind(this);
		this.state = {
			page: 1,
			totalSize: 0,
			sizePerPage: 10,
			balance: '0.00',
            service: { status: "ACTIVE" },
            servers: [],
            offset: 0,
            currentIndex: 0,
            amount: 0,
            action: "",
            openConfirmation: false,
            renewProductInfo: {},
            validated: true,
            messageOK: '',
            relay_domains: [],
            trust_networks: [],
            transport: [],
            spam_quarantine: [],
            virus_quarantine: [],
            users_whitelist: [],
            statistics: [],
            trackers: [],
            users_blacklist: [],
            loading: false,
            askAPI: false,
            readyVS: false, // enable server actions
            server_ip: '',
            username: '',
            password: '',
            port: '',
            user_id: '',
            ticket: '',
            server: '',
            pmail: '',
            hetzner_status : 'running',
            stateMachine : 'list',
            gateways: [],
            createGatewayModalOpen : false,
            partners:[],
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
		}
		this.columns = [{
            dataField: 'status',
            text: "Stato",
            sort: false,
            classes: 'cut-words',
            formatter: (cellContent, row) => {
                return(this.statusLabelize(cellContent))
            }
        },{
            dataField: 'server',
            text: "Nome",
            sort: false,
            classes: 'cut-words',
            headerStyle: () => {
                return({"width": "500px"})
            },
            formatter: (cellContent, row) => {
                return(<strong>{cellContent}</strong>)
            }
        },{
            dataField: 'server_type',
            text: "Tipo",
            sort: false,
            classes: 'cut-words',
            headerStyle: () => {
                return({"width": "150px","text-align": "center"})
            },
            style: {"text-align": "center"}
        },{
            dataField: 'customer.personalName',
            text: "Partner",
            sort: false,
            classes: 'cut-words',
            headerStyle: () => {
                return({"width": "250px"})
            },
            formatter: (cellContent, row) => {
                return(<Button className="buttonAction" onClick = {(e) => { this.changeAccount(row)}}> {cellContent} </Button>)
            }
        },{
            dataField: 'azioni',
            text: "Azioni",
            sort: false,
            classes: 'cut-words',
            style: {width: "800px"},
            headerStyle: () => {
                return({"text-align": "right", "width": "800px"})
            },
			headerFormatter: () => {
                return (
                    <div>
						<label style={{"margin-right":"10px"}}>Azioni</label>                                                   
                        <Button icon="add" className="details-add" onClick={(e) => {this.setState({AddGatewayOpen: true})}} />
                    </div>
                );
            },
            formatter: (cellContent, row) => {
                return(
                    <div style={{display: "flex", "justify-content": "flex-end"}}>
                        <Button onClick = {(e) => {e.preventDefault(); e.stopPropagation();this.setState({selectedServer: row, EditIDOpen: true})}}>Modifica ID</Button>
                        <Button onClick = {() => {this.setState({selectedServer: row, EditPasswordOpen: true})}}>Modifica Password</Button>
                        <Button onClick = {(e) => {e.preventDefault(); e.stopPropagation();this.setState({selectedServer: row, EditPortOpen: true})}}>Modifica porta</Button>
                        <Button onClick = {(e) => {e.preventDefault(); e.stopPropagation();this.setState({selectedServer: row, AssignServerOpened: true})}}>Assegna ad un altro Partner</Button>
                        <ModalConfirm modalCfg={{ type: "delete"}} onYes={(e) => {this.deleteServer(row.id)} } />
                    </div>
                )
            }
        }]
	}

	loadUsers = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
			.then((response) => {
				this.setState({
					partners: response.data
				});
			})
			.catch((error) => {
                console.log(error)
            });
	}

	componentDidMount() {
		this.loadUsers()
		this.generateGatewayArray()
	}

    changeID = async (row) => {

    }

    changePort = async (row) => {
        
    }

    deleteServer = async (id) => {
        this.notify("Caricamento", "info", "trash", false)
        axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/deleteserver`, {id: id})
		.then((response) => {
			this.generateGatewayArray()
			this.notify(<FormattedMessage id="notify.server-deleted" />, "success", "trash", true)
		})
		.catch((error) => {
			console.log(error)
			this.notify(<FormattedMessage id="notify.server-deleted.failed" />, "error", "trash", true)
		});
	}

    changeAccount = async (row) => {
		await axios.post(`${process.env.REACT_APP_API_URL}/api/contact/adminauth`, {userid: row.userid})
            .then((response) => {
                if(!localStorage.getItem("baseuser")){
                    localStorage.setItem("baseuser", localStorage.getItem("user"))
                    localStorage.setItem("basejwt", localStorage.getItem("jwt"))
                }
                localStorage.setItem("jwt", response.data.token);
                localStorage.setItem("isAdmin", false);
                localStorage.setItem("user", response.data.user);
                localStorage.setItem("restore", true);
                localStorage.setItem("multitenancy", response.data.multitenancy);
                window.location.reload();
            })
	}

	componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }

	statusLabelize = (status) => {
        switch(status){
            case 'ACTIVE' :
                return <Label color='green' horizontal>{status} </Label>
            case 'TO DELETE' :
                return <Label color='red' horizontal>{status} </Label>
            case 'EXPIRED' :
                return <Label color='red' horizontal>{status} </Label>
            case 'RENEW' :
                return <Label color='yellow' horizontal>{status} </Label>
        }
    }

	generateGatewayArray = (partnerId) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/vmservers/gateways`, {
            params: {partnerId: partnerId}
        })
            .then((response) => {
                this.setState({
                    gateways : response.data.dataset
                })
            })
            .catch((error) => {
                console.log("" + error)
            });
    }

	handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        var currentIndex = (page - 1) * sizePerPage;
        if (type === 'search') {
            currentIndex = 0;
        }
        this.setState({ loading: true });
        var filter = {
            currentIndex: currentIndex,
            sizePerPage: sizePerPage ? sizePerPage : this.state.sizePerPage,
            q: searchText,
            sortField: sortField,
            sortOrder: sortOrder,
            partnerId: this.state.partnerId ? this.state.partnerId : null
        };
        this.setState({ filter: filter });
        this.setState({
            page,
            sizePerPage,
            currentIndex: currentIndex,
        })
        this.getServers(filter);
    }

	onChange = (nome, val) => {
        let valuetocheck = val.value
        val.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        this.setState({ pmail: val.value });
    }


	changeAccount = async (row) => {
		await axios.post(`${process.env.REACT_APP_API_URL}/api/contact/adminauth`, {userid: row.userid})
            .then((response) => {
                if(!localStorage.getItem("baseuser")){
                    localStorage.setItem("baseuser", localStorage.getItem("user"))
                    localStorage.setItem("basejwt", localStorage.getItem("jwt"))
                }
                localStorage.setItem("jwt", response.data.token);
                localStorage.setItem("isAdmin", false);
                localStorage.setItem("user", response.data.user);
                localStorage.setItem("restore", true);
                localStorage.setItem("multitenancy", response.data.multitenancy);
                window.location.reload();
            })
	}

	render() {
		const { page, sizePerPage, totalSize, loading } = this.state;
		const sizePerPageList = [5, 10, 15];
		const defaultSorted = [{
			dataField: 'id',
			order: 'desc'
		}]
		const isAdmin = (localStorage.getItem('isAdmin') === "true");
		const { formatMessage } = this.props.intl;
		return <div className="kx-page">
			<ModalEditPassword source ={"server"} data = {this.state.selectedServer} notify = {this.notify} open = {this.state.EditPasswordOpen} onClose = {() => {this.setState({EditPasswordOpen: false})}} />
            <ModalReAssignGateway data = {this.state.selectedServer}  notify={this.notify} open={this.state.AssignServerOpened} onClose={() => {this.setState({AssignServerOpened:false}); this.generateGatewayArray()}} />
            <ModalEditID data = {this.state.selectedServer} notify = {this.notify} open = {this.state.EditIDOpen} onClose = {() => {this.setState({EditIDOpen: false})}} />
			<ModalEditPort data = {this.state.selectedServer} notify = {this.notify} open = {this.state.EditPortOpen} onClose = {() => {this.setState({EditPortOpen: false})}} />
            <ModalConvertServerToGateway notify = {this.notify} open = {this.state.AddGatewayOpen} onClose = {() => {this.setState({AddGatewayOpen: false}); this.generateGatewayArray()}} />
            <ToolkitProvider
                keyField="id"
                data={this.state.gateways}
                columns={this.columns}
                defaultSorted={defaultSorted}
                bootstrap4
                search
            >
                {
                    toolkitprops => [
                        <div className="searchBar">
                            <div className="searchBtn">
                                <SearchBar ref={n => this.searchKit = n} id="s1" delay={1500} {...toolkitprops.searchProps} placeholder={formatMessage({ id: "table.search" })} />
                                <ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
                            </div>
                            {isAdmin ?
                                <div className="d-flex flex-row align-items-center">
                                    <Dropdown
                                        className="parnter-list"
                                        placeholder="Partner"
                                        onChange={(e, data) => {this.generateGatewayArray(data.value)}}
                                        name="partnerId"
                                        clearable                                                                
                                        selection
                                        search
                                        options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                                    />
                                </div> : null}
                        </div>,
                        <div className='infodiv boxTabella'>
                            <BootstrapTable
                                {...toolkitprops.baseProps}
                                hover
                                classes="table-responsive"
                                pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                                onTableChange={this.handleTableChange}
                            />
                        </div>
                    ]
                }
            </ToolkitProvider>
            {
                this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
            }
        </div>
	}
}

export default injectIntl(ConfigurationGateways);