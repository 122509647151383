import React, { Component } from "react";
import "./alert.scss";

import { Alert } from "reactstrap";

class AlertBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <div>
        <div className="alert-box">
          <Alert
            isOpen={this.state.visible && this.props.alertDisplay}
            toggle={this.onDismiss}
          >
            {this.props.message}
          </Alert>
        </div>
      </div>
    );
  }
}

export default AlertBox;
