import React, { Component } from 'react';
import './list-item.scss';
import ArrowDown from './arrow-down.png';
import { Collapse } from 'reactstrap';
import Tabs from '../tabs/tabs';

import { Grid, Card, Table, Icon, Button, Modal, Form, Dropdown } from 'semantic-ui-react';
import Moment from 'moment';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const friendOptions = [
	{
		text: 'TXT',
		value: 'TXT'
	},
	{
		text: 'A',
		value: 'A'
	},
	{
		text: 'CNAME',
		value: 'CNAME'
	},
	{
		text: 'MX',
		value: 'MX'
	}
];

class ListItem extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.state = {
			collapse: false,
			user: {},
			dns: [],
			domainid: '',
			open: false,
			name: '',
			type: '',
			ttl: '',
			extra: '',
			ip: '',
			domain: '',
			isEdit: false,
			isDetails: false,
			showDetails: false,
			background: 'white',
			dropdownselect: null
		};
		this.loadUser = this.loadUser.bind(this);
		this.loadUser();
	}

	loadUser = () => {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
				headers: {
					'x-auth-token': localStorage.getItem('jwt')
				}
			})
			.then((res) => {
				this.setState({ user: res.data });
			});
	};
	toggle() {
		this.setState({ collapse: !this.state.collapse });
	}

	loadDNS = (id) => {
		const token = localStorage.getItem('jwt');
		const decoded = jwt_decode(token);
		if (decoded.isAdmin !== true) {
			axios
				.get(`${process.env.REACT_APP_API_URL}/api/dns/me/` + id, {
					headers: {
						'x-auth-token': localStorage.getItem('jwt')
					}
				})
				.then((response) => {
					this.setState({ dns: response.data, domainid: id, showDetails: true });
				})
				.catch((error) => {
					console.log(error)
				});
		} else {
			axios
				.get(`${process.env.REACT_APP_API_URL}/api/dns/` + id, {
					headers: {
						'x-auth-token': token
					}
				})
				.then((response) => {
					this.setState({ dns: response.data, domainid: id, showDetails: true });
				})
				.catch((error) => {
					console.log(error)
				});
		}
	};

	onSubmit = (e) => {
		e.preventDefault();
		// get our form data out of state
		const { name, type, ttl, extra, ip, domain, domainid } = this.state;
		const token = localStorage.getItem('jwt');
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/api/dns/me`,
				{ name, type, ttl, extra, ip, domain, domainid },
				{
					headers: {
						'x-auth-token': token
					}
				}
			)
			.then((response) => {
				this.loadDNS(this.state.domainid)
				this.close();
			}).catch(e => {
				this.close();
			})
	};

	domaindns = () => {
		this.props.history.push("domaindns", { domainid: this.state.domainid, domain: this.props.domain, changeLanguage: this.props.changeLanguage, language: this.props.language })
	}

	onSubmitUpdate = (e) => {
		e.preventDefault();
		// get our form data out of state
		const { name, type, ttl, extra, ip, domain, domainid } = this.state;
		const token = localStorage.getItem('jwt');
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/api/dns/me/` + this.state.domainid + '/' + this.state.id,
				{ name, type, ttl, extra, ip, domain, domainid },
				{
					headers: {
						'x-auth-token': token
					}
				}
			)
			.then((response) => {
				// this.props.showAlert('Modifica DNS riuscita.');
				// this.editFormToggle(response);
				this.loadDNS(this.state.domainid)

				this.close();
			}).catch(e => {
				this.close();
			})
	};

	show = (size) => () => this.setState({ size, open: true });
	close = () => this.setState({ open: false });

	edit = (dns) => {
		this.setState({
			isEdit: true,
			name: dns.name,
			type: dns.type,
			domain: dns._id
		});
		this.show('small')();
	};

	showDetails = () => {
		this.setState({ isDetails: true });
		this.show('mini')();
	};
	addDns = () => {
		this.setState({
			isEdit: false,
			isDetails: false
		});
		this.show('small')();
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		const { open, size } = this.state;

		return (
			<div>
				<div>
					<Grid divided="vertically">
						<Grid.Column width={4}>
							<b>Domain Name</b>
						</Grid.Column>
						{this.state.showDetails ? (
							<div className="domaindetails-heading">
								{/* <div className="dns-heading">
									<b>Domain Name System (DNS)</b>
								</div> */}
								<div style={{ width: "100%", textAlign: "right" }}>
									{/* <Button primary className="details-add" onClick={this.showDetails}>
										Details
									</Button> */}
									<Button basic color="red" className="details-add" onClick={this.domaindns}>
										View DNS
									</Button>
								</div>
							</div>
						) : null}

						<Grid.Row className="second-row" columns={2}>
							<Grid.Column width={4}>
								{this.props.domain.map((domain, domainkey) => (
									<div key={domainkey}>
										<Card
											onClick={() => this.loadDNS(domain.id)}
											className="card-custom-style"
											color="red"
											style={
												this.state.domainid == domain._id ? (
													{ background: 'lemonchiffon' }
												) : (
														{ backgroundColor: 'white' }
													)
											}
											header={
												<div className="domain-content">
													<div className="led active" />
													<b className="domain-bold-name">{domain.name}</b>
													<span>
														<Icon name="angle right" />
													</span>
												</div>
											}
										/>
									</div>
								))}
							</Grid.Column>

							{this.state.showDetails ? (
								<Grid.Column width={12}>

								</Grid.Column>
							) : (
									<div className="no-domain-div">
										<div>Select a Domain to view Details</div>
									</div>
								)}
						</Grid.Row>
					</Grid>
				</div>
			</div>
		);
	}
}

export default ListItem;
