import React, { Component } from 'react';
//import '../list-item/list-item';
import { Collapse } from 'reactstrap';
import Tabs from '../tabs/tabs';
import Modal from '../All/Modal';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';

import { Table, Icon, Button, Form, Checkbox, Dropdown, Menu, Pagination } from 'semantic-ui-react';
import Moment from 'moment';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

const friendOptions = [
    {
        text: 'TXT',
        value: 'TXT'
    },
    {
        text: 'A',
        value: 'A'
    },
    {
        text: 'CNAME',
        value: 'CNAME'
    },
    {
        text: 'MX',
        value: 'MX'
    }
];

class Servers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            server_name: '',
            server_type: '',
            server_ttl: '',
            Server_ip: '',
            mailname: '',
            mailtype: '',
            mailttl: '',
            mailextra: '',
            mailip: '',
            domain: '',
            isEdit: false,
            isDetails: false,
            showDetails: false,
            background: 'white',
            dropdownselect: null,
            selectedServers: [],
            serverBoolean: false,
            mailServers: [],
            webServers: [],
            dnsServers: [],
            mailServer: '',
            webServer: '',
            dnsServer: '',
            servers: {
                web: '',
                mail: '',
                dns: ''
            }
        };
        //const decoded = jwt_decode(token);
        this.token = localStorage.getItem('jwt');
        const decoded = jwt_decode(this.token);
        axios.defaults.headers.common['x-auth-token'] = this.token;

        this.getDNSServers();
        this.getMailServers();
        this.getWebServers();
    }

    onChange = (event, val) => {
        this.setState({ [val.name]: val.value }, () => { //async
            //this.prepareServers();
        });
    }

    onServerChange = (name, val) => {
        this.setState({ [name]: val });
    }

    prepareServers = () => {
        const { getServers } = this.props;
        getServers(
            {
                server_web: this.state.webServer,
                server_email: this.state.mailServer,
                isp_id: this.state.dnsServer
            }
        );
    }

    getDNSServers = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/server/getdns`)
            .then((s) => {
                this.setState({ dnsServers: s.data.servers });
                this.setState({ dnsServer: s.data.servers[0].id }, () => {
                });
            }).catch(e => {
            })
    }

    getWebServers = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/server/getWebServers`
            )
            .then((s) => {
                this.setState({ webServers: s.data.servers });
                this.setState({ webServer: s.data.servers[0].ip }, () => {
                });
            }).catch(e => {
            })
    }


    getMailServers = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/server/getMailServers`
            )
            .then((s) => {
                this.setState({ mailServers: s.data.servers })
                this.setState({ mailServer: s.data.servers[0].ip }, () => {
                });
            }).catch(e => {
            })
    }

    serverData = (ip, serverType) => {
        if (ip) {
            const result = this.state[serverType].find(server => (server.ip === ip));
            return result;
        }
        else {
            return null;
        }
    }

    modifyServer = () => {
        this.props.notify("caricamento", "info", "save", false);
        axios.post(`${process.env.REACT_APP_API_URL}/api/server/addServer`,
            { name: this.state.name, ip: this.state.ip, type: this.state.type }
        ).then((response) => {
            this.props.notify(this.props.intl.formatMessage({ id: "msg.host_saved" }), "success", 'save', true);
            this.getServers();
        }).catch(e => {
            console.log(e);
            //+ e.response.data.message
            this.props.notify(this.props.intl.formatMessage({ id: "msg.host_not_saved" }), "error", 'save', true);
        })
    }


    render() {
        return (

            <div className="container">
                <div className="row mb-2">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                        <h2 className="server-style-title"><FormattedMessage id="reg.mail_server" /></h2>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9">
                        <div className="ui action k-dropgroup">
                            <Dropdown
                                className="left-rounded"
                                placeholder="type"
                                onChange={this.onChange}
                                name="mailServer"
                                // fluid
                                selection
                                options={this.state.mailServers.map(({ ip, name }) => ({ value: ip, text: name }))}
                                value={this.state.mailServer}
                            />
                            <Modal type={{ Formtype: "server", ButtonLabel: this.props.intl.formatMessage({ id: "app.edit" }), Icon: "edit" }} formData={() => this.serverData(this.state.mailServer, 'mailServers')} onChange={this.onServerChange} addServer={this.modifyServer} notify={this.props.notify} />
                            {/* <Button icon>
                                                <Icon name='world' />
                                            </Button> */}
                        </div>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                        <h2 className="server-style-title"><FormattedMessage id="reg.web_server" /></h2>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9">
                        <div className="ui action k-dropgroup">
                            <Dropdown
                                className="left-rounded"
                                placeholder="type"
                                onChange={this.onChange}
                                name="webServer"
                                /* onChange={(e) => {
                                    this.getMailServers(e.target.textContent)
                                }} */
                                //fluid
                                selection
                                options={this.state.webServers.map(({ ip, name }) => ({ value: ip, text: name }))}
                                value={this.state.webServer}
                            />
                            <Modal type={{ Formtype: "server", ButtonLabel: this.props.intl.formatMessage({ id: "app.edit", Icon: "edit" }), Icon: "edit" }} formData={() => this.serverData(this.state.webServer, 'webServers')} onChange={this.onServerChange} addServer={this.modifyServer} notify={this.props.notify} />
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                        <h2 className="server-style-title"><FormattedMessage id="reg.dns_server" /></h2>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9">
                        <div className="ui action k-dropgroup">
                            <Dropdown
                                className="left-rounded"
                                placeholder="type"
                                onChange={this.onChange}
                                name="dnsServer"
                                //fluid
                                selection
                                options={this.state.dnsServers.map(({ id, description }) => ({ value: id, text: description }))}
                                value={this.state.dnsServer}
                            />
                        </div>
                    </div>
                </div>
                <div className="next-button-center" style={{ textAlign: "center", margin: "3%" }}>
                    <Button onClick={this.prepareServers}><FormattedMessage id="reg.next_step" /></Button>
                    {/* <a style={{ width: "20%" }} onClick={this.prepareServers}><span className="invisible">next</span></a> */}
                </div>
            </div>
        );
    }
}

export default injectIntl(Servers);
