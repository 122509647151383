import React, { Component } from 'react';
import {Modal, Form} from 'semantic-ui-react';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';

class ModalEditID extends Component {
    constructor(props) {
        super(props);
		this.instance = React.createRef();
        this.state = {
            hostname:"",
            target:""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    getcname = async(id) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/barmetal/getcname`, {id: id})
            .then((response) => {
                this.setState({hostname:response.data.hostname, target:response.data.target})
            })
            .catch((error) => {
                console.log(error)
                this.props.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
            })
    }

    
    save = async() => {
        this.props.notify("Caricamento", "info", "save", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/barmetal/addcname`, {hostname: this.state.hostname, target:this.state.target, id:this.props.data.hetzner_id})
            .then((response) => {
                this.props.notify("Cname aggiunto correttamente a ns1.cloudonthecloud.it", "success", 'save', true)
                this.props.onClose()
            })
            .catch((error) => {
                console.log(error)
                this.props.notify("" + error, 'error', 'save', true);
            })
    }

    firstSetUp = () => {
        if(!this.state.statewritten && this.props.open){
            this.getcname(this.props.data.hetzner_id)
            this.setState({
                statewritten: true
            })
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                statewritten: false
            })
        }
    }
    
    async handleInputChange(event, { name, value }) {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        await this.setState({
            [name]: value
        });
    }

    render() {
        const {open, onClose} = this.props;
        const { formatMessage } = this.props.intl;
        this.firstSetUp();
        return (
            <div>
                <Modal closeIcon={true}
                    className="overflow-yes"
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto', width: '30%' }}
                    open={open}
                    onClose={() => onClose(false)}>
                    <Modal.Header>
                    Aggiungi cname
                    </Modal.Header>
                    <Modal.Content className="modal-min-height">
                        <Modal.Description>
                                <div className="server-form">
                                    <Form className="overflow-yes">
                                        <Form.Input name="hostname" defaultValue={this.state.hostname} onChange = {this.handleInputChange} label={"Hostname:"}/>
                                        <Form.Input name="target" defaultValue={this.state.target} onChange = {this.handleInputChange} label={"Target:"}/>
                                        <Form.Button onClick={() => {this.save()}} >
                                            {formatMessage({ id: "app.save" })}
                                        </Form.Button>
                                    </Form>
                                </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalEditID);