import React, { Component } from 'react';
import './list.scss';
import axios from 'axios';

import AlertBox from '../utils/alert';
import LeftSidebar from '../sidebar/left-sidebar';
import { Checkout } from '../../../Checkout';
import { FormattedMessage, injectIntl } from 'react-intl';
import StepExampleOrdered from '../stepper/index';
import TableFullWidth from './Table';
import Servers from './servers'
import ModalConfirmPayment from '../All/ModalConfirmPayment';
import Modal from './Modal';

//import Modal from '../All/Modal';

import Header from '../header/header';
import Footer from '../footer/footer';
import { Card, Icon, Dropdown, Button, Input, Form, Grid, Segment, Divider, Statistic, Header as HeaderUI } from 'semantic-ui-react';
import NotifyComponent from '../notify/notifyComponent';


const durationsList = (intl) => [
	{ text: '1 ' + intl.formatMessage({ id: "reg.year" }), value: '1Y' },
	{ text: '2 ' + intl.formatMessage({ id: "reg.year" }), value: '2Y' },
	{ text: '3 ' + intl.formatMessage({ id: "reg.year" }), value: '3Y' },
	{ text: '4 ' + intl.formatMessage({ id: "reg.year" }), value: '4Y' },
	{ text: '5 ' + intl.formatMessage({ id: "reg.year" }), value: '5Y' }
];


class DomainRegistration extends Component {
	constructor(props) {
		super(props);
		this.token = localStorage.getItem('jwt');
		axios.defaults.headers.common['x-auth-token'] = this.token;
		this.addDomain = this.addDomain.bind(this);
		this.showAlert = this.showAlert.bind(this);
		this.notify = this.notify.bind(this);
		const { formatMessage } = this.props.intl;
		this.state = {
			filter: '',
			sizePerPage: 10,
			currentIndex: 0,
			totalCount: 0,
			isTrue: false,
			domain: '',
			duration: '1Y',
			alertMessage: '',
			alertDisplay: false,
			checked: false,
			checkout: false,
			domainDetails: '',
			stepNo: 1,
			adduser: false,
			firstname: '',
			lastname: '',
			email: '',
			phonenumber: null,
			street: '',
			city: '',
			countrycode: null,
			postalcode: null,
			contacts: {
				registrant: { id: '', fullname: '' },
				admin: { id: '', fullname: '' },
				technical: { id: '', fullname: '' },
				billing: { id: '', fullname: '' }
			},
			registrar: 'internetbs',
			contactlist: [],
			servers: [],
			authcode: '',
			amount: 0,
			balance: 0,
			prices: {},
			action: props.location.state.action,
			showContactsTable: false,
			contactType: 'registrant',
			registrant: {},
			disableCheckout: false,
			steps: [
				{
					key: 'Registrant',
					disabled: false,
					className: 'firstStep'
				},
				{
					key: 'Clock',
					disabled: true,
					className: 'secondStep',
				},
				{
					key: 'Server',
					disabled: true,
					className: 'xd-step thirdStep',
				},
				{
					key: 'Users',
					disabled: true,
					className: 'fourthStep',
				},
				{
					key: 'Payment', disabled: true,
					className: 'fifthStep',
				}
			],
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
		};
		this.getPrices();
		this.getContacts();
		//remove go to step 2 - contacts
		/* 		this.state.steps[0].disabled = true;
				this.state.steps[1].disabled = true;
				this.state.steps[2].disabled = true;
				this.state.steps[3].disabled = true;
				this.state.steps[4].disabled = false; */
		// remove
	}
	getPrices = () => {
		let product = {
			product: {
				type: 'domain',
				name: this.props.location.state.domain,
				action: this.state.action
			}
		};
		axios.post(`${process.env.REACT_APP_API_URL}/api/domains/regprices`, product)
			.then((response) => {
				this.setState({ prices: response.data });
			})
			.catch((error) => {
				console.log(error)
				this.notify('Problem with domain registration.\nError: PL0', 'danger');
			});
	}


	componentWillUnmount = () => {
		window.stop();
		window.clearTimeout()
	}

	sleep = (ms) => {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	notify = (content, type, icon, completed) => {
		if(!this.state.openNotify) {            
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed,
				openNotify: true
			}, async () => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
		} else {
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed
			}, async() => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
			
		}
	}

	//Alert inner code
	showAlert(message) {
		this.setState({
			alertMessage: message,
			alertDisplay: true
		});

		setTimeout(() => {
			this.setState({ alertDisplay: false, alertMessage: '' });
		}, 3000);
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	changeAuthcode = (e) => {
		let valuetocheck = e.target.value
        e.target.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
		let authcode = ""
		for(let ch of e.target.value){
			if(ch != ' '){
				authcode+=ch
			}
		}
		this.setState({authcode: authcode})
	}

	addDomain(no) {
		const { domain } = this.state;
		const token = localStorage.getItem('jwt');

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/api/domains/me`,
				{ name: domain },
				{
					headers: {
						'x-auth-token': token
					}
				}
			)
			.then((response) => {
				if (response.data.status !== 'failed') {
					this.state.steps[0].disabled = true;
					this.state.steps[1].disabled = false;
					this.setState({
						steps: this.state.steps,
						domain: '',
						domainDetails: response.data.message
					});
				} else {
					this.showAlert(response.data.message);
				}
			})
			.catch((error) => {
				this.showAlert(error.message);
			});
	}

	changeStep = () => {
		this.state.steps[1].disabled = true;
		this.state.steps[2].disabled = false;
		this.setState({
			steps: this.state.steps
		});
	};

	addUser = () => {
		this.setState({ adduser: true });
	};

	contactPage = (direction) => {
		let index = this.state.currentIndex;
		if (direction === 'prev') {
			if (this.state.currentIndex > 0) {
				index = this.state.currentIndex - this.state.sizePerPage;
			}
		}
		if (direction === 'next') {
			if (this.state.currentIndex + this.state.sizePerPage < this.state.totalCount) {
				index = this.state.currentIndex + this.state.sizePerPage;
			}
		}
		axios.
			get(`${process.env.REACT_APP_API_URL}/api/contact/filter`, {
				params: {
					currentIndex: index,
					sizePerPage: this.state.sizePerPage,
					q: this.state.filter
				}
			})
			.then((response) => {
				this.setState({
					contactlist: response.data.dataset,
					currentIndex: index,
					totalCount: response.data.totalCount
				});
			})
			.catch((error) => {
				this.showAlert(error.message);
			});
	}

	addContact = (data, callback) => {
		axios.post(`${process.env.REACT_APP_API_URL}/api/contact/contact`, data)
			.then((response) => {
				this.getContacts();
				this.notify("Il contatto è stata salvato", "success");
				callback(response, null);
			})
			.catch((e) => {
				this.notify("Il contatto non è stata salvato:" + e.response.data.message, "danger");
				console.log(e.response);
				callback(null, e);
				// ??? this.showAlert(error.message);
			});
	};

	getContacts = (filter) => {
		if (filter) {
			var options = { params: { q: filter } };
		}
		this.setState({ filter: filter });
		axios.get(`${process.env.REACT_APP_API_URL}/api/contact/filter`, options)
			.then((response) => {
				this.setState({
					contactlist: response.data.dataset,
					currentIndex: 0,
					totalCount: response.data.totalCount
				});
			})
			.catch((error) => {
				this.showAlert(error.message);
			});
	};

	selectContact = (type) => {
		this.setState({ showContactsTable: true, contactType: type });
	}

	validateContact = (contact) => {
		let validateMsg = '';
		const { formatMessage } = this.props.intl;
		const tld = this.props.location.state.domain.split('.').pop().toLowerCase();
		if (tld === 'it') {
			//email, fiscalcode, vat, countrycode, countrystate, contacttype === 'private' | 'company', phonenumber, postalcode
			if (contact.email === null || contact.email == '') {
				validateMsg = formatMessage({ id: "err.email_error" });
			}
			if (contact.contacttype == 'private' && contact.fiscalcode == '') {
				validateMsg = formatMessage({ id: "err.fiscalcode_error" });
			}
			if (contact.contacttype == 'company' && contact.vat == '') {
				validateMsg = formatMessage({ id: "err.vat_error" });
			}
			if (contact.countrycode == 'IT' && contact.countrystate == '') {
				validateMsg = formatMessage({ id: "err.province_error" });
			}
			if (contact.postalcode === null || contact.postalcode == '') {
				validateMsg = formatMessage({ id: "err.postalcode_error" });
			}
			if (contact.phonenumber === null || contact.phonenumber == '' || contact.phonenumber.length < 6 ) {
				validateMsg = formatMessage({ id: "err.phonenumber_error" });
			}
			if (validateMsg) {
				this.notify(`${formatMessage({ id: 'err.contact_not_valid' })}: ${validateMsg}`, 'danger');
			}
		}
		return validateMsg === '' // true or false
	}

	onSelectContcat = (contact, contactType) => {
		/* validate contact for IT */
		if (!this.validateContact(contact)) {
			return false;
		}
		var contactCopy = Object.assign({}, this.state.contacts);
		var fullname = `${contact.firstname} ${contact.lastname}`;
		contactCopy[contactType] = Object.assign({}, { id: contact.id, fullname: fullname.trim() });
		if (contactType === 'registrant') {
			if (contactCopy['admin']['id'] === '') {
				contactCopy['admin'] = Object.assign({}, { id: contact.id, fullname: fullname.trim() });
			}
			if (contactCopy['technical']['id'] === '') {
				contactCopy['technical'] = Object.assign({}, { id: contact.id, fullname: fullname.trim() });
			}
			if (contactCopy['billing']['id'] === '') {
				contactCopy['billing'] = Object.assign({}, { id: contact.id, fullname: fullname.trim() });
			}
		}
		var changedState = { contacts: contactCopy, showContactsTable: false };
		if (contactType === 'registrant') {
			changedState.registrant = contact;
		}
		this.setState(changedState);
	}

	changeTab = (tab, data) => {
		if (tab === 'first') { // choose dns
			this.state.steps[0].disabled = true;
			this.state.steps[1].disabled = false;
			this.setState({ steps: this.state.steps, registrar: data });
		}
		if (tab === 'second') { // choose dns
			this.state.steps[1].disabled = true;
			this.state.steps[2].disabled = false;
			this.setState({ steps: this.state.steps });
		}
		else if (tab === 'third') { //choose contact			
			this.state.steps[2].disabled = true;
			this.state.steps[3].disabled = false;
			this.setState({ steps: this.state.steps });
			axios.get(`${process.env.REACT_APP_API_URL}/api/server/ispconfig/${data.isp_id}/defaultcontacts`)
				.then((response) => {
					let contacts = response.data.contacts;
					var contactCopy = Object.assign({}, this.state.contacts);
					if (contacts.admin) {
						contactCopy.admin = contacts.admin;
					}
					if (contacts.technical) {
						contactCopy.technical = contacts.technical;
					}
					if (contacts.billing) {
						contactCopy.billing = contacts.billing;
					}
					this.setState({ contacts: contactCopy });
				})
				.catch((error) => {
					this.showAlert(error.message);
				});
		}
		else if (tab === 'forth') { //payment (input contact)
			//find price
			let product = {
				product: {
					type: 'domain',
					name: this.props.location.state.domain,
					action: this.state.action,
					registrar: this.state.registrar
				}
			};
			axios.post(`${process.env.REACT_APP_API_URL}/api/domains/price`, product)
				.then((response) => {
					this.setState({ amount: response.data.amount });
				})
				.catch((error) => {
					console.log(error)
					this.notify('Problem with domain registration.\nError: PL0', 'danger');
				});
			this.state.steps[3].disabled = true;
			this.state.steps[4].disabled = false;
			this.setState({ steps: this.state.steps });
		}
		// else {
		// 	this.state.steps[1].disabled = true;
		// 	this.state.steps[2].disabled = false;
		// 	this.setState({ steps: this.state.steps, contact: user });
		// }
	};

	getSingleContact = (contact) => {
		this.setState({ contact: contact });
	};

	getServers = (servers) => {
		this.setState({ servers: servers })
		this.changeTab("third", servers)
	}

	searchContact = (event, data) => {
		let valuetocheck = data.value
        data.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
		this.getContacts(data.value);
	}

	conatctsOK = () => {
		const { registrant, admin, technical, billing } = this.state.contacts;
		if (registrant.id && admin.id && technical.id && billing.id) {
			return true;
		}
		else {
			return false;
		}
	}
	/* payment section */
	getUserData = (userdata) => {
		this.setState({ balance: userdata.balance });
	}

	paymentConfirmConfig = {
		type: "confirm",
		color: "#ff9900",
		btn_label: this.props.intl.formatMessage({ id: "app.pay_from_account" }),
		btn_size: 'normal'
	}
	onConfirmOpen = () => {
		return {
			balance: this.state.balance,
			name: this.state.action === 'register' ? 'Domain Registration' : 'Domain Transfer',
			description: this.props.location.state.domain
		};
	}
	Debug_payFromAccount = (contact, amount) => {
		this.setState({ disableCheckout: true });
	}
	payFromAccount = (contact, amount) => {
		this.setState({ disableCheckout: true });
		axios.
			post(`${process.env.REACT_APP_API_URL}/api/billing/debit`, {
				"amount": amount,
				"product": this.state.action === 'register' ? 'Domain Registration' : 'Domain Transfer',
				"details": this.props.location.state.domain
			})
			.then((response) => {
				this.setState({ balance: response.data.new_balance });
				//this.props.intl.formatMessage({ id:"owner.success_changed" }
				this.notify(<FormattedMessage id="notify.domain-payed" />, 'success', 'check', true);
				let domain = { domain: this.props.location.state.domain, duration: this.state.duration, contacts: this.state.contacts, action: this.state.action, authcode: this.state.authcode, servers: this.state.servers, registrar: this.state.registrar };
				this.saveDomain(domain);
				this.props.history.push("/domains")
			})
			.catch((error) => {
				console.log(error);
				this.notify(<FormattedMessage id="notify.domain-payed.failed" />, 'error', 'check', true);
			});
	}
	saveDomain = (domain) => {
		this.notify("caricamento", 'info', 'undo', false);
		axios
			.post(`${process.env.REACT_APP_API_URL}/api/domains/saveDomain`,
				{
					domainDetails: domain
				}
			).then((response) => {
				if (response.data.status === 'success') {
					this.notify(<FormattedMessage id="notify.retry-domain-command" />, 'success', "undo", true);
					//refactor - when domain will be registered //saveDns(dns, s.data.zoneid, s.data.domainid)
					//history.push("/") - go to index
				}
			}).catch(e => {
				this.notify(<FormattedMessage id="notify.retry-domain-command.failed" />, 'error', "undo", true);
			})
	}

	goToBalance = () => {
		this.props.history.push('/billing');
	}
	goHome = () => {
		// prev this.props.history.push('/home');	
		this.props.history.push('/domains');
	}


	render() {
		const { formatMessage } = this.props.intl;
		const { intl } = this.props;
		const currentAction = this.props.location.state.action;
		var actionLabel = "";
		if (currentAction === 'register') {
			actionLabel = "reg.register";
		}
		else {
			actionLabel = "reg.transfer";
		}
		return (
			<div className="kx-page">
				<AlertBox message={this.state.alertMessage} alertDisplay={this.state.alertDisplay} />
				<Header history={this.props.history} language={this.props.language} changeLanguage={this.props.changeLanguage} getUserData={this.getUserData} />

				<div className="with-nav-bar with-left-nav">
					<div className="">
						<div className="kx-left-nav">
							<LeftSidebar history={this.props.history} location={this.props.location}
								styles={{ sidebar: { background: "blue" } }} />
						</div>
					</div>

					<div className="kx-content">

						<div className="kx-container">


							<div className="col-12 no-margin-card">
								<div className="stepper-height text-center">
									<StepExampleOrdered steps={this.state.steps} size="tiny" />
								</div>
								{this.state.adduser ? <Modal notify = {this.notify} /> : null}
								{!this.state.steps[0].disabled ? (
									<div className="cards-container">
										<Card fluid className="red">
											<Card.Content textAlign="center">
												<Card.Header>
													<FormattedMessage id="reg.the_domain" /> <strong>{this.props.location.state.domain}</strong>
													{(this.props.location.state.action === 'register') ?
														<span> <FormattedMessage id="reg.is_available" /> <Icon className="check-circle" name="check circle" /></span> :
														<span> <FormattedMessage id="reg.not_available" /> <Icon className="check-circle-red" name="check circle" /><br /><FormattedMessage id="reg.transfer_domain" /></span>
													}
												</Card.Header>
											</Card.Content>
										</Card>
										<Card className="w80p">
											<Card.Content>
												<Card.Header><FormattedMessage id="reg.select_registrant" /></Card.Header>
											</Card.Content>
											<Card.Content>
												<Card.Description>
													<div>
														<Grid>
															{this.state.prices.internetbs > 0 ?
															<Grid.Row className="no-gutters border-50w">																
																<Grid.Column width={5} className="text-right -first-column -custom-width-column-registrant">
																	<div className="padding-info custom-font" ><FormattedMessage id={actionLabel} /> {this.props.location.state.domain}</div>
																</Grid.Column>
																<Grid.Column width={4} className="text-left">
																	<div className="padding-info custom-font"><strong>IBS</strong></div>
																</Grid.Column>
																<Grid.Column width={3} className="text-center">
																	<div className="padding-info custom-font"><FormattedMessage id="reg.from" /> <strong>{this.state.prices.internetbs}€</strong></div>
																</Grid.Column>
																<Grid.Column width={3} className="next-button text-left">
																	<Button onClick={() => this.changeTab('first', 'internetbs')}><FormattedMessage id="reg.next_step" /></Button>
																	{/* <a onClick={() => this.changeTab('first', 'internetbs')}><span className="invisible">next</span></a> */}
																</Grid.Column>
															</Grid.Row>
															: null }
															{this.state.prices.ascio > 0 ?
															<Grid.Row className="no-gutters">
																<Grid.Column width={5} className="text-right -first-column -custom-width-column-registrant">
																	<div className="padding-info custom-font" ><FormattedMessage id={actionLabel} /> {this.props.location.state.domain}</div>
																</Grid.Column>
																<Grid.Column width={4} className="text-left">
																	<div className="padding-info custom-font"><strong>Ascio</strong></div>
																</Grid.Column>
																<Grid.Column width={3} className="text-center">
																	<div className="padding-info custom-font"><FormattedMessage id="reg.from" /> <strong>{this.state.prices.ascio}€</strong></div>
																</Grid.Column>
																<Grid.Column width={3} className="next-button text-left">
																	<Button onClick={() => this.changeTab('first', 'ascio')}><FormattedMessage id="reg.next_step" /></Button>
																	{/* <a onClick={() => this.changeTab('first', 'ascio')}><span className="invisible">next</span></a> */}
																</Grid.Column>
															</Grid.Row>
															: null }
														</Grid>
													</div>
												</Card.Description>
											</Card.Content>
										</Card>
									</div>
								) : null}
								{!this.state.steps[1].disabled ? (
									<div className="cards-container">
										<Card fluid className="red">
											<Card.Content textAlign="center">
												<Card.Header>
													<FormattedMessage id="reg.the_domain" /> <strong>{this.props.location.state.domain}</strong>
													{(this.props.location.state.action === 'register') ?
														<span> <FormattedMessage id="reg.is_available" /> <Icon className="check-circle" name="check circle" /></span> :
														<span> <FormattedMessage id="reg.not_available" /> <Icon className="check-circle-red" name="check circle" /><br /><FormattedMessage id="reg.transfer_domain" /></span>
													}
												</Card.Header>
											</Card.Content>
										</Card>
										<Card className="w80p">
											{this.state.action === 'register' ?
												<Card.Content>
													<Card.Header><FormattedMessage id="reg.select_duration" /></Card.Header>
												</Card.Content>
												: null}

											{(this.props.location.state.action === 'transfer') ?
												<Card.Content>
													<Card.Header><FormattedMessage id="reg.auth_code" /></Card.Header>
												</Card.Content>
												: null
											}
											<Card.Content>
												<Card.Description>
													<div className="w70p">
														{this.state.action === 'register' ?
															<div>
																<Dropdown
																	placeholder="Select Duration"
																	onChange={(e, data) => {
																		let year = e.target.textContent;
																		let yearNumber = year[0];
																		this.setState({ duration: data.value });
																	}}
																	selection
																	options={durationsList(intl)}
																	defaultValue={durationsList(intl)[0].value}
																/>
																<Button className="m-2" onClick={() => this.changeTab('second')}><FormattedMessage id="reg.next_step" /></Button>
															</div>
															:
															<div>
																<Input size="small" name="authcode" value={this.state.authcode} onChange={this.changeAuthcode} />
																<Button disabled={this.state.authcode === null || this.state.authcode.match(/^ *$/) !== null} className="m-2" onClick={() => this.changeTab('second')}><FormattedMessage id="reg.next_step" /></Button>
															</div>
														}

														{/* <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
														<div className="next-button">
															<a onClick={() => this.changeTab('second')}><span className="invisible">next</span></a>
														</div>
													</div> */}
													</div>
												</Card.Description>
											</Card.Content>
										</Card>
									</div>
								) : null}
								{!this.state.steps[2].disabled ? (
									<div className="cards-container">
										<Card fluid className="red">
											<Card.Content textAlign="center">
												<Card.Header>
													<FormattedMessage id="reg.the_domain" /> <strong>{this.props.location.state.domain}</strong>
													{(this.props.location.state.action === 'register') ?
														<span> <FormattedMessage id="reg.is_available" /> <Icon className="check-circle" name="check circle" /></span> :
														<span> <FormattedMessage id="reg.not_available" /> <Icon className="check-circle-red" name="check circle" /><br /><FormattedMessage id="reg.transfer_domain" /></span>
													}
												</Card.Header>
											</Card.Content>
										</Card>
										<Card className="w80p">
											<Card.Content>
												<Card.Header>
													<FormattedMessage id="reg.config_servers" />
												</Card.Header>
											</Card.Content>
											<Card.Content>
												<Card.Description>
													<Servers domain={this.props.location.state.domain} changeTab={this.changeTab} getServers={this.getServers} notify = {this.notify} />
												</Card.Description>
											</Card.Content>
										</Card>
									</div>
								) : null}
								{!this.state.steps[3].disabled ? (
									<div className="cards-container">
										<Card fluid className="red">
											<Card.Content textAlign="center">
												<Card.Header>
													<FormattedMessage id="reg.the_domain" /> <strong>{this.props.location.state.domain}</strong>
													{(this.props.location.state.action === 'register') ?
														<span> <FormattedMessage id="reg.is_available" /> <Icon className="check-circle" name="check circle" /></span> :
														<span> <FormattedMessage id="reg.not_available" /> <Icon className="check-circle-red" name="check circle" /><br /><FormattedMessage id="reg.transfer_domain" /></span>
													}
												</Card.Header>
											</Card.Content>
										</Card>
										{!this.state.showContactsTable ? (
											<Card className="w80p">
												<Card.Content>
													<Card.Header><FormattedMessage id="reg.config_contacts" /></Card.Header>
												</Card.Content>
												<Card.Content>
													<Card.Description>
														<Form>
															<Form.Field>
																<div className="row">
																	<div className="col-12 col-sm-12 col-md-6 col-lg-3">
																		<label className="step-label"><FormattedMessage id="reg.registrtant_contact" /></label>
																	</div>
																	<div className="col-12 col-sm-12 col-md-6 col-lg-9">
																		<div className="ui action input">
																			<input tabIndex="-1" type="text" value={this.state.contacts.registrant.fullname} placeholder={formatMessage({ "id": "reg.select..." })} />
																			<button className="ui button step-btn" onClick={() => this.selectContact('registrant')}><i className="icon gear"></i></button>
																		</div>
																	</div>
																</div>
															</Form.Field>
															<Form.Field>
																<div className="row">
																	<div className="col-12 col-sm-12 col-md-6 col-lg-3">
																		<label className="step-label"><FormattedMessage id="reg.admin_contact" /></label>
																	</div>
																	<div className="col-12 col-sm-12 col-md-6 col-lg-9">
																		<div className="ui action input">
																			<input tabIndex="-1" type="text" value={this.state.contacts.admin.fullname} placeholder={formatMessage({ "id": "reg.select..." })} />
																			<button className="ui button step-btn" onClick={() => this.selectContact('admin')}><i className="icon gear"></i></button>
																		</div>
																	</div>
																</div>
															</Form.Field>
															<Form.Field>
																<div className="row">
																	<div className="col-12 col-sm-12 col-md-6 col-lg-3">
																		<label className="step-label"><FormattedMessage id="reg.technical_contact" /></label>
																	</div>
																	<div className="col-12 col-sm-12 col-md-6 col-lg-9">
																		<div className="ui action input">
																			<input tabIndex="-1" type="text" value={this.state.contacts.technical.fullname} placeholder={formatMessage({ "id": "reg.select..." })} />
																			<button className="ui button step-btn" onClick={() => this.selectContact('technical')}><i className="icon gear"></i></button>
																		</div>
																	</div>
																</div>
															</Form.Field>
															<Form.Field>
																<div className="row">
																	<div className="col-12 col-sm-12 col-md-6 col-lg-3">
																		<label className="step-label"><FormattedMessage id="reg.billing_contact" /></label>
																	</div>
																	<div className="col-12 col-sm-12 col-md-6 col-lg-9">
																		<div className="ui action input">
																			<input tabIndex="-1" type="text" value={this.state.contacts.billing.fullname} placeholder={formatMessage({ "id": "reg.select..." })} />
																			<button className="ui button step-btn" onClick={() => this.selectContact('billing')}><i className="icon gear"></i></button>
																		</div>
																	</div>
																</div>
															</Form.Field>
															<div className="next-button-center" style={{ textAlign: "center", margin: "3%" }}>
																<Button disabled={this.conatctsOK() ? false : true} onClick={() => this.changeTab('forth')}><FormattedMessage id="reg.next_step" /></Button>
																{/* <a disabled={this.conatctsOK() ? false : true} type='submit' onClick={() => this.changeTab('forth')}><span className="invisible">next</span></a> */}
															</div>
														</Form>


													</Card.Description>
												</Card.Content>
											</Card>)
											: null}
										{this.state.showContactsTable ? (
											<Card fluid>
												<Card.Content>
													<Card.Header>
														Configura i contatti
															</Card.Header>
												</Card.Content>
												<Card.Content>
													<Card.Description>
														<div className="contactsTable">
															<div className="row">
																<div className="col-6 mb-2">
																	<Input icon='search' placeholder={formatMessage({ "id": "table.search" }) + '...'} onChange={this.searchContact} />
																</div>
															</div>
															<div className="row">
																<div className="col-12">
																	<TableFullWidth
																		onChange={this.onChange}
																		contactlist={this.state.contactlist}
																		addContact={this.addContact}
																		adduser={this.addUser}
																		contactType={this.state.contactType}
																		onSelectContact={this.onSelectContcat}
																		contactPage={this.contactPage}
																		changeTab={this.changeTab}
																	/>
																</div>
															</div>
														</div>

													</Card.Description>
												</Card.Content>
											</Card>)
											: null}
									</div>
								) : null}
								{!this.state.steps[4].disabled ? (
									<div className="cards-container">
										<Card fluid className="red">
											<Card.Content textAlign="center">
												<Card.Header>
													<FormattedMessage id="reg.the_domain" /> <strong>{this.props.location.state.domain}</strong>
													{(this.props.location.state.action === 'register') ?
														<span> <FormattedMessage id="reg.is_available" /> <Icon className="check-circle" name="check circle" /></span> :
														<span> <FormattedMessage id="reg.not_available" /> <Icon className="check-circle-red" name="check circle" /><br /><FormattedMessage id="reg.transfer_domain" /></span>
													}
												</Card.Header>
											</Card.Content>
										</Card>
										<Card className="w70p">
											<Card.Content>
												<Card.Header>
													Riepilogo e Pagamento
											</Card.Header>
											</Card.Content>
											<Card.Content>
												<Card.Description>
													<div className="row">
														<div className="col-12">
															<FormattedMessage id="reg.action" />: <span className="server-style-title">{this.state.action}</span><br />
															{this.state.action === 'register' ?
																[
																	<FormattedMessage id="reg.duration" />, ':',
																	<span className="server-style-title" key={22}>{this.state.duration}<br /></span>
																] :
																null}
															<FormattedMessage id="contact.name" />: <span className="server-style-title">{this.state.registrant.firstname}{' '}{this.state.registrant.lastname}</span><br />
															<FormattedMessage id="app.email" />: <span className="server-style-title">{this.state.registrant.email}</span><br />
															<FormattedMessage id="contact.phone" />: <span className="server-style-title">{this.state.registrant.phonenumber}</span><br />
															<FormattedMessage id="contact.city" />: <span className="server-style-title">{this.state.registrant.city}</span><br />
															<FormattedMessage id="reg.registrar" />: <span className="server-style-title">{this.state.registrar == "internetbs" ? "IBS" : "ASCIO"}</span><br />
															<FormattedMessage id="app.price" />: <span key={27} className="price">&euro; {(1 * this.state.duration.replace("Y", "")) * this.state.amount}</span><br />
														</div>
													</div>
													<div className="row mt-2">
														<div className="col-12">
															<div className="checkout">
																{this.state.amount > 0 && this.state.balance >= this.state.amount ?
																	<div className="all-inline">
																		{/* <div className="pr-2"><FormattedMessage id="app.payment" /></div> */}
																		<ModalConfirmPayment disableCheckout={this.state.disableCheckout} config={this.paymentConfirmConfig} onYes={() => this.payFromAccount(1, (1 * this.state.duration.replace("Y", "")) * this.state.amount)} amount={this.state.amount} onOpen={this.onConfirmOpen} notify = {this.notify}/>
																		<Button onClick={this.goHome}><FormattedMessage id="app.cancel" /></Button>
																	</div>
																	: null
																}
																{this.state.balance < this.state.amount ?
																	<div className="text-danger">
																		<FormattedMessage id="app.balance_low" values={{ balance: this.state.balance }} /> <Button onClick={this.goToBalance}><FormattedMessage id="app.recharge" /></Button>
																	</div>
																	: null
																}
																{/* <Checkout
																reload={() => this.props.reload()}
																domain={{ domain: this.props.location.state.domain, duration: this.state.duration, contacts: this.state.contacts, action: this.state.action, authcode: this.state.authcode, servers: this.state.servers, registrar: this.state.registrar }}
																name={this.state.action === 'register' ? 'Domain Registration' : 'Domain Transfer'}
																description={this.props.location.state.domain}
																amount={this.state.amount}
																changeState={() => this.setState({ checked: false })}
																showAlert={this.showAlert}
																history={this.props.history}
															/> : null} */}
															</div>
														</div>
													</div>
													{/* <Checkout
									reload={() => this.props.reload()}
									domain={this.state.domainDetails}
									name={'Konsolex Domain Registration'}
									description={'Domain Registration'}
									amount={1}
									changeState={() => this.setState({ checked: false })}
									showAlert={this.showAlert}
								/> */}
												</Card.Description>
											</Card.Content>
										</Card>
									</div>)
									: null}
							</div>
						</div>
					</div>
				</div>
				{
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
				<Footer />
			</div>
		);
	}
}

export default injectIntl(DomainRegistration);
