import React, { Component } from "react";
import "./header.scss";
import { Redirect } from "react-router-dom";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import Notification from "../notifications/notification";
import { FormattedMessage } from 'react-intl';
import axios from "axios";
import Changelog from "../All/modalChangelogs"
import { Checkbox, Dropdown, Flag } from "semantic-ui-react";

function cambia (multitenancy){
  if (localStorage.getItem('theme') == 'dark') {
    let variab = document.querySelector(':root');
    variab.style.setProperty('--text','white');
    variab.style.setProperty('--background','#1d1d1d');
    variab.style.setProperty('--backpage',"#383838");
    variab.style.setProperty('--konsoleorange',"#7b9fd2");
    variab.style.setProperty('--konsolebuttonhover', '#9bb2d3');
    variab.style.setProperty('--dashTab', '#5f5f5f');
    variab.style.setProperty('--backbar',"#1f1f1f");
    variab.style.setProperty('--shadowChat',"#546b8d");
    variab.style.setProperty('--colorChatLeft',"#e9e9e9");
    variab.style.setProperty('--scritteDash', '#cedfea');
    variab.style.setProperty('--backgroundCall', '#5b5b5b');
    variab.style.setProperty('--insideCloud', '#383838');
    variab.style.setProperty('--outsideCloude', '#1d1d1d');
    variab.style.setProperty('--greyshade', 'grey');
    variab.style.setProperty('--changelog', "url('/changelog dark.png')");
    variab.style.setProperty('--sfondologin', "url('/sfondo_nuvole_01_dark.png')");

    if(multitenancy){
      variab.style.setProperty('--logo',"url('/KonsoleX_logo_03_dark.png')");
    }
    variab.style.setProperty('--dashboard',"url('/dashboard dark.png')");
    variab.style.setProperty('--dominiMenu',"url('/domain dark.png')");
    variab.style.setProperty('--domain',"url('/domain inclusivi dark.png')");
    variab.style.setProperty('--host',"url('/host dark.png')");
    variab.style.setProperty('--dns',"url('/DNS dark.png')");
    variab.style.setProperty('--serverMenu',"url('/KonsoleX_logo_server_02_dark.png')");
    variab.style.setProperty('--server',"url('/KonsoleX_logo_server_02_dark.png')");
    variab.style.setProperty('--gateway',"url('/gateway_dark.png')");
    variab.style.setProperty('--server',"url('/server cloud dark.png')");
    variab.style.setProperty('--firewall',"url('/Firewall icon dark.png')");
    variab.style.setProperty('--storagebox',"url('/storagebox notte.png')");
    variab.style.setProperty('--clientMenu',"url('/personal icon dark.png')");
    variab.style.setProperty('--servizi',"url('/servizi attivi 02 dark.png')");
    variab.style.setProperty('--clienti',"url('/clients icon dark.png')");
    variab.style.setProperty('--multiten',"url('/multitenancy dark.png')");
    variab.style.setProperty('--fatture',"url('/fatture dark.png')");
    variab.style.setProperty('--admin', "url('/Admin_dark.png')");
    variab.style.setProperty('--restart', "url('/restart_dark.png')");
    variab.style.setProperty('--powerOff', "url('/arresta_dark.png')");
    if(multitenancy){
      variab.style.setProperty('--footer', "url('/on the cloud dark.png')");
    }
    if(multitenancy){
      variab.style.setProperty('--login', "url('/TAB icon night.png')");
    }
    variab.style.setProperty('--ticket', "url('/Tickets dark.png')");
    variab.style.setProperty('--toggleIcon', "url('/moon.png')");
  } else if (localStorage.getItem('theme')=='light') {
    let variab = document.querySelector(':root');
    variab.style.setProperty('--text','black');
    variab.style.setProperty('--background','white');
    variab.style.setProperty('--backpage',"#f5f5f5");
    variab.style.setProperty('--konsoleorange',"#f86b00");
    variab.style.setProperty('--konsolebuttonhover', '#f0934c');
    variab.style.setProperty('--backbar',"#f1f1f1");
    variab.style.setProperty('--shadowChat',"#b24d00");
    variab.style.setProperty('--colorChatLeft',"#c7c7c7");
    variab.style.setProperty('--backgroundCall', '#cccccc');
    variab.style.setProperty('--dashTab', "rgb(233, 233, 233)");
    variab.style.setProperty('--scritteDash', '#71787a');
    variab.style.setProperty('--greyshade', 'lightgrey');
    variab.style.setProperty('--insideCloud', 'white');
    variab.style.setProperty('--outsideCloude', '#f5f5f5');
    variab.style.setProperty('--changelog', "url('/changelog.png')");
    variab.style.setProperty('--sfondologin', "url('/sfondo_nuvole_01.png')");
    if(multitenancy){
      variab.style.setProperty('--logo',"url('/KonsoleX_logo_03.png')");
    }
    variab.style.setProperty('--dashboard',"url('/Dashboard.png')");
    variab.style.setProperty('--dominiMenu',"url('/logo_domain_giorno.png')");
    variab.style.setProperty('--domain',"url('/domain inclusivi.png')");
    variab.style.setProperty('--host',"url('/host giorno.png')");
    variab.style.setProperty('--dns',"url('/DNS giorno.png')");
    variab.style.setProperty('--serverMenu',"url('/KonsoleX_logo_server_02.png')");
    variab.style.setProperty('--server',"url('/KonsoleX_logo_server_02.png')");
    variab.style.setProperty('--gateway',"url('/Gateway_giorno.png')");
    variab.style.setProperty('--server',"url('/server cloud.png')");
    variab.style.setProperty('--firewall',"url('/Firewall giorno.png')");
    variab.style.setProperty('--storagebox',"url('/storagebox giorno.png')");
    variab.style.setProperty('--clientMenu',"url('/clientmenu.png')");
    variab.style.setProperty('--servizi',"url('/servizi attivi.png')");
    variab.style.setProperty('--clienti',"url('/clients icon.png')");
    variab.style.setProperty('--multiten',"url('/multitenancy giorno.png')");
    variab.style.setProperty('--fatture',"url('/fatture.png')");
    variab.style.setProperty('--admin', "url('/Admin.png')");
    variab.style.setProperty('--restart', "url('/restart.png')");
    variab.style.setProperty('--powerOff', "url('/arresta.png')");
    if(multitenancy){
      variab.style.setProperty('--footer', "url('/logo cloud on the cloud.png')");
    }
    if(multitenancy){
      variab.style.setProperty('--login', "url('/TAB_icon_day.png')");
    }
    variab.style.setProperty('--ticket', "url('/Tickets giorno.png')");
    variab.style.setProperty('--toggleIcon', "url('/sun.png')");
  }
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: -1,
      logo: "",
      logolink: "",
      loggedIn: true,
      sidebarOpen: false,
      telegramID: true,
      sidebarWidth: "0px",
      isSecondLevelUser: false,
      locationID: -1,
      balance: "",
      bonus: "",
      welcome: "",
      user: {},
      notificationsNotSeen: [],
      modalChangelogOpen: false,
      checked: localStorage.getItem("theme") == "dark",
      restore: false
    };
    this.token = localStorage.getItem("jwt");
    this.toggleSidebar = this.toggleSidebar.bind(this);
    if (this.token) {
      this.loadUser();
    }
  }

  loadUser = () => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
        headers: {
          "x-auth-token": localStorage.getItem("jwt"),
        },
      })
      .then((res) => {
        this.setState({ user: res.data });
        localStorage.setItem("changelog", res.data.changelog)
        if(res.data.changelog == 0){
          this.setState({modalChangelogOpen : true})
        }
        if (this.props.getUserData) {
          localStorage.setItem("user", res.data.userid);
          this.props.getUserData(res.data);
          if(this.props.getUserName){
            this.props.getUserName(res.data);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleLogo = () =>{
    let location = "" + window.location;
    if(location.includes("?id=")){
      let id = "" + location.split(['=']).pop()
      axios.post(`${process.env.REACT_APP_API_URL}/api/users/logonoauth?id=${id}`)
        .then((response) => {
          if(response.data === 404){
            this.setState({
              logo: "/KonsoleX_logo_03.png",
              logolink: "/dashboard"
            })
          }
          else{
            let variab = document.querySelector(':root');
            variab.style.setProperty('--logo',`url('${response.data.logo}')`);
            variab.style.setProperty('--login',`url('${response.data.logo}')`);
            variab.style.setProperty('--footer',`url('${response.data.logo}')`);
            this.setState({
              locationID: id,
              logo: response.data.logo,
              logolink: response.data.logolink
            })
          }
        })
    } else {
      this.checkSecondLevel();
    }
  }

  checkSecondLevel = async () =>{
		await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
      .then(async (response) => {
        if(response){
          if (!response.data.telegramID) {
            await this.setState({
              telegramID: false,
          });
          }
          await this.setState({
            isSecondLevelUser: response.data.isseconduser,
            id: response.data.id,
            balance: response.data.balance,
            bonus: response.data.bonus,
            welcome: response.data.welcome,
            name: response.data.name
          });
          if(this.state.isSecondLevelUser === true){
            await axios.post(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondleveltofirstlevelid?id=${this.state.id}`)
              .then(async (response) => {
                await this.setState({
                  locationID: response.data
                })
              })
            await axios.post(`${process.env.REACT_APP_API_URL}/api/users/logonoauth?id=${this.state.locationID}`)
              .then(async (response) => {
                if(response.data.logo){
                  let variab = document.querySelector(':root');
                  variab.style.setProperty('--logo',`url('${response.data.logo}')`);
                  variab.style.setProperty('--login',`url('${response.data.logo}')`);
                  variab.style.setProperty('--footer',`url('${response.data.logo}')`);
                  await this.setState({
                    logo: response.data.logo,
                    logolink: response.data.logolink
                  })
                }
                else{
                  if(localStorage.getItem("theme") == 'light') {
                    this.setState({logo: "/KonsoleX_logo_03.png", logolink: "/dashboard"})
                  } else {
                    this.setState({logo: "/KonsoleX_logo_03_dark.png", logolink: "/dashboard"})
                  }
                  
                }
              })
          }
          else{
            if(localStorage.getItem("theme") == 'light') {
              this.setState({logo: "/KonsoleX_logo_03.png", logolink: "/dashboard"})
            } else {
              this.setState({logo: "/KonsoleX_logo_03_dark.png", logolink: "/dashboard"})
            }
          }
          this.setState({ loading: false }); 
        } 
        else{
          this.setState({logo: "/KonsoleX_logo_03.png"})
        }
    })
  }

  componentDidMount() {
    this.handleLogo();
    if (localStorage.getItem("user")){
      this.loadNotifications(localStorage.getItem("user"))
    }
    let restore = localStorage.getItem("restore")
    this.setState({restore: restore})
    cambia(this.state.logo != '/KonsoleX_logo_03.png' || this.state.logo != '/KonsoleX_logo_03_dark.png');
  }

  restoreJwt = async () => {
    if(localStorage.getItem("baseuser")){
        localStorage.setItem("jwt", localStorage.getItem("basejwt"));
        localStorage.setItem("isAdmin", true);
        localStorage.setItem("user", localStorage.getItem("baseuser"));
        localStorage.setItem("restore", false);
        this.props.history.push("/dashboard");
    }
  }

  toggleSidebar() {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
    if (this.state.sidebarOpen === false) {
      this.setState({ sidebarWidth: "350px" });
    } else {
      this.setState({ sidebarWidth: "0" });
    }
  }

  loadNotifications = (id) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/notifications/notseen/${id}`)
      .then((res) => {
        this.setState({
          notificationsNotSeen: res.data.notification
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  logOut = () => {
    let tmp = localStorage.getItem("theme")
    localStorage.clear()
    localStorage.setItem("theme", tmp)
    this.setState(() => ({
      loggedIn: false,
      checked: localStorage.getItem("theme") == "dark"}))
  }

  getUserName = (userInfo) => {
    this.setState({ name: userInfo.name })
  }

  setupChangelog = async () => {
		if(localStorage.getItem("changelog") == '0'){
			await axios.post(`${process.env.REACT_APP_API_URL}/api/users/setupchangelog`)
				.then((response) => {
					localStorage.setItem("changelog", "1");
				})
				.catch((error) => {
					console.log(error)
				})
		}
	};

  toggleUserProfile() {
    this.props.history.push("/profile");
  }

  toggleService() {
    this.props.history.push("/services");
  }

  toggleClients() {
    this.props.history.push("/contactlist");
  }

  toggleMultiten() {
    this.props.history.push("/multitenancy");
  }

  toggleBillings() {
    this.props.history.push("/billing");
  }

  darkMode = () => {
    if(!this.state.checked) {
      localStorage.setItem('theme','dark')
      localStorage.setItem('check', true);
      axios.post(`${process.env.REACT_APP_API_URL}/api/users/darkmode`, { darkmode: true })
    } else {
      localStorage.setItem('theme','light')
      localStorage.setItem('check', false);
      axios.post(`${process.env.REACT_APP_API_URL}/api/users/darkmode`, { darkmode: false })
      
    }
    this.setState({checked: !this.state.checked})
    cambia(this.state.logo == '/KonsoleX_logo_03.png' || this.state.logo == '/KonsoleX_logo_03_dark.png');
  };

  redirect  = () => {
    this.props.history.push('/billing');
  }

  render() {
    const getUserData = this.props.getUserData;
    let hasScrollbar = window.innerWidth > document.documentElement.clientWidth;
    let immagine = ""
    if(this.state.user.propic != null) {
      immagine = this.state.user.propic
    } else {
      immagine = "/placeholder.png"
    }

    if (this.state.loggedIn === false) {
      let loginrow = "/login?id=" + this.state.locationID;
      if(this.state.isSecondLevelUser){
        return (
          <Redirect to={{ pathname: loginrow, popMessage: "Logout riuscito!" }} />
        );
      }
      else{
        return (
          <Redirect to={{ pathname: "/login", popMessage: "Logout riuscito!" }} />
        );
      }
    }
    return (
      <div className="kx-navbar">
        {localStorage.getItem('check') ? cambia(this.state.logo == '/KonsoleX_logo_03.png' || this.state.logo == '/KonsoleX_logo_03_dark.png') : null}
        <Navbar expand="md" light="light" className="shadow-none p-0">
          <Navbar.Brand style={{width: "400px", display: "flex"}}>
            <a href={this.state.logolink}>
            {this.state.logo === "" ? 
              "" : 
              <img
              src={this.state.logo}
              className="d-inline-block align-middle logoDim"
              alt="Logo"
              style = {{"max-height" : "50px"}}
            />}
            </a>
            <div style={{display: "flex", "align-items": "center"}}>
              <a style={{"color": "#f86b00", "cursor": "pointer", "padding-right": "20px", "margin-top": "7px"}} id="changelog" onClick={() => this.setState({modalChangelogOpen : true})}>Version 2.7.1</a>
              <Changelog open = {this.state.modalChangelogOpen} onClose = {() => {this.setState({modalChangelogOpen: false}); this.setupChangelog()}}/>
              <Checkbox id="darkToggle" toggle onChange={this.darkMode} checked={this.state.checked} style={{"margin-top": "4px"}}></Checkbox>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          {this.token ? (
            <Navbar.Collapse
              className="navbar-collapse"
              id="responsive-navbar-nav"
            >
              <div className="mr-2 nav-partner soldiContainer" onClick={this.redirect}>
                {!this.state.isSecondLevelUser ? <span className="soldiContainer"><FormattedMessage id="app.balance"/>: {this.state.balance>20 ? <span className="ui green horizontal label tagInSaldo">&euro; {this.state.balance}</span> : <span className="ui red horizontal label tagInSaldo">&euro; {this.state.balance}</span>}</span> : null}
                {!this.state.isSecondLevelUser ? this.state.bonus * 1 > 0 ? <span className="ml-3">Bonus: <span className="ui green horizontal label tagInSaldo">&euro; {this.state.bonus}</span></span> : null : null}
                {!this.state.isSecondLevelUser ? this.state.welcome * 1 > 0 ? <span className="ml-3">Welcome: <span className="ui green horizontal label tagInSaldo">&euro; {this.state.welcome}</span></span> : null : null}
              </div>
              {this.state.notificationsNotSeen>99 ? document.querySelector(':root').style.setProperty('--badgeWid',"30px") : document.querySelector(':root').style.setProperty('--badgeWid',"20px")}
              <Nav className="">
                <Notification style={{"margin-bottom": "0px"}} telegramID={this.state.telegramID} parentChanger={(dato) =>{this.setState({notificationsNotSeen:dato})}}/>
                <div className="eg_notification_head" style={{"margin-left":"-25%"}}>
                  <div className="d-flex justify-content-between">
                    <h5 className="not-badge">
                      
                      {this.state.notificationsNotSeen ? (
                        <span>
                          {this.state.notificationsNotSeen}
                        </span>
                      ) : null}
                    </h5>
                  </div>
                </div>
              </Nav>
              <Nav>
                <NavItem className="mr-2 nav-partner">
                  {this.state.name || ""}
                </NavItem>
                
                {hasScrollbar ? <NavItem style={{display: "flex", "align-items": "center", "margin-right": "10px"}}>
                  <Dropdown 
                    trigger={<img className="ui avatar image" src={immagine} style={{ display: 'inline-block', height:"40px", width:"40px"}} />}
                    pointing='top right'
                    icon={null}
                  >
                    <Dropdown.Menu className="dropMenuProfilo">
                      <Dropdown.Item icon="user" text="Profilo utente" onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.toggleUserProfile()}}/>
                      <Dropdown.Item className='language changeLanguage' onClick={(e) => {e.stopPropagation()}} style={{cursor: "default"}}>
                        <Flag name='it' style={{cursor: "pointer"}} onClick={(e) => {this.props.changeLanguage('it')}}/>
                        <Flag name='gb' style={{cursor: "pointer"}} onClick={(e) => {this.props.changeLanguage('en')}}/>
                      </Dropdown.Item>
                      <Dropdown.Divider onClick={(e) => {e.stopPropagation()}}/>
                      {!this.state.isSecondLevelUser ? <Dropdown.Item onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.toggleService()}}><FormattedMessage id="nav.services" /></Dropdown.Item> : null}
                      <Dropdown.Item onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.toggleClients()}}><FormattedMessage id="nav.contacts" /></Dropdown.Item>
                      {!this.state.isSecondLevelUser ? <Dropdown.Item text='Multitenancy' onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.toggleMultiten()}}/> : null}
                      <Dropdown.Item text='Host' onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.props.history.push('/serverlist')}}></Dropdown.Item>
                      {!this.state.isSecondLevelUser ? <Dropdown.Item onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.toggleBillings()}}><FormattedMessage id="nav.invoices" /></Dropdown.Item> : null}
                      <Dropdown.Item onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.props.history.push('/tickets')}}><FormattedMessage id="dash.ticket" /></Dropdown.Item>
                      {localStorage.getItem("isAdmin") == 'true' ? <Dropdown.Item onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.props.history.push('/configuration')}}><span>Configuratore</span></Dropdown.Item> : null}
                      <Dropdown.Divider onClick={(e) => {e.stopPropagation()}}/>
                      <Dropdown.Item icon="power off" text='Logout' onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.logOut()}}/>
                      {this.state.restore == 'true' ? (<Dropdown.Item icon='undo' text='Restore' onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.restoreJwt()}}/>) : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </NavItem> :
                <NavItem style={{display: "flex", "align-items": "center"}}>
                <Dropdown 
                  trigger={<img className="ui avatar image" src={immagine} style={{ display: 'inline-block', height:"40px", width:"40px"}} />}
                  pointing='top right'
                  icon={null}
                >
                  <Dropdown.Menu className="dropMenuProfilo">
                    <Dropdown.Item icon="user" text="Profilo utente" onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.toggleUserProfile()}}/>
                    <Dropdown.Item className='language changeLanguage' onClick={(e) => {e.stopPropagation()}} style={{cursor: "default"}}>
                      <Flag name='it' style={{cursor: "pointer"}} onClick={(e) => {this.props.changeLanguage('it')}}/>
                      <Flag name='gb' style={{cursor: "pointer"}} onClick={(e) => {this.props.changeLanguage('en')}}/>
                    </Dropdown.Item>
                    <Dropdown.Divider onClick={(e) => {e.stopPropagation()}}/>
                    {!this.state.isSecondLevelUser ? <Dropdown.Item onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.toggleService()}}><FormattedMessage id="nav.services" /></Dropdown.Item> : null}
                    <Dropdown.Item onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.toggleClients()}}><FormattedMessage id="nav.contacts" /></Dropdown.Item>
                    {!this.state.isSecondLevelUser ? <Dropdown.Item text='Multitenancy' onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.toggleMultiten()}}/> : null}
                    <Dropdown.Item text='Host' onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.props.history.push('/serverlist')}}></Dropdown.Item>
                    {!this.state.isSecondLevelUser ? <Dropdown.Item onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.toggleBillings()}}><FormattedMessage id="nav.invoices" /></Dropdown.Item> : null}
                    <Dropdown.Item onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.props.history.push('/tickets')}}><FormattedMessage id="dash.ticket" /></Dropdown.Item>
                    {localStorage.getItem("isAdmin") == 'true' ? <Dropdown.Item onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.props.history.push('/configuration')}}><span>Configuratore</span></Dropdown.Item> : null}
                    <Dropdown.Divider onClick={(e) => {e.stopPropagation()}}/>
                    <Dropdown.Item icon="power off" text='Logout' onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.logOut()}}/>
                    {this.state.restore == 'true' ? (<Dropdown.Item icon='undo' text='Restore' onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.restoreJwt()}}/>) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </NavItem>}
              </Nav>
            </Navbar.Collapse>
          ) : null}
        </Navbar>
      </div>
    );
  }
}

export default Header;
