import React, { Component } from 'react';
import {Modal, Tab, Form, Label, Select, Radio, Dropdown, Input} from 'semantic-ui-react';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';

class ModalAddStoragebox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersArray: [],
            userid: "",
            storageboxID: "",
            storageboxPsw: ""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    firstSetUp = () => {
        if(!this.state.statewritten && this.props.open){
            this.getUsers()
            this.setState({
                statewritten: true
            })
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                statewritten: false
            })
        }
    }

    getUsers = async() => {
        await axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then(async (response) => {
                this.setState({usersArray:response.data})
            })
            .catch((err) => {
                console.log(err);
            })
    }

    async handleInputChange(event, { name, value }) {
        await this.setState({
            [name]: value
        });
    }

    save = async () => {
        this.props.notify("Caricamento", "info", "add", false)
        if(this.state.userid != "" && this.state.storageboxID != "" && this.state.storageboxPsw != ""){
            await axios.post(`${process.env.REACT_APP_API_URL}/api/barmetal/addstoragebox`, {id: this.state.storageboxID, userid: this.state.userid, storageboxPsw:this.state.storageboxPsw})
                .then((response) => {
                    this.props.notify(<FormattedMessage id="notify.cloudbox-create" />, "success", "add", true)
                })
                .catch((error) => {
                    console.log(error)
                    this.props.notify(<FormattedMessage id="notify.cloudbox-create.failed" />, "error", "add", true)
                })
            this.props.onClose()
        }
        else this.props.notify(<FormattedMessage id="notify.cloudbox-create.failed" />, "error", "add", true)
    }    

    render() {
        const {open, onClose} = this.props;
        const { formatMessage } = this.props.intl;
        this.firstSetUp();
        return (
            <div>
                <Modal closeIcon={true}
                    className="overflow-yes"
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto', width: '30%' }}
                    open={open}
                    onClose={() => onClose(false)}>
                        <Modal.Header>
                        <FormattedMessage id="app.server_info" />
                    </Modal.Header>
                    <Modal.Content className="modal-min-height">
                        <Modal.Description id="modalAddStoragebox">
                            <Form className="overflow-yes">
                            <Form.Input className="storageInput" name="storageboxID" value = {this.state.storageboxID} onChange = {this.handleInputChange} label={"Cloudbox ID"} placeholder="Cloudbox ID"/>
                            <Form.Input className="storageInput" name="storageboxPsw" value = {this.state.storageboxPsw} onChange = {this.handleInputChange} label={"Cloudbox Password"} placeholder="Password"/>
                            <Form.Dropdown 
                                placeholder="Proprietario"
                                search
                                label="Partner"
                                value = {this.state.userid}
                                onChange={this.handleInputChange}
                                name="userid"
                                clearable
                                selection
                                options={this.state.usersArray.map(({ name, userid }) => ({ value: userid, text: name }))}
                            />
                            <Form.Button onClick={() => {this.save()}} >
                            {formatMessage({ id: "app.save" })}
                            </Form.Button>
                            </Form>
                        </Modal.Description>
                        
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalAddStoragebox);