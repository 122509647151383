import React, { Component } from 'react';
import { Form, Button, Select } from 'semantic-ui-react';
import './server.scss';
import axios from 'axios';
import ReactPhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/dist/style.css';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';


class FormUserBalance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            cash: 0,
            bonus: 0,
            cash_note: '',
            bonus_note: '',
        };
        if (this.props.formData.id) {
            this.state.id = this.props.formData.id;
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    //handleChange = (e, { name, value }) => this.setState({ [name]: value });
    handleInputChange(event, { name, value, checked, type }) {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        if (type === 'checkbox') {
            value = checked;
        }
        this.setState({
            [name]: value
        });
    }

    changeBalance = () => {
        const { changeBalance, closeMe } = this.props;
        changeBalance(this.state, function (res, err) {
            if (!err) {
                closeMe();
            }
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <div className="server-form">
                    <Form className="overflow-yes">
                        <Form.Group>
                            <Form.Input type="number" label={formatMessage({ id: "app.modify_money" })} value={this.state.cash || '0'} name="cash" placeholder="" onChange={this.handleInputChange} />
                            <Form.Input label={formatMessage({ id: "app.modify_money_note" })} value={this.state.cash_note || ''} name="cash_note" placeholder="Note" onChange={this.handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input type="number" label={formatMessage({ id: "app.modify_bonus" })} value={this.state.bonus || '0'} name="bonus" placeholder="" onChange={this.handleInputChange} />
                            <Form.Input label={formatMessage({ id: "app.modify_bonus_note" })} value={this.state.bonus_note || ''} name="bonus_note" placeholder="Note" onChange={this.handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <div className="field"></div>
                            <Form.Button floated="right" onClick={this.changeBalance} className="save-button">
                                {this.state.id ? formatMessage({ id: "app.save" }) : formatMessage({ id: "app.add" })}
                            </Form.Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        );
    }
}
export default injectIntl(FormUserBalance);
