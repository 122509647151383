import React, { Component } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import FormWidthField from './Form';
import FormISPConfig from './ISPConfigForm';
import FormContact from './ContactForm';
import FormUser from './UserForm';
import FormUserBalance from './UserBalanceForm';
import FormMovement from './MovementForm';
import FormInvoice from './InvoiceForm';
import FormReport from './ReportForm';
import FormOrder from './OrderForm';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';

class ModalModalExample extends Component {
	constructor(props) {
		super(props);
		this.state = { modalOpen: false }
		this.externalClose = this.externalClose.bind(this);
		this.instance = React.createRef()
	}

	handleOpen = () => this.setState({ modalOpen: true })
	handleClose = () => this.setState({ modalOpen: false });

	externalClose() {
		this.setState({ modalOpen: false });
	}

	render() {
		const { onChange, addServer, addContact, type, formData, notify, size, changeBalance, addMovement, afterAction, saveInvoice } = this.props;
		const { formatMessage } = this.props.intl;
		let classN = ""
		let btnSize = "small";
		if (size) {
			btnSize = size;
		}
		return (
			//const ModalModalExample = ({ onChange, addServer, addContact, type, formData }) => (
			<div>
				<Modal closeIcon={true}
					closeOnDimmerClick={true}
					open={this.state.modalOpen}
					onClose={this.handleClose}
					size={size}
					className={classN}
					trigger={
						<Button icon size={btnSize} onClick={this.handleOpen}>
							{(type.Formtype === "server") ? <Icon name={type.Icon} title={type.ButtonLabel} /> : ""}
							{(type.Formtype === "contact") ? <Icon name={type.Icon} title={type.ButtonLabel} /> : ""}
							{(type.Formtype === "dns") ? "Add DNS" : ""}
							{(type.Formtype === "bill") ? <Icon name={type.Icon} title={type.ButtonLabel} /> : ""}
							{(type.Formtype === "user" || type.Formtype === "user_balance" || type.Formtype === "order") ? <Icon name={type.Icon} title={type.ButtonLabel} /> : ""}
							{(type.Formtype === "movement") ? <Icon name={type.Icon} title={type.ButtonLabel} /> : ""}
							{(type.Formtype === "reports") ? <Icon name={type.Icon} title={type.ButtonLabel} /> : ""}
							{(type.Formtype === "invoice") ? <Icon name={type.Icon} title={type.ButtonLabel} /> : ""}	
						</Button>
					}
				>
					<Modal.Header>
						{(type.Formtype === "server") ? formatMessage({ id: "srv.header" }) : ""}
						{(type.Formtype === "contact") ? formatMessage({ id: "contact.header" }) : ""}
						{(type.Formtype === "dns") ? "Add DNS" : ""}
						{(type.Formtype === "user") ? formatMessage({ id: "app.user_change" }) : ""}
						{(type.Formtype === "user_balance") ? formatMessage({ id: "app.user_balance_adjust" }) : ""}
						{(type.Formtype === "movement") ? formatMessage({ id: "app.add_movement" }) : ""}
					</Modal.Header>
					<Modal.Content className="modal-min-height">
						<Modal.Description>
							{(type.Formtype === "server") ?
								<FormWidthField type={type} formData={formData} onChange={onChange} addServer={addServer} closeMe={this.handleClose} notify={notify} />
								: ""}
							{(type.Formtype === "contact") ?
								<FormContact instance={this.props.instance} type={type} formData={formData} onChange={onChange} addContact={addContact} closeMe={this.handleClose} notify={notify} />
								: ""}
							{(type.Formtype === "user") ?
								<FormUser type={type} formData={formData} onChange={onChange} addContact={addContact} closeMe={this.handleClose} notify={notify} />
								: ""}
							{(type.Formtype === "user_balance") ?
								<FormUserBalance type={type} formData={formData} changeBalance={changeBalance} closeMe={this.handleClose} notify={notify} />
								: ""}
							{(type.Formtype === "invoice") ?
								<FormInvoice type={type}  saveInvoice={saveInvoice} closeMe={this.handleClose} notify={notify} />
								: ""}
							{(type.Formtype === "movement") ?
								<FormMovement type={type} addMovement={addMovement} closeMe={this.handleClose} notify={notify} />
								: ""}
							{(type.Formtype === "reports") ?
								<FormReport type={type} afterAction={afterAction} closeMe={this.handleClose} notify={notify} />
								: ""}
							{(type.Formtype === "order") &&
								<FormOrder type={type} formData={formData} afterAction={afterAction} closeMe={this.handleClose} notify={notify} />
							}
						</Modal.Description>
					</Modal.Content>
				</Modal>
			</div>
		);
	}
}

export default injectIntl(ModalModalExample);
