import React, { Component } from "react";

import axios from "axios";

import { Collapse } from "reactstrap";

class TabDNSItem extends Component {
  constructor(props) {
    super(props);
    this.editFormToggle = this.editFormToggle.bind(this);
    this.deleteDNS = this.deleteDNS.bind(this);
    this.state = {
      editFormCollapse: false,
      id: this.props.dns.id,
      name: this.props.dns.name,
      type: this.props.dns.type,
      ttl: this.props.dns.ttl,
      extra: this.props.dns.extra,
      ip: this.props.dns.ip,
      domainid: this.props.domainid
    };
  }

  editFormToggle(editedDNS) {
    if (editedDNS.data) {
      this.props.reload();
    }
    this.setState({ editFormCollapse: !this.state.editFormCollapse });
  }

  deleteDNS() {
    const token = localStorage.getItem("jwt");
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/dns/me/` +
          this.state.domainid +
          "/" +
          this.state.id,
        {
          headers: {
            "x-auth-token": token
          }
        }
      )
      .then(response => {
        this.props.showAlert("DNS eliminato.");
        this.props.reload();
      })
      .catch(function(error) {
        this.props.showAlert("Il DNS non è stato eliminato.");
      });
  }

  onChange = e => {
    let valuetocheck = e.target.value
    e.target.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    // get our form data out of state
    const { name, type, ttl, extra, ip, domain } = this.state;
    const token = localStorage.getItem("jwt");
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/dns/me/` +
          this.state.domainid +
          "/" +
          this.state.id,
        { name, type, ttl, extra, ip, domain },
        {
          headers: {
            "x-auth-token": token
          }
        }
      )
      .then(response => {
        this.props.showAlert("Modifica DNS riuscita.");
        this.editFormToggle(response);
      });
  };

  render() {
    const { name, type, ttl, extra, ip } = this.state;
    return (
      <div>
        <Collapse className="row dns-row" isOpen={!this.state.editFormCollapse}>
          <div className="col-1" />
          <div className="col-1 dns-text">{this.props.dns.type}</div>
          <div className="col-3 dns-text">{this.props.dns.name}</div>
          <div className="col-4 dns-text">{this.props.dns.ip}</div>
          <div className="col-1 dns-text">{this.props.dns.extra}</div>
          <div className="col-1 dns-text">{this.props.dns.ttl}</div>
          <div className="col-1 text-center">
            <i onClick={this.editFormToggle} className="edit-dns far fa-edit" />
          </div>
        </Collapse>
        <Collapse isOpen={this.state.editFormCollapse}>
          <form className="dns-form-container" onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-4">
                <div className="form-group text-left">
                  <label>Tipo</label>
                  <select
                    name="type"
                    className="form-control"
                    value={type}
                    onChange={this.onChange}
                    required
                  >
                    <option defaultValue />
                    <option value="a">A</option>
                    <option value="cname">CNAME</option>
                    <option value="txt">TXT</option>
                    <option value="mx">MX</option>
                    <option value="google mail">GOOGLE MAIL</option>
                  </select>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="form-group text-left"
                  style={{ marginBottom: "10px" }}
                >
                  <label>Nome:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    required
                    value={name}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="col-4">
                <div
                  className="form-group text-left"
                  style={{ marginBottom: "10px" }}
                >
                  <label>IP/Data:</label>
                  <input
                    type="text"
                    name="ip"
                    className="form-control"
                    required
                    value={ip}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div
                  className="form-group text-left"
                  style={{ marginBottom: "10px" }}
                >
                  <label>TTL:</label>
                  <input
                    type="text"
                    name="ttl"
                    className="form-control"
                    value={ttl}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="col-4">
                <div
                  className="form-group text-left"
                  style={{ marginBottom: "10px" }}
                >
                  <label>AUX:</label>
                  <input
                    type="text"
                    name="extra"
                    className="form-control"
                    value={extra}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>

            <div className="text-right">
              <button
                type="button"
                className="btn btn-lg btn-danger delete-dns"
                onClick={this.deleteDNS}
              >
                Elimina DNS
              </button>
              <button type="submit" className="btn btn-lg btn-primary">
                <a>Modifica DNS</a>
              </button>
              <button
                type="button"
                className="btn btn-lg btn-outline-dark redo-dns"
                onClick={this.editFormToggle}
              >
                Annulla
              </button>
            </div>
          </form>
        </Collapse>
      </div>
    );
  }
}

export default TabDNSItem;
