import React, { Component } from 'react';
import { Button, Icon, Table, Menu } from 'semantic-ui-react';
import Modal from '../All/Modal';
import { FormattedMessage, injectIntl } from 'react-intl';

class TableFullWidth extends Component {

	notify = (content, type, icon, completed) => {
		if(!this.state.openNotify) {            
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed,
				openNotify: true
			}, async () => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
		} else {
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed
			}, async() => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
			
		}
	}

	render() {
		const { onChange, addContact, contactlist, changeTab, contactType, onSelectContact, intl, contactPage } = this.props;
		return (
			<div className="xxx-main-celled">
				<Table celled compact>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell><FormattedMessage id="contact.name" /></Table.HeaderCell>
							<Table.HeaderCell><FormattedMessage id="app.email" /></Table.HeaderCell>
							<Table.HeaderCell>Società</Table.HeaderCell>
							<Table.HeaderCell><FormattedMessage id="contact.phone" /></Table.HeaderCell>
							<Table.HeaderCell><FormattedMessage id="contact.city" /></Table.HeaderCell>
							<Table.HeaderCell />
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{contactlist.map((val, ind) => (
							<Table.Row key={val.id}>
								<Table.Cell>
									{val.firstname} {val.lastname}
								</Table.Cell>
								<Table.Cell>{val.email}</Table.Cell>
								<Table.Cell>{val.company ? val.company : "-"}</Table.Cell>
								<Table.Cell>{val.phonenumber}</Table.Cell>
								<Table.Cell>{val.city}</Table.Cell>
								<Table.Cell collapsing>
									<Button className='dmitrytablebutton' icon labelPosition="right" onClick={() => onSelectContact(val, contactType)}>
										<FormattedMessage id="reg.next_step" />
										<Icon name="right arrow" />
									</Button>
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>

					<Table.Footer fullWidth>
						<Table.Row>
							<Table.HeaderCell>
								<Modal type={{ Formtype: "contact", ButtonLabel: intl.formatMessage({ id: "app.new" }), Icon: "user plus" }} formData={{}} addContact={addContact} notify = {this.notify} />
							</Table.HeaderCell>
							<Table.HeaderCell colSpan="5">
								<Menu floated='right' pagination>
									<Menu.Item as='a' icon onClick={() => contactPage('prev')} >
										<Icon className='dmitrytablebutton' name='chevron left ' />
									</Menu.Item>
									<Menu.Item as='a' icon onClick={() => contactPage('next')}>
										<Icon className='dmitrytablebutton' name='chevron right' />
									</Menu.Item>
								</Menu>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</div>
		);
	}
}

export default injectIntl(TableFullWidth);