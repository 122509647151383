import React, { Component } from 'react';
import {Modal, Form} from 'semantic-ui-react';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';

class ModalEditPassword extends Component {
    constructor(props) {
        super(props);
		this.instance = React.createRef();
        this.state = {
            storageboxPsw: ""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    save = async() => {
        this.props.notify("Caricamento", "info", "save", false)
        if (this.props.source == "storageboxes") {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/barmetal/editstorageboxpassword`, {id: this.props.data.id, password:this.state.storageboxPsw})
            .then((response) => {
                this.props.notify("Cloudbox modificato correttamente", "success", 'save', true)
                this.props.onClose()
            })
            .catch((error) => {
                console.log(error)
                this.props.notify("" + error, 'error', 'save', 'true');
            })
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/editserverpassword`, {id: this.props.data.id, password:this.state.storageboxPsw})
                .then((response) => {
                    this.props.notify("Server modificato correttamente", "success", 'save', true)
                    this.props.onClose()
                })
                .catch((error) => {
                    console.log(error)
                    this.props.notify("" + error, 'error', 'save', true);
                })
        }
    }

    firstSetUp = () => {
        if (this.props.source == "storageboxes") {
            if(!this.state.statewritten && this.props.open){
                this.getUsers()
                this.setState({
                    statewritten: true,
                    storageboxPsw: this.props.data.password
                })
            }
            if(this.state.statewritten && !this.props.open){
                this.setState({
                    statewritten: false,
                    storageboxPsw: null
                })
            }
        } else {
            if(!this.state.statewritten && this.props.open){
                this.getUsers()
                this.setState({
                    statewritten: true,
                    storageboxPsw: this.props.data.proxmoxpsw
                })
            }
            if(this.state.statewritten && !this.props.open){
                this.setState({
                    statewritten: false,
                    storageboxPsw: null
                })
            }
        }
    }

    getUsers = async() => {
        await axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then(async (response) => {
                this.setState({usersArray:response.data})
            })
            .catch((err) => {
                console.log(err);
            })
    }

    
    async handleInputChange(event, { name, value }) {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        await this.setState({
            [name]: value
        });
    }    

    render() {
        const {open, onClose} = this.props;
        const { formatMessage } = this.props.intl;
        this.firstSetUp();
        return (
            <div>
                <Modal closeIcon={true}
                    className="overflow-yes"
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto', width: '30%' }}
                    open={open}
                    onClose={() => onClose(false)}>
                    <Modal.Header>
                        Modifica Password
                    </Modal.Header>
                    <Modal.Content className="modal-min-height">
                        <Modal.Description>
                                <div className="server-form">
                                    <Form className="overflow-yes">
                                        <Form.Input name="storageboxPsw" defaultValue={this.state.storageboxPsw} onChange = {this.handleInputChange} label={"Nuova password:"}/>
                                        <Form.Button onClick={() => {this.save()}} >
                                            {formatMessage({ id: "app.save" })}
                                        </Form.Button>
                                    </Form>
                                </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalEditPassword);