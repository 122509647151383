import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Dropdown, Dimmer, Loader, Icon } from 'semantic-ui-react';
import Modal from './Modal';
import ModalConfirm from './ModalConferm';
import Header from '../header/header';
import Footer from '../footer/footer';
import LeftSidebar from '../sidebar/left-sidebar';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import NotifyComponent from '../notify/notifyComponent';

const { SearchBar, ClearSearchButton } = Search;

class AllContacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: -1,
            user: null,
            contactlist: [],
            accountlist: [],
            partners: [],
            partnerId: '',
            offset: 0,
            page: 1,
            totalSize: 0,
            sizePerPage: 15,
            currentIndex: 0,
            loading: true,
            isSecondLevelUser: false,
            canAdd: true,
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
        }
        localStorage.setItem('api-vs-key', '');
        axios.defaults.headers.common['x-api-vs-key'] = this.token;
        this.token = localStorage.getItem('jwt');
        axios.defaults.headers.common['x-auth-token'] = this.token;
        this.isAdmin = (localStorage.getItem('isAdmin') === "true");
        this.columns = this.defineColumns();
        this.getContacts = this.getContacts.bind(this);
    }

    checkSecondLevel = async () =>{
		await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
            .then(async (response) => {
                await this.setState({
                    isSecondLevelUser: response.data.isseconduser,
					id: response.data.id
                });
				if(this.state.isSecondLevelUser === false){
					this.getContacts();
                    if (this.isAdmin) {
                        this.loadUsers();
                    }
				}
				else{
					await axios.get(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelusers?id=${this.state.id}`)
						.then( async (response) => {
							if(response.data.clients === true){
								this.getContacts();
                                if (this.isAdmin) {
                                    this.loadUsers();
                                }
							}
                            else{
                                this.setState({
                                    canAdd : false
                                })
                            }
						})
				}
				this.setState({ loading: false });
            })
	}
    
    componentDidMount = async () =>  {
        await this.checkSecondLevel();
    }

    componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }

    getContacts = (params) => {
        //this.setState({ loading: true });
        axios.
            get(`${process.env.REACT_APP_API_URL}/api/contact/filter`, {
                params: {
                    currentIndex: this.state.currentIndex,
                    sizePerPage: this.state.sizePerPage,
                    partnerId: params ? params.partnerId : null
                }
            })
            .then((response) => {
                //this.setState({ contactlist: response.data.dataset });
                this.setState(() => ({
                    contactlist: response.data.dataset,
                    totalSize: response.data.totalCount,
                    loading: false
                }));
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.showAlert(error.message);
            });
    };

    onPartnerChange = (e, data) => {
        this.setState({ partnerId: data.value });
        this.getContacts({ partnerId: data.value });
    };

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        var currentIndex = (page - 1) * sizePerPage;
        if (type === 'search') {
            currentIndex = 0;
        }
        this.setState({ loading: true });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/contact/filter`, {
                params: {
                    currentIndex: currentIndex,
                    sizePerPage: sizePerPage ? sizePerPage : this.state.sizePerPage,
                    q: searchText,
                    sortField: sortField,
                    sortOrder: sortOrder,
                    partnerId: this.state.partnerId ? this.state.partnerId : null
                }
            })
            .then((response) => {
                let result = response.data.dataset;
                let totalCount = response.data.totalCount;
                this.setState(() => ({
                    contactlist: response.data.dataset,
                    page,
                    /*data: result.slice(currentIndex, currentIndex + sizePerPage),*/
                    totalSize: totalCount,
                    sizePerPage,
                    currentIndex: currentIndex,
                    loading: false
                }));
            });
    }

    modifyContact = (data, callback) => {
        this.notify("Caricamento", "info", "pencil", false)
        axios.put(`${process.env.REACT_APP_API_URL}/api/contact/contact`, data)
            .then(async(response) => {
                this.notify(<FormattedMessage id="notify.contact-modified" />, "success", "pencil", true)
                await this.sleep(2000)
                this.getContacts();
                callback(response, null);
            }).catch(e => {
                this.notify(<FormattedMessage id="notify.contact-modified.failed" />, "error", "pencil", true)
                console.log(e.response);
                callback(null, e);
            });
    }

    addContact = (data, callback) => {
        this.notify("Caricamento", "info", "add", false)
        axios.post(`${process.env.REACT_APP_API_URL}/api/contact/contact`, data)
            .then(async(response) => {
                this.notify(<FormattedMessage id="notify.contact-saved" />, "success", "save", true)
                await this.sleep(2000)
                this.getContacts()
                callback(response, null);
            })
            .catch((e) => {
                this.notify(<FormattedMessage id="notify.contact-saved.failed" />, "error", "save", true);
                console.log(e.response);
                callback(null, e);
            });
    };

    deleteContact = (id) => {
        this.notify("Caricamento", "info", "trash", false)
        axios.delete(`${process.env.REACT_APP_API_URL}/api/contact/contact/${id}`, {})
            .then(async (response) => {
                this.notify(<FormattedMessage id="notify.contact-deleted" />, "success", "trash", true)
                await this.sleep(2000)
                this.getContacts();
            }).catch(e => {
                this.notify(<FormattedMessage id="notify.contact-deleted.failed" />, "error", "trash", true);
                console.log(e);
            });
    }

    loadUsers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then((response) => {
                this.setState({
                    partners: response.data
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    onChange = (name, val) => {
        this.setState({ [name]: val });
    }


    defineColumns = () => {
        let columns = [{
                dataField: 'lastname',
                text: this.props.intl.formatMessage({ id: "contact.name" }),
                sort: true,
                formatter: (cellContent, row) => {
                    return (
                        <div className="textToCopy"><span className='textToShow' onClick={(e) => {e.stopPropagation();e.preventDefault()}} onMouseDown={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            let elements = document.getElementsByClassName('tagToCopy')
                            for(let element of elements) {
                                element.innerText="Copied"
                            }
                            
                            await this.sleep(500)
                            for (let element of elements) {
                                element.innerText="Copy"
                            }
                            navigator.clipboard.writeText([row.firstname, row.lastname].filter(Boolean).join(" "));
                            
                        }}>{[row.firstname, row.lastname].filter(Boolean).join(" ")}</span><div className="tagToCopy">Copy</div></div>
                    )
                }
            }, {
                dataField: 'company',
                sort: true,
                text: this.props.intl.formatMessage({ id: "contact.company" }),
                classes: 'cut-words',
                formatter: (cellContent, row) => {
                    return(
                        <div className="textToCopy"><span className='textToShow' onClick={(e) => {e.stopPropagation();e.preventDefault()}} onMouseDown={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            let elements = document.getElementsByClassName('tagToCopy')
                            for(let element of elements) {
                                element.innerText="Copied"
                            }
                            
                            await this.sleep(500)
                            for (let element of elements) {
                                element.innerText="Copy"
                            }
                            navigator.clipboard.writeText(row.company);
                            
                        }}>{row.company}</span><div className="tagToCopy">Copy</div></div>
                    )
                }
            }, {
                dataField: 'email',
                sort: true,
                text: this.props.intl.formatMessage({ id: "app.email" }),
                classes: 'cut-words',
                formatter: (cellContent, row) => {
                    return(
                        <div className="textToCopy"><span className='textToShow' onClick={(e) => {e.stopPropagation();e.preventDefault()}} onMouseDown={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            let elements = document.getElementsByClassName('tagToCopy')
                            for(let element of elements) {
                                element.innerText="Copied"
                            }
                            
                            await this.sleep(500)
                            for (let element of elements) {
                                element.innerText="Copy"
                            }
                            navigator.clipboard.writeText(row.email);
                            
                        }}>{row.email}</span><div className="tagToCopy">Copy</div></div>
                    )
                }
            }, {
                dataField: 'phonenumber',
                text: this.props.intl.formatMessage({ id: "contact.phone" }),
                classes: 'cut-words',
                formatter: (cellContent, row) => {
                    return(
                        <div className="textToCopy"><span className='textToShow' onClick={(e) => {e.stopPropagation();e.preventDefault()}} onMouseDown={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            let elements = document.getElementsByClassName('tagToCopy')
                            for(let element of elements) {
                                element.innerText="Copied"
                            }
                            
                            await this.sleep(500)
                            for (let element of elements) {
                                element.innerText="Copy"
                            }
                            navigator.clipboard.writeText(row.phonenumber);
                            
                        }}>{row.phonenumber}</span><div className="tagToCopy">Copy</div></div>
                    )
                }
            }];
        if (this.isAdmin) {
            columns.push({
                dataField: 'user.name',
                text: this.props.intl.formatMessage({ id: "app.partner" }),
            });
        } 
        columns.push({
            dataField: 'df1',
            text: '',
            isDummyField: true,
            headerStyle: (colum, colIndex) => {
                return { width: '78px' };
            },
            headerFormatter: () => {
                if(this.state.canAdd)
                return (<Modal instance = {this.instance} type={{ Icon: "user plus", Formtype: "contact", ButtonLabel: this.props.intl.formatMessage({ id: "app.new" }) }} formData={{}} addContact={this.addContact} notify = {this.notify}/>);
            },
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                return (
                    <div className="d-flex flex-row" >
                        <Modal instance = {this.instance} type={{ Icon: "pencil square", Formtype: "contact", ButtonLabel: this.props.intl.formatMessage({ id: "app.edit" }) }} formData={row} onChange={this.onChange} addContact={this.modifyContact} notify={this.notify} />
                        <ModalConfirm modalCfg={{ type: "delete"}} onBoh={this.deleteContact} onYes={() => this.deleteContact(row.id)} />
                    </div>
                );
            },
            formatExtraData: {
                lng: this.props.language
            }
        })
        return columns;
    }

    getUserData = (userdata) => {
        this.setState({ balance: userdata.balance });
    }

    render() {
        const { page, sizePerPage, totalSize, loading } = this.state;
        const sizePerPageList = [5, 10, 15];
        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];
        const isAdmin = (localStorage.getItem('isAdmin') === "true");
        const { formatMessage } = this.props.intl;
        return (
            <div className="kx-page">
                {loading ?
                    <Dimmer active inverted>
                        <Loader size='medium'></Loader>
                    </Dimmer>
                    : null}

                <Header history={this.props.history} language={this.props.language} changeLanguage={this.props.changeLanguage} getUserData={this.getUserData} />

                <div className="with-nav-bar with-left-nav">


                    <div className="">
                        <div className="kx-left-nav">
                            <LeftSidebar history={this.props.history} location={this.props.location}
                                styles={{ sidebar: { background: "blue" } }} />
                        </div>
                    </div>

                    <div className="kx-content">

                        <div className="kx-container">

                            <div className="row">
                            <br/>
                                <div className="col-12">
                                    <ToolkitProvider
                                        keyField="id"
                                        data={this.state.contactlist}
                                        columns={this.columns}
                                        defaultSorted={defaultSorted}
                                        bootstrap4
                                        search
                                    >
                                        {
                                            toolkitprops => [
                                                <div className="searchBar">
                                                    <div className="searchBtn">
                                                        <SearchBar ref={n => this.searchKit = n} id="s1" {...toolkitprops.searchProps} delay={1000} placeholder={formatMessage({ id: "table.search" })} />
                                                        <ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
                                                    </div>
                                                    {isAdmin ?
                                                        <div className="d-flex flex-row align-items-center">
                                                            <Dropdown
                                                                className="parnter-list"
                                                                placeholder="Partner"
                                                                onChange={this.onPartnerChange}
                                                                name="partnerIdInv"
                                                                clearable
                                                                value={this.state.partnerCustomer}
                                                                selection
                                                                search
                                                                options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                                                            />
                                                        </div> : null}
                                                </div>,
                                                <div className='infodiv boxTabella'>
                                                    <h5>{this.props.intl.formatMessage({ id: "contact.contacts" })}: <Icon name='refresh' onClick={(e) => this.getContacts()} link={true} style={{ margin: 'auto'}}/></h5>
                                                    <BootstrapTable
                                                        {...toolkitprops.baseProps}
                                                        striped hover
                                                        classes="table-responsive-lg"
                                                        remote={{ search: true, sort: true }}
                                                        pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                                                        onTableChange={this.handleTableChange}
                                                    />
                                                </div>
                                            ]
                                        }
                                    </ToolkitProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
                <Footer/>
            </div >)
    }
}

export default injectIntl(AllContacts);
