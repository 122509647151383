import React, { Component } from "react";
import "./notification.scss";
import { DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import { Icon, Modal, Button, Popup } from "semantic-ui-react";
import axios from "axios";

import { FormattedMessage } from "react-intl";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      notifications: [],
      notSeenNotifications: [],
      myInterval: null,
      modalOpen: false,
      telcheck: ""
    };
    this.interval = null;
  }

  handleClose = () => this.setState({ modalOpen: false });

  componentDidMount() {
    this.getTelCode();
    if (localStorage.getItem("user")) {
      this.loadNotifications(localStorage.getItem("user"));
    } else {
      setTimeout(async () => {
        this.loadNotifications(localStorage.getItem("user"));
      }, 3000);
    }

    this.interval = setInterval(() => {
      this.loadNotifications(localStorage.getItem("user"));
    }, 15000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    if (this.interval) clearInterval(this.interval);
  }

  loadNotifications = (id) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/notifications/${id}`)
      .then((res) => {
        let notificationarray = res.data.notification.reverse()
        this.setState({
          notifications: notificationarray
        })
        let notSeenNotifications = []
        for(let notifica of notificationarray){
          if(!notifica.isSeen){
            notSeenNotifications.push(notifica)
          }
        }
        this.setState({
          notSeenNotifications: notSeenNotifications
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  isSeen = (notificationId) => {
    let id = localStorage.getItem("user")
    return axios
      .put(`${process.env.REACT_APP_API_URL}/api/notifications/`, {
        id: id,
        notificationId: notificationId,
      })
      .then((res) => {
        let notificationarray = res.data.notification.reverse()
        this.setState({
          notifications: notificationarray
        })
        let notSeenNotifications = []
        for(let notifica of notificationarray){
          if(!notifica.isSeen){
            notSeenNotifications.push(notifica)
          }
        }
        this.setState({
          notSeenNotifications: notSeenNotifications
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  toggleDropdownOpen = () => {
    this.setState({ showDropdown: true });
  };

  toggleDropdownClose = () => {
    this.setState({ showDropdown: false });
  };

  formatAMPM = (date) => {
    var hours = `${date.getHours()}`;
    var minutes = `${date.getMinutes()}`;
    var checkMins = minutes.length < 2 ? `0${minutes}` : minutes;
    var checkHrs = hours.length < 2 ? `0${hours}` : hours;
    var strTime = checkHrs + ":" + checkMins;
    return strTime;
  };

  formatDate = (date) => {
    var _date = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var strTime = _date + "/" + month + "/" + year;
    return strTime;
  };

  getTelCode = async () => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/telegram/tel-code`)
      .then(async (response) => {
        this.setState({ telcheck: response.data })
      })
  }

  allRead = async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/notifications/allread`)
      .then(async (response) => {
        
      })
  }

  handleNotificationDelete = async (e,idNotification) => {
    e.preventDefault()
    e.stopPropagation()
    await axios.put(`${process.env.REACT_APP_API_URL}/api/notifications/deletenotification`, {notificationId: idNotification})
    this.loadNotifications(localStorage.getItem("user"))
  }

  render() {
    return (
      <div className="notification">
        <div className="mb-2 notif-btns">
          <DropdownButton
            as={ButtonGroup}
            key={"left"}
            // id={`dropdown-button-drop-down`}
            drop={"left"}
            // variant="secondary"
            id="campanella"
            title={
              <Icon
                name={
                  this.state.notSeenNotifications && this.state.notSeenNotifications.length > 0
                    ? "bell"
                    : "bell outline"
                }
              />
            }
          >
            <div>{this.state.notSeenNotifications.length>99 ? document.querySelector(':root').style.setProperty('--badgeWid',"30px") : document.querySelector(':root').style.setProperty('--badgeWid',"20px")}
              <div className="eg_notification_box">
                <div className="eg_notification_head">
                  <div className="d-flex justify-content-between">
                    <h5 className="notifications-title"><FormattedMessage id={`app.notification`} /></h5>
                    <h5 className="not-badge">
                    
                      {this.state.notSeenNotifications && this.state.notSeenNotifications.length > 0 ? (
                        <div>
                          <span>
                            {this.state.notSeenNotifications.length}
                          </span><br/>
                          <Popup className="toolt" position="bottom center" content="Segna tutte come già lette" trigger={<Icon disabled={this.state.notSeenNotifications.length == 0} className="allReadIcon" name="check circle" onClick={() => this.allRead()}/>}/>
                        </div>
                      ) : null}
                    </h5>
                  </div>
                </div>
                {!this.props.telegramID ? (
                  <Dropdown.Toggle
                    split
                    variant="success"
                    id="dropdown-split-basic"
                  >
                    <div className="activate-telegram"
                      // className="eg_notification_head eg_notification_yellow isSeen"
                      onClick={() => {
                        this.setState({ modalOpen: true });
                      }}
                    >
                      <div style={{ color: "#0088cc", width: "100%" }}>
                        <FormattedMessage id={`app.telegram`} />
                      </div>
                      <a className="telegram-link" target="_blank">
                        t.me/konsolexnn_bot
                      </a>
                    </div>
                  </Dropdown.Toggle>
                ) : null}
                {this.state.notifications && this.state.notifications.length > 0 ? (
                  <ul className="ul-notifications">
                    {this.state.notifications.map((val, ind) => {
                      return (
                        <li
                          key={ind}
                          className={
                            val.isSeen
                              ? "isSeen"
                              : "eg_notification_yellow isSeen"
                          } 
                          onClick={async () => { await this.isSeen(val.id); await this.props.parentChanger(this.state.notSeenNotifications.length) }}
                        >
                          <div className="d-flex">
                            <div className="not1 pr-3">
                              <div className="d-flex justify-content-between">
                                <div className="not-title"><FormattedMessage id={`${val.subject}`} /></div>
                                <div className="datennotseen">
                                  <span>
                                    {this.formatDate(new Date(val.notified_date))}
                                    <span className="date-space">&nbsp;</span>
                                    {this.formatAMPM(new Date(val.notified_date))}
                                  </span>
                                </div>
                              </div>
                              <FormattedMessage id={`${val.description}`} />
                            </div>
                            <div style={{display:"flex","flex-direction":"column","align-items":"center"}}>
                            {val.isSeen ? (<Button id="testino" onClick={(e) => {this.handleNotificationDelete(e,val.id)}} icon="delete" size="big" style={{"margin-left":"0px","margin-top":"0px","margin-right":"0px","margin-bottom":"0px","padding":"0px"}}></Button>) : null}
                            {val.isSeen ? (<div className="ml-auto mt-2 ball" id="rectanglenotification" style={{width:"10px","padding":"0px", height:"100%"}}></div>) : (
                              <div className="ml-auto mt-2 ball notSeen"></div>
                            )}
                            </div>
                            </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </div>
            </div>
          </DropdownButton>
        </div>
        <Modal
          className="delete-modal"
          closeIcon={true}
          closeOnDimmerClick={true}
          open={this.state.modalOpen}
          onClose={this.handleClose}
        // trigger={<div>open</div>}
        >
          <div className="telegram-div">
            <div className="telegram-head-logo">
              <img
                src={require("../../../assets/img/telegram.png")}
                className="telegram-icon"
              />
              <div className="logo-head">Konsolex telegram notification</div>
            </div>
            <div className="open-telegram-div">
              <a id="pulsantetelegram" className="send-button" style={{ color: "white", cursor: "pointer" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); window.open("https://t.me/konsolexnn_bot", "_blank"); }}>
                Open Telegram
              </a>
            </div>
            <div className="telegram-or">
              <div> OR scan qrcode</div>
              <div> </div>
            </div>
            <div className="telegram-head-logo">
              <img
                src={require("../../../assets/img/konsolex.png")}
                className="logo-icon"
              />
            </div>
            <br/>
            <div>
              <p><center>Codice da inserire sul bot di Telegram: <strong>{this.state.telcheck}</strong></center></p>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Notification;
