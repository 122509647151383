import React, { Component } from 'react';
import { Form, Button, Dropdown } from 'semantic-ui-react';
import './server.scss';
import axios from 'axios';
import ReactPhoneInput from 'react-phone-input-2'
//import 'react-phone-input-2/dist/style.css';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';


class FormMovement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            product: '',
            description: '',
            amount: '',
            partner: '',
            partners: [],
            products: [],
            disableSubmit: true
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.isAdmin = (localStorage.getItem('isAdmin') === "true");
    }

    componentDidMount() {
        if (this.isAdmin) {
            this.loadPartners();
            this.loadProducts();
        }
    }


    //handleChange = (e, { name, value }) => this.setState({ [name]: value });
    handleInputChange(event, { name, value, checked, type }) {
        if (type === 'checkbox') {
            value = checked;
        }
        this.setState({
            [name]: value
        }, () => this.disableSubmit());
    }

    addMovement = () => {
        const { addMovement, closeMe } = this.props;
        let data = {
            product: this.state.product,
            description: this.state.description,
            amount: this.state.amount,
            partner: this.state.partner
        };
        addMovement(data, function (res, err) {
            console.log('From Parent', res, err);
            if (!err) {
                closeMe();
            }
        });
    }

    loadPartners = () => {
        //axios.get(`${process.env.REACT_APP_API_URL}/api/users`)
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then((response) => {
                this.setState({
                    partners: response.data
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    loadProducts = () => {
        /* let data = [
            { name: 'virtual server' },
            { name: 'virtual server volume' },
        ]; */
        axios.get(`${process.env.REACT_APP_API_URL}/api/billing/products`)
            .then((response) => {
                this.setState({
                    products: response.data
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    disableSubmit = () => {
        let res = false;
        if (this.state.product == '' || this.state.description == '' || this.state.amount == '' || this.state.partner == '') {
            res = true;
        }
        let amount = this.state.amount * 1;
        if (amount <= 0) {
            res = true;
        }
        this.setState({
            disableSubmit: res
        });
    }

    handleAddition = (e, { value }) => {
        this.setState((prevState) => ({
            products: [{ name: value, value: value }, ...prevState.products],
        }))
    }

    render() {
        const { formatMessage } = this.props.intl;
        const isAdminUser = (localStorage.getItem('isAdmin') === "true");
        return (
            <div>
                <div className="server-form">
                    <Form className="overflow-yes">
                        <Form.Group>
                            {/* <Form.Input width={10} type="text" label={formatMessage({ id: "app.product" })} value={this.state.product || ''} name="product" placeholder="" onChange={this.handleInputChange} /> */}
                            <Form.Dropdown
                                label={formatMessage({ id: "app.product" })}
                                placeholder=""
                                onChange={this.handleInputChange}
                                name="product"
                                clearable
                                selection
                                search
                                allowAdditions
                                onAddItem={this.handleAddition}
                                options={this.state.products.map(({ name }) => ({ value: name, text: name }))}
                            />
                            <Form.Input label={formatMessage({ id: "app.total" })} value={this.state.amount || ''} name="amount" placeholder="0" onChange={this.handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input type="text" label={formatMessage({ id: "app.description" })} value={this.state.description || ''} name="description" placeholder="" onChange={this.handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Dropdown
                                label="Partner"
                                className="parnter-list"
                                placeholder=""
                                onChange={this.handleInputChange}
                                name="partner"
                                clearable
                                selection
                                search
                                options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                            />
                        </Form.Group>
                        <Form.Group>
                            <div className="field"></div>
                            <Form.Button floated="right" onClick={this.addMovement} className="save-button" disabled={this.state.disableSubmit}>
                                {formatMessage({ id: "app.add" })}
                            </Form.Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        );
    }
}
export default injectIntl(FormMovement);
