import React, { Component } from 'react';
import { Form, Button, Dropdown } from 'semantic-ui-react';
import './server.scss';
import axios from 'axios';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import Moment from "moment";


class OrderForm extends Component {
    constructor(props) {
        super(props);
        let inputData = this.props.formData;
        this.state = {
            id: inputData.id,
            order_nr: inputData.order_nr,
            payment_date: Moment().clone().format('YYYY-MM-DD'),
            amount: inputData.request_amount,
            status: inputData.status,
            transaction: inputData.transaction,
            partner: inputData.customer && inputData.customer.name,
            disableSubmit: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        //
    }


    //handleChange = (e, { name, value }) => this.setState({ [name]: value });
    handleInputChange(event, { name, value, checked, type }) {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        if (type === 'checkbox') {
            value = checked;
        }
        this.setState({
            [name]: value
        }, () => null);
    }

    afterAction = () => {
        const { afterAction, closeMe } = this.props;
        let data = {
            id: this.state.id,
            payment_date: this.state.payment_date,
            amount: this.state.amount,
            status: this.state.status,
            transaction: this.state.transaction
        };
        afterAction(data, function (res, err) {
            if (!err) {
                closeMe();
            }
        });
    }


    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <div className="server-form">
                    <h4><FormattedMessage id="app.order" /> {this.state.order_nr}</h4>
                    <h5>{this.state.partner}</h5>
                    <Form className="overflow-yes">
                        <Form.Group>
                            <Form.Input type="date" label={formatMessage({ id: "bill.payment_date" })} value={this.state.payment_date || ''} name="payment_date" placeholder="" onChange={this.handleInputChange} />
                            <Form.Input type="text" label={formatMessage({ id: "app.total" })} value={this.state.amount || ''} name="amount" placeholder="" onChange={this.handleInputChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input type="text" label={formatMessage({ id: "bill.transaction" })} value={this.state.transaction || ''} name="transaction" onChange={this.handleInputChange} />
                            <Form.Dropdown
                                label="Stato"
                                onChange={this.handleInputChange}
                                fluid
                                selection
                                name="status"
                                value={this.state.status || ""}
                                options={[
                                    { text: 'Da pagare', value: 'pending', key: 'pending' },
                                    { text: 'Pagato', value: 'payed', key: 'payed' }
                                ]}
                            />
                        </Form.Group>
                        <Form.Group>
                            <div className="field"></div>
                            <Form.Button floated="right" onClick={this.afterAction} className="save-button" disabled={this.state.disableSubmit}>
                                {formatMessage({ id: "app.save" })}
                            </Form.Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        );
    }
}
export default injectIntl(OrderForm);
