import React, { Component } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import FormCreateCustomer from './FormCreateCustomer';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';

class CreateCustomerModal extends Component {
	constructor(props) {
		super(props);
		this.state = { modalOpen: false, stateMachine : 0 }
		this.externalClose = this.externalClose.bind(this);
	}

	handleOpen = () => this.setState({ modalOpen: true })
	handleClose = () =>{
		this.setState({ modalOpen: false });
		this.props.onClose()
	}

	externalClose() {
		this.setState({ modalOpen: false });
	}

	render() {
		const { onChange, addServer, addContact, type, formData, notify, size, changeBalance, addMovement, afterAction, saveInvoice } = this.props;
		const { formatMessage } = this.props.intl;
		let btnSize = "small";
		if (size) {
			btnSize = size;
		}
		return (
			//const ModalModalExample = ({ onChange, addServer, addContact, type, formData }) => (
			<div>
				<Modal closeIcon={true}
					closeOnDimmerClick={true}
					open={this.state.modalOpen}
					onClose={this.handleClose}
					size={size}
					trigger={
						<Button icon size={btnSize} onClick={this.handleOpen}>
							<Icon name={type.Icon} title={type.ButtonLabel} />
						</Button>
					}
					style = {{width : "20%"}}
				>
					<Modal.Header>
						<FormattedMessage id = "contact.newCustomerAccount" /> {/* cambiare contact.header */}
					</Modal.Header>
					<Modal.Content className="modal-min-height">
						<Modal.Description>
							<FormCreateCustomer type={type} formData={formData} onChange={onChange} addContact={addContact} closeMe={this.handleClose} notify={notify} stateMachine = {this.props.stateMachine} />
						</Modal.Description>
					</Modal.Content>
				</Modal>
			</div>
		);
	}
}

export default injectIntl(CreateCustomerModal);
