import React, { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import Header from '../header/header';
import Footer from '../footer/footer';
import LeftSidebar from '../sidebar/left-sidebar';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import NotifyComponent from '../notify/notifyComponent';

//
class StripeResponse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            bonus: 0,
            loading: true,
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
        }
        this.token = localStorage.getItem('jwt');
        axios.defaults.headers.common['x-auth-token'] = this.token;
    }

    componentDidMount() {
        let search = this.props.location.search;
        const params = new URLSearchParams(search);
        const session_id = params.get('session_id');
        this.getPaymentState(session_id);
    }

    componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }
    /*
    search methods
    */
    getPaymentState = (session_id) => {
        //this.setState({ loading: true });
        this.notify("Caricamento", "info", "stripe", false)
        axios.
            post(`${process.env.REACT_APP_API_URL}/api/billing/verify-checkout-session`, {
                session_id: session_id
            })
            .then((response) => {
                this.notify(<FormattedMessage id="notify.strype-pay" />, "success", "stripe", true)
                this.setState({
                    balance: response.data.new_balance.toFixed(2),
                    bonus: response.data.new_bonus.toFixed(2),
                    loading: false
                });
            })
            .catch((e) => {
                this.setState({ loading: false });
                this.notify(<FormattedMessage id="notify.stripe-pay.failed" />, "error", "stripe", true)
                console.log(e.message);
            });
    };

    getUserData = (userdata) => {
        this.setState({
            balance: userdata.balance,
            bonus: userdata.bonus
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="kx-page">
                {this.state.loading ?
                    <Dimmer active inverted>
                        <Loader size='medium'></Loader>
                    </Dimmer>
                    : null}

                <Header history={this.props.history} language={this.props.language} changeLanguage={this.props.changeLanguage} getUserData={this.getUserData} />

                <div className="with-nav-bar with-left-nav">


                    <div className="">
                        <div className="kx-left-nav">
                            <LeftSidebar history={this.props.history} location={this.props.location}
                                styles={{ sidebar: { background: "blue" } }} />
                        </div>
                    </div>

                    <div className="kx-content">

                        <div className="kx-container full-height">


                            {/*<Modal type={{ Formtype: "contact", ButtonLabel: "New" }} formData={{}} addContact={this.addContact} />*/}


                            <h5 className="saldo-info pr-2 mb-3">
                                <span>Saldo: <span className="orange-color">&euro; {this.state.balance}</span></span>
                                <span className="ml-3">Bonus: <span className="orange-color">&euro; {this.state.bonus}</span></span>
                            </h5>
                            <div className="flex-box">
                                <div className="infodiv-mid">
                                    <h5>Stripe</h5>
                                    <div className="kx-callout kx-callout-info">
                                        <FormattedMessage id="bill.stripe_payment_success" values={{ br: <br /> }} />
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                {
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
                <Footer />

            </div >)
    }
}

export default injectIntl(StripeResponse);
