import React, { Component } from "react";
import "./pagination.scss";

class Pagination extends Component {
  constructor(props) {
    super(props);

    this.paginationClick = this.paginationClick.bind(this);
    this.state = {
      currentPage: 1,
      showPagination: false
    };
  }

  /*componentDidUpdate() {
    if (this.state.totalDomains === 0) {
      this.props.reload();
      this.setState({ totalDomains: this.props.domainsLength });
    }
  }*/

  componentWillReceiveProps(nextProps) {
    //this.setState({ totalDomains: nextProps.domainsLength });
    if (nextProps.domainsLength < 9) {
      this.setState({ showPagination: false });
    } else {
      this.setState({ showPagination: true });
    }
  }

  paginationClick(direction) {
    if (direction === "up") {
      this.setState({ currentPage: this.state.currentPage + 1 });
      this.props.updateOffset("up");
    } else {
      this.setState({ currentPage: this.state.currentPage - 1 });
      this.props.updateOffset("down");
    }
  }

  render() {
    return (
      this.state.showPagination && (
        <nav>
          <ul className="pagination justify-content-center">
            {this.state.currentPage > 1 && (
              <li className="page-item">
                <a
                  className="page-link"
                  onClick={() => {
                    this.paginationClick("down");
                  }}
                >
                  <span>&laquo;</span>
                </a>
              </li>
            )}
            <li className="page-item">
              <span className="page-link current-page">
                {this.state.currentPage}
              </span>
            </li>
            {this.props.domainsLength / this.state.currentPage > 8 && (
              <li className="page-item">
                <a
                  className="page-link"
                  onClick={() => {
                    this.paginationClick("up");
                  }}
                >
                  <span>&raquo;</span>
                </a>
              </li>
            )}
          </ul>
        </nav>
      )
    );
  }
}

export default Pagination;
