import React, { Component } from 'react';
import './list.scss';
import axios from 'axios';

import AlertBox from '../utils/alert';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Dimmer, Loader } from 'semantic-ui-react';

class List extends Component {
	constructor(props) {
		super(props);
		this.addDomain = this.addDomain.bind(this);
		this.showAlert = this.showAlert.bind(this);
		this.state = {
			isTrue: false,
			domain: '',
			alertMessage: '',
			alertDisplay: false,
			checked: false,
			checkout: false,
			domainDetails: '',
			stepNo: 1,
			adduser: false,
			firstname: '',
			lastname: '',
			email: '',
			phonenumber: null,
			street: '',
			city: '',
			countrycode: null,
			postalcode: null,
			error: true,
			contactlist: [],
			steps: [
				{
					key: 'Check',
					disabled: false,
					icon: 'truck',
					title: 'Shipping',
					description: 'Choose your shipping options'
				},
				{ key: 'Bildling', disabled: true, icon: 'info', title: 'Confirm Order' },

				{
					key: 'Users',
					disabled: true,
					icon: 'payment',
					title: 'Billing',
					description: 'Enter billing information'
				},
				{ key: 'Billing', disabled: true, icon: 'info', title: 'Confirm Order' }
			],
			showLoader: false
		};

		//this.getContacts();
	}

	onChange = (e) => {
		let valuetocheck = e.target.value
        e.target.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '')
		let val = e.target.value;
		val = val.replace(/\s/g, '');
		val = val.replace(/www./g, '')
		val = val.replace(/https:/g, '')
		val = val.replace(/http:/g, '')
		val = val.replace(/https/g, '')
		val = val.replace(/http/g, '')
		
		if(val.includes(".")) {
			let check = val.substring(val.indexOf("."))
			if(check.length>=3) {
				this.setState({error: false});
			} else {
				this.setState({error: true})
			}
		} else {
			this.setState({error: true})
		}
		this.setState({ [e.target.name]: val });
	};

	addDomain(no) {
		this.setState({ showLoader: true });
		const { domain } = this.state;
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/api/domains/check`, { name: domain },
			)
			.then((response) => {
				if (response.data.status !== 'failed') {
					// this.state.steps[0].disabled = true;
					// this.state.steps[1].disabled = false;
					this.setState({
						steps: this.state.steps,
						domainDetails: response.data.message,
						showLoader: false
					});
					var action = (response.data.status === 'AVAILABLE' ? 'register' : 'transfer');
					this.props.history.push('/domainRegistration', { domain: this.state.domain, action: action });
				} else {
					//this.showAlert(response.data.message);
					this.props.notify(response.data.message, 'error', 'add', true);
					this.setState({
						showLoader: false
					});
					// this.props.history.push('/domainRegistration');
				}
			})
			.catch((error) => {
				this.showAlert(error.message);
				this.setState({
					showLoader: false
				});
			});
	}

	//Alert inner code
	showAlert(message) {
		this.setState({
			alertMessage: message,
			alertDisplay: true
		});

		setTimeout(() => {
			this.setState({ alertDisplay: false, alertMessage: '' });
		}, 3000);
	}

	changeStep = () => {
		this.state.steps[1].disabled = true;
		this.state.steps[2].disabled = false;
		this.setState({
			steps: this.state.steps
		});
	}; 

	addUser = () => {
		this.setState({ adduser: true });
	};

	addContact = () => {
		const { domain } = this.state;
		const token = localStorage.getItem('jwt');

		let user = {
			uid: new Date().getTime(),
			firstname: this.state.firstname,
			lastname: this.state.lastname,
			email: this.state.email,
			phonenumber: this.state.phonenumber,
			street: this.state.street,
			city: this.state.city,
			countrycode: this.state.countrycode,
			postalcode: this.state.postalcode
		};
		axios
			.post(`${process.env.REACT_APP_API_URL}/api/contact/contact`, user, {
				headers: {
					'x-auth-token': token
				}
			})
			.then((response) => {
				this.getContacts();
			})
			.catch((error) => {
				this.showAlert(error.message);
			});
	};

	getContacts = () => {
		const token = localStorage.getItem('jwt');
		axios
			.get(`${process.env.REACT_APP_API_URL}/api/contact/contact`, {
				headers: {
					'x-auth-token': token
				}
			})
			.then((response) => {
				this.setState({ contactlist: response.data });
			})
			.catch((error) => {
				this.showAlert(error.message);
			});
	};

	render() {
		const { formatMessage } = this.props.intl;
		const { domain } = this.state;
		return (
			<div className="row mb-3 new-domain">
				<div className="col-md-8 offset-md-3">
					<AlertBox message={this.state.alertMessage} alertDisplay={this.state.alertDisplay} />
					<div className="row no-gutters mt-3 mb-2">
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pr-1">
							<input
								type="text"
								className="form-control form-control-lg new-domain-input"
								name="domain"
								value={domain}
								onChange={this.onChange}
								required="required"
								placeholder={formatMessage({ id: "domain.verify_text" })}
							/>
						</div>
						<div className="col-lg-1 col-md-2 col-sm-12 col-xs-12">
							{!this.state.showLoader ? (
								<button onClick={() => this.addDomain(1)} className="btn btn-primary btn-lg btn-list button-domain" disabled={this.state.domain.length < 3 || this.state.error}>
									<FormattedMessage id="app.checkdomain" />
								</button>
							) : (
									<div>
										<Dimmer
											style={{
												width: '53%',
												background: 'red',
												borderRadius: '6px'
											}}
											active
										>
											<span>
												<Loader size="small" />
											</span>
										</Dimmer>
									</div>
								)}
						</div>

						{/* <div className="row justify-content-center list">
							<div className="col-12 list-body">
								<ListItem history={this.props.history} key={domain._id} domain={this.props.domains} loadData={this.props.loadData} />
							</div>
						</div> */}

					</div>
				</div>
			</div>
		);
	}
}

export default injectIntl(List);
