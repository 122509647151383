import React, { Component } from 'react';
import axios from 'axios';
import { Form, Modal, Dropdown, Button, Icon, Input } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';


class ModalAddProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersArray: [],
            project_name : "",
            api_key : "",
            hetznerid: "",
            userid: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    componentDidMount(){
        this.getUsers()
    }

    getUsers = async() => {
        await axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then(async (response) => {
                this.setState({usersArray:response.data})
            })
            .catch((err) => {
                console.log(err);
            })
    }
    
    
    handleInputChange(event, { name, value }) {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        this.setState({
            [name]: value
        });
    }


    save = async () =>{
        this.props.notify("Caricamento", "info", "add", false)
        if (this.state.userid && this.state.api_key && this.state.hetznerid && this.state.project_name) {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/vm/addproject`, {project_name: this.state.project_name, api_key: this.state.api_key, hetznerid: this.state.hetznerid, userid:this.state.userid})
                .then((response) => {
                    this.props.notify("Progetto creato", "success", "add", true)
                    this.props.onClose()
                    })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            this.props.notify("Completa prima tutti i campi", "error", "add", true)
        }
    }

    onChangeUser = async (e, data) => {
        await this.setState({ userid: data.value });
    };

    


    render() {
        const { open, onClose, type } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    style = {{width: "30%"}}
                >
                <Modal.Header>
                    Nuovo Progetto
                </Modal.Header>
                    <Modal.Content>
                        <div>
                            <Form id="formProgetto" className="overflow-yes">                           
                                <Form.Input  onChange={this.handleInputChange} name = "project_name" value={this.state.project_name} label={"Nome Progetto"} />
                                <Form.Input  onChange={this.handleInputChange} name = "hetznerid" value={this.state.hetznerid} label={"ID Hetzner"} />
                                <Form.Input  onChange={this.handleInputChange} name = "api_key" value={this.state.api_key} label={"Apikey"} />
                                <Form.Dropdown 
                                    placeholder="Assegna a Cliente"
                                    style = {{"border-width" : "3px", "border-style" : "groove", "max-width": "100%", "border-radius" : "5px", "border-color" : "#000000"}}
                                    search
                                    label = {"Cliente"}
                                    onChange={this.onChangeUser}
                                    name="domain"
                                    clearable
                                    selection
                                    options={this.state.usersArray.map(({ name, userid }) => ({ value: userid, text: name }))}
                                />
                                <Form.Button style = {{"margin-right" : "5%", "margin-left" : "80%", "margin-bottom" : "3%", "margin-top" : "3%"}}  onClick={this.save} className="save-button">
                                    {formatMessage({ id: "app.save" })}
                                </Form.Button>
                            </Form>
                        </div>
                        
                        
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalAddProject);
