import React, { Component } from 'react';
import './table.scss';
import axios from 'axios';
import { Form, Modal } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useHistory, withRouter } from 'react-router-dom';

class Contract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contract: 0,
            checked: false
        }
    }

    componentDidMount = () => {
        this.loadData()
        if (this.state.contract !== 1) {
			this.setState({
				contractOpen: true
			});
    }}

    loadData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
            .then((response) => {
                this.setState({
                    contract: response.data.contract
                });
            })
            .then(() => {
                if (this.state.contract === 1) {
                    this.props.onClose(false)
                }
            })
            .catch((error) => {
                console.log(error)
            });
        }
    

    onClick = () => {
        if (this.state.checked===false){
            this.setState({checked:true,contract:1});}
    };

    onSubmit = () => {
        const {contract} = this.state;
        if (this.state.checked === true) {
            axios.post(`${process.env.REACT_APP_API_URL}/api/users/contract`,{contract})
                .then((response) => {
                    this.props.onClose(false)
            })
    }}

    goToUrl = async () => {
        const win = window.open("/Contratto_Cloudonthecloud.pdf", "_blank");
        win.focus()
    };


    render() {
        const { open, onClose } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <Modal 
                    closeOnDimmerClick={false}
                    style={{ margin: '0 auto' }}
                    open={open}
                    onClose={() => onClose(false)}
                    closeOnEscape={false}
                    onchange={this.onChange}
                >
                    <Modal.Header getUserData={this.getUserData}>
                        <FormattedMessage id="app.contractheader" />
                    </Modal.Header>
                    <Modal.Content className="modal-min-width">
                        <Modal.Description>
                            <div>
                                <div className="server-form">
                                    <Form className="overflow-yes" onSubmit={this.onSubmit} history={this.props.history}>
                                    <br/>
                                    <strong><FormattedMessage id="app.contractbody" /></strong>
                                    <br/>
                                    <br/>
                                    <Form.Checkbox history={this.props.history} label={formatMessage({ id: "app.contractcheckboxupdated" })} checked={this.state.checked} onClick={this.onClick}/>
                                    <Form.Group classname="form-group" >
                                    <Form.Button type='submit' className="save-button" onClick = {this.goToUrl}>{formatMessage({ id: "app.contractvisualize" })}</Form.Button>
                                    </Form.Group>
                                        <Form.Group>
                                            <div className="field"></div>
                                            <Form.Button type='submit' floated="right" className="save-button" onSubmit={this.onSubmit}>
                                                {formatMessage({ id: "app.accept" })}
                                            </Form.Button>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default withRouter(injectIntl(Contract));
