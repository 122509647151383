import React, { Component } from 'react';
import { FormattedMessage, injectIntl, FormattedDate } from 'react-intl';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Button, Form, Dropdown} from 'semantic-ui-react';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import axios from 'axios';
import '../All/table.scss';
import '../BarMetal/BarMetal.scss'
import moment from "moment"
import ModalReAssignStoragebox from './ModalReAssignStoragebox'
import ModalAddStoragebox from '../BarMetal/ModalAddStoragebox';
import ModalEditPassword from './ModalEditPassword';
import ModalEditInstructions from './ModalEditInstructions';
import ModalConfirm from '../All/ModalConferm';
import ModalCname from './ModalCname';
import NotifyComponent from '../notify/notifyComponent';
const { SearchBar, ClearSearchButton } = Search;


class ConfigurationStorageBoxes extends Component {
	constructor(props) {
		super(props);
		localStorage.setItem('api-vs-key', '');
		axios.defaults.headers.common['x-api-vs-key'] = this.token;
		this.token = localStorage.getItem('jwt');
		axios.defaults.headers.common['x-auth-token'] = this.token;
		this.isAdmin = (localStorage.getItem('isAdmin') === "true");
		this.instance = React.createRef();
		this.state = {
			dati:0,
			serverList: [],
			storageboxList: [],
			storageboxTable: [],
			page: 1,
			totalSize: 0,
			sizePerPage: 10,
			storagePaid: "",
			storageId: -1,
			oldName: "",
			stateMachine: 'storagelist',
			storageBoxID: -1,
			storageBoxName: "",
			partners: [],
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
		};
		this.onNameChange = this.onNameChange.bind(this);
		this.columns =  [
			{
				dataField: 'status',
				sort: true,
				classes: 'statusclass',
				text: this.props.intl.formatMessage({ id: "app.status" }),
				formatter: (cellContent, row, rowIndex, formatExtraData) => {
					let classDiv = "ui green horizontal label";
					if(cellContent == 'ACTIVE'){
						classDiv = "ui green horizontal label";
					} else if(cellContent == 'PENDING'){
						classDiv = 'ui yellow horizontal label';
					}
					return (
						<div className="d-flex" onClick={()=>{this.changeStorageboxStatus(row.id, row.status)}}>
							<div className={classDiv} >{cellContent}</div>
						</div>)
				}
			}, {
				dataField: 'name',
				text: "StorageBox",
				sort: true,
				classes: "serverclass",
				formatter: (val, row) => {
					return <div>
						<div  
						className='textToCopy'>
							<strong className='textToShow' onClick={(e) => {e.stopPropagation();e.preventDefault()}} 
							onMouseDown={async (e) => {
								let elements = document.getElementsByClassName('tagToCopy')
								for(let element of elements) {
									element.innerText="Copied"
								}
								
								await this.sleep(500)
								for (let element of elements) {
									element.innerText="Copy"
								}
							}}>{row.name}</strong>
							<div className='tagToCopy' onClick={(e) => {e.stopPropagation();e.preventDefault()}} onMouseDown={async (e) => {
								let elements = document.getElementsByClassName('tagToCopy')
								for(let element of elements) {
									element.innerText="Copied"
								}
								
								await this.sleep(500)
								for (let element of elements) {
									element.innerText="Copy"
								}
								
								}}>Copy</div>
						</div>
					</div>
				}
			}, {
				dataField: 'expiration_date',
				sort: true,
				text: this.props.intl.formatMessage({id: "app.expired"}),
				formatter: (cellContent, row) => {
					var extraCSS = 'serverBlack';
					let renew = moment(cellContent);
					let now = moment();
					let delta = moment.duration(renew.diff(now)).asDays();
					if(delta < 7) {
						extraCSS="serverRed"
					}
					if (cellContent) {
						return (<span id={extraCSS}><FormattedDate style={{color: {extraCSS}}} value={cellContent} year='numeric' month='2-digit' day='2-digit' /></span>)
					}
				}
			}];
			if (this.isAdmin) {
				this.columns.push({
					dataField: 'customer.name',
					text: this.props.intl.formatMessage({ id: "app.partner" }),
					style: { "min-width": "150px", "max-width": "150px", "word-break":"break-word"},
					formatter: (cellContent, row) => {
						return <Button className="buttonAction" onClick = {(e) => { this.changeAccount(row)}}> {cellContent} </Button>
					}
				});
			}
			this.columns.push({
				dataField: 'azioni',
            text: this.props.intl.formatMessage({ id: "app.action" }),
            isDummyField: true,
            headerStyle: (colum, colIndex) => {
                return {"text-align": "right" };
            },
			headerFormatter: () => {
                return (
                    <div>
						<label style={{"margin-right":"10px"}}>Azioni</label>                                                   
                        <Button icon="add" className="details-add" onClick={(e) => {this.setState({addStorageboxOpen: true})}} />
                    </div>
                );
            },
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                try{
					return (
						<div className="buttonServer">
							<Button onClick = {() => {this.setState({selectedStoragebox: row, ModalCnameOpened: true})}}>Aggiungi cname</Button>
							<Button onClick = {() => {this.setState({selectedStoragebox: row, AssignStorageboxOpened: true})}}>Assegna Partner</Button>
							<Button onClick = {() => {this.setState({selectedStoragebox: row, EditPasswordOpen: true})}}>Modifica Password</Button>
							<Button onClick = {() => {this.setState({selectedStoragebox: row, EditInstructionsOpen: true})}}>Aggiungi istruzioni</Button>
							<ModalConfirm modalCfg={{ type: "delete"}} onYes={() => this.deleteStoragebox(row.id)} />
						</div>
					)} catch(e) {
						console.log(e)
					}
				},
				formatExtraData: {
					lng: this.props.language
				}
			})
	}

	loadUsers = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
			.then((response) => {
				this.setState({
					partners: response.data
				});
			})
			.catch((error) => {
                console.log(error)
            });
	}

	componentDidMount() {
		this.loadUsers()
		this.storageboxTable()
	}

	deleteStoragebox = (id) => {
		this.notify("Caricamento", "info", "trash", false)
		axios.post(`${process.env.REACT_APP_API_URL}/api/barmetal/deletestoragebox`, {id: id})
		.then((response) => {
			this.storageboxTable()
			this.notify("Cloudbox eliminato con successo!", 'success', "trash", true);
		})
		.catch((error) => {
			console.log(error)
			this.notify(error.message, 'error', "trash", true);
		});
	}

	changeStorageboxStatus = (id, status) => {
		this.notify("Caricamento", "info", "trash", false)
		if (status == "ACTIVE"){status = "PENDING"} else {status = "ACTIVE"}
		axios.post(`${process.env.REACT_APP_API_URL}/api/barmetal/changestorageboxstatus`, {id: id, status:status})
		.then((response) => {
			this.storageboxTable()
			this.notify("Stato modificato con successo!", 'success', "trash", true);
		})
		.catch((error) => {
			console.log(error)
			this.notify(error.message, 'error', "trash", true);
		});
	}

	storageboxTable = (partnerId) => {
		axios.post(`${process.env.REACT_APP_API_URL}/api/barmetal/storageboxtablewithdeleted`, {partnerId: partnerId})
			.then((response) => {
				this.setState({
					storageboxTable: response.data,
				});
			})
			.catch((error) => {
				console.log(error)
				this.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
			});
	}

	changeAccount = async (row) => {
		await axios.post(`${process.env.REACT_APP_API_URL}/api/contact/adminauth`, {userid: row.userid})
            .then((response) => {
                if(!localStorage.getItem("baseuser")){
                    localStorage.setItem("baseuser", localStorage.getItem("user"))
                    localStorage.setItem("basejwt", localStorage.getItem("jwt"))
                }
                localStorage.setItem("jwt", response.data.token);
                localStorage.setItem("isAdmin", false);
                localStorage.setItem("user", response.data.user);
                localStorage.setItem("restore", true);
                localStorage.setItem("multitenancy", response.data.multitenancy);
                window.location.reload();
            })
	}

	componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }

	storageboxList = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/barmetal/storageboxlist`)
			.then((response) => {
				let storageboxListData = response.data
				for(let storagebox of storageboxListData) {
					switch (storagebox.storagebox.location) {
						case "FSN1":
							Object.assign(storagebox.storagebox,{locationName: "Falkenstein", country: "DE"}) 
							break
						case "NBG1":
							Object.assign(storagebox.storagebox,{locationName: "Nuremberg", country: "DE"})
							break
						case "HEL1":
							Object.assign(storagebox.storagebox,{locationName: "Helsinki", country: "FI"})
							break
						case "ASH1":
							Object.assign(storagebox.storagebox,{locationName: "Ashburn", country: "USA"})
							break
					}
				}
				this.setState({
					storageboxList: storageboxListData
				});
			})
			.catch((error) => {
				console.log(error)
				this.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
			});
	}

	handleStorageChange = (event, {value}) => {
		this.storageboxInfo(value)
		this.storageboxSnapshotList(value)
		
	}

	storageboxUpdateName = (id, storagebox_name) => {
		this.notify("Caricamento", "info", "pencil", false)
		axios.post(`${process.env.REACT_APP_API_URL}/api/barmetal/updatestorageboxname`, {id:id, storagebox_name:storagebox_name})
			.then((response) => {
				this.notify("Name successfully updated", 'success', "pencil", true);
			})
			.catch((error) => {
				console.log(error)
				this.notify(""+error, 'error', 'pencil', true);
			});
	}

	onNameChange = (e, data) => {
		let valuetocheck = data.value
        data.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
		if(this.state.storageId != 0) {
			this.setState({
				storageName: data.value
			})
			if(data.value != this.state.oldName) {
				document.querySelector(':root').style.setProperty('--visible', 'visible')
			} else {
				document.querySelector(':root').style.setProperty('--visible', 'hidden')
			}
		}
	}

	serverDetails = (e, row) => {
        this.setState({selectedStoragebox: row, editStorageboxOpened: true});
    }

	render() {
		const { page, sizePerPage, totalSize, loading } = this.state;
		const sizePerPageList = [5, 10, 15];
		const defaultSorted = [{
			dataField: 'id',
			order: 'desc'
		}]
		const { formatMessage } = this.props.intl;
		const isAdmin = (localStorage.getItem('isAdmin') === "true");
		return <div className="kx-page">
			<ModalReAssignStoragebox data = {this.state.selectedStoragebox}  notify={this.notify} open={this.state.AssignStorageboxOpened} onClose={() => {this.setState({AssignStorageboxOpened:false}); this.storageboxTable()}} />
			<ModalAddStoragebox notify = {this.notify} open = {this.state.addStorageboxOpen} onClose = {() => {this.setState({addStorageboxOpen: false}); this.storageboxTable()}} />
			<ModalEditPassword source ={"storageboxes"} data = {this.state.selectedStoragebox} notify = {this.notify} open = {this.state.EditPasswordOpen} onClose = {() => {this.setState({EditPasswordOpen: false})}} />
			<ModalCname data = {this.state.selectedStoragebox} notify = {this.notify} open = {this.state.ModalCnameOpened} onClose = {() => {this.setState({ModalCnameOpened: false})}} />
			<ModalEditInstructions data = {this.state.selectedStoragebox} notify = {this.notify} open = {this.state.EditInstructionsOpen} onClose = {() => {this.setState({EditInstructionsOpen: false}); this.storageboxTable()}} />
					<ToolkitProvider
						keyField="id"
						data={this.state.storageboxTable}
						columns={this.columns}
						defaultSorted={defaultSorted}
						bootstrap4
						search
					>
						{
							toolkitprops => [
								<div className="searchBar">
									<div className="searchBtn">
										<SearchBar ref={n => this.searchKit = n} id="s1" delay={1500} {...toolkitprops.searchProps} placeholder={formatMessage({ id: "table.search" })} />
										<ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
									</div>
									{isAdmin ?
										<div className="d-flex flex-row align-items-center">
											<Dropdown
												className="parnter-list"
												placeholder="Partner"
												onChange={(e, data) => {this.storageboxTable(data.value)}}
												name="partnerId"
												clearable                                                                
												selection
												search
												options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
											/>
										</div> : null}
								</div>,
								<div className='infodiv boxTabella'>
									<BootstrapTable
										{...toolkitprops.baseProps}
										hover
										classes="table-responsive"
										pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
										onTableChange={this.handleTableChange}
									/>
								</div>
							]
						}
					</ToolkitProvider>
					{
						this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
					}
			</div>
	}
}

export default injectIntl(ConfigurationStorageBoxes);