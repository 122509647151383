import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { default as ModalContact } from '../All/Modal';
import ModalConfirmPayment from '../All/ModalConfirmPayment';
import '../list-item/list-item.scss';
import Header from '../header/header';
import LeftSidebar from '../sidebar/left-sidebar';
import Footer from '../footer/footer';
import { Breadcrumb, Icon, Button, Dropdown, Modal } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Moment from 'moment';
import 'moment/locale/it';
import axios from 'axios';
import _ from 'lodash';
import NotifyComponent from '../notify/notifyComponent';

const { SearchBar, ClearSearchButton } = Search;

class ChangeOwner extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.token = localStorage.getItem('jwt');
		axios.defaults.headers.common['x-auth-token'] = this.token;
		this.state = {
			collapse: false,
			domainid: this.props.location.state.domainid,
			domaininfo: {},
			open: false,
			confirm: false,
			page: 1,
			totalSize: 0,
			sizePerPage: 10,
			currentIndex: 0,
			isEdit: false,
			isDetails: false,
			showDetails: false,
			background: 'white',
			dropdownselect: null,
			contactlist: [],
			contact: {},
			amount: 0,
			balance: 0,
			contact_types: [
				{ type: 'owner', description: this.props.intl.formatMessage({ id: "reg.registrtant_contact" }) },
				{ type: 'admin', description: this.props.intl.formatMessage({ id: "reg.admin_contact" }) },
				{ type: 'tech', description: this.props.intl.formatMessage({ id: "reg.technical_contact" }) },
				{ type: 'billing', description: this.props.intl.formatMessage({ id: "reg.billing_contact" }) }
			],
			new_contacts: [{contact_description: this.props.intl.formatMessage({ id: "reg.registrtant_contact" }),
			contact_id: -1,
			contact_name: "",
			contact_type: "owner"},

			{contact_description: this.props.intl.formatMessage({ id: "reg.admin_contact" }),
			contact_id: -1,
			contact_name: "",
			contact_type: "admin"},

			{contact_description: this.props.intl.formatMessage({ id: "reg.technical_contact" }),
			contact_id: -1,
			contact_name: "",
			contact_type: "tech"},
			
			{contact_description: this.props.intl.formatMessage({ id: "reg.billing_contact" }),
			contact_id: -1,
			contact_name: "",
			contact_type: "billing"}],
			
			contact_type: 'owner',
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
		};
		this.loadDomain(this.props.location.state.domainid);
		this.loadPrice('change_owner');
	}

	componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }

	getUserData = (userdata) => {
		this.setState({ balance: userdata.balance });
	}

	getContacts = () => {
		const token = localStorage.getItem('jwt');
		axios.get(`${process.env.REACT_APP_API_URL}/api/contact/filter`,
			{
				params: {
					currentIndex: this.state.currentIndex,
					sizePerPage: this.state.sizePerPage,
				}
			})
			.then((response) => {
				this.setState({ contactlist: response.data.dataset, totalSize: response.data.totalCount });
			})
			.catch((error) => {
				console.log(error.message);
			});
	};

	loadPrice = async (product_category) => {
		await axios.get(`${process.env.REACT_APP_API_URL}/api/users/price`, { params: { product_category: product_category } }
		).then(async (res) => {
			if (res.data.fixed_price > 0) {
				this.setState({ amount: res.data.fixed_price });
				this.getContacts();
			}
			else {
				this.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
			}
			try {
				let data = [{
					contact_type: "owner",
					contact_description: this.props.intl.formatMessage({ id: "reg.registrtant_contact" }),
					contact_id: JSON.stringify(this.state.domaininfo.admin_id),
					contact_name: [this.state.domaininfo.registrant.firstname, this.state.domaininfo.registrant.lastname].filter(Boolean).join(" ")
				},
				{
					contact_type: "admin",
					contact_description: this.props.intl.formatMessage({ id: "reg.admin_contact" }),
					contact_id: this.state.domaininfo.registrant_id,
					contact_name: [this.state.domaininfo.admin.firstname, this.state.domaininfo.admin.lastname].filter(Boolean).join(" ")
				},
				{
					contact_type: "tech",
					contact_description: this.props.intl.formatMessage({ id: "reg.technical_contact" }),
					contact_id: this.state.domaininfo.tech_id,
					contact_name: [this.state.domaininfo.tech.firstname, this.state.domaininfo.tech.lastname].filter(Boolean).join(" ")
				},
				{
					contact_type: "billing",
					contact_description: this.props.intl.formatMessage({ id: "reg.billing_contact" }),
					contact_id: this.state.domaininfo.billing_id,
					contact_name: [this.state.domaininfo.billing.firstname, this.state.domaininfo.billing.lastname].filter(Boolean).join(" ")
				}]
				this.setState({
					new_contacts: data
				});
			}
			catch(error) {
				console.log(error)
			}
				
		}).catch((error) => {
			console.log(error.message);
			this.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
		});
	}

	loadDomain = (domainid) => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/domains/${domainid}`
		).then((res) => {
			this.setState({ domaininfo: res.data });
		});
	}

	toggle() {
		this.setState({ collapse: !this.state.collapse });
	}

	show = (size) => () => this.setState({ size, open: true });
	close = () => this.setState({ open: false });
	showConfirm = (size) => () => this.setState({ size, confirm: true });
	closeConfirm = () => this.setState({ confirm: false });

	showDetails = () => {
		this.setState({ isDetails: true });
		this.show('mini')();
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	viewContact = (row) => {
		this.setState({
			contact: row
		});
		this.show('small')();
	};

	editDomain = (amount) => {
		let data = {};
		for (const contact of this.state.new_contacts) {
			Object.assign(data, { [contact.contact_type]: contact.contact_id });
		}
		this.notify("Caricamento", "info", "pencil", false)
		axios.
			post(`${process.env.REACT_APP_API_URL}/api/domains/contactUpdate`, {
				"domain": this.props.location.state.domain,
				"amount": amount,
				"product": "change owner",
				"details": data
			})
			.then((response) => {
				this.setState({ balance: response.data.new_balance });
				this.notify(<FormattedMessage id="notify.owner-changed" />, "success", "pencil", true)

			})
			.catch((error) => {
				// tbd universal error handler
				if (error.response) {
					let err_response = error.response.data;
					switch (err_response.message) {
						case 'NO_PRICE_FOUND':
							this.notify(<FormattedMessage id="notify.owner-changed.price-error" />, "error", "pencil", true)
							break;
						case 'NO_MONEY':
							this.notify(<FormattedMessage id="notify.owner-changed.money-error" />, "error", "pencil", true)
							break;
						default:
							this.notify(<FormattedMessage id="notify.owner-changed.failed" />, "error", "pencil", true)
					}
				}
				else {
					this.notify(<FormattedMessage id="notify.owner-changed.failed" />, "error", "pencil", true)
				}
			});
	}

	/* obsolet */
	payFromAccountV1 = (contact, amount) => {
		this.notify("Caricamento", "info", "add", false)
		axios.
			post(`${process.env.REACT_APP_API_URL}/api/billing/debit`, {
				"amount": amount,
				"product": "change owner",
				"details": `new contactid: ${contact}`
			})
			.then((response) => {
				this.setState({ balance: response.data.new_balance });
				this.changeOwner(contact);
				this.notify(this.props.intl.formatMessage({ id: "changeowner.success_changed" }, { domain: this.props.location.state.domain }), "success", "add", true)

			})
			.catch((error) => {
				console.log(error);
				this.notify(<FormattedMessage id="changeowner.error_changed" />, "error", "add", true)
			});
	}

	onConfirmOpen = () => {
		return {
			balance: this.state.balance,
			name: "Change Owner"
		};
	}

	/* obsolet */
	changeOwner = (contact) => {
		this.notify("Caricamento", "info", "pencil", false)
		axios.
			post(`${process.env.REACT_APP_API_URL}/api/domains/changeOwner`, {
				"domain": this.props.location.state.domain,
				"ownerid": contact
			})
			.then((success) => {
				//this.notify(`Owner for ${this.props.location.state.domain} is changed`, 'success');
				this.notify(this.props.intl.formatMessage({ id: "changeowner.success_changed" }, { domain: this.props.location.state.domain }), "success", "pencil", true)

			})
			.catch((error) => {
				console.log(error.response.data.message);
				this.notify(<FormattedMessage id="changeowner.error_changed" />, "error", "pencil", true)
				//saveDomain(domain, showAlert, history)
			});
	}

	removeContact = (contact_type) => {
		let prev_contacts = JSON.parse(JSON.stringify(this.state.new_contacts));//clone
		let index = prev_contacts.findIndex(e => e.contact_type === contact_type);
		if (index != -1) {
			prev_contacts.splice(index, 1);
		}
		this.setState({
			new_contacts: prev_contacts
		});
	}

	chooseContact = (contact) => {
		let type = this.state.contact_type;
		let description = (this.state.contact_types.filter(row => row.type === type));
		let prev_contacts = JSON.parse(JSON.stringify(this.state.new_contacts));//clone
		let data = {
			contact_type: type,
			contact_description: description[0].description,
			contact_id: contact.id,
			contact_name: [contact.firstname, contact.lastname].filter(Boolean).join(" ")
		}
		let index = prev_contacts.findIndex(e => e.contact_type === type);
		if (index != -1) {
			prev_contacts.splice(index, 1);
		}
		prev_contacts.push(data);
		this.setState({
			new_contacts: prev_contacts
		});
	}

	paymentConfirmConfig = {
		type: "confirm",
		btn_label: this.props.intl.formatMessage({ id: "changeowner.confirmbtn" })
	}

	columns = [{
		dataField: 'id',
		text: this.props.intl.formatMessage({ id: "app.id" }),
		headerStyle: (colum, colIndex) => {
			return { width: '40px' };
		}
	}, {
		dataField: 'df_name',
		headerFormatter: () => { return (this.props.intl.formatMessage({ id: "contact.name" })) },
		formatter: (cellContent, row) => {
			return ([row.firstname, row.lastname].filter(Boolean).join(" "))
		}
	}, {
		dataField: 'email',
		text: this.props.intl.formatMessage({ id: "app.email" }),
		classes: 'cut-words'
	}, {
		dataField: 'phonenumber',
		headerFormatter: () => { return (this.props.intl.formatMessage({ id: "contact.phone" })) },
		classes: 'cut-words'
	},
	{
		dataField: 'df1',
		isDummyField: true,
		headerStyle: (colum, colIndex) => {
			return { width: '160px', 'text-align': 'center' };
		},
		headerFormatter: () => {
			return (<Modal type={{ Formtype: "contact", ButtonLabel: this.props.intl.formatMessage({ id: "app.new" }) }} formData={{}} addContact={this.modifyContact} />);
		},
		align: 'center',
		formatter: (cellContent, row, rowIndex, formatExtraData) => {
			return (
				<div className="d-flex justify-content-center">
					<ModalContact type={{ Formtype: "contact", ButtonLabel: this.props.intl.formatMessage({ id: "app.edit" }), Icon: 'pencil square' }} formData={row} onChange={this.onChange} addContact={this.modifyContact} notify={this.notify} />
					<Button className="icon" size="small" onClick={() => this.chooseContact(row)}><FormattedMessage id="app.select" /></Button>
				</div>
			);
		},
		formatExtraData: {
			lng: this.props.language
		}
	}
	];


	handleTableChange = _.debounce((type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
		var currentIndex = (page - 1) * sizePerPage;
		if (type === 'search') {
			currentIndex = 0;
		}
		axios
			.get(`${process.env.REACT_APP_API_URL}/api/contact/filter`, {
				params: {
					currentIndex: currentIndex,
					sizePerPage: sizePerPage ? sizePerPage : this.state.sizePerPage,
					q: searchText,
					sortField: sortField,
					sortOrder: sortOrder
				}
			})
			.then((response) => {
				//this.setState({ contactlist: response.data });
				let result = response.data.dataset;
				let totalCount = response.data.totalCount;
				this.setState(() => ({
					domains: response.data.dataset,
					page,
					// data: result.slice(currentIndex, currentIndex + sizePerPage),
					contactlist: response.data.dataset,
					totalSize: totalCount,
					sizePerPage,
					currentIndex: currentIndex
				}));
			});
	}, 800);

	onContactTypeChange = (e, data) => {
		this.setState({ contact_type: data.value });
	}

	modifyContact = (data, callback) => {
		this.notify("Caricamento", "info", "pencil", false)
		axios.put(`${process.env.REACT_APP_API_URL}/api/contact/contact`, data)
			.then((response) => {
				this.notify(this.props.intl.formatMessage({ id: "changeowner.success_changed" }), "success", "pencil", true)
				this.getContacts();
				callback(response, null);
			}).catch(e => {
				this.notify(<FormattedMessage id="changeowner.error_changed" />, "error", "pencil", true)
				console.log(e.response);
				callback(null, e);
			});
	}

	render() {
		const { open, size, confirm } = this.state;
		const { formatMessage } = this.props.intl;
		const { page, sizePerPage, totalSize } = this.state;
		const sizePerPageList = [2, 4];
		const defaultSorted = [{
			dataField: 'id',
			order: 'desc'
		}];
		return (
			<div className="kx-page">
				<Header history={this.props.history} language={this.props.language} changeLanguage={this.props.changeLanguage} getUserData={this.getUserData} />

				<div className="with-nav-bar with-left-nav">


					<div className="">
						<div className="kx-left-nav">
							<LeftSidebar history={this.props.history} location={this.props.location}
								styles={{ sidebar: { background: "blue" } }} />
						</div>
					</div>

					<div className="modal-container">


						<Modal className="mini-modals" size={size} open={open} onClose={this.close}>
							<div className="details-heading">
								<Modal.Header><FormattedMessage id="app.details" /></Modal.Header>
							</div>
							<Modal.Content>
								<div className="text-left">
									<div className="row">
										<div className="col-lg-12 col-md-12 col-sm-12 info-text">
											<b><FormattedMessage id="app.registrtant" />: </b> {this.state.domaininfo.registrant ? (this.state.domaininfo.registrant.firstname + ' ' + this.state.domaininfo.registrant.lastname) : null}
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12 info-text">
											<b><FormattedMessage id="app.status" />: </b>
											{this.state.domaininfo.domainstatus}
										</div>
									</div>
									<div className="row justify-content-between">
										<div className="col-lg-12 col-md-12 col-sm-12 info-text">
											<b><FormattedMessage id="app.registered" />: </b>
											{Moment(this.state.domaininfo.registered).locale(this.props.language).format('L')}
										</div>
										<div className="col-lg-12 col-md-12 col-sm-12 info-text">
											<b><FormattedMessage id="app.expired" />: </b>
											{Moment(this.state.domaininfo.expired).locale(this.props.language).format('L')}
										</div>
									</div>
								</div>
							</Modal.Content>
							<Modal.Actions>
								<div className="cancel-button">
									<Button negative icon="crossmark" onClick={this.close}>
										<FormattedMessage id="app.close" />
									</Button>
								</div>
							</Modal.Actions>
						</Modal>
					</div>
					<div className="kx-content">

						<div className="kx-container">
							<div className="row">
								<div className="col-12">
									<Breadcrumb className="kx-breadcrumb">
										<Breadcrumb.Section link onClick={() => this.props.history.goBack()}><FormattedMessage id="app.domains" /></Breadcrumb.Section>
										<Breadcrumb.Divider icon='right chevron' />
										<Breadcrumb.Section link onClick={this.showDetails}>{this.props.location.state.domain}</Breadcrumb.Section>
										<Breadcrumb.Divider icon='right arrow' />
										<Breadcrumb.Section active><FormattedMessage id="app.change_owner" /></Breadcrumb.Section>
									</Breadcrumb>
									<div className="contact-info grid-striped">
										{this.state.new_contacts.length > 0 ?
											<div>
												<div className="contact-info-rows mb-2">
													{this.state.new_contacts.map((row, key) =>
														<div key={key} className="row no-gutters">
															<div className="col"><Icon name="delete" link={true} onClick={() => this.removeContact(row.contact_type)} /></div>
															<div className="col-3">{row.contact_description}</div>
															<div className="col-8">{row.contact_name}</div>
														</div>)}
												</div>
												<ModalConfirmPayment config={this.paymentConfirmConfig} onYes={() => this.editDomain(this.state.amount)} amount={this.state.amount} onOpen={this.onConfirmOpen} notify={this.notify} new_contacts={this.state.new_contacts}/>
											</div>
											: null}
									</div>
									<div id="textDescriptionChangeOwner"><FormattedMessage id="changeowner.description" /></div>
									<ToolkitProvider
										keyField="id"
										data={this.state.contactlist}
										columns={this.columns}
										defaultSorted={defaultSorted}
										hover
										bootstrap4
										condensed
										search
									>
										{
											toolkitprops => [
												<div className="searchBar">
													<Dropdown
														className="parnter-list"
														placeholder={formatMessage({ id: "contact.type" })}
														onChange={this.onContactTypeChange}
														name="partnerIdMov"
														clearable
														value={this.state.contact_type}
														selection
														search
														options={this.state.contact_types.map(({ type, description }) => ({ value: type, text: description }))}
													/>
													<div className="searchBtn">
														<SearchBar {...toolkitprops.searchProps} placeholder={formatMessage({ id: "table.search" })} />
														<ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
													</div>
												</div>,
												<div className='infodiv boxTabella'>
												<BootstrapTable
													{...toolkitprops.baseProps}
													classes="table-responsive-lg"
													remote={{ search: true, pagination: true, sort: true }}
													striped hover bootstrap4 condensed
													pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
													onTableChange={this.handleTableChange}
												/></div>
											]
										}
									</ToolkitProvider>
								</div>
							</div>
						</div>
					</div>
				</div>
				{
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
				<Footer />
			</div>
		);
	}
}

export default injectIntl(ChangeOwner);
