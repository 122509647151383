import React, { Component } from 'react';
import {Form, Icon, Modal, Popup} from 'semantic-ui-react';
import "./FormCreateCustomer.scss";
import axios from 'axios';
//import PhoneInput from 'react-phone-input-2'
//import 'react-phone-input-2/lib/style.css'
import { FormattedMessage, injectIntl } from 'react-intl';
import utils from '../../services/utils'


class FormCreateCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id : -1,
            userid: '',
            personalID: -1,
            personalName : "",
            email : "",
            password : "",
            password2: "",
            stateMachine : 0,
            logo: "",
            domainsChecked: 0,
            serversChecked: 0,
            firewallChecked: 0,
            servicesChecked: 0,
            clientsChecked: 0,
            hostsChecked: 0,
            ispChecked: 0,
            footer: "", 
            hidden: "none",
            iframe: "",
            inputType: "password",
            inputType2: "password"
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleLogo = this.handleLogo.bind(this);
        this.handleLogoLink = this.handleLogoLink.bind(this);
        this.handleFooter = this.handleFooter.bind(this);
    }

    loadData = async () =>{
        let personalName = this.props.formData.firstname + " " + this.props.formData.lastname;
        await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
        .then( async (response) => {
            await this.setState({
                logo: response.data.logo || "",
                email : this.props.formData.email, 
                personalName: personalName,
                personalID: response.data.id,
                userid: response.data.userid
            });
        })
        
        await axios.get(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelcontact?email=${this.props.formData.email}`)
        .then(async (response) => {
            await this.setState({
                id: response.data
            });
            if(this.state.id !== -1) {
                axios.post(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondleveltofirstleveluserid?id=${this.state.id}`)
                .then(async (response) => {
                    if(response.data == this.state.userid){
                        axios.get(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelusers?id=${this.state.id}`)
                        .then(async (response) => {
                            await this.setState({
                                    stateMachine: 1,
                                    domainsChecked: response.data.domains,
                                    serversChecked: response.data.servers,
                                    firewallChecked: response.data.firewall,
                                    servicesChecked: response.data.service,
                                    clientsChecked: response.data.clients,
                                    hostsChecked: response.data.hosts,
                                    ispChecked: response.data.isp
                                })
                        })
                    }
                })
                
            }
        })

    }


    componentDidMount(){
        this.setState({
            stateMachine: this.props.stateMachine
        })
        this.loadData();
    }

    handleInputChange(event, { name, value }) {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        this.setState({
            [name]: value
        });
    }


    secondLevelUserSignUp = async () =>{
        this.props.notify("Caricamento", "info", "user", false)
        let personalName = this.state.personalName;
        let email = this.state.email;
        let password = this.state.password;
        await axios.post(`${process.env.REACT_APP_API_URL}/api/users/secondLevelUserSignup`, {
            personalName,
            email,
            password
        }).then((response) => {
            this.setState({id : response.data.id});
            this.props.notify(<FormattedMessage id="notify.second-level-signup" />, "success", "user", true)
        }).catch((error) => {
            this.props.notify(<FormattedMessage id="notify.second-level-signup.failed" />, "error", "user", true)
        });

        await axios.post(`${process.env.REACT_APP_API_URL}/api/multitenancy/saveapikey`, {
                id: this.state.id
            }).then((response) => {
                this.setState({stateMachine : 1});
            })
    }


    toggleChange = (name) => {
        switch(name)
        {
            case "domainsChecked":
                this.setState({domainsChecked: !this.state.domainsChecked})        
                axios.patch(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelupdate?id=${this.state.id}&domains=${!this.state.domainsChecked}`)
                
                break;
            case "serversChecked":
                this.setState({serversChecked: !this.state.serversChecked})        
                axios.patch(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelupdate?id=${this.state.id}&servers=${!this.state.serversChecked}`)
                
                break;
            case "firewallChecked":
                this.setState({firewallChecked: !this.state.firewallChecked})        
                axios.patch(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelupdate?id=${this.state.id}&firewall=${!this.state.firewallChecked}`)
                
                break;
            case "servicesChecked":
                this.setState({servicesChecked: !this.state.servicesChecked})        
                axios.patch(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelupdate?id=${this.state.id}&service=${!this.state.servicesChecked}`)
                
                break;
            case "clientsChecked":
                this.setState({clientsChecked: !this.state.clientsChecked})        
                axios.patch(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelupdate?id=${this.state.id}&clients=${!this.state.clientsChecked}`)
                
                break;
            case "hostsChecked":
                this.setState({hostsChecked: !this.state.hostsChecked})        
                axios.patch(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelupdate?id=${this.state.id}&hosts=${!this.state.hostsChecked}`)
                
                break;
            case "ispChecked":
                this.setState({ispChecked: !this.state.ispChecked})        
                axios.patch(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelupdate?id=${this.state.id}&isp=${!this.state.ispChecked}`)
                
                break
        }
                
    }

    handleLogo = async (event, {value}) => {
        await this.setState({logo: value});
        await axios.patch(`${process.env.REACT_APP_API_URL}/api/users/logoupdate?id=${this.state.personalID}&logo=${this.state.logo}`)
    }

    handleLogoLink = async (event, {value}) => {
        await this.setState({logolink: value});
        await axios.patch(`${process.env.REACT_APP_API_URL}/api/users/logoupdate?id=${this.state.personalID}&logolink=${this.state.logolink}`)
    }

    handleFooter = async (event, {value}) => {
        await this.setState({footer: value});
        await axios.patch(`${process.env.REACT_APP_API_URL}/api/users/logoupdate?id=${this.state.personalID}&footer=${this.state.footer}`)
    }

    showIframe = async () => {
        let iframe = "<iframe style='position: absolute; height: 100%; border: none' src='https://konsolex.cloudonthecloud.com/login?id=" + this.state.personalID +"' display = 'block' width= '100%' allow='fullscreen'></iframe>"
        this.setState({hidden:"inline", iframe: iframe})
    }

    firstSetUp = () => {
        if(!this.state.statewritten && this.props.open){
            this.setState({
                stateMachine: this.props.stateMachine,
                statewritten: true
            })
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                statewritten: false
            })
        }
    }

    changePassword = (e, data) => {
        let valuetocheck = data.value
        data.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        this.setState({
            password: data.value
        })
    }

    changePassword2 = (e, data) => {
        let valuetocheck = data.value
        data.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        this.setState({
            password2: data.value
        })
    }

    commitChangePassword = async() => {
        this.props.notify("Caricamento", "info", "pencil", false)
        if(this.state.password == this.state.password2){
            await axios.post(`${process.env.REACT_APP_API_URL}/api/users/secondLevelChangePassword`, {
                password: this.state.password,
                id: this.state.id
            }).then((response) => {
                this.props.notify(<FormattedMessage id="notify.change-password-2level" />, "success", "pencil", true)
            }).catch((error) => {
                this.props.notify(<FormattedMessage id="notify.change-password-2level.failed" />, "error", "pencil", true)
            });
        }
    }

    switchPassword = () => {
        if(this.state.inputType == "password") {
            this.setState({inputType: "text"})
        } else {
            this.setState({inputType: "password"})
        }
    }

    switchPassword2 = () => {
        if(this.state.inputType2 == "password") {
            this.setState({inputType2: "text"})
        } else {
            this.setState({inputType2: "password"})
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const isAdmin = (localStorage.getItem('isAdmin') === "true");
        this.firstSetUp();
        return (
            <div>{ this.state.stateMachine === 0 ?
                <div>
                    <Form autoComplete="off">
                            <Form.Input label= {formatMessage({ id: "app.nameandsurname" })} value={this.state.personalName || ''} name="nameandsurname" />
                            <Form.Input label= {formatMessage({ id: "app.email" })} value={this.state.email || ''} name="email" />
                            <Form.Group className='formGeneratePassword'>
                                <Form.Input autoComplete="new-password" type={this.state.inputType} icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword()} />} iconPosition='right' label= {formatMessage({ id: "app.password" })} value={this.state.password || ''} name="password" placeholder="Password" onChange={this.handleInputChange} />
                                <Form.Button className="buttonGeneratePassword" onClick={() => this.setState({ password: utils.generatePassword()})}>Genera Password</Form.Button>
                            </Form.Group>
                        
                        <Form.Group  style = {{ display: "block", "margin-left": "auto", "margin-right": "auto"}}>
                            <Form.Button floated="center" onClick={this.secondLevelUserSignUp} width={isAdmin ? 8 : 16} className="save-button">
                                {formatMessage({ id: "app.create" })}
                            </Form.Button>
                        </Form.Group>
                    </Form>
                </div>
                : null}
                {this.state.stateMachine === 1 ? <div>
                    <div style = {{display: "block", "margin-left": "auto", "margin-right": "auto", 'text-align':'center'}}>
                        <Form.Group style = {{"align-items": "center", "justify-content" : "left", "flex-direction": "row", "vertical-align": "middle", 'text-align':'center' }}>
                            <div className = "row">
                                <div className = "col"/>
                                <div className = "col-5">
                                    <label style = {{ "padding-left": "0", "padding-right": "2rem", "padding-bottom": "1rem" }} floated = "left" > <strong>Domini</strong> </label> <br/>
                                </div>
                                <div className = "col-3">
                                    <Form.Checkbox className="to-bottom"  style = {{transform: "scale(0.7)", "padding-bottom" : "0.65rem"}} floated = "right" toggle name="domain" checked={this.state.domainsChecked} onChange={() => this.toggleChange("domainsChecked")} />
                                </div>
                                <div className = "col"/>
                            </div>
                            <div className = "row">
                                <div className = "col"/>
                                <div className = "col-5">
                                    <label style = {{ "padding-left": "0", "padding-right": "2rem", "padding-bottom": "1rem" }} floated = "left" > <strong>Server</strong> </label> <br/>
                                    </div>
                                <div className = "col-3">
                                <Form.Checkbox className="to-bottom"  style = {{transform: "scale(0.7)", "padding-bottom" : "0.65rem"}} floated = "right" toggle name="servers" checked={this.state.serversChecked} onChange={() => this.toggleChange("serversChecked")} />
                                </div>
                                <div className = "col"/>
                            </div>
                            {/*<div className = "row">
                                <div className = "col"/>
                                <div className = "col-5">
                                    <label style = {{ "padding-left": "0", "padding-right": "2rem", "padding-bottom": "1rem" }} floated = "left" > <strong> {formatMessage({ id: "dash.firewalls" })}</strong> </label> <br/>
                                </div>
                                <div className = "col-3">
                                    <Form.Checkbox className="to-bottom"  style = {{transform: "scale(0.7)", "padding-bottom" : "0.65rem"}} floated = "right" toggle name="firewall" checked={this.state.firewallChecked} onChange={() => this.toggleChange("firewallChecked")} />
                                </div>
                                <div className = "col"/>
                            </div>
                            <div className = "row">
                                <div className = "col"/>
                                <div className = "col-5">
                                    <label style = {{ "padding-left": "0", "padding-right": "2rem", "padding-bottom": "1rem" }} floated = "left" > <strong> {formatMessage({ id: "dash.servizi" })}</strong> </label> <br/>
                                </div>
                                <div className = "col-3">
                                    <Form.Checkbox className="to-bottom"  style = {{transform: "scale(0.7)", "padding-bottom" : "0.65rem"}} floated = "right" toggle name="services" checked={this.state.servicesChecked} onChange={() => this.toggleChange("servicesChecked")} />
                                </div>
                                <div className = "col"/>
                            </div>*/}
                            <div className = "row">
                                <div className = "col"/>
                                <div className = "col-5">
                                    <label style = {{ "padding-left": "0", "padding-right": "2rem", "padding-bottom": "1rem" }} floated = "left" > <strong> {formatMessage({ id: "dash.clienti" })}</strong> </label> <br/>
                                </div>
                                <div className = "col-3">
                                    <Form.Checkbox className="to-bottom"  style = {{transform: "scale(0.7)", "padding-bottom" : "0.65rem"}} floated = "right" toggle name="clients" checked={this.state.clientsChecked} onChange={() => this.toggleChange("clientsChecked")} />
                                </div>
                                <div className = "col"/>
                            </div>
                            <div className = "row">
                                <div className = "col"/>
                                <div className = "col-5">
                                    <label style = {{ "padding-left": "0", "padding-right": "2rem", "padding-bottom": "1rem" }} floated = "left" > <strong> {formatMessage({ id: "dash.hosts" })}</strong> </label> <br/>
                                </div>
                                <div className = "col-3">
                                    <Form.Checkbox className="to-bottom"  style = {{transform: "scale(0.7)", "padding-bottom" : "0.65rem"}} floated = "right" toggle name="hosts" checked={this.state.hostsChecked} onChange={() => this.toggleChange("hostsChecked")} />
                                </div>
                                <div className = "col"/>
                            </div>
                        </Form.Group>
                    </div>
                    
                    <div style = {{display: "block", "margin-left": "auto", "margin-right": "auto", 'text-align':'center'}}>
                    <Form.Input style = {{display:this.state.hidden}} value={this.state.iframe || ''} name="iframe"/>
                        <Form.Group style = {{display: "inline-flex", "margin-left": "auto", "margin-right": "auto"}}>
                            <Form.Button className="save-button" onClick={this.showIframe}>
                            <a style={{color:"white"}} data-title={formatMessage({id: "contact.iframetip"})}>
                                    {formatMessage({ id: "contact.iframe" })}</a>
                            </Form.Button>
                            <Form.Button className="save-button" style={{width:"170px"}} onClick={() => this.setState({stateMachine: 2})}>
                                {formatMessage({ id: "app.change_password" })}
                            </Form.Button>                        
                        </Form.Group>
                        <Form.Group>
                            <label style = {{"font-size" : "small", "padding-bottom": "5px"}}><strong> <h9> {formatMessage({ id: "contact.logo" })} <Popup className="toolt" content={formatMessage({id: "tip.logo"})} position="right center" trigger={<Icon name='question' style = {{color: "#4284c4", "font-size" : "10px", "margin-left" : "-3%"}}></Icon>} /> </h9></strong></label>
                            <Form.Input style = {{"border-width" : "2px", "border-style" : "groove", "border-radius" : "5px"}} value={this.state.logo || ''} name="logo" placeholder={this.state.logo} onChange={this.handleLogo} />
                        </Form.Group>
                        {this.state.logo === "" ? "" :<img src={this.state.logo} alt="logo" style = {{"max-height" : "60px", "max-width" : "200px", "padding-top": "5px", "margin-left": "auto", "margin-right": "auto", "display": "block"}}  /> }
                        <Form.Group style = {{display: "block", "margin-left": "auto", "margin-right": "auto"}}>
                            <label style = {{"font-size" : "small", "padding-bottom": "5px"}}><strong> <h9> Redirect Link: <Popup className="toolt" content={formatMessage({id: "tip.logolink"})} position="right center" trigger={<Icon name='question' style = {{color: "#4284c4", "font-size" : "10px", "margin-left" : "-3%"}}></Icon>} />  </h9></strong></label>
                            <Form.Input style = {{"border-width" : "2px", "border-style" : "groove", "border-radius" : "5px"}} value={this.state.logolink || ''} name="logolink" placeholder={this.state.logolink} onChange={this.handleLogoLink} />
                        </Form.Group>
                        <Form.Group style = {{display: "block", "margin-left": "auto", "margin-right": "auto"}}>
                        <label style = {{"font-size" : "small", "padding-bottom": "5px"}}><strong> <h9> Footer: <Popup className="toolt" content={formatMessage({id: "tip.footer"})} position="right center" trigger={<Icon name='question' style = {{color: "#4284c4", "font-size" : "10px", "margin-left" : "-3%"}}></Icon>} /> </h9></strong></label>
                            <Form.Input style = {{"border-width" : "2px", "border-style" : "groove", "border-radius" : "5px"}} value={this.state.footer || ''} name="footer" placeholder={this.state.footer} onChange={this.handleFooter} />
                        </Form.Group>
                        <Form.Group  style = {{display: "block", "margin-left": "auto", "margin-right": "auto", "margin-top":"3%"}}>
                            <Form.Button  width={isAdmin ? 8 : 16} className="save-button" onClick={this.props.closeMe}>
                                {formatMessage({ id: "app.save" })}
                            </Form.Button>
                        </Form.Group>
                    </div>
                </div>
                : null}
                { this.state.stateMachine === 2 ? 
                    <div id="changeUserPassword">
                        <label style = {{"font-size" : "small", "padding-bottom": "5px"}}><strong> <h9> Password: </h9></strong></label>
                        <Form>
                        <Form.Input
                            style = {{"border-width" : "2px", "border-style" : "groove", "border-radius" : "5px"}}
                            type={this.state.inputType}
                            name="password"
                            icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword()} />} iconPosition='right'
                            value={this.state.password || ""}
                            onChange={this.changePassword}
                            placeholder="Password"
                            autoComplete="do-not-autofill"
                            id="field2"
                        />
                        <label style = {{"font-size" : "small", "padding-bottom": "5px"}}><strong> <h9> {formatMessage({id: "app.conferm_password"})}: </h9></strong></label>
                        <Form.Input
                            style = {{"border-width" : "2px", "border-style" : "groove", "border-radius" : "5px"}}
                            type={this.state.inputType2}
                            icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword2()} />} iconPosition='right'
                            name="password"
                            value={this.state.password2 || ""}
                            onChange={this.changePassword2}
                            placeholder="Confirm Password"
                            autoComplete="do-not-autofill"
                            id="field3"
                        /> 
                        </Form>
                        <div id="pulsantiChange">
                            <Form.Button icon="arrow left" id="frecciaIndietro" onClick={(e) => this.setState({stateMachine: 1})}>
                                
                            </Form.Button>  
                            <Form.Button style={{"margin-bottom":"3%"}} onClick={() => this.commitChangePassword()} className="save-button">
                                {formatMessage({ id: "app.change_password" })}
                            </Form.Button> 
                        </div>     
                    </div>
                :null}
            </div>
        );
    }
}

export default injectIntl(FormCreateCustomer);
