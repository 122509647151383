import React, { Component } from 'react';
import './console.scss';

import axios from 'axios';
import jwt_decode from 'jwt-decode';

import LeftSidebar from '../sidebar/left-sidebar'
import Header from '../header/header';
import List from '../list/list';
import Pagination from '../pagination/pagination';
import Footer from '../footer/footer';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Dropdown, Dimmer, Loader } from 'semantic-ui-react';
import Modal from '../All/ModalWithoutButton';
import ModalConfirmRenew from '../All/ModalConfirmRenew';
import NotifyComponent from '../notify/notifyComponent';


const { SearchBar, ClearSearchButton } = Search;


//import HttpService from "../../services/http-service";

//const http = new HttpService();
class Console extends Component {
	constructor(props) {
		super(props);
		this.token = localStorage.getItem('jwt');
		axios.defaults.headers.common['x-auth-token'] = this.token;
		this.isAdmin = (localStorage.getItem('isAdmin') === "true");;
		this.updateOffset = this.updateOffset.bind(this);
		this.state = {
			domains: [],
			offset: 0,
			page: 1,
			totalSize: 0,
			sizePerPage: 10,
			currentIndex: 0,
			partners: [],
			partnerId: '',
			nsOpened: false,
			renewOpened: false,
			selectedDomain: {},
			renewDomainInfo: {},
			balance: 0,
			loading: true,
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
		};
		if (this.token) {
			if (this.isAdmin) {
				this.loadUsers();
			}
		}
		/* columns definition */
		this.columns = [{
			dataField: 'id',
			text: this.props.intl.formatMessage({ id: "app.id" }),
			headerStyle: (colum, colIndex) => {
				return { width: '50px', textAlign: 'center' };
			},
			sort: true,
			headerSortingStyle: {
				backgroundColor: '#f8f8f8',
				borderBottomColor: '#c1c1c1'
			}
		}, {
			dataField: 'name',
			text: this.props.intl.formatMessage({ id: "app.domain" }),
			sort: true,
			headerSortingStyle: {
				backgroundColor: '#f8f8f8',
				borderBottomColor: '#c1c1c1'
			}
		}, {
			dataField: 'registered',
			text: this.props.intl.formatMessage({ id: "domain.registered" }),
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '100px', textAlign: 'center' };
			},
			formatter: (cellContent, row) => {
				if (cellContent) {
					return (<FormattedDate value={cellContent} year='numeric' month='2-digit' day='2-digit' />)
				}
			}
		}, {
			dataField: 'expires',
			text: this.props.intl.formatMessage({ id: "domain.expiry" }),
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '100px', textAlign: 'center' };
			},
			formatter: (cellContent, row) => {
				var extraCSS = '';
				if (row.alert === 'RENEW') {
					var extraCSS = 'text-danger';
				}
				if (cellContent) {
					return (<span className={extraCSS}><FormattedDate value={cellContent} year='numeric' month='2-digit' day='2-digit' /></span>)
				}
			},
			headerSortingStyle: {
				backgroundColor: '#ffffff',
				borderBottomColor: '#c1c1c1'
			}
		}, {
			dataField: 'domainstatus',
			text: this.props.intl.formatMessage({ id: "domain.status" }),
		}];
		if (this.isAdmin) {
			this.columns.push({
				dataField: 'user.name',
				text: this.props.intl.formatMessage({ id: "app.partner" }),
			});
		}
		this.columns.push({
			dataField: '',
			text: '',
			headerStyle: (colum, colIndex) => {
				return { width: '40px' };
			},
			formatter: (val, row) => {
				return (
					<Dropdown icon='setting' direction='left' button className='icon'>
						<Dropdown.Menu className="xxx-menu-right">
							{row.alert === 'RENEW' ? <Dropdown.Item text={this.props.intl.formatMessage({ id: 'app.renew' })} onClick={() => this.payRenewDomain(row)} /> : null}
							<Dropdown.Item text='DNS' onClick={() => this.domaindns(row)} />
							<Dropdown.Item text={this.props.intl.formatMessage({ id: 'app.change_owner' })} onClick={() => this.changeOwner(row)} />
							<Dropdown.Item text={this.props.intl.formatMessage({ id: 'app.change_ns' })} onClick={() => this.changeNS(row)} />
							<Dropdown.Divider />
							<Dropdown.Item text='Else...' />
						</Dropdown.Menu>
					</Dropdown>);
			}
		});
	}

	componentDidMount() {
		this.loadDomains();
	}

	updateOffset(direction) {
		if (direction === 'up') {
			this.setState({ offset: this.state.offset + 8 });
		} else {
			this.setState({ offset: this.state.offset - 8 });
		}
	}

	componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }

	domaindns = (domain) => {
		this.props.history.push("domaindns", { domainid: domain.id, domain: domain.name, language: this.props.language })
	}

	changeOwner = (domain) => {
		this.props.history.push("changeowner", { domainid: domain.id, domain: domain.name, language: this.props.language })
	}

	/* renew management */
	payRenewDomain = (domain) => {
		this.setState({ renewDomainInfo: domain, renewOpened: true });
	}
	renewConfirmConfig = {
		type: "confirm",
		color: "blue",
		btn_label: this.props.intl.formatMessage({ id: "app.pay_from_account" }),
		btn_size: 'normal',
		hideTrigger: true
	}
	onConfirmOpen = () => {
		return {
			balance: this.state.balance,
			name: this.props.intl.formatMessage({ id: "app.renew" }),
			description: this.state.renewDomainInfo.name
		};
	}
	doRenewDomian = (domain, duration) => {
		this.notify("Caricamento", "info", "undo", false)
		axios.post(`${process.env.REACT_APP_API_URL}/api/domains/renew`, { domain: domain.name, duration: duration })
			.then((response) => {
				this.setState({ amount: response.data.amount });
				//this.notify(`Domain ${domain.name} has been renewed`, 'success');
				this.notify(this.props.intl.formatMessage({ id: "app.success_renew" }, { domain: domain.name }), 'success', 'undo', true);
			})
			.catch((error) => {
				console.log(error)
				//this.notify('Problem with domain renew', 'danger');
				this.notify(this.props.intl.formatMessage({ id: "app.err_renew" }), 'error', 'undo', true);
			});
		this.onRenewClose();
	}
	onRenewClose = () => this.setState({ renewOpened: false });
	/* end renew management */

	changeNS = (domain) => {
		this.setState({ selectedDomain: domain, nsOpened: true });
	}

	loadDomains = (params) => {
		this.setState({ loading: true });
		//const token = localStorage.getItem('jwt');
		//const decoded = jwt_decode(token);
		axios
			.get(`${process.env.REACT_APP_API_URL}/api/domains`, {
				params: {
					currentIndex: this.state.currentIndex,
					sizePerPage: this.state.sizePerPage,
					partnerId: params ? params.partnerId : null
				}
			})
			.then((response) => {
				this.setState({
					domains: response.data.dataset,
					totalSize: response.data.totalCount,
					loading: false
				});
			})
			.catch(function (error) {
				this.setState({ loading: false });
			});
	};


	loadUsers = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
			.then((response) => {
				this.setState({
					partners: response.data
				});
			})
			.catch((error) => {
                console.log(error)
            });
	}

	onPartnerChange = (e, data) => {
		this.setState({ partnerId: data.value });
		this.loadDomains({ partnerId: data.value });
	}

	handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
		var currentIndex = (page - 1) * sizePerPage;
		if (type === 'search') {
			currentIndex = 0;
		}
		this.setState({ loading: true });
		axios
			.get(`${process.env.REACT_APP_API_URL}/api/domains`, {
				params: {
					currentIndex: currentIndex,
					sizePerPage: sizePerPage ? sizePerPage : this.state.sizePerPage,
					q: searchText,
					sortField: sortField,
					sortOrder: sortOrder,
					partnerId: this.state.partnerId ? this.state.partnerId : null
				}
			})
			.then((response) => {
				let result = response.data.dataset;
				let totalCount = response.data.totalCount;
				this.setState(() => ({
					domains: response.data.dataset,
					page,
					data: result.slice(currentIndex, currentIndex + sizePerPage),
					totalSize: totalCount,
					sizePerPage,
					currentIndex: currentIndex,
					loading: false
				}));
			});
	}

	onNsClose = () => this.setState({ nsOpened: false });

	getUserData = (userdata) => {
		this.setState({ balance: userdata.balance });
	}


	render() {
		const { page, sizePerPage, totalSize, loading } = this.state;
		const sizePerPageList = [5, 10, 15];
		const defaultSorted = [{
			dataField: 'id',
			order: 'desc'
		}];
		const isAdmin = (localStorage.getItem('isAdmin') === "true");
		const { formatMessage } = this.props.intl;
		return (
			<div className="kx-page">
				{loading ?
					<Dimmer className="background-loader" active inverted>
						<img className="loadergif" src={"/loader.gif"} />
					</Dimmer>

					: null}


				<Header history={this.props.history} language={this.props.language} changeLanguage={this.props.changeLanguage} getUserData={this.getUserData} />


				<div className="with-nav-bar with-left-nav">


					<div className="">
						<div className="kx-left-nav">
							<LeftSidebar history={this.props.history} location={this.props.location}
								styles={{ sidebar: { background: "blue" } }} />
						</div>
					</div>

					<div className="kx-content">

						<div className="kx-container">
							<Modal selectedDomain={this.state.selectedDomain} type={{ Formtype: "server", ButtonLabel: this.props.intl.formatMessage({ id: "app.edit" }) }} notify={this.notify} open={this.state.nsOpened} onClose={this.onNsClose} />
							<ModalConfirmRenew renewDomainInfo={this.state.renewDomainInfo} config={this.renewConfirmConfig} onYes={(domain, duration) => this.doRenewDomian(domain, duration)} init={this.onConfirmOpen} notify={this.notify} open={this.state.renewOpened} onClose={this.onRenewClose} />

							<div className="row">
								{/* <FormattedMessage id="app.confirm_delete_text" />
						{formatMessage({ id: "dns.record_type" }) */}

								<div className="col-12">
									{/* Domain Table */}
									{/* <BootstrapTable keyField='id'
								remote
								data={this.state.domains}
								columns={this.columns}
								striped hover
								pagination={paginationFactory({ page, sizePerPage, totalSize })}
								onTableChange={this.handleTableChange}
							/> */}


									<div className="row margin-row-300">
										<div className="col-12">
											<div className="text-center">
												<img src={'/ricerca_icona_grigia.png'} />
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4"></div>
										<div className="col-12 col-md-6 ">
											<List
												history={this.props.history}
												reload={this.loadDomains}
												domains={this.state.domains.slice(0 + this.state.offset, 8 + this.state.offset)}
												loadData={this.loadDomains}
												notify={this.notify}
											/>
										</div>
									</div>

									{/*<ToolkitProvider
										keyField="id"
										data={this.state.domains}
										columns={this.columns}
										defaultSorted={defaultSorted}
										bootstrap4
										search
									>*/}
									{
										toolkitprops => [
											<SearchBar delay={1500} {...toolkitprops.searchProps} placeholder={formatMessage({ id: "table.search" })} />,
											<ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />,

											...(isAdmin ?
												[<Dropdown
													className="parnter-list"
													placeholder="Partner"
													onChange={this.onPartnerChange}
													name="partnerId"
													clearable
													selection
													search
													options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
												/>] : []),
											<div className='info-div'>
											<BootstrapTable
												{...toolkitprops.baseProps}
												class="table-responsive-lg"
												remote={{ search: true, pagination: true, sort: true }}
												striped hover
												pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
												onTableChange={this.handleTableChange}
											/></div>
										]
									}
									{/*</ToolkitProvider>*/}

								</div>
							</div>
						</div>

					</div>
					{/* <Pagination
						reload={this.loadData}
						domainsLength={this.state.domains.length}
						updateOffset={this.updateOffset}
					/> */}

				</div>
				{
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
				<Footer />
			</div>
		);
	}
}

export default injectIntl(Console);
