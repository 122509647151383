import React, { Component } from 'react';
import axios from 'axios';
import { Route, Redirect, Switch } from "react-router-dom";
import { Form, Modal, Dropdown, Input, Checkbox, Select, Icon } from 'semantic-ui-react';
import PhoneInput from 'react-phone-input-2'
import { FormattedMessage, injectIntl } from 'react-intl';
import utils from '../../services/utils'

class ModalSignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contacttype : "company",
            fiscalcode: "",
            piva: "", 
            firstname: "",
            lastname: "",
            companyname: "",
            email: "", //must contain: blank@blank.blank
            phonenumber: "", // 7 - 10 chars
            address: "", 
            city: "",
            countrycode: "", //take it from country
            postalcode: "",
            countrystate: "", 
            placeofbirth: "",
            typeofmail: "pec", //sdi / pec
            validmail: false,
            validcompanymail: false,
            allvalid: false,
            password: "",
            password2: "",
            companymail: "",
            terms: false,
            privacy: false,
            inputType: "password",
            inputType2: "password"
        }
        this.getProvinces();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleMailChange = this.handleMailChange.bind(this);
        this.handleCompanyMailChange = this.handleCompanyMailChange.bind(this);
    }
    clientTypes = [
        {
            key: 'private',
            text: 'Private',
            value: 'private'
        },
        {
            key: 'company',
            text: 'Company',
            value: 'company'
        }];

    typeofmail = [
        {
            key: 'pec',
            text: 'PEC',
            value: 'pec'
        },
        {
            key: 'sdi',
            text: 'SDI',
            value: 'sdi'
        }];
    
    countryOptions = [
        { key: "af", value: "af", flag: "af", text: "Afghanistan" },
        { key: "ax", value: "ax", flag: "ax", text: "Åland" },
        { key: "al", value: "al", flag: "al", text: "Albania" },
        { key: "dz", value: "dz", flag: "dz", text: "Algeria" },
        { key: "as", value: "as", flag: "as", text: "American Samoa" },
        { key: "ad", value: "ad", flag: "ad", text: "Andorra" },
        { key: "ao", value: "ao", flag: "ao", text: "Angola" },
        { key: "ai", value: "ai", flag: "ai", text: "Anguilla" },
        { key: "ag", value: "ag", flag: "ag", text: "Antigua and Barbuda" },
        { key: "ar", value: "ar", flag: "ar", text: "Argentina" },
        { key: "am", value: "am", flag: "am", text: "Armenia" },
        { key: "aw", value: "aw", flag: "aw", text: "Aruba" },
        { key: "au", value: "au", flag: "au", text: "Australia" },
        { key: "at", value: "at", flag: "at", text: "Austria" },
        { key: "az", value: "az", flag: "az", text: "Azerbaijan" },
        { key: "bs", value: "bs", flag: "bs", text: "Bahamas" },
        { key: "bh", value: "bh", flag: "bh", text: "Bahrain" },
        { key: "bd", value: "bd", flag: "bd", text: "Bangladesh" },
        { key: "bb", value: "bb", flag: "bb", text: "Barbados" },
        { key: "by", value: "by", flag: "by", text: "Belarus" },
        { key: "be", value: "be", flag: "be", text: "Belgium" },
        { key: "bz", value: "bz", flag: "bz", text: "Belize" },
        { key: "bj", value: "bj", flag: "bj", text: "Benin" },
        { key: "bm", value: "bm", flag: "bm", text: "Bermuda" },
        { key: "bt", value: "bt", flag: "bt", text: "Bhutan" },
        { key: "bo", value: "bo", flag: "bo", text: "Bolivia" },
        { key: "ba", value: "ba", flag: "ba", text: "Bosnia and Herzegovina" },
        { key: "bw", value: "bw", flag: "bw", text: "Botswana" },
        { key: "bv", value: "bv", flag: "bv", text: "Bouvet Island" },
        { key: "br", value: "br", flag: "br", text: "Brazil" },
        { key: "io", value: "io", flag: "io", text: "British Indian Ocean Territory" },
        { key: "bn", value: "bn", flag: "bn", text: "Brunei" },
        { key: "bg", value: "bg", flag: "bg", text: "Bulgaria" },
        { key: "bf", value: "bf", flag: "bf", text: "Burkina Faso" },
        { key: "bi", value: "bi", flag: "bi", text: "Burundi" },
        { key: "cv", value: "cv", flag: "cv", text: "Cape Verde" },
        { key: "kh", value: "kh", flag: "kh", text: "Cambodia" },
        { key: "cm", value: "cm", flag: "cm", text: "Cameroon" },
        { key: "ca", value: "ca", flag: "ca", text: "Canada" },
        { key: "ky", value: "ky", flag: "ky", text: "Cayman Islands" },
        { key: "cf", value: "cf", flag: "cf", text: "Central African Republic" },
        { key: "td", value: "td", flag: "td", text: "Chad" },
        { key: "cl", value: "cl", flag: "cl", text: "Chile" },
        { key: "cn", value: "cn", flag: "cn", text: "China" },
        { key: "cx", value: "cx", flag: "cx", text: "Christmas Island" },
        { key: "cc", value: "cc", flag: "cc", text: "Cocos Islands" },
        { key: "co", value: "co", flag: "co", text: "Colombia" },
        { key: "km", value: "km", flag: "km", text: "Comoros" },
        { key: "cd", value: "cd", flag: "cd", text: "Congo" },
        { key: "cg", value: "cg", flag: "cg", text: "Congo Brazzaville" },
        { key: "ck", value: "ck", flag: "ck", text: "Cook Islands" },
        { key: "cr", value: "cr", flag: "cr", text: "Costa Rica" },
        { key: "ci", value: "ci", flag: "ci", text: "Côte d'Ivoire" },
        { key: "hr", value: "hr", flag: "hr", text: "Croatia" },
        { key: "cu", value: "cu", flag: "cu", text: "Cuba" },
        { key: "cy", value: "cy", flag: "cy", text: "Cyprus" },
        { key: "cz", value: "cz", flag: "cz", text: "Czech Republic" },
        { key: "dk", value: "dk", flag: "dk", text: "Denmark" },
        { key: "dj", value: "dj", flag: "dj", text: "Djibouti" },
        { key: "dm", value: "dm", flag: "dm", text: "Dominica" },
        { key: "do", value: "do", flag: "do", text: "Dominican Republic" },
        { key: "ec", value: "ec", flag: "ec", text: "Ecuador" },
        { key: "eg", value: "eg", flag: "eg", text: "Egypt" },
        { key: "sv", value: "sv", flag: "sv", text: "El Salvador" },
        { key: "gq", value: "gq", flag: "gq", text: "Equatorial Guinea" },
        { key: "er", value: "er", flag: "er", text: "Eritrea" },
        { key: "ee", value: "ee", flag: "ee", text: "Estonia" },
        { key: "sz", value: "sz", flag: "sz", text: "Eswatini" },
        { key: "et", value: "et", flag: "et", text: "Ethiopia" },
        { key: "fk", value: "fk", flag: "fk", text: "Falkland Islands" },
        { key: "fo", value: "fo", flag: "fo", text: "Faroe Islands" },
        { key: "fj", value: "fj", flag: "fj", text: "Fiji" },
        { key: "fi", value: "fi", flag: "fi", text: "Finland" },
        { key: "fr", value: "fr", flag: "fr", text: "France" },
        { key: "gf", value: "gf", flag: "gf", text: "French Guyane" },
        { key: "pf", value: "pf", flag: "pf", text: "French Polynesia" },
        { key: "tf", value: "tf", flag: "tf", text: "French Territories" },
        { key: "ga", value: "ga", flag: "ga", text: "Gabon" },
        { key: "gm", value: "gm", flag: "gm", text: "Gambia" },
        { key: "ge", value: "ge", flag: "ge", text: "Georgia" },
        { key: "de", value: "de", flag: "de", text: "Germany" },
        { key: "gh", value: "gh", flag: "gh", text: "Ghana" },
        { key: "gi", value: "gi", flag: "gi", text: "Gibraltar" },
        { key: "gr", value: "gr", flag: "gr", text: "Greece" },
        { key: "gl", value: "gl", flag: "gl", text: "Greenland" },
        { key: "gd", value: "gd", flag: "gd", text: "Grenada" },
        { key: "gp", value: "gp", flag: "gp", text: "Guadeloupe" },
        { key: "gu", value: "gu", flag: "gu", text: "Guam" },
        { key: "gt", value: "gt", flag: "gt", text: "Guatemala" },
        { key: "gn", value: "gn", flag: "gn", text: "Guinea" },
        { key: "gw", value: "gw", flag: "gw", text: "Guinea-Bissau" },
        { key: "gy", value: "gy", flag: "gy", text: "Republic of Guyana" },
        { key: "ht", value: "ht", flag: "ht", text: "Haiti" },
        { key: "hm", value: "hm", flag: "hm", text: "Heard Island" },
        { key: "hn", value: "hn", flag: "hn", text: "Honduras" },
        { key: "hk", value: "hk", flag: "hk", text: "Hong Kong" },
        { key: "hu", value: "hu", flag: "hu", text: "Hungary" },
        { key: "is", value: "is", flag: "is", text: "Iceland" },
        { key: "in", value: "in", flag: "in", text: "India" },
        { key: "id", value: "id", flag: "id", text: "Indonesia" },
        { key: "ir", value: "ir", flag: "ir", text: "Iran" },
        { key: "iq", value: "iq", flag: "iq", text: "Iraq" },
        { key: "ie", value: "ie", flag: "ie", text: "Ireland" },
        { key: "il", value: "il", flag: "il", text: "Israel" },
        { key: "it", value: "it", flag: "it", text: "Italy" },
        { key: "jm", value: "jm", flag: "jm", text: "Jamaica" },
        { key: "jp", value: "jp", flag: "jp", text: "Japan" },
        { key: "jo", value: "jo", flag: "jo", text: "Jordan" },
        { key: "kz", value: "kz", flag: "kz", text: "Kazakhstan" },
        { key: "ke", value: "ke", flag: "ke", text: "Kenya" },
        { key: "ki", value: "ki", flag: "ki", text: "Kiribati" },
        { key: "kp", value: "kp", flag: "kp", text: "North Korea" },
        { key: "kr", value: "kr", flag: "kr", text: "South Korea" },
        { key: "kw", value: "kw", flag: "kw", text: "Kuwait" },
        { key: "kg", value: "kg", flag: "kg", text: "Kyrgyzstan" },
        { key: "la", value: "la", flag: "la", text: "Laos" },
        { key: "lv", value: "lv", flag: "lv", text: "Latvia" },
        { key: "lb", value: "lb", flag: "lb", text: "Lebanon" },
        { key: "ls", value: "ls", flag: "ls", text: "Lesotho" },
        { key: "lr", value: "lr", flag: "lr", text: "Liberia" },
        { key: "ly", value: "ly", flag: "ly", text: "Libya" },
        { key: "li", value: "li", flag: "li", text: "Liechtenstein" },
        { key: "lt", value: "lt", flag: "lt", text: "Lithuania" },
        { key: "lu", value: "lu", flag: "lu", text: "Luxembourg" },
        { key: "mo", value: "mo", flag: "mo", text: "Macao" },
        { key: "mk", value: "mk", flag: "mk", text: "Macedonia" },
        { key: "mg", value: "mg", flag: "mg", text: "Madagascar" },
        { key: "mw", value: "mw", flag: "mw", text: "Malawi" },
        { key: "my", value: "my", flag: "my", text: "Malaysia" },
        { key: "mv", value: "mv", flag: "mv", text: "Maldives" },
        { key: "ml", value: "ml", flag: "ml", text: "Mali" },
        { key: "mt", value: "mt", flag: "mt", text: "Malta" },
        { key: "mh", value: "mh", flag: "mh", text: "Marshall Islands" },
        { key: "mq", value: "mq", flag: "mq", text: "Martinique" },
        { key: "mr", value: "mr", flag: "mr", text: "Mauritania" },
        { key: "mu", value: "mu", flag: "mu", text: "Mauritius" },
        { key: "yt", value: "yt", flag: "yt", text: "Mayotte" },
        { key: "mx", value: "mx", flag: "mx", text: "Mexico" },
        { key: "fm", value: "fm", flag: "fm", text: "Micronesia" },
        { key: "md", value: "md", flag: "md", text: "Moldova" },
        { key: "mc", value: "mc", flag: "mc", text: "Monaco" },
        { key: "mn", value: "mn", flag: "mn", text: "Mongolia" },
        { key: "me", value: "me", flag: "me", text: "Montenegro" },
        { key: "ms", value: "ms", flag: "ms", text: "Montserrat" },
        { key: "ma", value: "ma", flag: "ma", text: "Morocco" },
        { key: "mz", value: "mz", flag: "mz", text: "Mozambique" },
        { key: "mm", value: "mm", flag: "mm", text: "Myanmar" },
        { key: "na", value: "na", flag: "na", text: "Namibia" },
        { key: "nr", value: "nr", flag: "nr", text: "Nauru" },
        { key: "np", value: "np", flag: "np", text: "Nepal" },
        { key: "nl", value: "nl", flag: "nl", text: "Netherlands" },
        { key: "nc", value: "nc", flag: "nc", text: "New Caledonia" },
        { key: "nz", value: "nz", flag: "nz", text: "New Zealand" },
        { key: "ni", value: "ni", flag: "ni", text: "Nicaragua" },
        { key: "ne", value: "ne", flag: "ne", text: "Niger" },
        { key: "ng", value: "ng", flag: "ng", text: "Nigeria" },
        { key: "nu", value: "nu", flag: "nu", text: "Niue" },
        { key: "nf", value: "nf", flag: "nf", text: "Norfolk Island" },
        { key: "mp", value: "mp", flag: "mp", text: "Mariana Islands" },
        { key: "no", value: "no", flag: "no", text: "Norway" },
        { key: "om", value: "om", flag: "om", text: "Oman" },
        { key: "pk", value: "pk", flag: "pk", text: "Pakistan" },
        { key: "pw", value: "pw", flag: "pw", text: "Palau" },
        { key: "ps", value: "ps", flag: "ps", text: "Palestine" },
        { key: "pa", value: "pa", flag: "pa", text: "Panamá" },
        { key: "pg", value: "pg", flag: "pg", text: "Papua New Guinea" },
        { key: "py", value: "py", flag: "py", text: "Paraguay" },
        { key: "pe", value: "pe", flag: "pe", text: "Perú" },
        { key: "ph", value: "ph", flag: "ph", text: "Philippines" },
        { key: "pn", value: "pn", flag: "pn", text: "Pitcairn Islands" },
        { key: "pl", value: "pl", flag: "pl", text: "Poland" },
        { key: "pt", value: "pt", flag: "pt", text: "Portugal" },
        { key: "pr", value: "pr", flag: "pr", text: "Puerto Rico" },
        { key: "qa", value: "qa", flag: "qa", text: "Qatar" },
        { key: "re", value: "re", flag: "re", text: "Réunion" },
        { key: "ro", value: "ro", flag: "ro", text: "Romania" },
        { key: "ru", value: "ru", flag: "ru", text: "Russia" },
        { key: "rw", value: "rw", flag: "rw", text: "Rwanda" },
        { key: "sh", value: "sh", flag: "sh", text: "Saint Helena" },
        { key: "kn", value: "kn", flag: "kn", text: "Saint Kitts and Nevis" },
        { key: "lc", value: "lc", flag: "lc", text: "Saint Lucia" },
        { key: "pm", value: "pm", flag: "pm", text: "Saint-Pierre and Miquelon" },
        { key: "vc", value: "vc", flag: "vc", text: "Saint Vincent and the Grenadines" },
        { key: "ws", value: "ws", flag: "ws", text: "Samoa" },
        { key: "sm", value: "sm", flag: "sm", text: "San Marino" },
        { key: "st", value: "st", flag: "st", text: "São Tomé" },
        { key: "sa", value: "sa", flag: "sa", text: "Saudi Arabia" },
        { key: "sn", value: "sn", flag: "sn", text: "Senegal" },
        { key: "rs", value: "rs", flag: "rs", text: "Serbia" },
        { key: "sc", value: "sc", flag: "sc", text: "Seychelles" },
        { key: "sl", value: "sl", flag: "sl", text: "Sierra Leone" },
        { key: "sg", value: "sg", flag: "sg", text: "Singapore" },
        { key: "sk", value: "sk", flag: "sk", text: "Slovakia" },
        { key: "si", value: "si", flag: "si", text: "Slovenia" },
        { key: "sb", value: "sb", flag: "sb", text: "Solomon Islands" },
        { key: "so", value: "so", flag: "so", text: "Somalia" },
        { key: "za", value: "za", flag: "za", text: "South Africa" },
        { key: "gs", value: "gs", flag: "gs", text: "South Georgia and the South Sandwich Islands" },
        { key: "es", value: "es", flag: "es", text: "Spain" },
        { key: "lk", value: "lk", flag: "lk", text: "Sri Lanka" },
        { key: "sd", value: "sd", flag: "sd", text: "Sudan" },
        { key: "sr", value: "sr", flag: "sr", text: "Suriname" },
        { key: "sj", value: "sj", flag: "sj", text: "Svalbard and Jan Mayen" },
        { key: "se", value: "se", flag: "se", text: "Sweden" },
        { key: "ch", value: "ch", flag: "ch", text: "Switzerland" },
        { key: "sy", value: "sy", flag: "sy", text: "Syria" },
        { key: "tw", value: "tw", flag: "tw", text: "Taiwan" },
        { key: "tj", value: "tj", flag: "tj", text: "Tajikistan" },
        { key: "tz", value: "tz", flag: "tz", text: "Tanzania" },
        { key: "th", value: "th", flag: "th", text: "Thailand" },
        { key: "tl", value: "tl", flag: "tl", text: "Timorleste" },
        { key: "tg", value: "tg", flag: "tg", text: "Togo" },
        { key: "to", value: "to", flag: "to", text: "Tonga" },
        { key: "tt", value: "tt", flag: "tt", text: "Trinidad and Tobago" },
        { key: "tn", value: "tn", flag: "tn", text: "Tunisia" },
        { key: "tr", value: "tr", flag: "tr", text: "Turkey" },
        { key: "tc", value: "tc", flag: "tc", text: "Turks and Caicos Islands" },
        { key: "tk", value: "tk", flag: "tk", text: "Turkmenistan"},
        { key: "tv", value: "tv", flag: "tv", text: "Tuvalu" },
        { key: "ug", value: "ug", flag: "ug", text: "Uganda" },
        { key: "ua", value: "ua", flag: "ua", text: "Ukraine" },
        { key: "ae", value: "ae", flag: "ae", text: "United Arab Emirates" },
        { key: "gb", value: "gb", flag: "gb", text: "United Kingdom" },
        { key: "um", value: "um", flag: "um", text: "US Minor Islands" },
        { key: "us", value: "us", flag: "us", text: "United States of America" },
        { key: "uy", value: "uy", flag: "uy", text: "Uruguay" },
        { key: "uz", value: "uz", flag: "uz", text: "Uzbekistan" },
        { key: "va", value: "va", flag: "va", text: "Vatican City" },
        { key: "vu", value: "vu", flag: "vu", text: "Vanuatu" },
        { key: "ve", value: "ve", flag: "ve", text: "Venezuela" },
        { key: "vn", value: "vn", flag: "vn", text: "Vietnam" },
        { key: "vg", value: "vg", flag: "vg", text: "Virgin Islands" },
        { key: "vi", value: "vi", flag: "vi", text: "Virgin Islands of the United States" },
        { key: "wf", value: "wf", flag: "wf", text: "Wallis and Futuna Islands" },
        { key: "eh", value: "eh", flag: "eh", text: "Western Sahara" },
        { key: "ye", value: "ye", flag: "ye", text: "Yemen" },
        { key: "zm", value: "zm", flag: "zm", text: "Zambia" },
        { key: "zw", value: "zw", flag: "zw", text: "Zimbabwe" },
    ];

    async handlePhoneChange(value, country) {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        if (value.charAt(0) !== '+') {
            value = '+' + value;
        }
        if (country.dialCode) {
            value = value.replace(`+${country.dialCode}`, `+${country.dialCode}.`);
        }
        await this.setState({ phonenumber: value });
        this.allValid()
    }
    async handleMailChange(e, {value}){
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        let c = 0;
        for(let ch of value){
            if(ch == '@' && c < 1){
                c++;
            }
            if(ch == '.' && c >= 1){
                c++
            }
        }
        let validmail = false
        if(c == 2){
            validmail = true
        }

        await this.setState({
            email: value,
            validmail: validmail
        });
        this.allValid()
    }
    login = () => {
      // get our form data out of state
      const { email, password } = this.state;
  
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/auth`, {
          email,
          password,
        })
        .then((response) => {
          localStorage.setItem("jwt", response.data.token);
          localStorage.setItem("isAdmin", response.data.isAdmin);
          localStorage.setItem("user", response.data.user);
          localStorage.setItem("multitenancy", response.data.multitenancy);
          this.setState({
            loggedIn: true,
          });
        })
        .catch((error) => {
          console.log(error)
          this.props.notify(error.response.data, "error");
        });
    };
    async handleCompanyMailChange(e, {value}){
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        if(this.state.typeofmail == 'pec'){
            let c = 0;
            for(let ch of value){
                if(ch == '@' && c < 1){
                    c++;
                }
                if(ch == '.' && c >= 1){
                    c++
                }
            }
            let validmail = false
            if(c == 2){
                validmail = true
            }
            await this.setState({
                companymail: value,
                validcompanymail: validmail
            });

        }
        if(this.state.typeofmail == 'sdi'){
            let validmail = false;
            if(value.length == 7){
                validmail = true
            }
            if(value.length <= 7){
                await this.setState({
                    companymail: value,
                    validcompanymail: validmail
                });
            }
        }
        this.allValid()
    }


    getProvinces = () => {
        const token = localStorage.getItem('jwt');
        const { notify } = this.props;
        axios.get(`${process.env.REACT_APP_API_URL}/api/contact/contact/provinces`)
            .then((response) => {
                this.setState({ stateOptions: response.data });
            })
            .catch((error) => {
                notify(<FormattedMessage id="notify.loading-error" />, 'error', "close", true);
                //this.showAlert(error.message);
            });
    };

    
    contractOpen = async () => {
        let win = window.open("/Contratto_Cloudonthecloud.pdf", "_blank");
        win.focus()
    };
    privacyOpen = async () => {
        let win = window.open("/Privacy_Cloudonthecloud.pdf", "_blank");
        win.focus()
    };
    

    async handleInputChange(event, { name, value }) {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        let out = value
        if(name == "piva" ){
            out = ""
            for(let ch of value){
                if(/^\d+$/.test(ch)) out+=ch
            }
        }
        await this.setState({
            [name]: out
        });
        this.allValid()
    }

    allValid = async () => {
        let allvalid = false
        if( this.state.firstname != '' &&
            this.state.lastname != '' &&
            ((this.state.contacttype == 'company' && this.state.companyname != '' && this.state.piva != '' )||(this.state.contacttype == 'private' && this.state.fiscalcode != ''))&&
            this.state.address != '' &&
            this.state.city != '' &&
            this.state.postalcode != '' &&
            this.state.countrycode != '' &&
            ((this.state.countrycode == 'IT' && this.state.countrystate != '') || this.state.countrycode != 'IT') &&
            this.state.placeofbirth != '' &&
            this.state.validmail && 
            ((this.state.contacttype == 'company' && this.state.validcompanymail) || (this.state.contacttype == 'private'))&&
            this.state.phonenumber.length >= 10 &&
            this.state.privacy &&
            this.state.terms){
                allvalid = true;
        }
        await this.setState({allvalid : allvalid})
    }

    submit = async(e) => {
        this.props.notify("caricamento", "info", 'add', false);
        e.preventDefault()
        await axios.post(`${process.env.REACT_APP_API_URL}/api/users/signup`, this.state)
        .then((response) => {
            this.props.notify(<FormattedMessage id="notify.account-created" />,"success", 'add', true);
            this.login()
        })
        .catch((error) => {
            console.log(error)
            this.props.notify(<FormattedMessage id="notify.account-created.failed" />, "error", 'add', true);
        });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    switchPassword = () => {
        if(this.state.inputType == "password") {
            this.setState({inputType: "text"})
        } else {
            this.setState({inputType: "password"})
        }
    }

    switchPassword2 = () => {
        if(this.state.inputType2 == "password") {
            this.setState({inputType2: "text"})
        } else {
            this.setState({inputType2: "password"})
        }
    }
    
    firstSetUp = () => {
        if(!this.state.statewritten && this.props.open){
            this.setState({
                statewritten: true
            })
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                statewritten: false
            })
        }
    }

    render() {
        if (this.state.loggedIn === true) {
          return <Redirect to="/" />;
        }
        const { open, onClose } = this.props;
        const { formatMessage } = this.props.intl;
        const isAdmin = (localStorage.getItem('isAdmin') === "true");
        this.firstSetUp()
        return (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto', width: '50%' }}
                    open={open}
                    onClose={() => onClose(false)}
                >
                    <Modal.Header>
                        <FormattedMessage id="user.register" />
                    </Modal.Header>
                    <Modal.Content className="modal-min-height">
                        <Modal.Description>                   
                            <div>
                            <Form className="overflow-yes">
                                <Form.Group className='row'>
                                    <Form.Input className='col-lg-4' label={formatMessage({ id: "contact.firstname" })} value={this.state.firstname || ''} name="firstname" placeholder="First Name" onChange={this.handleInputChange} />
                                    <Form.Input className='col-lg-4' label={formatMessage({ id: "contact.lastname" })} value={this.state.lastname || ''} name="lastname" placeholder="Last Name" onChange={this.handleInputChange} />
                                    <Form.Field
                                        className='col-lg-4'
                                        control={Select}
                                        placeholder='Contact Type'
                                        name='contacttype'
                                        label={formatMessage({ id: "contact.type" })}
                                        options={this.clientTypes}
                                        onChange={this.handleInputChange}
                                        value={this.state.contacttype || ''}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input label="Email" value={this.state.email || ''} name="email" placeholder="Email" onChange={this.handleMailChange} />
                                    <div className="field">
                                        <label><FormattedMessage id="contact.phone" /></label>
                                        <PhoneInput enableAreaCodes={true} inputClass="phone" name="phonenumber" placeholder="Phone Number" country={'it'} regions={'eu-union'} value={this.state.phonenumber || ''} onChange={this.handlePhoneChange} />
                                    </div>
                                    {/* <Form.Input type="text" value={this.state.phonenumber || ''} label="Phone Number" name="phonenumber" placeholder="Phone Number" onChange={this.handleInputChange} /> */}
                                </Form.Group>
                                
                                {this.state.contacttype == 'company' ? 
                                    <Form.Group>
                                        <Form.Input label={formatMessage({ id: "app.company_mail" })} value={this.state.companymail || ''} name="companymail" placeholder="Company Mail" onChange={this.handleCompanyMailChange} />
                                        <Form.Field
                                            control={Select}
                                            name='typeofmail'
                                            label={formatMessage({ id: "app.mail_type" })}
                                            options={this.typeofmail}
                                            onChange={this.handleInputChange}
                                            value={this.state.typeofmail || ''}
                                        />
                                    </Form.Group> 
                                    : null}
                                <Form.Group>
                                    {this.state.contacttype == 'company' ? <Form.Input label={formatMessage({ id: "contact.company" })} value={this.state.companyname || ''} name="companyname" placeholder="Company" onChange={this.handleInputChange} /> : null}
                                    {this.state.contacttype == 'company' ?<Form.Input label={formatMessage({ id: "contact.vat" })} value={this.state.piva || ''} name="piva" placeholder="Vat Code" onChange={this.handleInputChange} />: null}
                                    {this.state.contacttype == 'private' ?<Form.Input label={formatMessage({ id: "contact.fiscal_code" })} value={this.state.fiscalcode || ''} name="fiscalcode" placeholder="Fiscal Code" onChange={this.handleInputChange} />: null}
                                </Form.Group>
                                <Form.Group className='row'>
                                    <Form.Field
                                        className='col-lg-4'
                                        control={Select}
                                        name="countrycode"
                                        placeholder='Country'
                                        search
                                        label={formatMessage({ id: "contact.country" })}
                                        options={this.countryOptions}
                                        onChange={this.handleInputChange}
                                        value={this.state.countrycode || ''}
                                    />
                                    <Form.Input className='col-lg-4' label={formatMessage({ id: "contact.city" })} value={this.state.city || ''} name="city" placeholder="City" onChange={this.handleInputChange} />
                                    <Form.Input className='col-lg-4' label={formatMessage({ id: "contact.zip_code" })} value={this.state.postalcode || ''} name="postalcode" placeholder="Postal Code" onChange={this.handleInputChange} />
                                </Form.Group>
                                <Form className="overflow-yes">
                                    <Form.Group>{this.state.countrycode == 'IT' ?
                                        <Form.Field
                                            control={Select}
                                            placeholder='State'
                                            name='countrystate'
                                            label={formatMessage({ id: "contact.state" })}
                                            autoComplete = "off"
                                            options={this.state.stateOptions}
                                            search
                                            allowAdditions
                                            additionLabel='Custom State: '
                                            onChange={this.handleInputChange}
                                            onAddItem={this.handleAddState}
                                            value={this.state.countrystate || ''}
                                        />: null}
                                        <Form.Input label={formatMessage({ id: "contact.street" })} value={this.state.address || ''} name="address" placeholder="Street" onChange={this.handleInputChange} />
                                        <Form.Field
                                            control={Select}
                                            name="placeofbirth"
                                            placeholder='Country'
                                            search
                                            label={formatMessage({ id: "contact.placeofbirth" })}
                                            options={this.countryOptions}
                                            onChange={this.handleInputChange}
                                            value={this.state.placeofbirth || ''}
                                        />
                                        {/* <Form.Input label="Country Code" value={this.state.countrycode || ''} name="countrycode" placeholder="Country Code" onChange={this.handleInputChange} /> */}
                                    </Form.Group>
                                </Form>
                                <Form.Group className='formGeneratePassword' autoComplete="off">
                                    <Form.Input autoComplete="new-password" label={"Password"} icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword()} />} iconPosition='right' value={this.state.password || ''} type={this.state.inputType} name="password" placeholder="password" onChange={this.handleInputChange} />
                                    <Form.Input autoComplete="new-password" label={"Ripetere Password"} icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword2()} />} iconPosition='right' value={this.state.password2 || ''} type={this.state.inputType2} name="password2" placeholder="password" onChange={this.handleInputChange} />
                                    <Form.Button className="buttonGeneratePassword" onClick={(e) => {e.preventDefault();e.stopPropagation();this.setState({ password: utils.generatePassword()})}}>Genera Password</Form.Button>
                                </Form.Group>
                                <div className='row' style={{"padding-left" : "2.5%"}}>
                                    <Checkbox checked = {this.state.terms} onChange = {() => {this.setState({terms: !this.state.terms}, () => this.allValid());}}/>
                                    <span>{formatMessage({ id: "app.contractcheckbox1" })}</span>
                                    <a style={{"padding-left":"0.5%"}} onClick = {this.contractOpen}>{formatMessage({ id: "app.contractcheckbox2" })}</a>
                                </div>
                                <div className='row' style={{"padding-left" : "2.5%"}}>
                                    <Checkbox checked = {this.state.privacy} onChange = {() => {this.setState({privacy: !this.state.privacy}, () => this.allValid()); }}/>
                                    <span>{formatMessage({ id: "app.privacycheckbox1" })}</span>
                                    <a style={{"padding-left":"0.5%"}} onClick = {this.privacyOpen}>{formatMessage({ id: "app.privacycheckbox2" })}</a>
                                </div>
                                <Form.Group>
                                    <Form.Button style={{position: "absolute", right: "10px", bottom: "-20px"}} floated="right" onClick = {this.submit} className="save-button" disabled = {!this.state.allvalid}>
                                        {formatMessage({ id: "app.create" })}
                                    </Form.Button>
                                </Form.Group>
                            </Form>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalSignUp);

