import React from 'react';
import { Button, Header, Image, Modal } from 'semantic-ui-react';
import FormWidthField from './Form';
import { FormattedMessage, injectIntl } from 'react-intl';

//CANCELLARE STA ROBA (Puzza molto)
const ModalModalExample = ({onChange,addContact}) => (
	<Modal
		style={{ margin: '0 auto' }}
		trigger={
			<Button floated="right" icon size="small">
				<FormattedMessage id="contact.add_contact" />
			</Button>
		}
	>
		<Modal.Header><FormattedMessage id="contact.add_contact" /></Modal.Header>
		<Modal.Content image>
			<Modal.Description>
				<FormWidthField onChange={onChange} addContact={addContact}/>
			</Modal.Description>
		</Modal.Content>
	</Modal>
);

export default ModalModalExample;
