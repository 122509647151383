import React from 'react';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import { FormattedMessage } from 'react-intl';
//import AlertBox from '../utils/alert';

const CURRENCY = 'EUR';

const fromEuroToCent = (amount) => amount * 100;

const successPayment = (data) => {
	alert('Payment Successful');
};

const errorPayment = (data) => {
	alert('Payment Error');
};

const token = localStorage.getItem('jwt');
axios.defaults.headers.common['x-auth-token'] = token;

const Button = ({ children, ...props }) => <button {...props}>{children}</button>;

//payment callback, domain is an object
const onToken = (amount, description, domain, reload, showAlert, history) => (token) =>
	axios
		.post(`${process.env.REACT_APP_API_URL}/api/domains/payment`, {
			description,
			source: token.id,
			currency: CURRENCY,
			amount: fromEuroToCent(amount)
		})
		.then((successPayment) => {
			showAlert('Payment Done. Try to register domain.');
			saveDomain(domain, showAlert, history);
		})
		.catch((errorPayment) => {
			showAlert(errorPayment.response.data.error.message);
			//saveDomain(domain, showAlert, history)
		});

//register/transfer domain according domain.action
function saveDomain(domain, showAlert, history) {
	axios
		.post(`${process.env.REACT_APP_API_URL}/api/domains/saveDomain`,
			{
				domainDetails: domain
			}
		).then((response) => {
			if (response.data.status === 'success') {
				showAlert("La richiesta di regostrazione/trasferimento è stata inviata.");
				//refactor - when domain will be registered //saveDns(dns, s.data.zoneid, s.data.domainid)
				//history.push("/") - go to index
			}
		}).catch(e => {
			showAlert(e.response.data.message);
		})
}

export const Checkout = ({ name, description, amount, changeState, domain, reload, showAlert, history}) => (
	<div style={{ textAlign: 'center' }}>
		<div style={{ margin: '5%' }}>
			<StripeCheckout
				name={name}
				description={description}
				amount={fromEuroToCent(amount)}
				token={onToken(amount, description, domain, reload, showAlert, history)}
				currency={CURRENCY}
				stripeKey={process.env.REACT_APP_STRIPE_KEY}
			>
				<button onClick={() => changeState()} className="btn btn-danger">
					<FormattedMessage id="app.payment_btn" />
				</button>
			</StripeCheckout>
		</div>
	</div>
);

// export  Checkout;
