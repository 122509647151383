import React from 'react';
import { Form, Button } from 'semantic-ui-react';

const FormWidthField = ({ onChange, addContact }) => (
	<Form>
		<Form.Group>
			<Form.Input
				label="First name"
				name="firstname"
				placeholder="First Name"
				onChange={(e) => onChange(e)}
				width={8}
			/>
			<Form.Input label="Last Name" name="lastname" placeholder="Last Name" onChange={(e) => onChange(e)} width={8} />
		</Form.Group>
		<Form.Group>
			<Form.Input label="Email" name="email" placeholder="Email" onChange={(e) => onChange(e)} width={8} />
			<Form.Input label="Phone Number" name="phonenumber" placeholder="Phone Number" onChange={(e) => onChange(e)} width={8} />
		</Form.Group>
		<Form.Group>
			<Form.Input label="City" name="city" placeholder="City" onChange={(e) => onChange(e)} width={8} />
			<Form.Input label="Street" name="street" placeholder="Street" onChange={(e) => onChange(e)} width={8} />
		</Form.Group>
		<Form.Group>
			<Form.Input label="Country Code" name="countrycode" placeholder="Country Code" onChange={(e) => onChange(e)} width={8} />
			<Form.Input label="Postal Code" name="postalcode" placeholder="Postal Code" onChange={(e) => onChange(e)} width={8} />
		</Form.Group>
		<Button floated="right" onClick={() => addContact()}>
			Add Contact2
		</Button>
	</Form>
);

export default FormWidthField;
