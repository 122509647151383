import React, { Component } from 'react';
import axios from 'axios';
import { Modal, Dropdown, Button } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

class ModalLoadBalancerMetrics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dnsServers:[],
            dnsServer: -1
        }
    }

    firstSetUp(){
        if(this.props.open && !this.state.statewritten){
            this.getDNSServers()
            this.setState({
                statewritten : true
            })
        }
        if(!this.props.open && this.state.statewritten){
            this.setState({
                statewritten : false
            })
        }
    }

    getDNSServers = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/server/getdns`)
            .then((s) => {
                let servers = []
                for(let isp of s.data.servers){
                    servers.push(isp)
                }
                this.setState({ dnsServers: servers });
                this.setState({ dnsServer: servers[0].id }, () => {
                });
            }).catch(e => {
            })
    }
    
    onChange = (event, val) => {
        this.setState({ [val.name]: val.value }, () => { //async
            //this.prepareServers();
        });
    }

    submit = async () => {
        this.props.notify("Caricamento", "info", "save", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/domains/changednsatmigration`, {check: true, domainid: this.props.domain.id, newispid: this.state.dnsServer})
            .then((response) => {
                this.props.notify(<FormattedMessage id="notify.save-dns" />, "success", 'save', true);
                this.props.onClose()
            })
            .catch((response) => {
                this.props.notify(<FormattedMessage id="notify.save-dns.failed" />, "error", 'save', true);
            })
    }

    render() {
        const { open, onClose } = this.props;
        const { formatMessage } = this.props.intl;
        this.firstSetUp()
        return (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto', width: '25%', height: '110%' }}
                    open={open}
                    onClose={() => onClose(false)}
                >
                    <Modal.Header>
                        {this.props.intl.formatMessage({id: "dns.change"})}
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>     
                        <div className="ui action k-dropgroup">  
                            <Dropdown
                                style= {{"margin-top":"5%"}}
                                className="left-rounded"
                                placeholder="type"
                                onChange={this.onChange}
                                name="dnsServer"
                                //fluid
                                selection
                                options={this.state.dnsServers.map(({ id, description }) => ({ value: id, text: description }))}
                                value={this.state.dnsServer}
                            />
                        </div>
                        <div style = {{"margin-top":"5%", "text-align":"center", "position":"relative"}} >
                            <Button onClick={this.submit}><FormattedMessage id="app.save" /></Button>
                        </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalLoadBalancerMetrics);
