import React, { Component } from 'react';
import axios from 'axios';
import { Form, Modal, Dropdown, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';


class ModalAssignToCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersArray : [],
            contact : ""
        }
    }
    
    componentDidMount(){
        this.getUsers()
    }

    getUsers = async() => {
        await axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then(async (response) => {
                this.setState({usersArray:response.data})
            })
            .catch((err) => {
                console.log(err);
            })
    }
    

    assignTo = async () =>{
        this.props.notify("Caricamento", "info", "add", false)
        if (this.state.newuserid) {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/vm/assigntocustomer`, {id:this.props.id, userid:this.state.newuserid, servers:this.props.servers})
                .then((response) => {
                    this.props.notify("Progetto correttamente assegnato", "success", 'add', true)
                    this.props.onClose()
                    })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            this.props.notify("Seleziona prima un partner", "error", 'add', true)
        }
    }

    onChangeUser = async (e, data) => {
        await this.setState({ newuserid: data.value });
    };


    render() {
        const { open, onClose, type } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    style = {{width: "30%"}}
                >
                <Modal.Header>
                    Assegna Progetto
                </Modal.Header>
                    <Modal.Content>
                        <Form className='ConfiguratorForms overflow-yes'>
                            <Form.Dropdown 
                                placeholder="Assegna a Cliente"
                                search
                                onChange={this.onChangeUser}
                                name="domain"
                                clearable
                                selection
                                label = "Assegna il progetto al cliente:"
                                options={this.state.usersArray.map(({ name, userid }) => ({ value: userid, text: name }))}
                            />
                        <Form.Button onClick={this.assignTo} className="save-button">
                            {formatMessage({ id: "app.save" })}
                        </Form.Button>
                        </Form>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalAssignToCustomer);
