
import React, { Component } from 'react';
import { Form, Button, Dropdown, Item, Icon } from 'semantic-ui-react';
import './server.scss';
import axios from 'axios';

import './table.scss'

import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';

class FormInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            invoice_nr: '',
            invoice_date: '',
            // items:'',
            method: '',
            vat: '',
            total: 0,
            totalAmount: 0,
            totalprice: 0,
            product: '',
            item: '',
            price: null,
            quantity: '',
            amount: null,
            partner: '',
            partners: [],
            products: [],
            items: [],
            disableSubmit: true,
            headerElement: ['product', 'price', 'quantity', 'operation'],

            invoiceData: [],
            saveData: [],
            fName: '',
            lName: '',
            type: '',
            email: '',
            genData: [
                {
                    firstName: "foo",
                    lastName: "boo",
                    type: "male",
                    email: "fooboo@gmail.com"
                }
            ]
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.isAdmin = (localStorage.getItem('isAdmin') === "true");
        this.handleAddRow = this.handleAddRow.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.handleInputVlaueChange = this.handleInputVlaueChange.bind(this);
        this.handleRemoveRow = this.handleRemoveRow.bind(this);
        this.handleSocialNameChange = this.handleSocialNameChange.bind(this);
        // this.selectHandler = this.selectHandler.bind(this);
    }


    handleAddRow() {
        // const dd = getFormDataAsJSON(form);

        let array = this.state.invoiceData;
        array.push({ id: array.length + 1, itemname: "" });
        this.setState({ invoiceData: array });
    }

    handleInputVlaueChange(e, idx) {
        let nextData = this.state.invoiceData.slice();
        nextData[idx].name = e.target.value;
        let valuetocheck = nextData[idx].name
        nextData[idx].name = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        this.setState({ invoiceData: nextData });
    }

    handleSocialNameChange(socialName, idx) {
        let nextData = this.state.SocialData.slice();
        nextData[idx].itemname = socialName;
        let valuetocheck = nextData[idx].itemname
        nextData[idx].itemname = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        this.setState({ invoiceData: nextData });
    }

    handleRemoveRow(idx) {
        let someArray = this.state.invoiceData;
        someArray.splice(idx, 1);
        this.setState({ invoiceData: someArray });
    }

    renderHeader() {
        let headerElement = ['product', 'price', 'quantity', 'operation']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    onChange(fieldName, value) {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        this.setState({
            [fieldName]: value
        });
    }

    componentDidMount() {
        if (this.isAdmin) {
            this.loadPartners();
            this.loadProducts();
        }
    }

    handleInputChange(event, { name, value, checked, type }) {
        if (type === 'checkbox') {
            value = checked;
        }
        this.setState({
            [name]: value
        }, () => this.disableSubmit());
    }

    addProduct = () => {
        let items = this.state.invoiceData;
        items.push({
            product: this.state.product,
            price: this.state.price,
            quantity: this.state.quantity
        });
        this.setState({ invoiceData: items });
    }

    saveInvoice = () => {
        const { saveInvoice, closeMe } = this.props;

        let totalprice = 0

        let totalAmount = 0;
        this.state.invoiceData.forEach(item => {
            totalAmount += parseFloat(item.price) * parseFloat(item.quantity);
        });
        totalprice = parseFloat(this.state.price) * parseFloat(this.state.quantity)
        let invoice = {
            partner: this.state.partner,
            items: this.state.invoiceData,
            total: totalAmount,
            totalprice: totalprice
        };
        // axios.post(`${process.env.REACT_APP_API_URL}/api/invoices/invoicevalue`, invoice,{

        // "headers": {
        // 
        // "content-type": "application/json",
        // },
        // })
        saveInvoice(invoice, function (res, err) {
            if (!err) {
                closeMe();
            }
        });
    }





    loadPartners = () => {
        //axios.get(`${process.env.REACT_APP_API_URL}/api/users`)
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then((response) => {
                this.setState({
                    partners: response.data
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    loadProducts = () => {
        /* let data = [
        { name: 'virtual server' },
        { name: 'virtual server volume' },
        here focus
        ]; */
        axios.get(`${process.env.REACT_APP_API_URL}/api/billing/products`)
            .then((response) => {
                this.setState({
                    products: response.data
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    disableSubmit = () => {
        let res = false;
        if (this.state.product == '' || this.state.price == '' || this.state.quantity == '' || this.state.partner == '') {
            res = true;
        }
        this.setState({
            disableSubmit: res
        });
    }

    handleAddition = (e, { value }) => {
        this.setState((prevState) => ({
            products: [{ name: value, value: value }, ...prevState.products],
        }))
    }



    render() {
        const { formatMessage } = this.props.intl;
        const isAdminUser = (localStorage.getItem('isAdmin') === "true");
        return (
            <div>

                <div className="server-form">
                    <Form className="overflow-yes">
                        <Form.Group>
                            <Form.Dropdown
                                label="Partner"
                                className="parnter-list"
                                placeholder=""
                                onChange={this.handleInputChange}
                                name="partner"
                                clearable
                                selection
                                search
                                options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                            />
                        </Form.Group>
                        <div>
                        <table className="table-responsive" id='tabl'>
                            <colgroup>
                                <col span="1" className="col-product" />
                                <col span="1" className="col-price" />
                                <col span="1" className="col-quantity" />
                                <col span="1" className="col-operation" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th style={{width: "10%"}} key="th_1"><FormattedMessage id="app.product" /></th>
                                    <th style={{width: "30%"}} key="th_2"><FormattedMessage id="app.price" /></th>
                                    <th style={{width: "20%"}} key="th_3"><FormattedMessage id="app.quantity" /></th>
                                    <th key="th_4"></th>
                                </tr>
                            </thead>
                            <tbody>
                                { }
                                {this.state.invoiceData.map((item, idx) => (
                                    <tr key={idx}>
                                        <td>{item.product}</td>
                                        <td>{item.price}</td>
                                        <td>{item.quantity}</td>
                                        <td >
                                            <div className="btn-add-button">
                                                <Button size="small" onClick={() => this.handleRemoveRow(idx)}><Icon name="minus" /></Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
                        <hr></hr>
                        <Form.Group >
                            {/* <Form.Input width={10} type="text" label={formatMessage({ id: "app.product" })} value={this.state.product || ''} name="product" placeholder="" onChange={this.handleInputChange} /> */}
                            <Form.Input label={formatMessage({ id: "app.product" })} value={this.state.product || ''} name="product" placeholder={formatMessage({ id: "app.product" })} onChange={this.handleInputChange} inline />

                            {/* <Form.Input width={10} type="text" label={formatMessage({ id: "app.product" })} value={this.state.product || ''} name="product" placeholder="" onChange={this.handleInputChange} /> */}
                            <Form.Input label={formatMessage({ id: "app.price" })} value={this.state.price || ''} placeholder="0" name="price" onChange={this.handleInputChange} inline />
                            {/* <Form.Input width={10} type="text" label={formatMessage({ id: "app.product" })} value={this.state.product || ''} name="product" placeholder="" onChange={this.handleInputChange} /> */}
                            <Form.Input label={formatMessage({ id: "app.quantity" })} value={this.state.quantity || ''} placeholder="0" name="quantity" onChange={this.handleInputChange} inline />

                            <Form.Button size="small" onClick={this.addProduct} className="btn-add-button"><Icon name="add" /></Form.Button>

                        </Form.Group>
                        <Form.Group >
                            <div className="field"></div>
                            <Form.Button floated="right" type="reset" onClick={this.saveInvoice} disabled={this.state.disableSubmit}>
                                {formatMessage({ id: "app.save" })}
                            </Form.Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        );
    }
}
export default injectIntl(FormInvoice);

