import React, { Component } from 'react';
import axios from 'axios';
import { Dropdown, Modal, Icon, Grid, Card, Button } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import DNS_Servers from '../list/dns_servers'

class ModalMigrateDomian extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registrar: {},
            domain_name: '',
            id_domain: '0',
            prices: {},
            new_registrar: "",
            currentStep: "registrar",
            dnsServers: [],
            dnsServer: ""
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedDomain !== this.props.selectedDomain) {
            const currentDomain = this.props.selectedDomain;
            this.setState({
                id_domain: currentDomain.id,
                registrar: currentDomain.registrar,
                domain_name: currentDomain.name,
                currentStep: "registrar",
                new_registrar: ""
            });
            this.getPrices(currentDomain.name);
            this.getDNSServers();
        }
    }

    onDNSChange = (event, val) => {
        this.setState({ [val.name]: val.value }, () => { //async
            //this.prepareServers();
        });
    }

    /** logic */
    getPrices = (domain_name) => {
        let product = {
            product: {
                type: 'domain',
                name: domain_name,
                action: 'transfer',
                askConfirm: false
            }
        };
        axios.post(`${process.env.REACT_APP_API_URL}/api/domains/regprices`, product)
            .then((response) => {
                this.setState({ prices: response.data });
            })
            .catch((error) => {
                console.log(error)
                this.props.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
            });
    }

    getDNSServers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/server/getdnsmigration`)
            .then((s) => {
                this.setState({ dnsServers: s.data.servers });
                this.setState({ dnsServer: s.data.servers[0].id }, () => {
                });
            })
            .catch(e => {
                this.props.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
            })
    }

    askRegistrar = () => {
        this.setState({
            currentStep: "registrar",
            new_registrar: ""
        });
    }
    askDNS = (registrar) => {
        this.setState({
            currentStep: "dns",
            new_registrar: registrar
        });
    }
    askConfirmation = (registrar) => {
        this.setState({
            currentStep: "confirm",
            //new_registrar: registrar
        });
    }

    cancelConfirmation = () => {
        this.setState({ askConfirm: false });
    }

    handleMigrate = () => {
        const { onDone } = this.props;
        const { formatMessage } = this.props.intl;
        this.props.notify("Caricamento", "info", "sort amount up", false)
        let data = {
            id_domain: this.state.id_domain,
            domain: this.state.domain_name,
            registrar: this.state.new_registrar,
            isp_id: this.state.dnsServer
        }
        axios.post(`${process.env.REACT_APP_API_URL}/api/domains/migrate`, data)
            .then((response) => {
                if (response.data.status === "SUCCESS") {
                    this.props.notify(formatMessage({ id: "reg.migration_success" }), "success", "sort amount up", true)
                }
                else {
                    this.props.notify(formatMessage({ id: "reg.migration_failed" }) + "\n" + response.data.message, "error", "sort amount up", true)
                }
                onDone(true);
            })
            .catch((e) => {
                this.props.notify(formatMessage({ id: "reg.migration_failed" }) + "\n" + e.data.message, "error", "sort amount up", true)
            });
    }

    onClose = (doRefresh) => {
        const { open, onClose } = this.props;
        this.setState({
            currentStep: "registrar"
        });
        onClose(false);
    }

    getServers = (servers) => {
        this.setState({ servers: servers })
        this.changeTab("third", servers)
    }

    render() {
        const { open } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto' }}
                    open={open}
                    onClose={() => this.onClose(false)}
                >
                    <Modal.Header>
                        <FormattedMessage id="domain.migrate" /> <span className="kx-orange">{this.state.domain_name}</span>
                    </Modal.Header>
                    <Modal.Content className="modal-min-height">
                        <Modal.Description>
                            <div>
                                <div className="server-form">
                                    {this.state.currentStep == "registrar" &&
                                        <Card className="w80pCenter">
                                            <Card.Content>
                                                <Card.Header><FormattedMessage id="reg.select_registrant" /></Card.Header>
                                            </Card.Content>
                                            <Card.Content>
                                                <Card.Description>
                                                    <div>
                                                        <Grid>
                                                            {this.state.prices.ascio > 0 ?
                                                                <Grid.Row className="no-gutters">
                                                                    <Grid.Column width={6} className="text-right -first-column -custom-width-column-registrant">
                                                                        <div className="padding-info custom-font" ><FormattedMessage id="reg.keep" /> {this.state.domain_name} <FormattedMessage id="reg.on" /></div>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={4} className="text-left">
                                                                        <div className="padding-info custom-font"><strong>Ascio</strong></div>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={3} className="text-center">
                                                                        <div className="padding-info custom-font"><strong>{this.state.prices.ascio}€</strong></div>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={3} className="next-button text-left">
                                                                        {this.state.registrar !== 'ascio' && <Button onClick={() => this.changeTab('first', 'ascio')}><FormattedMessage id="reg.next_step" /></Button>}
                                                                        {/* <a onClick={() => this.changeTab('first', 'ascio')}><span className="invisible">next</span></a> */}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                : null}
                                                            {this.state.prices.internetbs > 0 ?
                                                                <Grid.Row className="no-gutters border-50w">
                                                                    <Grid.Column width={6} className="text-right -first-column -custom-width-column-registrant">
                                                                        <div className="padding-info custom-font" ><FormattedMessage id="reg.transfer" /> {this.state.domain_name} <FormattedMessage id="reg.on" /></div>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={4} className="text-left">
                                                                        <div className="padding-info custom-font"><strong>IBS</strong></div>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={3} className="text-center">
                                                                        <div className="padding-info custom-font"><strong>{this.state.prices.internetbs}€</strong></div>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={3} className="next-button text-left">
                                                                        <Button onClick={() => this.askDNS('internetbs')}><FormattedMessage id="reg.next_step" /></Button>
                                                                        {/* <a onClick={() => this.changeTab('first', 'internetbs')}><span className="invisible">next</span></a> */}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                : null}
                                                        </Grid>
                                                    </div>
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>}
                                    {this.state.currentStep === "dns" &&
                                        <div>
                                            <Card className="w80pCenter">
                                                <Card.Content>
                                                    <Card.Header><FormattedMessage id="reg.dns_server" /></Card.Header>
                                                </Card.Content>
                                                <Card.Content>
                                                    <Card.Description>
                                                        <div className="ui action k-dropgroup">
                                                            <Dropdown
                                                                className="left-rounded"
                                                                placeholder="type"
                                                                onChange={this.onDNSChange}
                                                                name="dnsServer"
                                                                //fluid
                                                                selection
                                                                options={this.state.dnsServers.map(({ id, description }) => ({ value: id, text: description }))}
                                                                value={this.state.dnsServer}
                                                            />
                                                        </div>
                                                        <div className="btn-right mt-3">
                                                            <Button onClick={() => this.askRegistrar()}><FormattedMessage id="app.back" /></Button>
                                                            <Button onClick={() => this.askConfirmation()}><FormattedMessage id="reg.next_step" /></Button>
                                                        </div>
                                                    </Card.Description>
                                                </Card.Content>
                                            </Card>
                                        </div>
                                    }
                                    {this.state.currentStep === "confirm" &&
                                        <div>
                                            <div className="mb-3">
                                                <FormattedMessage id="reg.confirm_migration" values={{ domain: this.state.domain_name, registrar: this.state.new_registrar }} />
                                            </div>
                                            <div className="btn-right mb-3">
                                                <Button onClick={this.onClose}>
                                                    <Icon name='remove' /> <FormattedMessage id="app.no" />
                                                </Button>
                                                <Button onClick={this.handleMigrate}>
                                                    <Icon name='checkmark' /> <FormattedMessage id="app.yes" />
                                                </Button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalMigrateDomian);
