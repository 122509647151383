import React, { Component } from 'react';
import { Form, Button, Dropdown } from 'semantic-ui-react';
import './server.scss';
import axios from 'axios';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import Moment from "moment";


class FormReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            from_date: Moment().clone().startOf('month').format('YYYY-MM-DD'),
            to_date: Moment().clone().format('YYYY-MM-DD'),
            partner: '',
            partners: [],
            disableSubmit: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.isAdmin = (localStorage.getItem('isAdmin') === "true");
    }

    componentDidMount() {
        if (this.isAdmin) {
            this.loadPartners();
        }
    }


    //handleChange = (e, { name, value }) => this.setState({ [name]: value });
    handleInputChange(event, { name, value, checked, type }) {
        if (type === 'checkbox') {
            value = checked;
        }
        this.setState({
            [name]: value
        }, () => null);
    }

    loadPartners = () => {
        //axios.get(`${process.env.REACT_APP_API_URL}/api/users`)
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then((response) => {
                this.setState({
                    partners: response.data
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    afterAction = () => {
        const { afterAction, closeMe } = this.props;
        let data = {
            from_date: this.state.from_date,
            to_date: this.state.to_date,
            partner: this.state.partner
        };
        afterAction(data, function (res, err) {
            console.log('From Parent', res, err);
            if (!err) {
                closeMe();
            }
        });
    }


    render() {
        const { formatMessage } = this.props.intl;
        const isAdminUser = (localStorage.getItem('isAdmin') === "true");
        return (
            <div>
                <div className="server-form">
                    <Form className="overflow-yes">
                        <Form.Group>
                            <Form.Input type="text" label={formatMessage({ id: "app.from_date" })} value={this.state.from_date || ''} name="from_date" placeholder="" onChange={this.handleInputChange} />
                            <Form.Input type="text" label={formatMessage({ id: "app.to_date" })} value={this.state.to_date || ''} name="to_date" placeholder="" onChange={this.handleInputChange} />
                        </Form.Group>
                        {isAdminUser ?
                            <Form.Group>
                                <Form.Dropdown
                                    label="Partner"
                                    className="parnter-list"
                                    placeholder=""
                                    onChange={this.handleInputChange}
                                    name="partner"
                                    clearable
                                    selection
                                    search
                                    options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                                />
                            </Form.Group>
                            : null}
                        <Form.Group>
                            <div className="field"></div>
                            <Form.Button floated="right" onClick={this.afterAction} className="save-button" disabled={this.state.disableSubmit}>
                                {formatMessage({ id: "app.download" })}
                            </Form.Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        );
    }
}
export default injectIntl(FormReport);
