import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter, customFilter } from 'react-bootstrap-table2-filter';
import { Dropdown, Dimmer, Loader, Icon } from 'semantic-ui-react';
import Modal from './Modal';
import CreateCustomerModal from './CreateCustomerModal';
import ModalAssignTo from './ModalAssignTo';
import ModalConfirm from './ModalConferm';
import Header from '../header/header';
import Footer from '../footer/footer';
import LeftSidebar from '../sidebar/left-sidebar';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import NotifyComponent from '../notify/notifyComponent';

const { SearchBar, ClearSearchButton } = Search;

//
class multitenancy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: -1,
            user: null,
            contactlist: [],
            accountlist: [],
            partners: [],
            partnerId: '',
            offset: 0,
            page: 1,
            totalSize: 0,
            sizePerPage: 10,
            currentIndex: 0,
            loading: true,
            isSecondLevelUser: false,
            assignedDomains: [],
            assignedServers: [],
            canAdd: true,
            update:false,
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
        }
        localStorage.setItem('api-vs-key', '');
        axios.defaults.headers.common['x-api-vs-key'] = this.token;
        this.token = localStorage.getItem('jwt');
        axios.defaults.headers.common['x-auth-token'] = this.token;
        this.isAdmin = (localStorage.getItem('isAdmin') === "true");
        this.notify = this.notify.bind(this);
        this.secondTableColumns = this.defineColumnsSecondTable();
        this.getContacts = this.getContacts.bind(this);
    }

    componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }
    
    handleClose = async () => {
        let assignedDomains = await this.getAssignedDomains();
        let assignedServers = await this.getAssignedServers();
        await this.setState({
            assignedDomains : assignedDomains,
            assignedServers : assignedServers
        })
        this.setState({update:true})
    }

    checkSecondLevel = async () =>{
		await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
            .then(async (response) => {
                await this.setState({
                    isSecondLevelUser: response.data.isseconduser,
					id: response.data.id
                });
				if(this.state.isSecondLevelUser === false){
					this.getContacts();
                    let assignedDomains = await this.getAssignedDomains();
                    let assignedServers = await this.getAssignedServers();
                    await this.setState({
                        assignedDomains : assignedDomains,
                        assignedServers : assignedServers
                    })
                    this.assignedOptions();
                    if (this.isAdmin) {
                        this.loadUsers();
                    }
				}
				else{
					await axios.get(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelusers?id=${this.state.id}`)
						.then( async (response) => {
							if(response.data.clients === true){
								this.getContacts();
                                if (this.isAdmin) {
                                    this.loadUsers();
                                }
							}
                            else{
                                this.setState({
                                    canAdd : false
                                })
                            }
						})
				}
				this.setState({ loading: false });
            })
	}
    

    getAssignedDomains = async () => {
        let domains;
        await axios.get(`${process.env.REACT_APP_API_URL}/api/users/assigneddomains`)
            .then((response) => {
                domains = response.data.assignedDomains;
            })
        return domains;
    }
    getAssignedServers = async () => {
        let servers;
        await axios.get(`${process.env.REACT_APP_API_URL}/api/users/assignedservers`)
            .then((response) => {
                servers = response.data.assignedServers;
            })
        return servers;
    }

    assignedOptions = () => {
        let columns = this.secondTableColumns;
        this.secondTableColumns = [];
        for(let i = 0; i < columns.length; i++ ){
            this.secondTableColumns.push(columns[i])
            if(i == 2){ 
                if(!this.state.isSecondLevelUser){
                    this.secondTableColumns.push({
                        dataField: 'secondleveluserid',
                        text: this.props.intl.formatMessage({ id: "domain.domainassignedto" }),
                        formatter: (row) => {
                            let domainArray;
                            for(let j = 0; j < this.state.assignedDomains.length; j++){
                                if(this.state.assignedDomains[j].secondleveluserid == row){
                                    domainArray = this.state.assignedDomains[j].domainarray;
                                }
                            }
                            return <div style = {{display: "inline-flex", "align-items": "center"}}>
                                <Dropdown
                                placeholder="domain.com"
                                name="domain"
                                selection
                                defaultValue = {domainArray ? domainArray[0] ? domainArray[0].domain : null : null}
                                options={domainArray ? domainArray.map(({domain}) => ({value: domain, text: domain})): []}
                                /> 
                            <div style = {{"margin-left":"10px"}}></div>
                            {!this.state.isSecondLevelUser ? <ModalAssignTo type={{ Icon: "add"}}  secondleveluserid = {row} notify = {this.notify} onClose = {this.handleClose}/>: null}
                            </div>

                        }
                    });
                if(!this.state.isSecondLevelUser){
                    this.secondTableColumns.push({
                        dataField: 'secondleveluserid',
                        text: this.props.intl.formatMessage({ id: "domain.serverassignedto" }),
                        formatter: (row) => {
                            let serverArray;
                            for(let j = 0; j < this.state.assignedServers.length; j++){
                                if(this.state.assignedServers[j].secondleveluserid == row){
                                    serverArray = this.state.assignedServers[j].serverarray;
                                }
                            }
                            return <div style = {{display: "inline-flex", "align-items": "center"}}>
                            <Dropdown
                            placeholder="server.domain.com"
                            name="server"
                            selection
                            defaultValue = {serverArray ? serverArray[0] ? serverArray[0].server : null : null}
                            options={serverArray ? serverArray.map(({server}) => ({value: server, text: server})): []}
                            />
                            <div style = {{"margin-left":"10px"}}></div>
                            {!this.state.isSecondLevelUser ? <ModalAssignTo type={{ Icon: "add"}} secondleveluserid = {row} notify = {this.notify} onClose = {this.handleClose}/>: null}
                            </div>
                        }
                    });
                };
                }
            }
        };
    }

    componentDidMount = async () =>  {
        await this.checkSecondLevel();
    }

    getContacts = async (params) => {
        await axios.
            get(`${process.env.REACT_APP_API_URL}/api/contact/accountfilter`, {params: {partnerId: params ? params.partnerId : null}})
            .then(async (response) => {
                this.setState({
                    contactlist: response.data.contactlist,
                    accountlist: response.data.accountlist
                });
            })
    };

    onPartnerChange = (e, data) => {
        this.setState({ partnerId: data.value });
        this.getContacts({ partnerId: data.value });
    };

    handleTableChange = async (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        var currentIndex = (page - 1) * sizePerPage;
        if (type === 'search') {
            currentIndex = 0;
        }
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/contact/accountfilter`, {
                params: {
                    currentIndex: currentIndex,
                    sizePerPage: sizePerPage ? sizePerPage : this.state.sizePerPage,
                    q: searchText,
                    sortField: sortField,
                    sortOrder: sortOrder,
                    partnerId: this.state.partnerId ? this.state.partnerId : null
                }
            })
            .then((response) => {
                this.setState(() => ({
                    contactlist: response.data.contactlist,
                    accountlist: response.data.accountlist,
                    page,
                    sizePerPage,
                    currentIndex: currentIndex
                }));
            });
    }

    modifyContact = (data, callback) => {
        this.notify("Caricamento", "info", "pencil", false)
        axios.put(`${process.env.REACT_APP_API_URL}/api/contact/contact`, data)
            .then(async(response) => {
                this.notify(<FormattedMessage id="notify.modify-contact" />, "success", "pencil", true)
                await this.sleep(2000)
                this.getContacts();
                callback(response, null);
            }).catch(e => {
                this.notify(<FormattedMessage id="notify.modify-contact.failed" />, "error", "pencil", true)
                callback(null, e);
            });
    }

    addContact = (data, callback) => {
        this.notify("Caricamento", "info", "add", false)
        axios.post(`${process.env.REACT_APP_API_URL}/api/contact/contact`, data)
            .then(async(response) => {
                this.notify(<FormattedMessage id="notify.add-contact" />, "success", "add", true)
                await this.sleep(2000)
                this.getContacts()
                callback(response, null);
            })
            .catch((e) => {
                this.notify(<FormattedMessage id="notify.add-contact.failed" />, "error", "add", true)
                console.log(e.response);
                callback(null, e);
            });
    };

    deleteAccount = (id) => {
        this.notify("Caricamento", "info", "trash", false)
        axios.delete(`${process.env.REACT_APP_API_URL}/api/multitenancy/accountdelete?id=${id}`)
            .then(async (response) => {
                this.notify(<FormattedMessage id="notify.delete-contact" />, "success", "trash", true)
                await this.sleep(2000)
                this.getContacts();
            }).catch(e => {
                this.notify(<FormattedMessage id="notify.delete-contact.failed" />, "error", "trash", true)
                console.log(e);
            });
    }

    loadUsers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then((response) => {
                this.setState({
                    partners: response.data
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    onChange = (name, val) => {
        this.setState({ [name]: val });
    }

    defineColumnsSecondTable = () => {
        let columns = [{
                dataField: 'secondleveluserid',
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '40px' };
                },
                formatter: (cellContent, row) => {
                    if(cellContent == -1){
                        return <div><Icon name='user outline' size = "large" /></div>
                    }
                    else{
                        return <div><Icon name='user circle' size = "large" /></div>
                    }
                }
            },
            {
                dataField: 'lastname',
                text: this.props.intl.formatMessage({ id: "contact.name" }),
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '30%' };
                },
                formatter: (cellContent, row) => {
                    return (<div className="textToCopy"><span className='textToShow' onClick={(e) => {e.stopPropagation();e.preventDefault()}} onMouseDown={async (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        let elements = document.getElementsByClassName('tagToCopy')
                        for(let element of elements) {
                            element.innerText="Copied"
                        }
                        
                        await this.sleep(500)
                        for (let element of elements) {
                            element.innerText="Copy"
                        }
                        navigator.clipboard.writeText([row.firstname, row.lastname].filter(Boolean).join(" "));
                        
                    }}>{[row.firstname, row.lastname].filter(Boolean).join(" ")}</span><div className="tagToCopy">Copy</div></div>)
                }
            }, {
                dataField: 'company',
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '30%' };
                },
                text: this.props.intl.formatMessage({ id: "contact.company" }),
                classes: 'cut-words',
                formatter: (cellContent, row) => {
                    return(
                        <div className="textToCopy"><span className='textToShow' onClick={(e) => {e.stopPropagation();e.preventDefault()}} onMouseDown={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            let elements = document.getElementsByClassName('tagToCopy')
                            for(let element of elements) {
                                element.innerText="Copied"
                            }
                            
                            await this.sleep(500)
                            for (let element of elements) {
                                element.innerText="Copy"
                            }
                            navigator.clipboard.writeText(row.company);
                            
                        }}>{row.company}</span><div className="tagToCopy">Copy</div></div>
                    )
                }
            }, {
                dataField: 'email',
                sort: true,
                text: this.props.intl.formatMessage({ id: "app.email" }),
                classes: 'cut-words',
                formatter: (cellContent, row) => {
                    return(
                        <div className="textToCopy"><span className='textToShow' onClick={(e) => {e.stopPropagation();e.preventDefault()}} onMouseDown={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            let elements = document.getElementsByClassName('tagToCopy')
                            for(let element of elements) {
                                element.innerText="Copied"
                            }
                            
                            await this.sleep(500)
                            for (let element of elements) {
                                element.innerText="Copy"
                            }
                            navigator.clipboard.writeText(row.email);
                            
                        }}>{row.email}</span><div className="tagToCopy">Copy</div></div>
                    )
                }
            }];
        if (this.isAdmin) {
            columns.push({
                dataField: 'user.name',
                text: this.props.intl.formatMessage({ id: "app.partner" }),
            });
        }
        columns.push({
            dataField: 'df1',
            text: '',
            isDummyField: true,
            headerStyle: (colum, colIndex) => {
                return { width: '130px' };
            },
            headerFormatter: () => {
                if(this.state.canAdd)
                    return (<Modal type={{ Icon: "user plus", Formtype: "contact", ButtonLabel: this.props.intl.formatMessage({ id: "app.new" }) }} formData={{}} addContact={this.addContact} notify={this.notify}/>);
            },
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                let icona
                if (row.secondleveluserid === -1) {
                    icona = "add"
                } else {
                    icona ="wrench"
                }
                return (
                    <div className="d-flex flex-row">
                        <Modal type={{Icon: "pencil square", Formtype: "contact", ButtonLabel: this.props.intl.formatMessage({ id: "app.edit" }) }} formData={row} onChange={this.onChange} addContact={this.modifyContact} notify={this.notify} />
                        {row.secondleveluserid != -1 ? <ModalConfirm modalCfg={{ type: "delete"}} onBoh={this.deleteAccount} onYes={() => this.deleteAccount(row.id)} onclose={() => this.getContacts()} /> : null}
                        {row.secondleveluserid != -1 ? <CreateCustomerModal type={{ Icon: icona, Formtype: "contact", ButtonLabel: this.props.intl.formatMessage({ id: "app.edit" }) }} formData={row} onChange={this.onChange} addContact={this.modifyContact} notify={this.notify} onClose={this.getContacts} stateMachine = {1}/>: <CreateCustomerModal type={{ Icon: icona, Formtype: "contact", ButtonLabel: this.props.intl.formatMessage({ id: "app.edit" }) }} formData={row} onChange={this.onChange} addContact={this.modifyContact} notify={this.notify} onClose={this.getContacts} stateMachine = {0}/>}
                    </div>
                );
            },
            formatExtraData: {
                lng: this.props.language
            }
        });
        return columns;
    }

    getUserData = (userdata) => {
        this.setState({ balance: userdata.balance });
    }

    render() {
        const { page, sizePerPage, totalSize, loading } = this.state;
        const sizePerPageList = [5, 10, 15];
        const defaultSorted = [{
            dataField: 'secondleveluserid',
            order: 'desc'
        }];
        const isAdmin = (localStorage.getItem('isAdmin') === "true");
        const { formatMessage } = this.props.intl;
        return (
            <div className="kx-page">
                {loading ?
                    <Dimmer active inverted>
                        <Loader size='medium'></Loader>
                    </Dimmer>
                    : null}

                <Header history={this.props.history} language={this.props.language} changeLanguage={this.props.changeLanguage} getUserData={this.getUserData} />

                <div className="with-nav-bar with-left-nav">


                    <div className="">
                        <div className="kx-left-nav">
                            <LeftSidebar history={this.props.history} location={this.props.location}
                                styles={{ sidebar: { background: "blue" } }} />
                        </div>
                    </div>

                    <div className="kx-content">

                        <div className="kx-container">

                            <div className="row">
                            {!this.state.isSecondLevelUser ? 
                                <div className="col-12">
                                <br/>
                                    {!this.state.update ? <ToolkitProvider
                                        keyField="id"
                                        data={this.state.contactlist}
                                        columns={this.secondTableColumns}
                                        defaultSorted={defaultSorted}
                                        bootstrap4
                                        search
                                    >
                                        {
                                            toolkitprops => [
                                                <div className="searchBar">
                                                    <div className="searchBtn">
                                                        <SearchBar ref={n => this.searchKit = n} id="s1" {...toolkitprops.searchProps} delay={1000} placeholder={formatMessage({ id: "table.search" })} />
                                                        <ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
                                                    </div>
                                                    {isAdmin ?
                                                        <div className="d-flex flex-row align-items-center">
                                                            <Dropdown
                                                                className="parnter-list"
                                                                placeholder="Partner"
                                                                onChange={this.onPartnerChange}
                                                                name="partnerIdInv"
                                                                clearable
                                                                value={this.state.partnerCustomer}
                                                                selection
                                                                search
                                                                options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                                                            />
                                                        </div> : null}
                                                </div>,
                                                <div className='infodiv boxTabella'>
                                                <h5>{this.props.intl.formatMessage({ id: "contact.contacts" }) } <Icon name='refresh' onClick={(e) => this.getContacts()} link={true} style={{ margin: 'auto'}}/></h5>
                                                <BootstrapTable
                                                    {...toolkitprops.baseProps}
                                                    remote={{ search: true, sort: true }}
                                                    striped hover
                                                    classes="table-responsive overflow-yes"
                                                    pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                                                    onTableChange={this.handleTableChange}
                                                /></div>
                                            ]
                                        }
                                    </ToolkitProvider>: this.setState({update:false}) }
                                </div> : null }

                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
                <Footer />

            </div >)
    }
}

export default injectIntl(multitenancy);
