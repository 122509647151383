import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Dropdown, Button } from 'semantic-ui-react';
import ModalConfirm from '../All/ModalConferm';
import ModalEditISP from './ModalEditISP';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalAssignISP from './ModalAssignISP';
import NotifyComponent from '../notify/notifyComponent';

const { SearchBar, ClearSearchButton } = Search;

class ISPConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: -1,
            user: '',
            serverList: [],
            partners: [],
            partnerId: '',
            offset: 0,
            page: 1,
            totalSize: 0,
            sizePerPage: 5,
            currentIndex: 0,
            loading: true,
            isSecondLevelUser: false,
            canAdd: true,
            modalEditISP: false,
            modalAddISP: false,
            data: [],
            serversArray: [],
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
        };
        localStorage.setItem('api-vs-key', '');
        axios.defaults.headers.common['x-api-vs-key'] = this.token;
        this.token = localStorage.getItem('jwt');
        axios.defaults.headers.common['x-auth-token'] = this.token;
        this.isAdmin = (localStorage.getItem('isAdmin') === "true");
        this.columns = this.defineColumns();
    }

    defineColumns = () => {
        const { formatMessage } = this.props.intl;
        var columns = [{
            dataField: 'description',
            text: formatMessage({ id: "app.description" }),
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left' };
            },
            style: { textOverflow: 'ellipsis' },
            align: 'left'
        }, {
            dataField: 'soap_uri',
            text: formatMessage({ id: "isp.uri" }),
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left' };
            },
            classes: 'txt-truncated',
            align: 'left',
            formatter: (cellContent, row) => {
                return(
                    <div className="textToCopy"><span className='textToShow' onClick={(e) => {e.stopPropagation();e.preventDefault()}} onMouseDown={async (e) => {
						e.stopPropagation();
						e.preventDefault();
                        let elements = document.getElementsByClassName('tagToCopy')
                        for(let element of elements) {
                            element.innerText="Copied"
                        }
                        
                        await this.sleep(500)
                        for (let element of elements) {
                            element.innerText="Copy"
                        }
						navigator.clipboard.writeText(row.soap_uri);
						
					}}>{row.soap_uri}</span><div className="tagToCopy">Copy</div></div>
                )
            }
        },
        {
            dataField: 'user.name',
            text: formatMessage({ id: "app.partner" }),
            align: 'left'
        },
        {
            dataField: 'server',
            text: "Server",
            align: 'left',
            formatter: (cellContent, row) => {
                let servername
                for(let server of this.state.serversArray){
                    if(server.isp_id == row.id){
                        servername=server.server
                    }
                }
                return servername
            }
        },
        {
            dataField: 'df1',
            isDummyField: true,
            text: '',
            headerStyle: (colum, colIndex) => {
                return { width: '190px', textAlign: 'right' };
            },
            headerFormatter: () => {
                if(this.state.canAdd)
                    return (<Button icon="pencil square" onClick={() => this.setState({modalAddISP: true})}/>)
            },
            formatter: (cellContent, row) => {
                let rowToEdit = row
                return (
                    <div className="d-flex flex-row">
                        <Button icon="pencil square" onClick={() => this.setState({data: row, modalEditISP: true})}/>
                        <Button onClick = {() => {this.setState({rowToEdit: rowToEdit}, () => {this.setState({modalAssignISPOpen: true})})}}>Assegna</Button>
                        <ModalConfirm modalCfg={{ type: "delete"}} onBoh={this.deleteISPServer} onYes={() => this.deleteISPServer(row.id)} />
                    </div>
                );
            },
            align: 'center'
        }
        ];
        return columns;
    };

    componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }

    checkSecondLevel = () =>{
		axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
            .then((response) => {
                this.setState({
                    isSecondLevelUser: response.data.isseconduser,
					id: response.data.id
                });
				if(this.state.isSecondLevelUser === false){
                    this.getServers();
                    if (this.isAdmin) {
                        this.loadPartners();
                    }
				}
				else{
					axios.get(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondlevelusers?id=${this.state.id}`)
						.then((response) => {
							if(response.data.isp === true){
                                this.getServers();
                                if (this.isAdmin) {
                                    this.loadPartners();
                                }
							}
                            else{
                                this.setState({
                                    canAdd : false
                                })
                            }
						})
				}
				this.setState({ loading: false });
            })
	}

    componentDidMount() {
        this.checkSecondLevel();
        this.getServersColumn()
    }

    onPartnerChange = (e, data) => {
        this.setState({ partnerId: data.value });
        this.getServers({ partnerId: data.value });
    };

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        var currentIndex = (page - 1) * sizePerPage;
        if (type === 'search') {
            currentIndex = 0;
        }
        this.setState({ loading: true });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/settings/filter`, {
                params: {
                    currentIndex: currentIndex,
                    sizePerPage: sizePerPage ? sizePerPage : this.state.sizePerPage,
                    q: searchText,
                    sortField: sortField,
                    sortOrder: sortOrder,
                    partnerId: this.state.partnerId ? this.state.partnerId : null
                }
            })
            .then((response) => {
                let result = response.data.dataset;
                let totalCount = response.data.totalCount;
                this.setState(() => ({
                    serverList: response.data.dataset,
                    page,
                    /*data: result.slice(currentIndex, currentIndex + sizePerPage),*/
                    totalSize: totalCount,
                    sizePerPage,
                    currentIndex: currentIndex,
                    loading: false
                }));
            });
    }


    addISPServer = (data, callback) => {
        data.userid = this.state.user;
        delete (data.id);
        this.notify("Caricamento", "info", "add", false)
        axios.post(`${process.env.REACT_APP_API_URL}/api/settings/ispconfig`, data
        ).then((response) => {
            this.notify("La configurazione è stata salvata", "success", "add", true);
            this.getServers();
            callback(response, null);
        }).catch(e => {
            this.notify("L'errore durante salvataggio", "error", 'add', true);
            callback(null, e);
        });
    }

    modifyISPServer = (data, callback) => {
        //data.userid = this.state.user;
        this.notify("Caricamento", "info", "pencil", false)
        axios.put(`${process.env.REACT_APP_API_URL}/api/settings/ispconfig`, data)
            .then((response) => {
                this.notify("La configurazione è stata salvata", "success", 'pencil', true);
                window.location.reload()
                callback(response, null);
            }).catch(e => {
                this.notify("L'errore durante salvataggio", "error", 'pencil', true);
                console.log(e);
                callback(null, e);
            });
    }

    deleteISPServer = (id) => {
        this.notify("Caricamento", "info", "trash", false)
        axios.delete(`${process.env.REACT_APP_API_URL}/api/settings/ispconfig/${id}`, {})
            .then((response) => {
                this.notify("La configurazione è stata eliminata", "success", 'trash', true);
                this.getServers();
            }).catch(e => {
                this.notify("L'errore durante eliminazione", "error", 'trash', true);
                console.log(e);
            });
    }

    loadPartners = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/partners`)
            .then((response) => {
                this.setState({
                    partners: response.data
                });
            })
            .catch((error) => {
                console.log(error)
            });
    }

    getServersObsolete = (uid) => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/settings/getISPconfig`, { user: uid }
        ).then((response) => {
            this.setState({ serverList: response.data.ispconfig });
        }).catch(e => {
            console.log(e);
        });
    }

    getServers = (params) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/settings/filter`, {
                params: {
                    currentIndex: this.state.currentIndex,
                    sizePerPage: this.state.sizePerPage,
                    partnerId: params ? params.partnerId : null
                }
            })
            .then((response) => {
                this.setState(() => ({
                    serverList: response.data.dataset,
                    totalSize: response.data.totalCount,
                    loading: false
                }));
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
            });
    };

    onChange = (name, val) => {
        this.setState({ [name]: val });
    }

    getServersColumn = async() => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/getallserversfromdb`)
            .then(async (response) => {
                this.setState({serversArray:response.data})
            })
            .catch((err) => {
                console.log(err);
            })
    }

    getUserData = (userdata) => {
        this.setState({ balance: userdata.balance });
    }

    render() {
        const { page, sizePerPage, totalSize, loading } = this.state;
        const sizePerPageList = [10, 15, 20];
        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];
        const isAdmin = (localStorage.getItem('isAdmin') === "true");
        const { formatMessage } = this.props.intl;
        return (
                    <ToolkitProvider
                        keyField="description"
                        data={this.state.serverList}
                        columns={this.columns}
                        defaultSorted={defaultSorted}
                        bootstrap4
                        search
                    >
                        {
                            toolkitprops => [
                                <div className="searchBar">
                                    <div className="searchBtn">
                                        <SearchBar ref={n => this.searchKit = n} id="s1" {...toolkitprops.searchProps} delay={1000} placeholder={formatMessage({ id: "table.search" })} />
                                        <ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
                                    </div>
                                    <ModalAssignISP open = {this.state.modalAssignISPOpen} notify = {this.notify} row = {this.state.rowToEdit} onClose = {() => this.setState({modalAssignISPOpen: false})}/>
                                    {isAdmin ?
                                        <div className="d-flex flex-row align-items-center">
                                            <Dropdown
                                                className="parnter-list"
                                                placeholder="Partner"
                                                onChange={this.onPartnerChange}
                                                name="partnerIdInv"
                                                clearable
                                                value={this.state.partnerCustomer}
                                                selection
                                                search
                                                options={this.state.partners.map(({ userid, name }) => ({ value: userid, text: name }))}
                                            />
                                        </div> : null}
                                </div>,                                                
                                <div className='infodiv boxTabella'>
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    remote={{ search: true, pagination: true, sort: true }}
                                    striped hover
                                    classes="table-responsive-lg"
                                    pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                                    // className="isp-boostrap-table"
                                    onTableChange={this.handleTableChange}
                                />
                                {
                                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                                }
                                <ModalEditISP open={this.state.modalAddISP} notify={this.notify} onClose={() => this.setState({modalAddISP: false}, () => this.getServers())}/>
                                <ModalEditISP open={this.state.modalEditISP} notify={this.notify} data={this.state.data} onClose={() => this.setState({modalEditISP: false}, () => this.getServers())}/>
                                </div>
                            ]
                        }
                    </ToolkitProvider>
        );
    }
}
export default injectIntl(ISPConfig);