import React from 'react';
import { Step } from 'semantic-ui-react';
import './stepper.scss'

const StepExampleGroupShorthand = (steps) => (
	<div className="step-container">
		<Step.Group items={steps.steps} size='tiny'/>
	</div>
)

export default StepExampleGroupShorthand;
