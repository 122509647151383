import React, { Component } from 'react';
import {Modal, Form, TextArea} from 'semantic-ui-react';
import axios from 'axios';
import { injectIntl } from 'react-intl';

class ModalTestSSH extends Component {
    constructor(props) {
        super(props);
		this.instance = React.createRef();
        this.state = {
            command: "",
            text: ""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    send = async() => {this.props.notify("Caricamento", "info", "arrow right", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/testssh`, {id: this.props.data.id, test: this.state.command})
            .then((response) => {
                this.props.notify("Comando inviato!", "success", 'arrow right', true)
                this.setState({text: response.data})
            })
            .catch((error) => {
                if(error.response.data.level == "client-authentication"){
                    this.props.notify("Autenticazione SSH fallita!", 'error', 'arrow right', true);
                } else {this.props.notify("" + error, 'danger', 'arrow right', true);}
            })
    }

    firstSetUp = () => {
        if(!this.state.statewritten && this.props.open){
            this.setState({
                statewritten: true
            })
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                statewritten: false
            })
        }
    }
    
    async handleInputChange(event, { name, value }) {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        await this.setState({
            [name]: value
        });
    }

    

    render() {
        const {open, onClose} = this.props;
        const { formatMessage } = this.props.intl;
        this.firstSetUp();
        return (
            <div>
                <Modal closeIcon={true}
                    className="overflow-yes"
                    closeOnDimmerClick={true}
                    style={{ margin: '0 auto', width: '30%' }}
                    open={open}
                    onClose={() => onClose(false)}>
                    <Modal.Header>
                        Testa Comandi SSH
                    </Modal.Header>
                    <Modal.Content className="modal-min-height">
                        <Modal.Description>
                                <div className="server-form">
                                    <Form className="overflow-yes">                                
                                        <Form.Input value={this.state.command} label={"Comando"} name={"command"} onChange={this.handleInputChange} placeholder="Scrivi qua il tuo comando" />
                                        <Form.Button style = {{display:"flex", "flex-direction":"flex-end"}} onClick={() => {this.send()}} >
                                            Send
                                        </Form.Button>
                                        <Form.Field type="text" value={this.state.text} label={"Output"} name={"text"} control={TextArea} onChange={this.handleInputChange} style={{border:"1px solid gray", "min-height":"45px", "max-height":"500px", "height":"250px"}} readOnly/>
                                    </Form>
                                </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalTestSSH);