import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import Header from '../header/header';
import Footer from '../footer/footer';
import LeftSidebar from '../sidebar/left-sidebar';
import { injectIntl } from 'react-intl';
import ConfigurationISPConfig from './ISPConfig';
import ConfigurationServers from './ConfigurationServers';
import ConfigurationStorageBoxes from './ConfigurationStorageBoxes'
import ConfigurationGateways from './ConfigurationGateways'
import ConfigurationSSH from './ConfigurationSSH'
import ConfigurationServerTypes from './ConfigurationServerTypes';
import axios from "axios"

class Configurations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin:false
        };
        localStorage.setItem('api-vs-key', '');
    }

    componentDidMount(){
        this.getadmin()
    }

    getadmin = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
			.then((response) => {
				this.setState({
					isAdmin: response.data.isAdmin
				});
			})
			.catch((error) => {
                console.log(error)
            });
	}
    
    render() {
        const { formatMessage } = this.props.intl;
        let panes = []
        if (this.state.isAdmin) {
            panes = [
                {
                    menuItem: "ISP",
                    render: () => <ConfigurationISPConfig addNotification = {this.props.addNotification} userdetails={this.props.userdetails} history = {this.props.history}/>             
                },{
                    menuItem: "Progetti Hetzner",
                    render: () => <ConfigurationServers addNotification = {this.props.addNotification} userdetails={this.props.userdetails}  history = {this.props.history}/>
                },{
                    menuItem: "SSH",
                    render: () => <ConfigurationSSH addNotification = {this.props.addNotification} userdetails={this.props.userdetails} history = {this.props.history}/>
                },{
                    menuItem: "CloudBox",
                    render: () => <ConfigurationStorageBoxes addNotification = {this.props.addNotification} userdetails={this.props.userdetails} history = {this.props.history}/>
                },{
                    menuItem: "Gateways",
                    render: () => <ConfigurationGateways addNotification = {this.props.addNotification} userdetails={this.props.userdetails} history = {this.props.history}/>
                },{
                    menuItem: "Server Types",
                    render: () => <ConfigurationServerTypes addNotification = {this.props.addNotification} userdetails={this.props.userdetails} history = {this.props.history}/>
                }
            ]
        }

        return (
            <div className="kx-page">
                <Header history={this.props.history} language={this.props.language} changeLanguage={this.props.changeLanguage} getUserData={this.getUserData} />
                <div className="with-nav-bar with-left-nav">
                    <div className="">
                        <div className="kx-left-nav">
                            <LeftSidebar history={this.props.history} location={this.props.location}
                                styles={{ sidebar: { background: "blue" } }} />
                        </div>
                    </div>
                    <div className="kx-content">
                        <div className="kx-container">
                            <div className="row">
                                <div className="col-12">
                                    <Tab id="configMenu" menu={{ secondary: true, pointing: true, size: "huge", style:{display: "flex", marginLeft: "3px"} }} panes={panes}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >);
    }
}
export default injectIntl(Configurations);