import React, { useState,useEffect,Component } from 'react';
import axios from 'axios';
import { Form, Modal, Dropdown, Button } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import changelog from './changelog.json';
import './modalChangelogs.scss'

class modalChangelogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changes: [],
        }
        
    }

    readChanges = (changes) => {
        let out = []
        for(let change of changes){
            if(typeof(change) == 'object'){
                out.push(Object.keys(change)[0])
                out.push(this.readChanges(change[Object.keys(change)[0]]))
            }
            else{
                out.push(change)
            }
        }
        return out
    }

    renderChanges = (changes) => {
        return <ul>
            {
                changes.map((change, i) => 
                    typeof(change) == "string" ? <li key = {i}> {change} </li> : this.renderChanges(change)
                )
            }
        </ul>
    }

    render() {  
        const { open, onClose } = this.props;
        return (
            <div>
                <Modal id="changelogBox" closeIcon={true}
                    style={{ margin: '0 auto', width: '30%'}}
                    open={open}
                    onClose={() => onClose(false)}
                >
                    <Modal.Header id="changelogHead" closeButton>
                        <img id="changeIcona" src="\changelog.png"/>
                        <h1 className="titolo" style={{ display: 'inline-flex' }}><strong className="titolo"><span className="titolo">Changelog</span></strong></h1>
                    </Modal.Header>
                    <Modal.Content id="scrollingTab" className="modal-min-height">
                        <Modal.Description>                   
                             {changelog.changelog.map((ver, i) => (
                                <div key={i} className="divChange">
                                    <h2 id="versione">Version: {ver.version}</h2> 
                                    {this.renderChanges(this.readChanges(ver.changes))}
                                </div>       
                            ))}
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(modalChangelogs);
