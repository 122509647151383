import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Input } from 'semantic-ui-react';
import axios from 'axios';
import './callToBuy.scss';
import NotifyComponent from '../notify/notifyComponent';

class callToBuy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: "",
            listaPrezzi: [],
            modalAddSite: false,
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false,
            checkapi: true
        }
    }

    componentDidMount = () => {
        console.log(this.props.location)
        if(this.props.location.includes("Server") || this.props.location.includes("Cloudbox")){
            this.checkApi();
        }
        this.getPrezzi();
    }


    componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    checkApi = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/check-api`)
            .then((response) => {
                this.setState({checkapi: false})
            })
            .catch(() => {
            });
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }

    renderServiceCreate(location) {
        if(location == "Cloudbox") {
            this.props.history.push("/cloudbox/create")
        } else if(location == "Server") {
            this.props.history.push("/vmservers/create")
        }
    }

    getPrezzi = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/domains/getalltldprices`)
            .then((response) => {
                this.setState({listaPrezzi: response.data})
            })
            .catch((error) => {
                console.log(error)
            });
    }

    onChange = (e) => {
		let valuetocheck = e.target.value
        e.target.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '')
		let val = e.target.value;
		val = val.replace(/\s/g, '');
		val = val.replace(/www./g, '')
		val = val.replace(/https:/g, '')
		val = val.replace(/http:/g, '')
		val = val.replace(/https/g, '')
		val = val.replace(/http/g, '')
		//tutti caratteri speciali tranne punto e lettere accentate
		this.setState({ [e.target.name]: val });
	};

    addDomain() {
		this.setState({ showLoader: true });
        this.notify("Caricamento", "info", "add", false)
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/api/domains/check`, { name: this.state.domain },
			)
			.then((response) => {
				if (response.data.status !== 'failed') {
					// this.state.steps[0].disabled = true;
					// this.state.steps[1].disabled = false;
					this.setState({
						steps: this.state.steps,
						domainDetails: response.data.message,
						showLoader: false
					});
					var action = (response.data.status === 'AVAILABLE' ? 'register' : 'transfer');
					this.props.history.push('/domainRegistration', { domain: this.state.domain, action: action });
				} else {
					//this.showAlert(response.data.message);
					this.notify(<FormattedMessage id="notify.domain-add.failed" />, "error", "add", true)
					this.setState({
						showLoader: false
					});
					// this.props.history.push('/domainRegistration');
				}
			})
			.catch((error) => {
				this.showAlert(error.message);
				this.setState({
					showLoader: false
				});
			});
	}

    render() {
        let imgClass = "callImage"+this.props.location;
        const { formatMessage } = this.props.intl;
        if(this.props.location == "Domini") {
            return(
                <div className="callToBuy">
                    <div className="callToBox">
                        <div className="imageContainer"><img className={imgClass+" callImage"}/></div>
                        <p>Non possiedi ancora nessun Dominio, comprane uno per iniziare!</p>
                        <Input
                            type="text"
                            className="inputNewDomain"
                            name="domain"
                            value={this.state.domain}
                            onChange={this.onChange}
                            required="required"
                            placeholder={formatMessage({ id: "domain.verify_text" })}
                        />
                        <Button onClick={() => this.addDomain()} disabled={this.state.domain.length < 3}>
                            CONTROLLA DOMINIO
                        </Button>
                        <hr className='lineDivider'/>
                        <div className='prezziContainer'>
                            <span className='scorrimentoPrezzi'>
                                {this.state.listaPrezzi.map((item, i) => {
                                    return <span style={{"margin-left": "3px"}} >{item.tld}<span style={{"margin-left": "2px"}}  className='ui green horizontal label'>{item.price+"€"}</span></span>
                                })}
                            </span>
                        </div>
                    </div>
                    {
                        this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                    }
                </div>
            )
        } else if(this.props.location == "Siti") {
            return(
                <div className="callToBuy">
                    <div className="callToBox">
                        <div className="imageContainer"><img className={imgClass+" callImage"}/></div>
                        <p>Non possiedi ancora nessun sito, aggiungine uno per iniziare!</p>
                        <Button onClick={() => {this.props.addSite()}}>AGGIUNGI SITO</Button>
                    </div>
                    {
                        this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                    }
                </div>
            )
        } else
        return(
            <div className="callToBuy">
                <div className="callToBox">
                    <div className="imageContainer"><img className={imgClass+" callImage"}/></div>
                    {console.log(this.state.checkapi)}
                    {this.state.checkapi ? <p>Il tuo account non è abilitato per questo acquisto, contatta l'assistenza per l'attivazione</p> : <p>Non possiedi ancora nessun {this.props.location}, comprane uno per iniziare!</p>}
                    <Button onClick={() => this.renderServiceCreate(this.props.location)} disabled = {this.state.checkapi}>ACQUISTA SERVIZIO</Button>
                </div>
                {
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
			</div>
        )
    }

}

export default injectIntl(callToBuy)