import React, { Component } from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { Icon } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from "react-intl";
import NotifyComponent from '../notify/notifyComponent';

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personalName: "",
      email: "",
      password: "",
      password2: "",
      locationID: "",
      inputType: "password",
      openNotify: false,
      notifyType: "",
      notifyContent: "",
      notifyIcon: "",
      completed: false
    };
  }

  handleLogo = () => {
    let location = "" + window.location;
    if(location.includes("?id=")){
      let id = "" + location.split(['=']).pop()
      this.setState({
        locationID: id
      })
    }
  }

  componentDidMount() {
    this.handleLogo();
  }

  onChange = (e) => {
    let valuetocheck = e.target.value
    e.target.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
    this.setState({ [e.target.name]: e.target.value });
  };

  componentWillUnmount = () => {
		window.stop();
		window.clearTimeout()
	}

  sleep = (ms) => {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	notify = (content, type, icon, completed) => {
		if(!this.state.openNotify) {            
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed,
				openNotify: true
			}, async () => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
		} else {
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed
			}, async() => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
			
		}
	}

  onSubmit = (e) => {
    e.preventDefault();
    this.notify("", "info", 'add', false);
    // get our form data out of state
    const { personalName, email, password } = this.state;
    if (this.state.password == this.state.password2) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/users`, {
          personalName,
          email,
          password,
        })
        .then((response) => {
          this.notify(<FormattedMessage id="notify.account-created" />, "success", 'add', true);
          this.setState({
            personalName: "",
            email: "",
            password: "",
            password2: "",
          });
          this.props.history.push("/login");
        })
        .catch((error) => {
          this.notify(<FormattedMessage id="notify.account-created.failed" />, "error", 'add', true);
        });
    } else {
      this.notify(<FormattedMessage id="notify.password-not-match" />, "error", 'add', true);
    }
  };

  switchPassword = () => {
    if(this.state.inputType == "password") {
        this.setState({inputType: "text"})
    } else {
        this.setState({inputType: "password"})
    }
}

  render() {
    const { personalName, email, password, password2 } = this.state;
    return (
      <form className="form-login" onSubmit={this.onSubmit}>
        <div className="row">
          <div
            className="col-6 text-left"
            style={{ paddingTop: "8px", paddingBottom: "4px" }}
          >
            <h5>
              <FormattedMessage id="user.register" />
            </h5>
          </div>
          <div className="col-6 text-right">
            <h6
              id="show-login"
              style={{ paddingTop: "12px" }}
              onClick={this.props.toggle}
            >
              <Link className="link-blue" to={this.state.locationID != "" ? "/login" + "?id=" + this.state.locationID : "/login"}>
                <FormattedMessage id="app.back" />
              </Link>
            </h6>
          </div>
        </div>
        <hr className="reduced-hr-margin" style={{ marginTop: 0 }} />
        <div className="form-group text-left">
          <label>
            <FormattedMessage id="app.nameandsurname" />
          </label>
          <input
            type="text"
            className="form-control"
            name="personalName"
            required="required"
            value={personalName}
            onChange={this.onChange}
          />
        </div>
        <div className="form-group text-left">
          <label>
            <FormattedMessage id="app.email" />
          </label>
          <input
            type="email"
            className="form-control"
            name="email"
            required="required"
            value={email}
            onChange={this.onChange}
          />
        </div>
        <div className="form-group text-left">
          <label>
            <FormattedMessage id="app.password" />
          </label>
          <input
            type={this.state.inputType}
            icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword()} />} iconPosition='right'
            name="password"
            className="form-control"
            required="required"
            value={password}
            onChange={this.onChange}
          />
        </div>
        <div className="form-group text-left">
          <label>
            <FormattedMessage id="app.conferm_password" />
          </label>
          <input
            type={this.state.inputType}
            icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword()} />} iconPosition='right'
            name="password2"
            className="form-control"
            required="required"
            value={password2}
            onChange={this.onChange}
          />
        </div>
        <p id="pass-not-match">Le due password non coincidono!</p>
        <div className="form-group">
          <button type="submit" className="btn btn-primary btn-block">
            <FormattedMessage id="user.submit" />
          </button>
        </div>
        {
          this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
        }
      </form>
    );
  }
}

export default withRouter(Signup);
