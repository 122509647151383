import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter, customFilter } from 'react-bootstrap-table2-filter';
import { Dropdown, Dimmer, Loader, Button, Icon } from 'semantic-ui-react';
import Modal from './Modal';
import ModalConfirm from './ModalConferm';
import Header from '../header/header';
import Footer from '../footer/footer';
import LeftSidebar from '../sidebar/left-sidebar';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import ModalAddUser from './modalAddUser';
import NotifyComponent from '../notify/notifyComponent';

const { SearchBar, ClearSearchButton } = Search;

//
class Partners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            contactlist: [],
            partners: [],
            partnerId: '',
            offset: 0,
            page: 1,
            totalSize: 0,
            sizePerPage: 10,
            currentIndex: 0,
            loading: false,
            filter: {},
            modalAddContactOpen: false,
            add: false,
            currentInfo: {},
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false,
            isAdmin:false
        }
        localStorage.setItem('api-vs-key', '');
        axios.defaults.headers.common['x-api-vs-key'] = this.token;
        this.token = localStorage.getItem('jwt');
        axios.defaults.headers.common['x-auth-token'] = this.token;
        this.notify = this.notify.bind(this);
        this.columns = this.defineColumns();
        //this.getContacts = this.getContacts.bind(this);
    }

    componentDidMount() {
        this.getadmin();
        this.getPartners();
    }

    componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }

    /*
    search methods
    */
    getPartners = (params) => {
        this.setState({ filter: params })
        this.setState({ loading: true });
        axios.
            get(`${process.env.REACT_APP_API_URL}/api/users/filter`, {
                params: params/* {
					currentIndex: this.state.currentIndex,
					sizePerPage: this.state.sizePerPage,
					partnerId: params ? params.partnerId : null
				} */
            })
            .then((response) => {
                //this.setState({ contactlist: response.data.dataset });
                /*
                this.setState(() => ({
                    partners: response.data.dataset,
                    totalSize: response.data.totalCount,
                    loading: false
                }));
                */
                let totalCount = response.data.totalCount;
                this.setState(() => ({
                    partners: response.data.dataset,
                    //page,
                    // ???? data: result.slice(currentIndex, currentIndex + sizePerPage),
                    totalSize: totalCount,
                    //sizePerPage,
                    //currentIndex: currentIndex,
                    loading: false
                }));
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.showAlert(error.message);
            });
    };

    

    changeJwt = async (row) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/contact/adminauth`, {userid: row.value.userid})
            .then((response) => {
                if(!localStorage.getItem("baseuser")){
                    localStorage.setItem("baseuser", localStorage.getItem("user"))
                    localStorage.setItem("basejwt", localStorage.getItem("jwt"))
                }
                localStorage.setItem("jwt", response.data.token);
                localStorage.setItem("isAdmin", false);
                localStorage.setItem("user", response.data.user);
                localStorage.setItem("restore", true);
                localStorage.setItem("multitenancy", response.data.multitenancy);
                window.location.reload();
            })
    }

    restoreJwt = async () => {
        if(localStorage.getItem("baseuser")){
            localStorage.setItem("jwt", localStorage.getItem("basejwt"));
            localStorage.setItem("isAdmin", true);
            localStorage.setItem("user", localStorage.getItem("baseuser"));
            localStorage.setItem("restore", false);
            window.location.reload();
        }
    }


    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        var currentIndex = (page - 1) * sizePerPage;
        if (type === 'search') {
            currentIndex = 0;
        }
        this.setState({ loading: true });
        var filter = {
            currentIndex: currentIndex,
            sizePerPage: sizePerPage ? sizePerPage : this.state.sizePerPage,
            q: searchText,
            sortField: sortField,
            sortOrder: sortOrder,
            partnerId: this.state.partnerId ? this.state.partnerId : null
        };
        this.setState({ filter: filter });
        this.setState({
            page,
            sizePerPage,
            currentIndex: currentIndex,
        })
        this.getPartners(filter);
    }


    /*
    Partner/User methods
    */
    modifyPartner = (data, callback) => {
        this.notify("Caricamento", "info", "pencil", false)
        if (!_.isEmpty(data.new_password)) {
            if (data.new_password !== data.conferm_password) {
                this.notify(this.props.intl.formatMessage({ id: "app.passwords_not_match" }), "error", "pencil", true)
                //callback(null, true);
                return false;
            }
        }
        if (!data.id) {
            this.notify(this.props.intl.formatMessage({ id: "notify.partner-not-saved" }), "error", "pencil", true)
            //callback(null, true);
            return false;
        }
        axios.put(`${process.env.REACT_APP_API_URL}/api/users/${data.id}`, data)
            .then((response) => {
                this.notify(this.props.intl.formatMessage({ id: "notify.partner-saved" }), "success", "pencil", true)
                this.getPartners();
                callback(response, null);
            }).catch(e => {
                this.notify(this.props.intl.formatMessage({ id: "notify.partner-not-saved" }), "error", "pencil", true)
                console.log(e);
                callback(null, e);
            });
    }
    addPartner = (data, callback) => {
        this.notify("Caricamento", "info", "add", false)
        if (!_.isEmpty(data.new_password)) {
            if (data.new_password !== data.conferm_password) {
                this.notify(this.props.intl.formatMessage({ id: "app.passwords_not_match" }), "error", "add", true)
                //callback(null, true);
                return false;
            }
        }
        if (data.id) {
            this.notify(this.props.intl.formatMessage({ id: "notify.partner-duplicated" }), "error", "add", true)
            //callback(null, true);
            return false;
        }
        axios.post(`${process.env.REACT_APP_API_URL}/api/users/partner`, data)
            .then((response) => {
                this.notify(this.props.intl.formatMessage({ id: "notify.partner-saved" }), "success", "add", true)
                this.getPartners();
                callback(response, null);
            }).catch(e => {
                this.notify(this.props.intl.formatMessage({ id: "notify.partner-not-saved" }), "error", "add", true)
                console.log(e);
                callback(null, e);
            });
    }

    changeBalance = (data, callback) => {
        this.notify("Caricamento", "info", "pencil", false)
        axios.post(`${process.env.REACT_APP_API_URL}/api/users/${data.id}/adjust-ballance`, data)
            .then((response) => {
                this.notify(this.props.intl.formatMessage({ id: "app.msg_user_balance_ok" }), "success", "pencil", true)
                this.getPartners(this.state.filter);
                callback(response, null);
            }).catch(e => {
                this.notify(this.props.intl.formatMessage({ id: "app.msg_user_balance_ko" }), "error", "pencil", true)
                console.log(e);
                callback(null, e);
            });
    }

    NO_deleteUser = (id) => {
        this.notify("Caricamento", "info", "pencil", false)
        axios.delete(`${process.env.REACT_APP_API_URL}/api/user/${id}`, {})
            .then((response) => {
                this.notify(this.props.intl.formatMessage({ id: "notify.delete-contact" }), "success", "pencil", true)
                this.getContacts();
            }).catch(e => {
                this.notify(this.props.intl.formatMessage({ id: "notify.delete-contact.failed" }), "error", "pencil", true)
                console.log(e);
            });
    }

    onChange = (name, val) => {
        this.setState({ [name]: val });
    }


    defineColumns = () => {
        var columns = [/*{
            dataField: 'id',
            text: this.props.intl.formatMessage({ id: "app.id" }),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '50px', textAlign: 'center' };
            }
        },*/ {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "contact.company" }),
                sort: true,
                classes: 'cut-words'
            }, {
                dataField: 'partitaIVA',
                sort: true,
                text: this.props.intl.formatMessage({ id: "app.vatnumber" }),
                classes: 'cut-words'
            }, {
                dataField: 'email',
                sort: true,
                text: this.props.intl.formatMessage({ id: "app.email" }),
                classes: 'cut-words'
            },{
                headerStyle: (colum, colIndex) => {
                    return { width: '90px' };
                },
                headerFormatter: () => {
                    return (<Button onClick = {() => this.restoreJwt()} size = 'tiny' > Restore </Button>);
                },
                formatter: (cellContent, row, rowIndex, formatExtraData) => {
                    return (
                        <div className="d-flex flex-row align-items-center" >
                            <div style={{"margin-left":"8px"}}/>
                            <Button onClick = {(e, value) => this.changeJwt(value)} value = {row} size = 'tiny' > login </Button>
                        </div>
                    );
                }
            }, {
                dataField: 'balance',
                text: this.props.intl.formatMessage({ id: "app.balance" }),
                classes: 'cut-words',
                align: 'right',
                headerStyle: (colum, colIndex) => {
                    return { width: '80px' };
                },
            },
            {
                dataField: 'cash',
                text: this.props.intl.formatMessage({ id: "app.cash" }),
                classes: 'cut-words',
                align: 'right',
                headerStyle: (colum, colIndex) => {
                    return { width: '80px' };
                },
            },
            {
                dataField: 'bonus',
                text: this.props.intl.formatMessage({ id: "app.bonus" }),
                classes: 'cut-words',
                align: 'right',
                headerStyle: (colum, colIndex) => {
                    return { width: '80px' };
                },
            },
            {
                dataField: 'welcome',
                text: "Welcome",
                classes: 'cut-words',
                align: 'right',
                headerStyle: (colum, colIndex) => {
                    return { width: '80px' };
                },
            }];
        columns.push({
            dataField: 'df1',
            text: '',
            isDummyField: true,
            headerStyle: (colum, colIndex) => {
                return { width: '88px' };
            },
            headerFormatter: () => {
                return (
                    <div className="-d-flex">                        
                    {(this.state.isAdmin) ?                            
                        <Button icon = "add" size = "small" onClick = {() => this.setState({modalAddContactOpen: true, add: true, currentInfo: {}})} />
                    :null}
                    </div>
                );
            },
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                return (
                    <div className="d-flex flex-row">
                        <Button icon = "pencil" size = "small" onClick = {() => this.setState({modalAddContactOpen: true, add: false, currentInfo: row})} />
                        <Modal type={{ Icon: "euro", Formtype: "user_balance", ButtonLabel: this.props.intl.formatMessage({ id: "app.edit" }) }} formData={row} changeBalance={this.changeBalance} notify={this.notify} />
                        {/* <ModalConfirm modalCfg={{ type: "delete", color: "grey" }} onBoh={this.deleteContact} onYes={() => this.deleteContact(row.id)} /> */}
                    </div>
                );
            },
            formatExtraData: {
                lng: this.props.language
            }
        });
        return columns;
    }

    getadmin = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
			.then((response) => {
				this.setState({
					isAdmin: response.data.isAdmin
				});
			})
			.catch((error) => {
                console.log(error)
            });
	}
    
    getUserData = (userdata) => {
        this.setState({ balance: userdata.balance });
    }

    render() {
        const { page, sizePerPage, totalSize, loading } = this.state;
        const sizePerPageList = [5, 10, 15];
        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];
        const { formatMessage } = this.props.intl;
        return (
            <div className="kx-page">
                {loading ?
                    <Dimmer active inverted>
                        <Loader size='medium'></Loader>
                    </Dimmer>
                    : null}
                {this.state.isAdmin ? <><Header history={this.props.history} language={this.props.language} changeLanguage={this.props.changeLanguage} getUserData={this.getUserData} />

                <div className="with-nav-bar with-left-nav">


                    <div className="">
                        <div className="kx-left-nav">
                            <LeftSidebar history={this.props.history} location={this.props.location}
                                styles={{ sidebar: { background: "blue" } }} />
                        </div>
                    </div>

                    <div className="kx-content">

                        <div className="kx-container">

                            <div className="row">
                                {/*<Modal type={{ Formtype: "contact", ButtonLabel: "New" }} formData={{}} addContact={this.addContact} />*/}

                                <div className="col-12">
                                    <ToolkitProvider
                                        keyField="lastname"
                                        data={this.state.partners}
                                        columns={this.columns}
                                        defaultSorted={defaultSorted}
                                        bootstrap4
                                        search
                                    >
                                        {
                                            toolkitprops => [
                                                <div className="searchBar">
                                                    <div className="searchBtn">
                                                        <SearchBar {...toolkitprops.searchProps} delay={1500} placeholder={formatMessage({ id: "table.search" })} />
                                                        <ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
                                                    </div>
                                                </div>,
                                                <div className='infodiv boxTabella'>
                                                <BootstrapTable
                                                    {...toolkitprops.baseProps}
                                                    remote={{ search: true, pagination: true, sort: true }}
                                                    striped hover
                                                    classes="table-responsive-lg"
                                                    pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                                                    onTableChange={this.handleTableChange}

                                                /></div>
                                            ]
                                        }
                                    </ToolkitProvider>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <ModalAddUser open = {this.state.modalAddContactOpen} onClose = {() => {this.setState({modalAddContactOpen : false}); if(this.state.isAdmin) this.getPartners()}} currentInfo = {this.state.currentInfo} add = {this.state.add} notify={this.notify}/>
                {
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
                <Footer /></>
                : null}
            </div >)
    }
}

export default injectIntl(Partners);
