import React, { Component } from 'react';
import '../All/table.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Dropdown, Button, Input } from 'semantic-ui-react';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import '../vmservers/vmservers.scss';
import ModalConferm from '../All/ModalConferm';
import ModalEditSSH from './ModalEditSSH';
import ModalTestSSH from './ModalTestSSH';
import ModalAddSSH from './ModalAddSSH';
import NotifyComponent from '../notify/notifyComponent';
const { SearchBar, ClearSearchButton } = Search;

class ConfigurationSSH extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
			totalSize: 0,
			sizePerPage: 10,
            keysArray: [],
            statewritten: false,
            selectedID: -1,
            openNotify: false,
            notifyType: "",
            notifyContent: "",
            notifyIcon: "",
            completed: false
        }
        localStorage.setItem('api-vs-key', '');
        axios.defaults.headers.common['x-api-vs-key'] = this.token;
        this.token = localStorage.getItem('jwt');
        axios.defaults.headers.common['x-auth-token'] = this.token;
    }

    componentDidMount(){
        this.getKeys()
    }

	componentWillUnmount = () => {
        window.stop();
        window.clearTimeout()
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    notify = (content, type, icon, completed) => {
        if(!this.state.openNotify) {            
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed,
                openNotify: true
            }, async () => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
        } else {
            this.setState({
                notifyContent: content,
                notifyType: type,
                notifyIcon: icon,
                completed: completed
            }, async() => {
                if(completed) {
                    await this.sleep(10000);
                    this.setState({
                        openNotify: false
                    })
                }
            })
            
        }
    }
    getKeys = async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/getkeyslist`)
        .then((response) => {
            if (response.data){
                this.setState({
                    keysArray: response.data
                })
            }
        })
        .catch((error) => {
            console.log(error)
            this.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
        });
    }

    deleteSSH = async (id) => { //DA FARE------------------------------------------------------------------------
        this.notify("Caricamento", "info", "trash", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vm/deleteproject`, {id: id})
        .then((response) => {
            this.notify("Progetto cancellato con successo", "success", "trash", true)
            this.getProjects()
        })
        .catch((error) => {
            this.notify(""+error, "error", "trash", true)
        });
    }
    
    columns = [
        {
            dataField: 'hz_name',
            sort: true,
            text: 'Nome nel portachiavi',
            headerStyle: (colum, colIndex) => {
                return { width: '400px' };
            },
            formatter: (cellContent, row) => {
                if (cellContent == "-1"){
                    return "Non presente nel portachiavi del progetto"
                } else {
                    return row.hz_name
                }
            }
        },
        {
            dataField: 'server_name',
            sort: true,
            text: 'Ultimo server creato con la chiave',
            headerStyle: (colum, colIndex) => {
                return { width: '400px' };
            }
        },        {
            dataField: 'azioni',
            sort: true,
            text: 'Azioni',
            headerStyle: (colum, colIndex) => {
                return { width: '1000px' };
            },
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                return <div>
                    <Button onClick = {() => this.setState({modalEditSSHOpen: true, selectedSSH: row})}>Modifica key esistenti</Button>
                    <Button onClick = {() => this.setState({modalTestSSHOpen: true, selectedSSH: row})}>Testa un comando con questa chiave</Button>
                    <Button onClick = {() => navigator.clipboard.writeText(row.private)}>Copia chiave privata</Button>
                    <Button onClick = {() => navigator.clipboard.writeText(row.public)}>Copia chiave pubblica</Button>
                </div>
            }
        },
        {
            headerFormatter: () => {
                return (
                    <div className="d-flex">
                        <Button icon = "add" onClick = {() => this.setState({modalAddSSHOpen: true})}/>
                    </div>
                )},
                
            formatter: (cellContent, row, rowIndex, formatExtraData) => {
                return <div>
                    <ModalConferm modalCfg={{ type: "delete"}} onYes={() => this.deleteProject(row.id)} />
                </div>
            }
        }
    ]

    deleteProject = (id) => {
        this.notify("Caricamento", "info", "trash", false)
        axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/deletessh`, {id:id})
        .then(async (response) => {
            this.notify("La chiave è stata eliminata", "success", "trash", true)
            this.getKeys();
        }).catch(e => {
            this.notify("L'errore durante l'eliminazione", "error", "trash", true);
            console.log(e);
        });
    }

    render() {
        const { page, sizePerPage, totalSize, loading } = this.state;
        const sizePerPageList = [5, 10, 15];
        const defaultSorted = [{
            dataField: 'id',
            order: 'desc'
        }];
        const { formatMessage } = this.props.intl;
        return (
            <div className="kx-page">
                <ModalEditSSH data = {this.state.selectedSSH} notify = {this.notify} open={this.state.modalEditSSHOpen} onClose={()=>{this.setState({modalEditSSHOpen: false}); this.getKeys() }} />
                <ModalTestSSH data = {this.state.selectedSSH} notify = {this.notify} open={this.state.modalTestSSHOpen} onClose={()=>{this.setState({modalTestSSHOpen: false}); this.getKeys() }} />
                <ModalAddSSH notify = {this.notify} open={this.state.modalAddSSHOpen} onClose={()=>{this.setState({modalAddSSHOpen: false}); this.getKeys() }} />
                <ToolkitProvider
                    keyField="id"
                    data={this.state.keysArray}
                    columns={this.columns}
                    defaultSorted={defaultSorted}
                    bootstrap4
                    search
                >
                    {
                        toolkitprops => [
                            <div className="searchBar">
                                <div className="searchBtn">
                                    <SearchBar ref={n => this.searchKit = n} id="s1" delay={1500} {...toolkitprops.searchProps} placeholder={formatMessage({ id: "table.search" })} />
                                    <ClearSearchButton  {...toolkitprops.searchProps} className="ui button pulsanteCerca" text={formatMessage({ id: "table.clear" })} />
                                </div>
                            </div>,
                            <div className='infodiv boxTabella'>
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    hover
                                    classes="table-responsive overflow-yes"
                                    pagination={paginationFactory({ page, sizePerPage, totalSize, sizePerPageList })}
                                    onTableChange={this.handleTableChange}
                                />
                            </div>
                        ]
                    }
                </ToolkitProvider>
                {
                    this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
                }
            </div>)
    }
}

export default injectIntl(ConfigurationSSH);
