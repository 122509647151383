import React, { Component } from 'react';
import axios from 'axios';
import { Button, Form, Modal, Divider, Grid, Label } from 'semantic-ui-react';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { unwatchFile } from 'fs';

class ModalWithoutButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domaininfo: {},
            ispconfig: [],
            ns_servers: [],
            ns_type: 'isp',
            isp_value: 0,
            ns1: '',
            ns2: '',
            custom_ns1: '',
            custom_ns2: ''
        }
        this.loadIspConfig();
        this.loadNSServers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedDomain !== this.props.selectedDomain) {
            this.loadDomain(this.props.selectedDomain.id);
            //IntercomAPI("update", { hide_default_launcher: this.props.hidden });
        }
    }

    loadIspConfig = (params) => {
        //const token = localStorage.getItem('jwt');
        //const decoded = jwt_decode(token);
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/settings/getISPconfigNS`)
            .then((response) => {
                this.setState({
                    ispconfig: response.data.ispconfig,
                });
            })
            .catch((error) => {
                console.log(error)
            });
    };

    loadNSServers = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/server`, { params: { type: "ns" } })
            .then((response) => {
                this.setState({
                    ns_servers: response.data.servers,
                });
            })
            .catch((error) => {
                console.log(error)
            });
    };


    loadDomain = (domainid) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/domains/${domainid}`
        ).then((res) => {
            this.setState({
                domaininfo: res.data,
                isp_value: res.data.isp_id ? res.data.isp_id : 0
            });
        });
    }


    handleTypeChange = (e, { value }) => this.setState({ ns_type: value });

    onChange = (e, data) => {
        this.setState({ [data.name]: data.value });
    };

    changeServers = () => {
        var ns1, ns2;
        const { formatMessage } = this.props.intl;
        this.props.notify("Caricamento", "info", "pencil", false)
        switch (this.state.ns_type) {
            case "isp":
                let row = this.state.ispconfig.find(row => row.id == this.state.isp_value);
                if (row) {
                    ns1 = row.ns1;
                    ns2 = row.ns2;
                }
                break;
            case "servers":
                ns1 = this.state.ns1;
                ns2 = this.state.ns2;
                break;
            case 'custom':
                ns1 = this.state.custom_ns1;
                ns2 = this.state.custom_ns2;
                break;
        }
        const servers = ns1 + ', ' + ns2;
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/domains/changeNS`, { domain: this.state.domaininfo.name, servers: servers, isp_id: this.state.isp_value })
            .then((response) => {
                this.props.notify(formatMessage({ id: "ns.ns_is_changed" }), "success", "pencil", true)
                this.props.onClose();
            })
            .catch(function (error) {
                console.log(error, error.response.data);
                this.props.notify(formatMessage({ id: "ns.ns_is_not_changed" }) + "\n" + error.response.data.message, "error", "pencil", true)
            });
    }


    firstSetUp = () => {
        if(!this.state.statewritten && this.props.open){
            this.setState({
                statewritten: true
            })
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                ns_type: 'isp',
                ns1: '',
                ns2: '',
                custom_ns1: '',
                custom_ns2: '',
                statewritten: false
            })
        }
    }

    syncZone = async(isp_id) => {
        this.props.notify("Caricamento", "info", "sync", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/domains/loadzones/${isp_id}`)
            .then((response) => {
                this.props.notify(<FormattedMessage id="notify.sync-zone" />, "success", "sync", true)
            })
            .catch((error) => {
                this.props.notify(<FormattedMessage id="notify.sync-zone.failed" />, "error", "sync", true)
            })
    }


    render() {
        const { addServer, addContact, type, formData, open, onClose } = this.props;
        const { formatMessage } = this.props.intl;
        this.firstSetUp()
        return (
            //const ModalModalExample = ({ onChange, addServer, addContact, type, formData }) => (

            <Modal closeIcon={true}
                closeOnDimmerClick={true}
                style={{ margin: '0 auto' }}
                open={open}
                onClose={onClose}
            >
                <Modal.Header>
                    {(type.Formtype === "change_ns" || true) ? formatMessage({ id: "app.change_ns" }) : ""}
                    <Button className="rightSyncButton" onClick={() => this.syncZone(this.state.isp_value)}>SINCRONIZZA</Button>
                </Modal.Header>
                <Modal.Content className="modal-min-height">
                    <Modal.Description>
                        <Form>
                            <Grid columns={2} padded>
                                <Grid.Column>
                                    <Label color='blue' horizontal>NS 1</Label>
                                    {this.state.domaininfo.ispconfig ? this.state.domaininfo.ispconfig.ns1 : null}
                                </Grid.Column>
                                <Grid.Column>
                                    <Label color='blue' horizontal>NS 2</Label>
                                    {this.state.domaininfo.ispconfig ? this.state.domaininfo.ispconfig.ns2 : null}
                                </Grid.Column>
                            </Grid>
                            <Form.Group >
                                <Form.Radio
                                    label={formatMessage({ id: "ns.from_isp" })}
                                    name='radioGroup'
                                    value='isp'
                                    checked={this.state.ns_type === 'isp'}
                                    onChange={this.handleTypeChange}
                                />
                            </Form.Group >
                            <Form.Group >
                                <Form.Dropdown
                                    placeholder={formatMessage({ id: "dns.record_type" })}
                                    onChange={this.onChange}
                                    fluid
                                    selection
                                    name='isp_value'
                                    options={this.state.ispconfig.map(({ id, description }) => ({ value: id, text: description }))}
                                    value={this.state.isp_value}
                                />
                                {/* defaultValue={formDataJson.title}  */}
                                {/* <Form.Input label="Title" name="title" defaultValue={formDataJson.name} placeholder="Title" onChange={(e) => onChange("name", e.target.value)} />  */}
                            </Form.Group>
                            <Divider />
                            <Form.Group >
                                <Form.Radio
                                    label={formatMessage({ id: "ns.from_servers" })}
                                    name='radioGroup'
                                    value='servers'
                                    checked={this.state.ns_type === 'servers'}
                                    onChange={this.handleTypeChange}
                                />
                            </Form.Group >
                            <Form.Group >
                                <Form.Dropdown
                                    placeholder="type"
                                    label="NS1"
                                    name="ns1"
                                    value={this.state.ns1}
                                    onChange={this.onChange}
                                    fluid
                                    selection
                                    options={this.state.ns_servers.map(({ name, ip }) => ({ value: ip, text: name + ' - ' + ip }))}
                                />
                                <Form.Dropdown
                                    placeholder="type"
                                    label="NS2"
                                    name="ns2"
                                    value={this.state.ns2}
                                    onChange={this.onChange}
                                    fluid
                                    selection
                                    options={this.state.ns_servers.map(({ name, ip }) => ({ value: ip, text: name + ' - ' + ip }))}
                                />
                            </Form.Group>
                            <Divider />
                            <Form.Group >
                                <Form.Radio
                                    label={formatMessage({ id: "ns.from_custom" })}
                                    name='radioGroup'
                                    value='custom'
                                    checked={this.state.ns_type === 'custom'}
                                    onChange={this.handleTypeChange}
                                />
                            </Form.Group >
                            <Form.Group>
                                <Form.Input label="NS1" name="custom_ns1" value={this.state.custom_ns1 || ''} placeholder="FQDN" onChange={this.onChange} />
                                <Form.Input label="NS2" name="custom_ns2" value={this.state.custom_ns2 || ''} placeholder="FQDN" onChange={this.onChange} />
                            </Form.Group>
                            <Button floated="right" onClick={() => this.changeServers()}>
                                <FormattedMessage id="ns.change_servers" />
                            </Button>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }
}

export default injectIntl(ModalWithoutButton);
