import React, { Component } from 'react';
import {Modal, Form, Dropdown, Icon } from 'semantic-ui-react';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';

class ModalConvertServerToGateway extends Component {
    constructor(props) {
        super(props);
		this.instance = React.createRef();
        this.state = {
            id:"",
            password:"",
            port:"",
            servers: [],
            inputType: "password"
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    save = async() => {
        this.props.notify("Caricamento", "info", "save", false)
        await axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/servertypeassigngateway`, {id: this.state.id, password:this.state.password, port:this.state.port, serverid:this.state.server})
            .then((response) => {
                this.props.notify("Server type assegnato correttamente", "success", 'save', true)
                this.props.onClose()
            })
            .catch((error) => {
                console.log(error)
                this.props.notify("" + error, 'error', 'save', true);
            })
    }

    firstSetUp = () => {
        if(!this.state.statewritten && this.props.open){
            this.setState({
                statewritten: true
            })
            this.getServers()
        }
        if(this.state.statewritten && !this.props.open){
            this.setState({
                statewritten: false
            })
        }
    }

    getServers = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/vmservers/getallserversfromdb`)
            .then(async (response) => {
                let servers = response.data
                this.setState(() => ({
                    servers: response.data
                }));
            })
            .catch((error) => {
                console.log(error)
                this.props.notify(<FormattedMessage id="notify.loading-error" />, "error", "close", true);
            })
    };
    
    async handleInputChange(event, { name, value }) {
        let valuetocheck = value
        value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
        await this.setState({
            [name]: value
        });
    }

    onChangeServer = async (e, data) => {
        await this.setState({ server: data.value });
    };

    switchPassword = () => {
        if(this.state.inputType == "password") {
            this.setState({inputType: "text"})
        } else {
            this.setState({inputType: "password"})
        }
    }

    render() {
        const {open, onClose} = this.props;
        const { formatMessage } = this.props.intl;
        this.firstSetUp();
        return (
            <div>
                <Modal closeIcon={true}
                    closeOnDimmerClick={true}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    style = {{width: "30%"}}
                >
                <Modal.Header>
                        Assegna il Type Gateway ad un server
                </Modal.Header>
                <Modal.Content className="modal-min-height">
                    <Modal.Description>
                        <div>
                            <div className="server-form">
                                <Form className="overflow-yes">
                                    <Form.Group className='ROW'>
                                        <Form.Dropdown
                                                placeholder="Server"
                                                search
                                                onChange={this.onChangeServer}
                                                name="domain"
                                                clearable
                                                selection
                                                label = "Server da rendere Gateway:"
                                                options={this.state.servers.map(({ id, server }) => ({ value: id, text: server }))}
                                            />
                                        </Form.Group>
                                    <Form.Group className='ROW' style={{"display":"flex"}}>
                                        <Form.Input className='col-lg-3' type="text" label="ID" placeholder="root" value={this.state.id} name="id" onChange={this.handleInputChange} />
                                        <Form.Input className='col-lg-3' type={this.state.inputType} icon={<Icon style={{cursor: "pointer"}} link name='eye' onClick={(e) => this.switchPassword()} />} iconPosition='right' label="Password" placeholder="password"  value={this.state.password} name="password" onChange={this.handleInputChange} />
                                        <Form.Input className='col-lg-3' type="text" label="Port" placeholder="8080"  value={this.state.port} name="port" onChange={this.handleInputChange} />
                                    </Form.Group>
                                    <Form.Button onClick={() => {this.save()}} >
                                        {formatMessage({ id: "app.save" })}
                                    </Form.Button>
                                </Form>
                            </div>
                        </div>
                    </Modal.Description>
                </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ModalConvertServerToGateway);