import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedDate, injectIntl } from "react-intl";
import { Icon } from 'semantic-ui-react';
import ModalSignUp from "./modalSignup"
import NotifyComponent from '../notify/notifyComponent';

class loginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      locationID: "",
      openSignup : false,
      inputType: "password",
      openNotify: false,
      notifyType: "",
      notifyContent: "",
      notifyIcon: "",
      completed: false
    };
  }

  handleLogo = () => {
    let location = "" + window.location;
    if(location.includes("?id=")){
      let id = "" + location.split(['=']).pop()
      this.setState({
        locationID: id
      })
    }
  }

  componentDidMount() {
    this.handleLogo();
  }
  componentWillUnmount = () => {
		window.stop();
		window.clearTimeout()
	}

	sleep = (ms) => {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	notify = (content, type, icon, completed) => {
		if(!this.state.openNotify) {            
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed,
				openNotify: true
			}, async () => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
		} else {
			this.setState({
				notifyContent: content,
				notifyType: type,
				notifyIcon: icon,
				completed: completed
			}, async() => {
				if(completed) {
					await this.sleep(10000);
					this.setState({
						openNotify: false
					})
				}
			})
			
		}
	}

  onSubmit = (e) => {
    e.preventDefault();
    // get our form data out of state
    const { email, password } = this.state;

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/auth`, {
        email,
        password,
      })
      .then((response) => {
        localStorage.setItem("jwt", response.data.token);
        localStorage.setItem("isAdmin", response.data.isAdmin);
        localStorage.setItem("user", response.data.user);
        localStorage.setItem("changelog", response.data.changelog);
        localStorage.setItem("multitenancy", response.data.multitenancy);
        localStorage.setItem("lang", response.data.language)
        if(response.data.darkmode){
          localStorage.setItem("theme", 'dark')
        }
        else{
          localStorage.setItem("theme", 'light')
        }
        this.props.login();
      })
      .catch((error) => {
        console.log(error)
        this.notify(error.response.data, "error", 'close', true);
      });
  };

  switchPassword = () => {
    if(this.state.inputType == "password") {
        this.setState({inputType: "text"})
    } else {
        this.setState({inputType: "password"})
    }
}

  onChange = (e) => {
    let valuetocheck = e.target.value
    e.target.value = valuetocheck.replace(/([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g, '');
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { email, password } = this.state;
    return (
      <form className="form-login" id="loginForm" onSubmit={this.onSubmit}>
        <div className="ui left icon input">
          <input
            type="text"
            className="form-control"
            name="email"
            value={this.state.email || ""}
            onChange={this.onChange}
            required="required"
            placeholder="Email"
          />
          <i className="user large icon"></i>
        </div>
        <div className="ui left icon input">
          <input
            type={this.state.inputType}
            name="password"
            value={this.state.password || ""}
            onChange={this.onChange}
            className="form-control"
            required="required"
            placeholder="Password"
          />
          <i className="lock icon"></i>
          <Icon className="eyeIcon" link name='eye' onClick={(e) => this.switchPassword()} />
        </div>
        <h6 className="link-pass text-right">
          <Link to={this.state.locationID != "" ? "/login/reset" + "?id=" + this.state.locationID : "/login/reset"} className="link-pass">
            <FormattedMessage id="login.remindpassword" />
          </Link>
        </h6>
        <div className="form-group">
          <button className="btn btn-primary btn-block" type="submit">
            <FormattedMessage id="login.access" />
          </button>
        </div>
        <hr className="reduced-hr-margin" />
        <h6 className="text-center" onClick={this.props.toggle}>
          {/*<Link to={this.state.locationID != "" ? "/login/signup" + "?id=" + this.state.locationID : "/login/signup"} className="link-blue">
            {this.state.locationID === "" ? <FormattedMessage id="login.addaccount"/>: ""}
            </Link>*/}
          <ModalSignUp open = {this.state.openSignup} onClose = {() => this.setState({openSignup : false})} history = {this.props.history} notify={this.notify}/>
          {this.state.locationID === "" ? <a onClick = {() => this.setState({openSignup: true})} style={{cursor: "pointer"}}><FormattedMessage id="login.addaccount" /> </a>: ""}
        </h6>
        {
          this.state.openNotify ? <NotifyComponent open={this.state.openNotify} completed={this.state.completed} icon={this.state.notifyIcon} content={this.state.notifyContent} type={this.state.notifyType} onClose={() => this.setState({openNotify: false})}/> : null
        }
      </form>
    );
  }
}

export default injectIntl(loginForm);
