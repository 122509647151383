import React, { Component } from 'react';
import './footer.scss';
import axios from "axios";

class Footer extends Component {
	state = {
		id: -1,
		logo: "",
		logolink: "",
		isSecondLevelUser: false,
		locationID: -1,
		footer: ""
	};

	handleLogo = () =>{
		let location = "" + window.location;
		if(location.includes("?id=")){
		  let id = "" + location.split(['=']).pop()
		  axios.post(`${process.env.REACT_APP_API_URL}/api/users/logonoauth?id=${id}`)
			.then((response) => {
			  if(response.data === 404){
				this.setState({
				  logo: '/logo cloud on the cloud.png',
				  logolink: "https://cloudonthecloud.it"
				})
			  }
			  else{
				this.setState({
				  locationID: id,
				  logo: response.data.logo,
				  logolink: response.data.logolink,
				  footer: response.data.footer
				})
			  }
			})
		} else {
			this.checkSecondLevel();
		}
	  }

	privacyOpen = async () => {
		const win = window.open("/Privacy_Cloudonthecloud.pdf", "_blank");
		win.focus()
    };
	
	contractOpen = async () => {
        const win = window.open("/Contratto_Cloudonthecloud.pdf", "_blank");
        win.focus()
    };

	tldOpen = async () => {
        const win = window.open("/Additional_tld.pdf", "_blank");
        win.focus()
    };

	checkSecondLevel = async () =>{
		await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`)
		.then(async (response) => {
		if(response){
			if (!response.data.telegramID) {
				await this.setState({
					telegramID: false,
				});
			}
			await this.setState({
				isSecondLevelUser: response.data.isseconduser,
				id: response.data.id
			});
			if(this.state.isSecondLevelUser === true){
				await axios.post(`${process.env.REACT_APP_API_URL}/api/multitenancy/secondleveltofirstlevelid?id=${this.state.id}`)
					.then(async (response) => {
					await this.setState({
						locationID: response.data
					})
				})
				await axios.post(`${process.env.REACT_APP_API_URL}/api/users/logonoauth?id=${this.state.locationID}`)
					.then(async (response) => {
					await this.setState({
						logo: response.data.logo,
						logolink: response.data.logolink,
						footer: response.data.footer
					})
				})
			} else{
				await this.setState({logo:'/logo cloud on the cloud.png'})
			}
			await this.setState({ loading: false }); 
		}
		else{
			await this.setState({logo: '/logo cloud on the cloud.png'})
		}
		})
	}
	
	componentDidMount() {
	this.handleLogo();
	}


	render() {
		return (
			<div className="kx-footer">
				<footer
					className="text-center footer"
					style={{
						fontSize: '10px',
						color: 'black'
					}}
				>
					<a href={this.state.logolink === "" ? "https://cloudonthecloud.it" : this.state.logolink}>
						<img src={this.state.logo === "" ? "/logo cloud on the cloud.png" : this.state.logo} className="img-fluid" style={{ marginBottom: 10, "max-width" : "200px", "max-height" : "50px"}} />
					</a>
					<p style={{ marginBottom: 0 }}>
						{this.state.locationID === -1 ? '© 2022 Cloudonthecloud LTD - MT21680902 | Powered by Cloudonthecloud | Cloudonthecloud All Rights Reserved.' : this.state.footer}
					</p>
					<p>
						{this.state.locationID === -1 ? <b style={{cursor:"pointer"}}><a onClick = {this.privacyOpen}>Privacy Policy</a> || <a onClick = {this.contractOpen}>Terms of Service </a> || <a onClick = {this.tldOpen}>Add Domains Conditions </a></b>: null}
					</p>
				</footer>
			</div>
		);
	}
}

export default Footer;
